import React, { useContext, useEffect } from "react";

import { Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toastFailed, toastSuccess } from "../../../bootstrap/config";
import AdminModal from "../../AdminModal";
import PeoplePicker from "../PeoplePicker";
import TextInput from "../TextInput";
import Datepicker from "../Datepicker";
import Dropdown from "../Dropdown";
import SignatureApproval from "../SignatureApproval";
import { ModalContext } from "../../../contexts/ModalProvider";

const TableInputModal = ({
  dataValues,
  setdataValues,
  selectedDataValue,
  name,
  columns,
  onChange,
}) => {
  const { modalState, setModalState, modalScope, setModalScope } = useContext(ModalContext);
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: "all",
  });
  const toast = useToast();

  useEffect(() => {
    if (selectedDataValue !== "") {
      reset(selectedDataValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataValue]);

  const handleAddRow = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        if (Array.isArray(dataValues)) {
          dataValues.push(values)
        } else {
          dataValues = [values];
        }
        setdataValues(dataValues);
        onChange({ target: { name, value: dataValues } });
        toast({ ...toastSuccess, description: "Entry added" });
        reset({});
      } else {
        toast({
          ...toastFailed,
          description: "Please complete all the required fields",
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
      setModalScope(undefined);
    }
  };

  const handleUpdateRow = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        delete values.index;
        let updatedData = [...dataValues];
        updatedData[selectedDataValue.index] = values;
        setdataValues(updatedData);
        onChange({ target: { name, value: updatedData } });
        toast({ ...toastSuccess, description: "Entry updated" });
        reset({});
      } else {
        toast({
          ...toastFailed,
          description: "Please complete all the required fields",
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
      setModalScope(undefined);
    }
  };

  const handleDeleteRow = async () => {
    try {
      let updatedData = dataValues.filter(
        (p, index) => index !== selectedDataValue.index
      );
      setdataValues(updatedData);
      onChange({ target: { name, value: updatedData } });
      toast({ ...toastSuccess, description: "Entry deleted" });
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
      setModalScope(undefined);
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    if (["add", "edit"].includes(action) && !isFormValid) {
      return toast({
        ...toastFailed,
        description: "Please complete all the required fields",
      });
    }
    switch (action) {
      case "add":
        handleAddRow();
        break;
      case "edit":
        handleUpdateRow();
        break;
      case "delete":
        handleDeleteRow();
        break;
      default:
        setModalState("closed");
        setModalScope(undefined);
    }
  };
  return (
    <AdminModal
      isOpenModal={modalState !== "closed" && modalScope === name}
      modalType={modalState}
      onAction={handleAction}
      collection={"entry"}
    >
      <Stack align="flex-start" direction="column" w="full">
        {columns?.map(({ type, value, label, options, variant, placeholder }, index) => {
          if (type === 'textInput') {
            return (
              <TextInput
                key={`field-${index}`}
                control={control}
                label={label}
                name={value}
                options={options}
                variant={variant}
                placeholder={placeholder}
              />
            )
          } else if (type === 'datepicker') {
            return (
              <Datepicker
                key={`field-${index}`}
                control={control}
                label={label}
                name={value}
                options={options}
                canSelectFutureDate={true}
                variant={variant}
              />
            )
          } else if (type === 'dropdown') {
            return (
              <Dropdown
                key={`field-${index}`}
                control={control}
                label={label}
                name={value}
                options={options}
                placeholder="Select"
              />
            )
          } else if (type === 'peoplePicker') {
            return (
              <PeoplePicker
                key={`field-${index}`}
                control={control}
                label={label}
                name={value}
                variant={variant}
              />
            )
          } else if (type === 'signature') {
            return (
              <SignatureApproval
                key={`field-${index}`}
                control={control}
                label={label}
                name={value}
                variant={variant}
              />
            )
          }
          return null;
        })}
      </Stack>
    </AdminModal>
  );
};

export default TableInputModal;
