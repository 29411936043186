import { Box, Button, Flex, HStack, Menu, MenuButton, MenuList, Spacer, Text } from '@chakra-ui/react';

import { ChevronDown } from '../icons';

const SortButton = ({
  sortBy,
  sortOrder,
  sortType,
  setSortType,
  setSortOrder,
  ml,
}: {
  sortBy: { label: string; key: string }[];
  sortOrder: 'asc' | 'desc';
  sortType: string;
  setSortType: (key: string) => void;
  setSortOrder: (order: 'asc' | 'desc') => void;
  ml?: number | string | {};
}) => (
  <Box ml={ml ?? 4}>
    <Menu autoSelect={false} >
      {({ onClose }) => (
        <>
          <MenuButton
            _active={{}}
            _hover={{}}
            as={Button}
            bg="white"
            fontSize="smm"
            fontWeight="800"
            h="34px"
            ml={['15px', '0']}
            px={3}
            rightIcon={<ChevronDown h="12px" w="12px" />}
            rounded="6px">
            <Flex align="center" mr="1">
              <Text fontSize="smm" fontWeight="semi_medium">
                Sort by
              </Text>
            </Flex>
          </MenuButton>
          <MenuList
            border="none"
            boxShadow="simple"
            rounded="lg"
            w="100px"
            zIndex={2}>
            {sortBy.map((sortItem) => (
              <Flex key={sortItem.key} px={3} py={2}>
                <Box >
                  <Text color="sortButton.menuItemFont" fontSize="14px">
                    {sortItem.label}
                  </Text>
                </Box>
                <Spacer />
                <HStack >
                  <Flex
                    justify="center"
                    align="center"
                    bg={sortType === sortItem.key && sortOrder === 'asc' ? 'sortButton.menuItemFocus' : undefined}
                    h="30px"
                    onClick={() => {
                      setSortType(sortItem.key);
                      setSortOrder('asc');
                      onClose();
                    }}
                    rounded="5px"
                    w="30px">
                    <ChevronDown
                      color={sortType === sortItem.key && sortOrder === 'asc' ? 'sortButton.icon.active' : 'sortButton.icon.default'}
                      cursor="pointer"
                      h="12px"
                      transform="rotate(180deg)"
                      w="12px"
                    />
                  </Flex>
                  <Spacer />
                  <Flex
                    align="center"
                    bg={sortType === sortItem.key && sortOrder === 'desc' ? 'sortButton.menuItemFocus' : undefined}
                    h="30px"
                    justify="center"
                    onClick={() => {
                      setSortType(sortItem.key);
                      setSortOrder('desc');
                      onClose();
                    }}
                    rounded="5px"
                    w="30px"
                  >
                    <ChevronDown
                      color={sortType === sortItem.key && sortOrder === 'desc' ? 'sortButton.icon.active' : 'sortButton.icon.default'}
                      cursor="pointer"
                      h="12px"
                      w="12px"
                    />
                  </Flex>
                </HStack>
              </Flex>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  </Box>
);

export default SortButton;

export const sortButtonStyles = {
  sortButton: {
    menuButtonBg: 'white',
    rightIcon: '#9A9EA1',
    icon: {
      default: '#9A9EA1',
      active: '#FFF',
    },
    menuItemFocus: '#462AC4',
    menuItemFontSelected: '#462AC4',
    // menuItemFont: '#9A9EA1',
  },
};
