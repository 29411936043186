import { gql } from '@apollo/client';

const GET_COMMON_FORMS = gql`
  query ($formTemplatesQueryInput: FormTemplatesQueryInput) {
    getFormTemplates(formTemplatesQueryInput: $formTemplatesQueryInput) {
      _id
      name
      icon
      isCommonForm
      pages {
        _id
        index
        name
        sections {
          _id
          index
          name
          description
          rows {
            _id
            index
            fields {
              _id
              name
              type
              label
              required
              validations
              variant
              placeholder
              tooltip
              canSelectFutureDate
              clearAfterEdit
              options {
                value
                label
              }
              image
              rows {
                value
                label
              }
              columns {
                type
                value
                label
                options {
                  value
                  label
                }
                variant
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_COMMON_FORMS;
