import { createIcon } from '@chakra-ui/icons';

const DotsHorizontal = createIcon({
  viewBox: '0 0 3 14',
  path: (
    <>
      <path
        d='M3 11.668C3 10.8395 2.32843 10.168 1.5 10.168C0.671573 10.168 3.05336e-07 10.8395 1.96701e-07 11.668C8.80661e-08 12.4964 0.671573 13.168 1.5 13.168C2.32843 13.168 3 12.4964 3 11.668Z'
        fill="currentColor"
      />
      <path
        d='M3 7.00049C3 6.17206 2.32843 5.50049 1.5 5.50049C0.671573 5.50049 3.05336e-07 6.17206 1.96701e-07 7.00049C8.80661e-08 7.82892 0.671572 8.50049 1.5 8.50049C2.32843 8.50049 3 7.82892 3 7.00049Z'
        fill="currentColor"
      />
      <path
        d='M3 2.33203C3 1.50361 2.32843 0.832033 1.5 0.832032C0.671573 0.832032 3.05336e-07 1.5036 1.96701e-07 2.33203C8.80661e-08 3.16046 0.671572 3.83203 1.5 3.83203C2.32843 3.83203 3 3.16046 3 2.33203Z'
        fill="currentColor"
      />
    </>
  ),
});

export default DotsHorizontal;
