import {
  Box,
  Flex,
  IconButton,
  Text,
  Image,
  Input,
  Spacer,
  Textarea,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../contexts/AppProvider";
import { DeleteIcon } from "../../../icons";
import {
  getFileExtension,
  imageToBase64,
  loadSavedImageByID,
} from "../../../utils/helpers";
import ConfirmationModal from "../../ConfirmationModal";
import Loader from "../../Loader";

const PhotoRenderer = ({
  index,
  error,
  photo,
  onOpen,
  selectedPhoto,
  setSelectedPhoto,
  onChange,
  uploadedImagesBase64,
  setUploadedImagesBase64,
  validations,
  name = "photos",
  disabled = false,
  isUploading = false,
  formIndex,
  displayTileDescription = true,
}): JSX.Element => {
  const [image, setImage] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);
  const [fetchingImageTimeout, setFetchingImageTimeout] = useState<any>(null);
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();
  const { settings } = useAppContext();

  const onTextFieldChange = (
    data: any,
    index: number,
    onChange: any,
    updatedField: "title" | "description"
  ) => {
    let updatedUploadedImagesBase64 = [...uploadedImagesBase64];
    let fileName = updatedUploadedImagesBase64[index].fileName;
    let extension = getFileExtension(fileName);
    let newName: string = "";
    if (updatedField === "title")
      newName =
        data.length > 0
          ? `${data}.${extension}`
          : updatedUploadedImagesBase64[index].backupFileName;
    else newName = `${fileName}`;
    updatedUploadedImagesBase64[index] = {
      title:
        updatedField === "title"
          ? data
          : updatedUploadedImagesBase64[index].title,
      description:
        updatedField === "description"
          ? data
          : updatedUploadedImagesBase64[index].description,
      fileName: `${newName}`,
      fileId: updatedUploadedImagesBase64[index].fileId,
      backupFileName: updatedUploadedImagesBase64[index].backupFileName,
    };
    // Update the fileName on SP after 2 seconds of no keystroke

    onChange({ target: { name, value: updatedUploadedImagesBase64 } });
    setUploadedImagesBase64(updatedUploadedImagesBase64);

    clearTimeout(typingTimeout);
    let temp = setTimeout(
      async () => await updateName(updatedUploadedImagesBase64[index]),
      1500
    );
    setTypingTimeout(temp);
  };

  useEffect(() => {
    return () => {
      setImage('')
    }
  }, [formIndex])

  useEffect(() => {
    if (!isUploading) {
      setTitle(photo.title)
      setDescription(photo.description)
      clearTimeout(fetchingImageTimeout);
      let temp = setTimeout(() => getImageFromSP(), 1000);
      setFetchingImageTimeout(temp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo, settings]);

  const getImageFromSP = async () => {
    setLoading(true);
    try {
      let url = await loadSavedImageByID(
        photo.fileId,
        settings?.spSiteUrl || '',
        settings?.spDocumentLibrary || '',
      );
      setImage(url);
    } catch {
      setLoadingFailed(true);
    }
    setLoading(false);
  };

  const updateName = async (item) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/files/updateFileName`, {
      params: {
        name: `${item.fileName}`,
        spSiteUrl: settings?.spSiteUrl || '',
        spDocumentLibrary: settings?.spDocumentLibrary || '',
        fileId: item.fileId,
        prevName: item.backupFileName,
      },
      withCredentials: true,
    });
  };

  const onRemove = (photo) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/files/removeFile`, {
      params: {
        fileId: photo.fileId,
        spSiteUrl: settings?.spSiteUrl || '',
        spDocumentLibrary: settings?.spDocumentLibrary || '',
      },
      withCredentials: true,
    });
    const updatedUploadedImagesBase64 = uploadedImagesBase64.filter(
      (p) => p.fileId !== photo.fileId
    );
    if (selectedPhoto === photo) {
      setSelectedPhoto(null);
    }
    onChange({ target: { name, value: updatedUploadedImagesBase64 } });
    setUploadedImagesBase64(updatedUploadedImagesBase64);
  };

  let { backupFileName, fileId, fileName } = photo;
  return (
    <Flex>
      <Box w="full" maxW="345px" h="200px" mt="20px" position="relative">
        <Text
          mt="10px"
          mb="4px"
          color="switch.label.normal"
          fontWeight="bold"
          fontSize="ssm"
          left="none"
          zIndex={1}
        />
        <Box
          maxW="345px"
          h="161px"
          p="5px"
          border="1px"
          borderColor={
            error ? "textInput.border.error" : "textInput.border.normal"
          }
          borderRadius="8px"
          borderWidth="1px"
        >
          {{ photo } ? (
            <>
              <Box position="absolute" top="12px" right="5px" zIndex={2}>
                {!disabled &&
                  <IconButton
                    bg="white"
                    aria-label="delete"
                    size="xs"
                    isRound={true}
                    borderWidth={1}
                    borderColor="textInput.border.normal"
                    _hover={{ color: "brePink" }}
                    icon={
                      <DeleteIcon
                        h="15"
                        w="15"
                        onClick={onConfirmationOpen}
                      />
                    }
                  />
                }
              </Box>
              <Flex
                w="full"
                h="full"
                alignContent="center"
                justifyContent="center"
              >
                {loading ?
                  <Flex w="full" h="full" align="center" justify="center">
                    <Spinner size="xl" thickness="4px" />
                  </Flex>
                  : loadingFailed ?
                    <Flex w="full" h="full" align="center" justify="center">
                      Failed to load photo
                    </Flex>
                    :
                    <Image
                      h="151px"
                      maxW="335px"
                      cursor={disabled ? "not-allowed" : "pointer"}
                      fit="contain"
                      src={image}
                      onClick={async () => {
                        if (!disabled) {
                          let imageData = await imageToBase64(image);
                          await setSelectedPhoto({
                            imageData,
                            index,
                            title,
                            description,
                            backupFileName,
                            fileId,
                            fileName,
                          });
                          onOpen();
                        }
                      }}
                    />
                }
              </Flex>
            </>
          ) : (
            <Loader size="sm" />
          )}
        </Box>
      </Box>
      {displayTileDescription && <Box pt="5px" pb={1} mb="none" w="full" ml="10px">
        <Text
          my="4px"
          color={
            error ? "textInput.labelFont.error" : "textInput.labelFont.normal"
          }
          fontWeight="bold"
          fontSize="11px"
          position="static"
          left="none"
          zIndex={2}
        >
          Title and Description
        </Text>
        <Input
          maxW="345px"
          borderRadius="8px"
          borderWidth="1px"
          h="40px"
          type="text"
          fontSize="smm"
          color="textInput.font"
          bg="textInput.bg"
          name="title"
          defaultValue={title}
          borderColor={
            error ? "textInput.border.error" : "textInput.border.normal"
          }
          _active={{
            bg: disabled ? "textInput.disabled.bg" : "textInput.activeBg",
          }}
          _focus={{
            borderColor: error
              ? "textInput.border.focus.error"
              : "textInput.border.focus.normal",
          }}
          _hover={{ cursor: "auto" }}
          onChange={(e) =>
            onTextFieldChange(e.target.value, index, onChange, "title")
          }
          isDisabled={disabled}
          cursor="pointer"
          _disabled={{
            bg: "textInput.disabled.bg",
            color: "textInput.disabled.font",
            borderColor: "textInput.disabled.border",
            cursor: "not-allowed",
          }}
          // can't be dynamic because of separate component, 200 character limit for SP File name
          maxLength={180}
          placeholder="Title"
          _placeholder={{ fontSize: "smm", color: "textInput.placeholder" }}
          css={{
            ".chakra-switch__thumb": {
              "&[data-checked]": {
                background: "#462AC4",
              },
            },
          }}
        />
        <Spacer mt="10px" />
        <Textarea
          maxW="345px"
          borderRadius="8px"
          borderWidth="1px"
          h="111px"
          fontSize="smm"
          color="textInput.font"
          bg="textInput.bg"
          name="description"
          defaultValue={description}
          borderColor={
            error ? "textInput.border.error" : "textInput.border.normal"
          }
          _active={{
            bg: disabled ? "textInput.disabled.bg" : "textInput.activeBg",
          }}
          _focus={{
            borderColor: error
              ? "textInput.border.focus.error"
              : "textInput.border.focus.normal",
          }}
          _hover={{ cursor: "auto" }}
          onChange={(e) =>
            onTextFieldChange(e.target.value, index, onChange, "description")
          }
          isDisabled={disabled}
          cursor="pointer"
          _disabled={{
            bg: "textInput.disabled.bg",
            color: "textInput.disabled.font",
            borderColor: "textInput.disabled.border",
            cursor: "not-allowed",
          }}
          maxLength={
            validations && validations.forceMaxLength
              ? (validations.maxLength as number)
              : undefined
          }
          placeholder="Add your description here"
          _placeholder={{ fontSize: "smm", color: "textInput.placeholder" }}
          css={{
            ".chakra-switch__thumb": {
              "&[data-checked]": {
                background: "#462AC4",
              },
            },
          }}
        />
      </Box>}
      <ConfirmationModal modalText={`Do you wish to delete this image?`} isOpen={isConfirmationOpen} onClose={onConfirmationClose} confirmAction={() => onRemove(photo)} />
    </Flex>
  );
};

export default PhotoRenderer;
