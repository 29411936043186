import {
  Accordion,
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '../../icons';
import { useQuery } from '@apollo/client';
import Loader from '../../components/Loader';
import { useEffect, useState } from 'react';
import GET_PROJECT_TEMPLATES_CATEGORIES from '../../gql/queries/GET_PROJECT_TEMPLATES_CATEGORIES';
import TemplatesCategory from '../../components/Templates/TemplatesCategory';
import { IProjectTemplateCategory } from '../../interfaces/IProjectTemplateCategory';
import NewTemplateModal from '../../components/Templates/NewTemplateModal';
import Can from '../../components/Can';

const Categories = () => {
  const [copyTemplate, setCopyTemplate] = useState<boolean>(false);
  const { data, loading, refetch } = useQuery(
    GET_PROJECT_TEMPLATES_CATEGORIES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [displayCopyFromDropDown, setDisplayCopyFromDropDown] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  let categories: IProjectTemplateCategory[] = (data?.projectTemplatesCategories || []).sort((a, b) => a._id.localeCompare(b._id));

  useEffect(() => {
    setDisplayCopyFromDropDown(Boolean(copyTemplate));
  }, [copyTemplate]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <NewTemplateModal isOpen={isOpen} onClose={onClose} />
      <Box overflowY='auto' overflowX='hidden' pr={4}>
        <HStack pb={6} justifyContent='space-between'>
          <Text fontSize='24px' fontWeight='700'>
            Templates
          </Text>
          <Can
            action="projectTemplates.create"
            data={{ projectTemplate: {} }}
            yes={() =>
              <Button
                h='32px'
                bg='brePink'
                color='white'
                fontSize='ssm'
                fontWeight='bold'
                alignItems='center'
                p={2}
                onClick={onOpen}
                leftIcon={<PlusIcon w='11px' h='11px' />}
                minW="none"
              >Create new Template</Button>
            }
          />
        </HStack>
        <Flex>
          <Accordion allowMultiple w='full'>
            {categories.map(category => <TemplatesCategory key={category._id} category={category} />)}
            {!categories?.length && (
              <Text>No template has been created yet.</Text>
            )}
          </Accordion>
        </Flex>
      </Box>
    </>
  );
};

export default Categories;
