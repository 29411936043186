import {
  As,
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getIcon, InfinityIcon, PlusIcon } from '../../icons';
import { useForm } from 'react-hook-form';
import { IFormTemplate } from '../../interfaces/IFormTemplate';
import { Checkbox, Dropdown, TextInput } from '../../components/FormControls';
import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { useRef, useState, useEffect } from 'react';
import CREATE_FORM_TEMPLATE from '../../gql/mutation/CREATE_FORM_TEMPLATE';
import UPDATE_FORM_TEMPLATE from '../../gql/mutation/UPDATE_FORM_TEMPLATE';
import DELETE_FORM_TEMPLATE from '../../gql/mutation/DELETE_FORM_TEMPLATE';
import IconPickerControl from '../../components/FormControls/IconPickerControl';
import TemplateHeader from '../../components/TemplateBuilder/TemplateHeader';
import Loader from '../../components/Loader';
import ConfirmationModal from '../../components/ConfirmationModal';
import TemplateContent from '../../components/TemplateBuilder/TemplateContent';
import TemplateForms from '../../components/TemplateBuilder/TemplateForms';
import useGetProjectTemplate from '../../hooks/useGetProjectTemplate';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import GET_COMMON_FORMS from '../../gql/queries/GET_COMMON_FORMS';
import { EOperationType } from '../../interfaces/EOperationType';
import usePrompt from '../../hooks/usePrompt';
import { unsavedChangesMessage } from '../../utils/defaults';

const Template = () => {
  const [editForm, setEditForm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstRender = useRef(true);
  const { isOpen: isConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();

  const { projectTemplateLoading, refetchProjectTemplate } = useGetProjectTemplate({ _id: id }, true);
  const {
    currentProjectTemplate, setCurrentProjectTemplate,
    currentProjectFormTemplate, setCurrentProjectFormTemplate,
    operationType, setOperationType,
    fieldsInEdit,
  } = useProjectsContext();
  usePrompt(fieldsInEdit.length > 0, unsavedChangesMessage);

  const [createFormTemplate] = useMutation(CREATE_FORM_TEMPLATE);
  const [updateFormTemplate] = useMutation(UPDATE_FORM_TEMPLATE);
  const [deleteFormTemplate] = useMutation(DELETE_FORM_TEMPLATE);

  useEffect(() => {
    return () => {
      setOperationType(EOperationType.EMPTY);
      setCurrentProjectFormTemplate(null);
      setCurrentProjectTemplate(null);
    };
  }, [setCurrentProjectFormTemplate, setCurrentProjectTemplate, setOperationType]);

  const { data } = useQuery(
    GET_COMMON_FORMS,
    {
      variables: {
        formTemplatesQueryInput: {
          isCommonForm: true,
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  const commonForms = data?.getFormTemplates || [];

  useEffect(() => {
    // Open a form on page load
    if (currentProjectTemplate?.formTemplates && firstRender.current) {
      if (currentProjectTemplate.formTemplates.length > 0) {
        setCurrentProjectFormTemplate(currentProjectTemplate?.formTemplates[0]);
      }
      firstRender.current = false;
      setLoading(false);
    }
  }, [currentProjectTemplate, setCurrentProjectFormTemplate]);

  const resetForm = () => {
    setEditForm('');
    reset({
      name: '',
      allowMultipleIterations: [true],
      icon: 'InfinityIcon',
      copyFromCommonForm: [],
      commonFormId: '',
    });
  };

  const { control, getValues, reset, setValue, watch } = useForm({
    mode: 'all',
    defaultValues: {
      _id: '',
      name: '',
      allowMultipleIterations: [true],
      icon: 'InfinityIcon',
      copyFromCommonForm: [] as boolean[],
      commonFormId: '',
    },
  });
  const formValues = watch();

  const createFormTemp = async () => {
    const values = getValues();
    const copyFromCommonForm = formValues.copyFromCommonForm[0] || false;

    if (!copyFromCommonForm && !Boolean(values.name.length)) {
      toast({ ...toastFailed, description: 'Enter form name' });
      return;
    }

    if (copyFromCommonForm && !Boolean(values.commonFormId.length)) {
      toast({ ...toastFailed, description: 'Select common form' });
      return;
    }

    const formTemplateInput: Partial<IFormTemplate> = {
      projectTemplateId: id,
      index: currentProjectTemplate?.formTemplates.length || 0,
      allowMultipleIterations: values.allowMultipleIterations[0] || false,
    };

    if (copyFromCommonForm) {
      formTemplateInput.commonFormId = values.commonFormId;
    } else {
      formTemplateInput.name = values.name;
      formTemplateInput.icon = values.icon;
    }
    await createFormTemplate({
      variables: { formTemplateInput },
    });
    await refetchProjectTemplate();
    toast({ ...toastSuccess, description: 'Form created successfully' });
    resetForm();
    onClose();

    return null;
  };

  const editFormTemp = async () => {
    const values = getValues();

    if (Boolean(values.name.length)) {
      await updateFormTemplate({
        variables: {
          formTemplateInput: {
            _id: values._id,
            name: values.name,
            icon: values.icon,
            allowMultipleIterations: values.allowMultipleIterations[0] || false,
          },
          operationType
        },
      });
      await refetchProjectTemplate();
      toast({ ...toastSuccess, description: 'Form updated successfully' });
    } else {
      toast({ ...toastFailed, description: 'Enter form name' });
    }
    resetForm();
    onClose();
    setEditForm('');

    return null;
  };

  const deleteFormTemp = async (_id) => {
    await deleteFormTemplate({
      variables: {
        formTemplateInput: { _id, projectTemplateId: id },
      },
    });
    await refetchProjectTemplate();
    onClose();
    toast({ ...toastSuccess, description: 'Form deleted successfully' });
  };

  const getDefaultIcon = (): As<any> => {
    if (control._formValues.icon) {
      return getIcon[control._formValues.icon];
    }
    return InfinityIcon;
  };

  const formsMoved = async (event) => {
    if (!event.source || !event.destination) {
      return;
    }
    const formTemplates = [...(currentProjectTemplate?.formTemplates || [])];
    const [removed] = formTemplates.splice(event.source.index, 1);
    formTemplates.splice(event.destination.index, 0, removed);

    setCurrentProjectTemplate(template => {
      if (!template) return null;
      return {
        ...template,
        formTemplates
      };
    });

    await Promise.all(formTemplates.map(async (formTemplate, index) => {
      await updateFormTemplate({
        variables: {
          formTemplateInput: {
            _id: formTemplate._id,
            index,
          },
          operationType: EOperationType.REORDERED_PAGES,
        },
      });
    }));

    await refetchProjectTemplate();
  };

  const onEditForm = (form) => {
    setEditForm(form._id);
    setValue('_id', form._id);
    setValue('name', form.name);
    setValue('allowMultipleIterations', form.allowMultipleIterations ? [true] : []);
    setValue('icon', form.icon);
    onOpen();
  };

  if (projectTemplateLoading) {
    return <Loader />;
  }

  return (
    <Box p={2} pr={6}>
      <TemplateHeader refetchTemplate={refetchProjectTemplate} />
      <Flex w='full'>
        <Flex w='full'>
          <TemplateForms
            onDragEnd={formsMoved}
            onEditForm={onEditForm}
            deleteForm={deleteFormTemp}
            addForm={onOpen}
          />
          <Box w="calc(80% - 1rem)">
            <TemplateContent
              form={currentProjectFormTemplate}
              loading={loading}
              header={
                <Text fontWeight='900' fontSize='md'>
                  {currentProjectFormTemplate?.name}
                </Text>
              }
            />
          </Box>
        </Flex>
        <Modal
          onClose={() => {
            if (editForm) {
              resetForm();
            }
            onClose();
          }}
          isOpen={isOpen}
          isCentered
          size='lg'
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{editForm ? 'Edit Form' : 'Add new form'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex alignItems='center'>
                <Flex flexDirection='column' mr='4'>
                  <Text
                    fontWeight='bold'
                    fontSize='11px'
                    position='static'
                    left='none'
                    zIndex={1}
                    color='textInput.labelFont.normal'
                    pb='1'
                    pl='1'
                  >
                    Icon
                  </Text>
                  <Popover placement='bottom-start'>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Box
                            px='3'
                            py='2'
                            borderRadius='6px'
                            borderColor='textInput.border.normal'
                            borderWidth='1px'
                            borderStyle='solid'
                            cursor={formValues.copyFromCommonForm[0] ? 'not-allowed' : 'pointer'}
                            onClick={(e) => {
                              if (formValues.copyFromCommonForm[0]) {
                                e.preventDefault();
                              }
                            }}
                            opacity={formValues.copyFromCommonForm[0] ? 0.5 : 1}
                          >
                            <Icon as={getDefaultIcon()} />
                          </Box>
                        </PopoverTrigger>
                        <PopoverContent w='fit-content'>
                          <PopoverBody>
                            <IconPickerControl
                              onClick={(value: string) => {
                                setValue('icon', value);
                                onClose();
                              }}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </>
                    )}
                  </Popover>
                </Flex>
                <TextInput
                  control={control}
                  name='name'
                  required={true}
                  label='Title'
                  placeholder='Title'
                  validations={{ notEmpty: true }}
                  disabled={formValues.copyFromCommonForm[0]}
                />
              </Flex>
              <Checkbox
                control={control}
                name='allowMultipleIterations'
                options={[{ label: 'Form can be completed more than once - i.e. multiple test runs', value: true }]}
              />
              {!editForm && (
                <>
                  <Checkbox
                    control={control}
                    name='copyFromCommonForm'
                    options={[{ label: 'Duplicate content from common forms', value: true }]}
                  />
                  {formValues.copyFromCommonForm[0] && (
                    <Dropdown
                      control={control}
                      name="commonFormId"
                      label="Common form"
                      options={commonForms.map(form => ({ label: form.name, value: form._id }))}
                      styles={{ fullWidth: true }}
                    />
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                  resetForm();
                }}
                mr='2'
              >
                Discard
              </Button>
              <Button
                onClick={editForm ? editFormTemp : createFormTemp}
                bg='brePink'
                color='white'
                _hover={{}}
                _focus={{}}
                _active={{}}
              >
                {!editForm && <PlusIcon stroke='white' mr='2' />}
                {editForm ? 'Save' : 'Create'} form
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <ConfirmationModal
        modalTitle={`Delete ${currentProjectFormTemplate?.name}?`}
        modalText={'Do you wish to delete this form?'}
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        confirmAction={() => deleteFormTemp(currentProjectFormTemplate?._id)}
      />
    </Box>
  );
};

export default Template;
