import { createIcon } from '@chakra-ui/icons';

const StarIcon = createIcon({
  path: (
    <path
      d='M5.43035 11.9354L4.18868 17.3121C4.15023 17.4749 4.16177 17.6455 4.2218 17.8017C4.28183 17.9578 4.38755 18.0922 4.52516 18.1874C4.66276 18.2825 4.82586 18.3339 4.99314 18.3349C5.16042 18.3359 5.32412 18.2864 5.46285 18.1929L10.0003 15.1679L14.5378 18.1929C14.6798 18.2872 14.8472 18.3357 15.0175 18.332C15.1879 18.3282 15.353 18.2724 15.4907 18.172C15.6284 18.0717 15.732 17.9315 15.7877 17.7705C15.8433 17.6095 15.8483 17.4352 15.802 17.2713L14.2778 11.9379L18.0578 8.53626C18.1789 8.42725 18.2654 8.28515 18.3065 8.12752C18.3477 7.9699 18.3417 7.80366 18.2894 7.64938C18.2371 7.4951 18.1407 7.35953 18.0122 7.25945C17.8836 7.15937 17.7286 7.09915 17.5662 7.08626L12.8153 6.70793L10.7595 2.1571C10.694 2.01056 10.5874 1.88612 10.4527 1.79881C10.318 1.7115 10.1609 1.66504 10.0003 1.66504C9.83982 1.66504 9.68272 1.7115 9.54801 1.79881C9.4133 1.88612 9.30673 2.01056 9.24118 2.1571L7.18535 6.70793L2.43451 7.08543C2.27489 7.09807 2.1223 7.15644 1.99498 7.25354C1.86766 7.35064 1.77101 7.48236 1.71659 7.63295C1.66218 7.78354 1.6523 7.94662 1.68813 8.10268C1.72397 8.25874 1.80401 8.40117 1.91868 8.51293L5.43035 11.9354ZM7.80785 8.33043C7.95659 8.31871 8.09945 8.26719 8.22143 8.18127C8.34342 8.09536 8.44005 7.9782 8.50118 7.8421L10.0003 4.52459L11.4995 7.8421C11.5606 7.9782 11.6573 8.09536 11.7793 8.18127C11.9012 8.26719 12.0441 8.31871 12.1928 8.33043L15.5028 8.59293L12.777 11.0463C12.5403 11.2596 12.4462 11.5879 12.5328 11.8946L13.577 15.5488L10.4637 13.4729C10.327 13.3812 10.1662 13.3322 10.0016 13.3322C9.83702 13.3322 9.67617 13.3812 9.53951 13.4729L6.28618 15.6421L7.16118 11.8538C7.19326 11.7144 7.18896 11.5692 7.14868 11.432C7.1084 11.2948 7.0335 11.1703 6.93118 11.0704L4.39951 8.60209L7.80785 8.33043Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default StarIcon;
