export enum EOperationType {
  EMPTY = '',
  ADDED_PAGE = 'added.page',
  ADDED_SECTION = 'added.section',
  ADDED_ROW = 'added.row',
  ADDED_FIELD = 'added.field',
  UPDATED_PAGE = 'updated.page',
  UPDATED_SECTION = 'updated.section',
  UPDATED_ROW = 'updated.row',
  UPDATED_FIELD = 'updated.field',
  DELETED_PAGE = 'deleted.page',
  DELETED_SECTION = 'deleted.section',
  DELETED_ROW = 'deleted.row',
  DELETED_FIELD = 'deleted.field',
  DUPLICATED_PAGE = 'duplicated.page',
  REORDERED_FORMS = 'reordered.form',
  REORDERED_PAGES = 'reordered.page',
  REORDERED_SECTIONS = 'reordered.section',
  REORDERED_ROWS = 'reordered.row',
  REORDERED_FIELDS = 'reordered.field',
}
