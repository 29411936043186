import { useEffect } from 'react';
import { ChakraProvider, CSSReset, Flex, Spinner } from '@chakra-ui/react';
import { Route, Switch, useHistory } from 'react-router-dom';

import './styles.css';
import useAuth from '../hooks/useAuth';
import useRoutes from '../hooks/useRoutes';
import IdleMonitor from '../components/IdleMonitor';
import AppProvider, { useAppContext } from '../contexts/AppProvider';
import FiltersProvider from '../contexts/FiltersProvider';
import SearchProvider from '../contexts/SearchProvider';
import ProjectsProvider from '../contexts/ProjectsProvider';
import theme from './theme';
import ModalProvider from '../contexts/ModalProvider';
import AuditFiltersProvider from '../contexts/AuditFiltersProvider';

function App() {
  const { user, roles } = useAppContext();
  const loadingUser = useAuth();
  const routes = useRoutes();
  const history = useHistory();

  useEffect(() => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    if (redirectUrl) {
      localStorage.removeItem('redirectUrl');
      history.push(redirectUrl);
    }
    // eslint-disable-next-line
  }, []);

  if (user === undefined || loadingUser || roles === undefined) {
    return (
      <ChakraProvider>
        <Flex w='100vw' h='100vh' alignItems='center' justifyContent='center'>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='brand.primary'
            size='xl'
          />
        </Flex>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      {user && <IdleMonitor />}
      <ModalProvider>
        <FiltersProvider>
          <AuditFiltersProvider>
            <SearchProvider>
              <ProjectsProvider>
                <Switch>
                  {routes.map((props) => (
                    <Route {...props} />
                  ))}
                </Switch>
              </ProjectsProvider>
            </SearchProvider>
          </AuditFiltersProvider>
        </FiltersProvider>
      </ModalProvider>
    </ChakraProvider>
  );
}

const AppWithContext = () => (
  <AppProvider>
    <App />
  </AppProvider>
);

export default AppWithContext;
