import { createIcon } from "@chakra-ui/icons";

const ListIcon = createIcon({
  displayName: "Grid",
  viewBox: "0 0 21 12",
  path: (
    <>
      <path d="M6 1H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1H3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 5.87158H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 5.87158H3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 10.7432H20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 10.7432H3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default ListIcon;
