import { createIcon } from '@chakra-ui/icons';

const PeerReviewIcon = createIcon({
  displayName: 'PeerReview',
  viewBox: '0 0 14 14',
  path: (
    <>
      <path
        d='M2.08057 5.05564C2.08057 5.62291 2.32845 6.16694 2.76968 6.56806C3.21091 6.96918 3.80935 7.19453 4.43334 7.19453C5.05734 7.19453 5.65578 6.96918 6.09701 6.56806C6.53824 6.16694 6.78612 5.62291 6.78612 5.05564C6.78612 4.48837 6.53824 3.94433 6.09701 3.54321C5.65578 3.14209 5.05734 2.91675 4.43334 2.91675C3.80935 2.91675 3.21091 3.14209 2.76968 3.54321C2.32845 3.94433 2.08057 4.48837 2.08057 5.05564V5.05564Z'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.583313 11.4724C0.583313 10.5442 0.988937 9.65392 1.71095 8.99754C2.43297 8.34116 3.41223 7.97241 4.43331 7.97241C5.4544 7.97241 6.43366 8.34116 7.15567 8.99754C7.87769 9.65392 8.28331 10.5442 8.28331 11.4724'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.34149 6.22241C8.34149 6.68654 8.5443 7.13166 8.90531 7.45985C9.26632 7.78804 9.75595 7.97241 10.2665 7.97241C10.777 7.97241 11.2667 7.78804 11.6277 7.45985C11.9887 7.13166 12.1915 6.68654 12.1915 6.22241C12.1915 5.75828 11.9887 5.31316 11.6277 4.98498C11.2667 4.65679 10.777 4.47241 10.2665 4.47241C9.75595 4.47241 9.26632 4.65679 8.90531 4.98498C8.5443 5.31316 8.34149 5.75828 8.34149 6.22241V6.22241Z'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1748 8.78543C9.65111 8.62584 10.1628 8.5732 10.6663 8.63198C11.1698 8.69077 11.6502 8.85924 12.0667 9.12306C12.4832 9.38688 12.8234 9.73826 13.0584 10.1473C13.2935 10.5563 13.4164 11.0109 13.4166 11.4724'
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});

export default PeerReviewIcon;
