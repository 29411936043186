import { useCallback, useEffect, useMemo } from 'react';

import { Button, Menu, MenuButton, MenuItem, MenuList, Stack } from '@chakra-ui/react';
import { TViewMode } from '../../interfaces/TViewMode';
import { useAppContext } from '../../contexts/AppProvider';
import useDevice from '../../hooks/useDevice';
import GridIcon from '../../icons/GridIcon';
import ListIcon from '../../icons/ListIcon';
import { ChevronDown } from '../../icons';

const ChangeViewButton = ({
  viewMode,
  setViewMode,
  views = [],
}: {
  viewMode: TViewMode;
  setViewMode: (mode: TViewMode) => void;
  views: TViewMode[];
}) => {
  const { user } = useAppContext();
  const device = useDevice();

  useEffect(() => {
    const savedView = localStorage.getItem('viewMode');
    if (savedView && (savedView === 'grid' || savedView === 'list' || savedView === 'group') && views.includes(savedView))
      setViewMode(savedView);
    else setViewMode(viewMode);
  }, [setViewMode, user, viewMode, views]);

  // use Memo not working for hook, used this for mobile
  useEffect(() => {
    if (device === 'mobile') setViewMode('grid');
  }, [device, setViewMode]);

  const changeViewMode = useCallback((_viewMode: TViewMode) => {
    setViewMode(_viewMode);
    localStorage.setItem('viewMode', _viewMode);
  }, [setViewMode]);

  const viewIcon = useMemo(
    () => ({
      grid: <GridIcon boxSize="18px" stroke="currentColor" />,
      list: <ListIcon boxSize="18px" stroke="currentColor" />,
    }),
    [],
  );

  if (device === 'mobile') return null;

  return (
    (<Menu autoSelect={false} >
      {
        <MenuButton
          _active={{}}
          _hover={{}}
          as={Button}
          bg="white"
          fontSize="14px"
          fontWeight="700"
          h="34px"
          ml={['15px', '0']}
          px={3}
          rightIcon={<ChevronDown h="12px" w="12px" />}
          rounded="6px">
          <Stack direction="row" spacing={2}>
            {viewIcon[viewMode]}
          </Stack>
        </MenuButton>
      }
      <MenuList
        border="none"
        boxShadow="simple"
        rounded="lg"
        minW="100px"
        zIndex={2}>
        {views.includes('grid') && (
          <MenuItem
            _focus={{ color: 'trackerItems.header.menuItemFocus' }}
            color={viewMode === 'grid' ? 'trackerItems.header.menuItemFontSelected' : 'trackerItems.header.menuItemFont'}
            fontSize="14px"
            onClick={() => changeViewMode('grid')}>
            <GridIcon mr={3} stroke="currentColor" />
            Card
          </MenuItem>
        )}
        {views.includes('list') && (
          <MenuItem
            _focus={{ color: 'trackerItems.header.menuItemFocus' }}
            color={viewMode === 'list' ? 'trackerItems.header.menuItemFontSelected' : 'trackerItems.header.menuItemFont'}

            fontSize="14px"
            onClick={() => changeViewMode('list')}>
            <ListIcon mr={3} stroke="currentColor" />
            List
          </MenuItem>
        )}
      </MenuList>
    </Menu>)
  );
};

export default ChangeViewButton;
