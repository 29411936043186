import { gql, useQuery } from "@apollo/client";

const SEARCH_USERS = gql`
  query ($searchQuery: SearchQuery) {
    searchUsers(searchQuery: $searchQuery) {
      _id
      firstName
      lastName
      displayName
    }
  }
`;

const usePeoplePickerUsers = (groupID?: string | undefined) => {
  const { data, loading, error } = useQuery(SEARCH_USERS, {
    variables: {
      searchQuery: {
        groupId: groupID,
      },
    },
    skip: !groupID,
  });
  return { loading, error, data };
};

export default usePeoplePickerUsers;
