import { CheckboxGroup, Input, Stack } from '@chakra-ui/react';

import { useAuditFiltersContext } from '../../../contexts/AuditFiltersProvider';
import { EAuditLogAction } from '../../../interfaces/IAuditLogs';
import FilterCheckBox from '../../Filters/FilterCheckBox';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

const StateChoiceFilter = ({
  name,
  options,
}: {
  name: string;
  options?: { label: string; value: string }[];
}) => {
  const { auditFilters, updateAuditFilters } = useAuditFiltersContext();

  const renderChoices = () => {
    switch (name) {
      case 'actions':
        return [
          EAuditLogAction.ADDED,
          EAuditLogAction.UPDATED,
          EAuditLogAction.DELETED,
          EAuditLogAction.COMPLETED,
          EAuditLogAction.DUPLICATED,
          EAuditLogAction.STARTED
        ].map((status) => (
          <FilterCheckBox key={status} label={status} value={status} />
        ));

      case 'collections':
        return [
          { label: 'Forms', value: 'forms' },
          { label: 'Form Templates', value: 'form-templates' },
          { label: 'Projects', value: 'projects' },
          { label: 'Project Templates', value: 'project-templates' },
        ].map(({ label, value }) => (
          <FilterCheckBox key={value} label={label} value={value} />
        ));
        default:
          return (options || []).map(({ label, value }) => <FilterCheckBox key={value} label={label} value={value} />);
    }
  };

  const renderInputFields = () => {
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        return (
          <Stack>
            <DatePicker
              autoComplete='off'
              dateFormat='dd MMM yyyy'
              name={name}
              placeholderText={'Select date'}
              onChange={(date) => {
                updateAuditFilters(name, date ? format(date, 'M/d/yyyy') : '');
              }}
              selected={
                auditFilters[name] ? new Date(String(auditFilters[name])) : null
              }
              disabledKeyboardNavigation
              showYearDropdown={true}
              dropdownMode='select'
              dateFormatCalendar='MMMM'
              showTimeSelect={false}
              maxDate={new Date()}
              calendarStartDay={1}
            />
          </Stack>
        );
      default:
        return (
          <CheckboxGroup
            onChange={(newValue) => updateAuditFilters(name, newValue)}
            value={auditFilters[name] || []}
          >
            <Stack direction='column' overflow='auto'>
              {renderChoices()}
            </Stack>
          </CheckboxGroup>
        );
    }
  };

  return <>{renderInputFields()}</>;
};

export default StateChoiceFilter;
