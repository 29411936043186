import { Button, IconButton, Tr, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';

import { DeleteIcon, EditIcon } from '../../../icons';
// import { getUserDetails } from '../../../utils/helpers';
import TableData from '../TableData';

const RecordKeepingTableRow = ({ dataValue, onRemove, disabled, onEdit }) => {
  const [reviewer, setReviewer] = useState<string>();
  const [projectLeader, setProjectLeader] = useState<string>();

  const getName = useMemo(
    () => async (userName: string) => {
      // const name = (await getUserDetails(userName))?.displayName;
      // return name;
      return '';
    },
    []
  );

  useEffect(() => {
    if (dataValue.reviewer) {
      getName(dataValue.reviewer)?.then((name) => setReviewer(name));
    }
  }, [dataValue.reviewer, getName]);

  useEffect(() => {
    if (dataValue.projectLeader) {
      getName(dataValue.projectLeader)?.then((name) => setProjectLeader(name));
    }
  }, [dataValue.projectLeader, getName]);
  return (
    <Tr>
      <TableData
        data={dataValue.pN}
        dataType='text'
        styles={{ paddingInline: '0px' }}
      />
      <TableData
        data={dataValue.testStandard}
        dataType='text'
        styles={{ paddingInline: '10px' }}
      />
      <TableData
        data={dataValue.projectLeader ? projectLeader : '-'}
        dataType='text'
      />
      <TableData data={dataValue.reviewer ? reviewer : '-'} dataType='text' />
      <TableData
        data={
          dataValue.reviewDate
            ? format(new Date(dataValue.reviewDate), 'dd MMM yyyy')
            : '-'
        }
        dataType='text'
      />
      <TableData
        data={
          dataValue.testDate
            ? format(new Date(dataValue.testDate), 'dd MMM yyyy')
            : '-'
        }
        dataType='text'
      />
      <TableData
        data={dataValue.comments}
        dataType='text'
        styles={{ paddingInline: '10px', whiteSpace: 'normal' }}
      />
      {!disabled && (
        <>
          <Td fontWeight='semi_medium' paddingInline='0px'>
            <Button
              disabled={disabled}
              _disabled={{ opacity: 0.75, cursor: 'not-allowed' }}
              bg='recordKeepingTableRow.button.bg'
              h='30px'
              color='recordKeepingTableRow.button.color'
              pl='5px'
              pr='10px'
              fontSize='ssm'
              borderRadius='10px'
              borderWidth='1px'
              borderColor='recordKeepingTableRow.button.color'
              onClick={onEdit}
            >
              <EditIcon
                w='12px'
                h='12px'
                ml='5px'
                mr='8px'
                stroke='recordKeepingTableRow.editIcon.stroke'
              />
              Edit
            </Button>
          </Td>
          <Td fontWeight='semi_medium' paddingInline='20px'>
            <IconButton
              bg='recordKeepingTableRow.deleteIcon.bg'
              aria-label='delete'
              size='xs'
              isRound={true}
              disabled={disabled}
              _disabled={{ opacity: 0.75, cursor: 'not-allowed' }}
              icon={
                <DeleteIcon
                  h='15'
                  w='15'
                  stroke='recordKeepingTableRow.deleteIcon.stroke'
                  onClick={onRemove}
                />
              }
            />
          </Td>
        </>
      )}
    </Tr>
  );
};

export default React.memo(RecordKeepingTableRow);

export const recordKeepingTableRowStyles = {
  recordKeepingTableRow: {
    button: {
      bg: '#FFFFFF',
      color: '#818197',
    },
    editIcon: {
      stroke: '#FFFFFF',
    },
    deleteIcon: {
      bg: '#F0F0F0',
      stroke: '#818197',
    },
  },
};
