import { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';

import { toastSuccess } from '../../bootstrap/config';
import ProjectHeaderStatus from '../../components/ProjectForm/ProjectHeaderStatus';
import DeleteIcon from '../../icons/DeleteIcon';
import EditIcon from '../../icons/EditIcon';
import { dateDecorated } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import GET_PROJECT_TEMPLATES_CATEGORIES from '../../gql/queries/GET_PROJECT_TEMPLATES_CATEGORIES';
import DELETE_PROJECT_TEMPLATE from '../../gql/mutation/DELETE_PROJECT_TEMPLATE';
import TableHeader from '../../components/FormControls/TableHeader';
import useSort from '../../hooks/useSort';
import { IProjectTemplateCategory } from '../../interfaces/IProjectTemplateCategory';
import { ChevronDown } from '../../icons';
import ConfirmationModal from '../ConfirmationModal';
import BinIcon from '../../icons/BinIcon';
import { IProjectTemplate } from '../../interfaces/IProjectTemplate';
import Can from '../Can';
import EyeIcon from '../../icons/Eye';

const TemplatesCategory = ({ category }: { category: IProjectTemplateCategory }) => {
  const history = useHistory();
  const toast = useToast();
  const { refetch } = useQuery(GET_PROJECT_TEMPLATES_CATEGORIES);
  const [deleteProjectTemplate] = useMutation(DELETE_PROJECT_TEMPLATE);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [templateToDelete, setTemplateToDelete] = useState<IProjectTemplate>();

  const { sortedData, sortOrder, sortType, setSortType, setSortOrder } = useSort(category.templates || [], 'name');
  const handleColumnClick = (key: string) => {
    setSortType(key);
    setSortOrder(sortOrder === 'asc' && key === sortType ? 'desc' : 'asc');
  };

  const deleteTemplate = async () => {
    if (templateToDelete) {
      await deleteProjectTemplate({
        variables: {
          deleteProjectTemplateInput: { _id: templateToDelete._id },
        },
      });
      await refetch();
      setTemplateToDelete(undefined);
      toast({ ...toastSuccess, description: 'Template deleted successfully' });
    }
  };

  return (
    <>
      <ConfirmationModal
        modalTitle="Delete project template"
        modalText={`Are you sure you want to delete template "<b>${templateToDelete?.name}</b>"? All form templates will also be removed.`}
        confirmButtonText="Remove"
        confirmButtonIcon={<BinIcon />}
        cancelButtonText="Discard"
        isOpen={isOpen}
        onClose={onClose}
        confirmAction={deleteTemplate}
      />
      <AccordionItem pb='4' key={category._id || 'no-category'} border="none">
        {({ isExpanded }) => (
          <Box bg='opaqueWhite' borderRadius={10} p={3}>
            <Box display='flex' alignItems='center' cursor='pointer'>
              <AccordionButton _focus={{}} _hover={{}} paddingX='0' alignItems='center'>
                {isExpanded ? (
                  <ChevronDownIcon h='24px' w='24px' />
                ) : (
                  <ChevronRightIcon h='24px' w='24px' />
                )}
                <Text ml='2' textAlign='left' fontSize='lg' fontWeight='bold'>
                  {category._id || 'No category'}
                </Text>
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <TableContainer
                border={'1px solid'}
                borderColor='#F0F0F0'
                borderRadius='20'
              >
                {Boolean(category.templates.length) ? (
                  <Table variant='simple'>
                    <Thead fontSize="smm" fontWeight="bold" >
                      <Tr>
                        <TableHeader
                          header='Template'
                          styles={{ paddingInline: 6, fontWeight: "bold", width: "20%" }}
                          icon={sortType === 'name' ? <ChevronDown
                            ml={2}
                            h="12px"
                            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                            w="12px"
                          /> : undefined}
                          onClick={() => handleColumnClick('name')}
                        />
                        <TableHeader
                          header='Owner'
                          styles={{ paddingInline: 6, fontWeight: "bold", width: "15%" }}
                          icon={sortType === 'owner.firstName' ? <ChevronDown
                            ml={2}
                            h="12px"
                            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                            w="12px"
                          /> : undefined}
                          onClick={() => handleColumnClick('owner.firstName')}
                        />
                        <TableHeader
                          header='UKAS'
                          styles={{ paddingInline: 6, fontWeight: "bold", width: "10%" }}
                          icon={sortType === 'isUkasRelated' ? <ChevronDown
                            ml={2}
                            h="12px"
                            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                            w="12px"
                          /> : undefined}
                          onClick={() => handleColumnClick('isUkasRelated')}
                        />
                        <TableHeader
                          header='Projects'
                          styles={{ paddingInline: 6, fontWeight: "bold", width: "10%" }}
                          icon={sortType === 'projects' ? <ChevronDown
                            ml={2}
                            h="12px"
                            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                            w="12px"
                          /> : undefined}
                          onClick={() => handleColumnClick('projects')}
                        />
                        <TableHeader
                          header='Created At'
                          styles={{ paddingInline: 6, fontWeight: "bold", width: "15%" }}
                          icon={sortType === 'metatags.addedAt' ? <ChevronDown
                            ml={2}
                            h="12px"
                            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                            w="12px"
                          /> : undefined}
                          onClick={() => handleColumnClick('metatags.addedAt')}
                        />
                        <TableHeader
                          header='Updated At'
                          styles={{ paddingInline: 6, fontWeight: "bold", width: "15%" }}
                          icon={sortType === 'metatags.updatedAt' ? <ChevronDown
                            ml={2}
                            h="12px"
                            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                            w="12px"
                          /> : undefined}
                          onClick={() => handleColumnClick('metatags.updatedAt')}
                        />
                        <Th w="5%" />
                      </Tr>
                    </Thead>
                    <Tbody>
                      {sortedData.map((template) => (
                        <Tr key={template._id}>
                          <Td w="20%">{template.name}</Td>
                          <Td w="15%" paddingInlineStart={4}>
                            <ProjectHeaderStatus
                              status={template?.owner?.jobTitle || ''}
                              heading={template?.owner?.displayName || ''}
                              avatar={template?.owner?.imgUrl}
                            />
                          </Td>
                          <Td w="10%">
                            {template.isUkasRelated ? 'Yes' : 'No'}
                          </Td>
                          <Td w="10%">{template.projects}</Td>
                          <Td w="15%">
                            {template.metatags?.addedAt ? dateDecorated(template.metatags?.addedAt) : '--'}
                          </Td>
                          <Td w="15%">
                            {template.metatags?.updatedAt ? dateDecorated(template.metatags?.updatedAt) : '--'}
                          </Td>
                          <Td w="5%">
                            <Flex
                              alignItems='center'
                              cursor='pointer'
                            >
                              <Can
                                action="projectTemplates.edit"
                                data={{ projectTemplate: template }}
                                yes={() =>
                                  <EditIcon
                                    mr='1'
                                    onClick={() => history.push(`/admin/templates/${template._id}`)}
                                  />
                                }
                                no={() =>
                                  <EyeIcon
                                    mr='1'
                                    color="white"
                                    onClick={() => history.push(`/admin/templates/${template._id}`)}
                                  />
                                }
                              />
                              <Can
                                action="projectTemplates.delete"
                                data={{ projectTemplate: template }}
                                yes={() =>
                                  <DeleteIcon
                                    stroke='black'
                                    onClick={() => {
                                      setTemplateToDelete(template);
                                      onOpen();
                                    }}
                                  />
                                }
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Text>No template has been created yet</Text>
                )}
              </TableContainer>
            </AccordionPanel>
          </Box>
        )}
      </AccordionItem>
    </>
  );
};

export default TemplatesCategory;
