import { Box } from "@chakra-ui/react";
import { useSearchContext } from "../contexts/SearchProvider";

const Overlay = (): JSX.Element => {
  const { search } = useSearchContext();
  return search.isSearchEnabled ? (
    <Box
      bg="rgb(137,137,137)"
      position={"absolute"}
      w={"100vw"}
      h={"100vh"}
      opacity={0.5}
      zIndex={3}
    />
  ) : (
    <></>
  );
};

export default Overlay;
