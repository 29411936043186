import { createIcon } from '@chakra-ui/icons';

const ClipboardIcon = createIcon({
  path: (
    <>
      <path
        d='M15.8333 2.50033H13.9583C13.9583 2.27931 13.8705 2.06735 13.7143 1.91107C13.558 1.75479 13.346 1.66699 13.125 1.66699H6.875C6.65399 1.66699 6.44202 1.75479 6.28574 1.91107C6.12946 2.06735 6.04167 2.27931 6.04167 2.50033H4.16667C3.2475 2.50033 2.5 3.24783 2.5 4.16699V16.667C2.5 17.5862 3.2475 18.3337 4.16667 18.3337H15.8333C16.7525 18.3337 17.5 17.5862 17.5 16.667V4.16699C17.5 3.24783 16.7525 2.50033 15.8333 2.50033ZM15.8333 16.667H4.16667V4.16699H5.83333V5.83366H14.1667V4.16699H15.8333V16.667Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default ClipboardIcon;
