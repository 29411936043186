import { useContext, useEffect, useState } from 'react';

import { Box, Button, Flex, Table, Thead, Tbody, Tr } from '@chakra-ui/react';
import { ModalContext } from '../../../contexts/ModalProvider';
import { Controller } from 'react-hook-form';
import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import { AddIcon } from '../../../icons';
import RecordKeepingTableRow from './RecordKeepingTableRow';
import RecordKeepingTableModal from './RecordKeepingTableModal';
import TableHeader from '../TableHeader';
import useValidate from '../../../hooks/useValidate';

interface IRecordKeepingTable extends IField {
  data?: any;
  description?: string;
  variant?: string;
  help?: string;
  styles?: {
    font?: string;
  };
  formIndex?: string;
}

interface IRecordKeepingTableData {
  _id: undefined;
  pN: string;
  testStandard: string;
  projectLeader: string;
  reviewer: string;
  reviewDate: string;
  testDate: string;
  comments: string;
  formIndex: string;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const RecordKeepingTable = ({
  control,
  name,
  label,
  validations = {},
  disabled = false,
  styles,
  formIndex,
}: IRecordKeepingTable) => {
  const validate = useValidate(
    label || name,
    validations || {},
    definedValidations
  );
  const [dataValues, setDataValues] = useState<IRecordKeepingTableData[]>([]);
  const { modalState, setModalState } = useContext(ModalContext);
  const [currentDataValue, setCurrentDataValue] = useState<any>();

  useEffect(() => {
    setDataValues(control._formValues.recordKeepingTableData);
  }, [control._formValues.recordKeepingTableData]);

  // Reset the form after closing
  useEffect(() => {
    if (modalState === 'closed') {
      setCurrentDataValue('');
    }
  }, [modalState]);

  // If modal opened in edit or delete mode, reset the form and set values of edited element
  const openRecordKeepingModal = (
    action: 'add' | 'edit' | 'delete',
    dataValue?: any
  ) => {
    if (action !== 'add') {
      setCurrentDataValue(dataValue);
    } else {
      setCurrentDataValue({
        pN: '',
        testStandard: '',
        projectLeader: '',
        reviewer: '',
        reviewDate: '',
        testDate: '',
        comments: '',
      });
    }
    setModalState(action);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        return (
          <>
            <RecordKeepingTableModal
              modalState={modalState}
              setModalState={setModalState}
              dataValues={dataValues}
              setDataValues={setDataValues}
              selectedDataValue={currentDataValue}
              onChange={onChange}
              formIndex={formIndex}
              name={name}
            />
            <Box w='full' id={name} mt='none' maxW='full'>
              {label && (
                <Flex
                  pt='15px'
                  pb={1}
                  align='center'
                  justify='space-between'
                  mb='none'
                >
                  <Box
                    color={
                      error
                        ? 'textInput.labelFont.error'
                        : styles
                        ? styles?.font
                        : 'textInput.labelFont.normal'
                    }
                    fontWeight='bold'
                    fontSize='md'
                    position='static'
                    left='none'
                    zIndex={2}
                  >
                    {label}
                  </Box>
                </Flex>
              )}
              <Box maxW='full' overflowX='auto'>
                <Table fontSize='smm'>
                  <Thead>
                    <Tr>
                      <TableHeader
                        header='P/N'
                        styles={{ paddingInline: '0px' }}
                      />
                      <TableHeader
                        header='Test Standard'
                        styles={{ paddingInline: '10px' }}
                      />
                      <TableHeader
                        header='Project Leader'
                        styles={{ paddingInline: '20px' }}
                      />
                      <TableHeader
                        header='Reviewer'
                        styles={{ paddingInline: '20px' }}
                      />
                      <TableHeader header='Review Date' />
                      <TableHeader
                        header='Test Date'
                        styles={{ paddingInline: '20px' }}
                      />
                      <TableHeader
                        header='Comments'
                        styles={{ paddingInline: '20px' }}
                      />
                      <TableHeader header='' />
                      <TableHeader header='' />
                    </Tr>
                  </Thead>
                  <Tbody left='-10px'>
                    {dataValues &&
                      dataValues?.map((dataValue, index) => (
                        <RecordKeepingTableRow
                          key={index}
                          dataValue={dataValue}
                          disabled={disabled}
                          onEdit={() =>
                            openRecordKeepingModal('edit', {
                              ...dataValue,
                              index: index,
                            })
                          }
                          onRemove={() =>
                            openRecordKeepingModal('delete', {
                              ...dataValue,
                              index: index,
                            })
                          }
                        />
                      ))}
                  </Tbody>
                </Table>
              </Box>
              <Box>
                <Button
                  disabled={disabled}
                  _disabled={{ opacity: 0.75, cursor: 'not-allowed' }}
                  bg='clientContractorTable.button.bg'
                  color='clientContractorTable.button.color'
                  mt='15px'
                  pl='0px'
                  pr='15px'
                  fontSize='smm'
                  borderRadius='10px'
                  onClick={() => openRecordKeepingModal('add')}
                  _hover={{
                    background: 'clientContractorTable.button.hoverBg',
                  }}
                  _active={{
                    background: 'clientContractorTable.button.activeBg',
                  }}
                >
                  <AddIcon
                    w='12.5px'
                    h='12.5px'
                    mr='05px'
                    stroke='clientContractorTable.addIcon.stroke'
                  />
                  Add Row
                </Button>
              </Box>
              {error && (
                <Box fontSize='ssm' ml={1} color='textInput.error'>
                  {error.message}
                </Box>
              )}
            </Box>
          </>
        );
      }}
    />
  );
};

export default RecordKeepingTable;

export const recordKeepingTableStyles = {
  recordKeepingTable: {
    button: {
      bg: '#FFFFFF',
      color: '#DC0043',
      hoverBg: '#FFFFFF',
      activeBg: '#FFFFFF',
    },
    addIcon: {
      stroke: '#DC0043',
    },
  },
};
