import { createIcon } from '@chakra-ui/icons';

const ScaleIcon = createIcon({
  path: (
    <path
      d='M17.3955 5.83301H2.60384C1.62717 5.83301 0.833008 6.58051 0.833008 7.49967V12.4997C0.833008 13.4188 1.62717 14.1663 2.60384 14.1663H17.3955C18.3722 14.1663 19.1663 13.4188 19.1663 12.4997V7.49967C19.1663 6.58051 18.3722 5.83301 17.3955 5.83301ZM17.3955 12.4997H2.60384C2.55634 12.4997 2.52384 12.4863 2.50967 12.4863C2.50384 12.4863 2.50051 12.488 2.49967 12.493L2.48967 7.53801C2.49551 7.52967 2.53301 7.49967 2.60384 7.49967H4.16634V9.99967H5.83301V7.49967H7.49967V10.833H9.16634V7.49967H10.833V9.99967H12.4997V7.49967H14.1663V10.833H15.833V7.49967H17.3955C17.4613 7.50051 17.4972 7.52301 17.4997 7.50634L17.5097 12.4613C17.5038 12.4697 17.4663 12.4997 17.3955 12.4997Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 20 20',
});

export default ScaleIcon;
