import { createIcon } from '@chakra-ui/icons';

const CalculatorIcon = createIcon({
  path: (
    <>
      <path
        d='M15.8333 1.66699H4.16667C3.2475 1.66699 2.5 2.41449 2.5 3.33366V16.667C2.5 17.5862 3.2475 18.3337 4.16667 18.3337H15.8333C16.7525 18.3337 17.5 17.5862 17.5 16.667V3.33366C17.5 2.41449 16.7525 1.66699 15.8333 1.66699ZM4.16667 16.667V3.33366H15.8333L15.8342 16.667H4.16667Z'
        fill='currentColor'
      />
      <path
        d='M5.83301 10H7.49967V11.6667H5.83301V10ZM5.83301 13.3333H7.49967V15H5.83301V13.3333ZM9.16634 10H10.833V11.6667H9.16634V10ZM5.83301 5H14.1663V8.33333H5.83301V5ZM9.16634 13.3333H10.833V15H9.16634V13.3333ZM12.4997 10H14.1663V15H12.4997V10Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default CalculatorIcon;
