import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import {
  Box,
  Button,
  Flex,
  Text,
  Spinner,
  Stack,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import { IField } from '../../interfaces/IField';
import { Controller } from 'react-hook-form';
import { Asterisk, PhotoIcon } from '../../icons';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useProjectsContext } from '../../contexts/ProjectsProvider';

import AnnotationModal from '../AnnotationModal';
import { prepareImage, updateFileContent } from '../../utils/helpers';
import PhotoRenderer from '../FormControls/Photos/PhotoRenderer';
import Can, { isPermitted } from '../Can';
import { useAppContext } from '../../contexts/AppProvider';
import useValidate from '../../hooks/useValidate';
import { DefinedValidations } from '../../interfaces/Validations';

interface ITemplateFieldImageInput extends IField {
  help?: string;
  setValue?: any;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && (!value || value.length === 0)) {
      return `Cannot be empty`;
    }
  },
};

const TemplateFieldImageInput = ({
  control,
  name,
  label,
  tooltip = '',
  validations = {},
}: ITemplateFieldImageInput) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);
  const { user } = useAppContext();
  const { currentProjectTemplate } = useProjectsContext();
  const hiddenFileInput = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPhoto, setSelectedPhoto] = useState<any>(null);
  const [uploadedImagesBase64, setUploadedImagesBase64] = useState<any>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { settings } = useAppContext();

  const isPermittedToEdit = isPermitted({ user, action: 'projectTemplates.edit', data: { projectTemplate: currentProjectTemplate } });

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setUploadedImagesBase64(control._formValues[name] || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control._formValues[name]]);

  const uploadFilesForField = async (file: File, fileName) => {
    const reportFilesData = new FormData();
    const compressedImage = await prepareImage(file);
    reportFilesData.append('spSiteUrl', settings?.spSiteUrl || '');
    reportFilesData.append('spDocumentLibrary', settings?.spDocumentLibrary || '');
    reportFilesData.append('fileName', fileName);
    reportFilesData.append(file.name, compressedImage || '');

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/files/saveStaticPhoto`,
      reportFilesData,
      { withCredentials: true }
    );
    setIsUploading(false);
    return res.data;
  };

  const handleChange = async (event, onChange) => {
    let encodedBase64Files = uploadedImagesBase64;
    let files = [...event.target.files];
    files.forEach((file, index) => {
      setIsUploading(true);
      const uFileName: string = uuidv4();
      const fileTempName = file.name.split('.');
      const extension = fileTempName[fileTempName.length - 1];
      const fileName = `${uFileName}.${extension}`;
      Promise.resolve(uploadFilesForField(file, fileName)).then((data) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          encodedBase64Files = [
            ...encodedBase64Files,
            {
              fileName, // used to fetch image from SP
              fileId: data[0].fileId, // this id will be used to update file name on SP
            },
          ];
        };
        reader.onloadend = async () => {
          onChange({ target: { name, value: encodedBase64Files } });
          setUploadedImagesBase64(encodedBase64Files);
        };
        document.getElementById('parent-container')?.scrollBy(0, 550);
      });
    });
  };

  const renderPhoto = (photo, index, error, onChange) => {
    return isUploading ? (
      <>
        <Flex
          w='full'
          h='full'
          align='center'
          justify='center'
          direction={'column'}
        >
          <Spinner size='xl' thickness='4px' />
        </Flex>
      </>
    ) : (
      <PhotoRenderer
        key={`photo-${index}`}
        index={index}
        error={error}
        photo={photo}
        isUploading={isUploading}
        onOpen={onOpen}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        onChange={onChange}
        uploadedImagesBase64={uploadedImagesBase64}
        setUploadedImagesBase64={setUploadedImagesBase64}
        validations={validations}
        formIndex={null}
        displayTileDescription={false}
        disabled={!isPermittedToEdit}
      />
    );
  };

  const renderContent = (error, onChange) => {
    return (
      <VStack align='left'>
        {isUploading ? (
          <>
            <Flex w='full' h='full' align='center' justify='center'>
              <Spinner size='xl' thickness='4px' />
            </Flex>
          </>
        ) : (
          uploadedImagesBase64?.map((photo, index) =>
            renderPhoto(photo, index, error, onChange)
          )
        )}
      </VStack>
    );
  };

  if (isUploading) {
    return (
      <Flex
        w='full'
        h='full'
        align='center'
        justify='center'
        direction={'column-reverse'}
      >
        <Text fontWeight='bold' fontSize='16px' mt={2}>
          Please wait for uploads to complete
        </Text>
        <Spinner size='xl' thickness='4px' />
      </Flex>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;
        return (
          <Box w='full' id={name}>
            <Text
              color='switch.label.normal'
              fontWeight='bold'
              fontSize='ssm'
              position='static'
              left='none'
              zIndex={1}
            >
              Photos{' '}
              {validations?.notEmpty && (
                <Asterisk
                  h='8px'
                  ml='5px'
                  mb='8px'
                  fill='imageCapture.iconAsterisk'
                  stroke='imageCapture.iconAsterisk'
                />
              )}
            </Text>
            <Stack align='left'>
              {isOpen && (
                <AnnotationModal
                  isOpen={isOpen}
                  onClose={onClose}
                  selectedPhoto={selectedPhoto}
                  onClickHandler={async (editedImage) => {
                    onClose();
                    let updatedUploadedImagesBase64;
                    if (selectedPhoto.index === -1) {
                      //save new
                      updatedUploadedImagesBase64 = [
                        ...uploadedImagesBase64,
                        {
                          index: uploadedImagesBase64.length,
                        },
                      ];
                      setUploadedImagesBase64(updatedUploadedImagesBase64);
                    } else {
                      setIsUploading(true);
                      //edit existing
                      await updateFileContent(
                        editedImage,
                        `Photos and Videos/${selectedPhoto.fileName}`,
                        settings
                      ).then(async (data) => {
                        updatedUploadedImagesBase64 = [...uploadedImagesBase64];
                        updatedUploadedImagesBase64[selectedPhoto.index] = {
                          index:
                            updatedUploadedImagesBase64[selectedPhoto.index]
                              .index,
                          fileId:
                            updatedUploadedImagesBase64[selectedPhoto.index]
                              .fileId,
                          fileName:
                            updatedUploadedImagesBase64[selectedPhoto.index]
                              .fileName,
                        };
                      });
                      setUploadedImagesBase64(updatedUploadedImagesBase64);
                      setSelectedPhoto(null);
                    }
                    onChange({
                      target: { name, value: updatedUploadedImagesBase64 },
                    });
                    setIsUploading(false);
                  }}
                />
              )}
              {renderContent(error, onChange)}
              <Can
                action="projectTemplates.edit"
                data={{ projectTemplate: currentProjectTemplate }}
                yes={() =>
                  <Box>
                    <input
                      type='file'
                      multiple
                      accept='image/*'
                      ref={hiddenFileInput}
                      onChange={(e) => handleChange(e, onChange)}
                      style={{ display: 'none' }}
                    />
                    <Button
                      w='130px'
                      bg='freeHandInput.addDrawingButton.bg'
                      color='freeHandInput.addDrawingButton.color'
                      mt='5px'
                      pl='5px'
                      pr='15px'
                      fontSize='smm'
                      borderRadius='10px'
                      _hover={{
                        bg: 'reportFormHeader.buttonDisableBg',
                        color: 'reportFormHeader.buttonDisableColor',
                      }}
                      onClick={handleClick}
                    >
                      <PhotoIcon
                        w='15px'
                        h='15px'
                        mx='10px'
                        stroke='freeHandInput.drawingIcon.stroke'
                      />
                      Add Photos
                    </Button>
                  </Box>
                }
              />
            </Stack>
            {error && (
              <Box
                fontSize='ssm'
                ml={1}
                mt={1}
                color='freeHandInput.label.error'
              >
                {error.message}
              </Box>
            )}
            {tooltip && (
              <Flex color='freeHandInput.tooltipColor' align='center' mt={3}>
                <InfoOutlineIcon />
                <Box fontSize='11px' ml={2}>
                  {tooltip}
                </Box>
              </Flex>
            )}
          </Box>
        );
      }}
    />
  );
};

export default TemplateFieldImageInput;
