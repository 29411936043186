import { Box, Flex, Select, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Controller } from 'react-hook-form';
import { IField } from '../../interfaces/IField';
import { DefinedValidations } from '../../interfaces/Validations';
import { Asterisk, ChevronDown } from '../../icons';
import useValidate from '../../hooks/useValidate';


interface IDropdown extends IField {
  placeholder?: string;
  variant?: string;
  options?: {
    label?: string;
    value?: string;
  }[];
  stroke?: string;
  help?: string;
  Icon?: any;
  attributeType?: 'Category' | 'Regulatory body';
  onAction?: (type?: 'Category' | 'Regulatory body') => void;
  styles?: {
    fullWidth?: boolean
  };
  inTable?: boolean;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const Dropdown = ({ control, name, stroke, label, placeholder = '', tooltip = '', styles, variant, validations = {},
  disabled = false, options = [], help = '', Icon, onAction, attributeType, inTable = false }: IDropdown) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);
  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange, onBlur, value } = field;
        const { error } = fieldState;
        return (
          <Box w='full' id={name} mt={inTable ? '-7px' : '2.5px'} minH={inTable ? "0px" : "80px"}>
            {label && (
              <Flex pt={2} align='center' justify='space-between' mb='none'>
                <Box
                  color={error ? 'dropdown.labelFont.error' : variant === 'secondaryVariant' ? 'dropdown.labelFont.secondaryVariant' : 'dropdown.labelFont.normal'}
                  fontWeight='bold'
                  fontSize='ssm'
                  position='static'
                  left='none'
                  zIndex={1}
                  minH="16px"
                >
                  {label}
                  {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill='textInput.iconAsterisk' stroke='textInput.iconAsterisk' />}
                  {tooltip && <Tooltip hasArrow label={tooltip} placement='top'><InfoOutlineIcon mb={1} h='14px' /></Tooltip>}
                </Box>
              </Flex>
            )}
            <Flex alignItems={Icon ? 'center' : ''} maxW={styles?.fullWidth ? '' : '360px'}>
              <Select
                css={{ paddingTop: '0' }}
                borderRadius='8px'
                borderWidth='1px'
                top='5px'
                fontSize='smm'
                h='42px'
                color='dropdown.font'
                bg='dropdown.bg'
                borderColor={error ? 'dropdown.border.error' : 'dropdown.border.normal'}
                onBlur={onBlur}
                value={value || ''}
                onChange={onChange}
                name={name}
                isDisabled={disabled}
                cursor='pointer'
                _active={{ bg: disabled ? 'dropdown.disabled.bg' : 'dropdown.activeBg' }}
                _focus={{ borderColor: error ? 'dropdown.border.focus.error' : 'dropdown.border.focus.normal' }}
                _disabled={{
                  bg: 'dropdown.disabled.bg',
                  color: 'dropdown.disabled.font',
                  borderColor: 'dropdown.disabled.border',
                  cursor: 'not-allowed',
                }}
                placeholder={placeholder}
                icon={<ChevronDown
                  w='14px'
                  h='7px'
                  mr='15px'
                  stroke='dropdown.chevronDownIcon'
                />}
              >
                {options && options.map(option => <option key={`${name}-${option.value}`} value={option.value}>{option.label}</option>)}
              </Select>
              {(Icon && onAction) &&
                <Icon
                  stroke={stroke}
                  ml='20px'
                  cursor='pointer'
                  mt='10px'
                  onClick={() => onAction(attributeType)}
                />}
            </Flex>
            {error && <Box fontSize='ssm' ml={1} mt={1} color='dropdown.error'>{error.message}</Box>}
          </Box>
        );
      }}
    />
  );
};

export const dropdownStyles = {
  dropdown: {
    font: '#777777',
    bg: '#FFFFFF',
    labelFont: {
      secondaryVariant: '#818197',
      normal: '#282F36',
      error: '#E53E3E',
    },
    border: {
      normal: '#CBCCCD',
      error: '#E53E3E',
      focus: {
        normal: '#777777',
        error: '#E53E3E',
      },
    },
    activeBg: '#EEEEEE',
    disabled: {
      font: '#2B3236',
      border: '#EEEEEE',
      bg: '#f7f7f7',
    },
    placeholder: '#777777',
    error: '#E53E3E',
    tooltip: '#9A9EA1',
    icon: '#818197',
    chevronDownIcon: '#282F36',
  },
};

export default Dropdown;
