import { useEffect, useRef } from 'react';

import { Box, Button, Flex, useOutsideClick } from '@chakra-ui/react';

import useDevice from '../../../hooks/useDevice';
import { CrossIcon } from '../../../icons';
import AuditLogFiltersPanelItem from './AuditLogFiltersPanelItem';
import { useAuditFiltersContext } from '../../../contexts/AuditFiltersProvider';

const availableFilters = [
  {
    name: 'actions',
    label: 'Action',
  },
  {
    name: 'dateFrom',
    label: 'From',
  },
  {
    name: 'dateTo',
    label: 'To',
  },
  {
    name: 'collections',
    label: 'Type',
  },
  {
    name: 'userIds',
    label: 'Users',
  },
];

const AuditFilterPanel = () => {
  const {
    showAuditFiltersPanel,
    setShowAuditFiltersPanel,
    defaultAuditFilters,
    setAuditFilters,
  } = useAuditFiltersContext();
  const panelRef = useRef(null);
  const device = useDevice();
  useOutsideClick({
    ref: panelRef,
    handler: () => setShowAuditFiltersPanel(false),
  });
  useEffect(() => {
    if (showAuditFiltersPanel && device === 'mobile')
      document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [device, showAuditFiltersPanel]);

  if (!showAuditFiltersPanel) return null;

  return (
    <Flex
      bg='filterPanel.bg'
      borderBottomStartRadius={['0px', '20px']}
      boxShadow='md'
      direction='column'
      h='100vh'
      position={['relative', 'absolute']}
      ref={panelRef}
      right='0'
      shrink={0}
      w={['full', '320px']}
      zIndex='10'
    >
      <Flex
        align='center'
        basis={['55px', '65px']}
        justify='space-between'
        px='4'
        shrink={0}
      >
        <Box color='brand.darkGrey' fontSize='16px' fontWeight='700'>
          Filter audit log items by
        </Box>
        <CrossIcon
          cursor='pointer'
          onClick={() => setShowAuditFiltersPanel(false)}
          stroke='filterPanel.closeIconColor'
        />
      </Flex>
      <Flex direction='column' grow={1} overflowY='auto' px='4'>
        {availableFilters.map(({ name, label }) => (
          <AuditLogFiltersPanelItem label={label} key={name} name={name} />
        ))}
      </Flex>
      <Flex
        align='center'
        basis={['60px', '70px']}
        bg='filterPanel.bg'
        borderBottomStartRadius={['0px', '20px']}
        bottom={0}
        boxShadow={['0px 0px 80px rgba(49, 50, 51, 0.15)', 'none']}
        justify='center'
        position={['sticky', 'relative']}
        shrink={0}
        w='full'
      >
        <Button
          _hover={{ opacity: 0.9 }}
          color='filterPanel.resetButtonColor'
          fontSize='14px'
          h='35px'
          onClick={() => setAuditFilters(defaultAuditFilters)}
          w={['40%', '115px']}
        >
          Reset all
        </Button>
        <Button
          _hover={{ opacity: 0.9 }}
          bg='filterPanel.doneButtonBg'
          color='filterPanel.doneButtonColor'
          fontSize='14px'
          h='35px'
          ml='10px'
          onClick={() => setShowAuditFiltersPanel(false)}
          w={['40%', '115px']}
        >
          Done
        </Button>
      </Flex>
    </Flex>
  );
};

export default AuditFilterPanel;

export const filtersPanelStyles = {
  filterPanel: {
    bg: 'white',
    closeIconColor: '#000',
    doneButtonBg: '#462AC4',
    doneButtonColor: '#ffffff',
    resetButtonBg: '#F0F2F5',
    resetButtonColor: '#818197',
    searchBoxBordercolor: '#81819750',
  },
};
