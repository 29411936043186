import React, { createContext, useContext, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

import { IFiltersContext } from "../interfaces/IFiltersContext";
import IFilters from "../interfaces/IFilters";
import GET_FILTERS_VALUES from "../gql/queries/GET_FILTERS_VALUES";
import { useAppContext } from "./AppProvider";

export const FiltersContext = createContext({} as IFiltersContext);

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within the FiltersProvider');
  }
  return context;
};

const FiltersProvider = (props: any) => {
  const { user } = useAppContext();
  const { data, refetch: refetchFiltersOptions } = useQuery(GET_FILTERS_VALUES);
  const [showFiltersPanel, setShowFiltersPanel] = useState<boolean>(false);
  const [openedFilterPanel, setOpenedFilterPanel] = useState<string | null>(null);
  const defaultFilters = useMemo(() => ({
    status: ["Not started", "In progress", "Completed"],
    projectLeadId: [user?._id!],
    testersIds: [user?._id!],
    traineesIds: [user?._id!],
  }), [user?._id]);
  const [filters, setFilters] = useState<IFilters>(defaultFilters);

  const updateFilters = (filterName: string, value) => {
    if (value.length) {
      setFilters(filters => ({ ...filters, [filterName]: value }))
    } else {
      setFilters(filters => {
        const newFilters = { ...filters };
        delete newFilters[filterName];
        return newFilters;
      });
    }
  };

  const value = useMemo(() => ({
    defaultFilters,
    filters, setFilters,
    filtersOptions: data?.filtersValues || [],
    refetchFiltersOptions,
    showFiltersPanel, setShowFiltersPanel,
    openedFilterPanel, setOpenedFilterPanel,
    updateFilters,
  }), [ // eslint-disable-line react-hooks/exhaustive-deps
    defaultFilters,
    filters,
    showFiltersPanel,
    openedFilterPanel,
    data,
  ]);

  return (
    <FiltersContext.Provider value={value}>
      {props.children}
    </FiltersContext.Provider>
  )
}

export default FiltersProvider;
