import {
  Avatar,
  Box,
  Stack,
  HStack,
  AvatarGroup,
  Tooltip,
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from "react-router-dom";
import { useAppContext } from '../../contexts/AppProvider';

import { useProjectsContext } from '../../contexts/ProjectsProvider';
import { useSearchContext } from '../../contexts/SearchProvider';
import { ChevronDown } from '../../icons';
import { IProject } from "../../interfaces/IProject";
import ISearch from '../../interfaces/ISearch';
import { isPermitted } from '../Can';
import Loader from '../Loader';
import ProjectsListHeader from './ProjectsListHeader';

const ProjectsList = ({ projects, totalProjects, sortType, sortOrder, setSortType, setSortOrder, loadMoreProjects, setupProject }: {
  projects: IProject[],
  totalProjects: number,
  sortOrder: 'asc' | 'desc';
  sortType: string;
  setSortType: (key: string) => void;
  setSortOrder: (order: 'asc' | 'desc') => void;
  loadMoreProjects: (page: number) => void;
  setupProject: (projectId: string) => void;
}) => {
  const { user } = useAppContext();
  const history = useHistory();
  const { setCurrentProject } = useProjectsContext();
  const { setSearch } = useSearchContext();

  const handleColumnClick = (key: string) => {
    setSortType(key);
    setSortOrder(sortOrder === 'asc' && key === sortType ? 'desc' : 'asc');
  };

  return (
    <Box
      border="1px solid"
      borderColor='#F0F0F0'
      bg="white"
      borderRadius='6px'
      w="full"
      h="full"
      overflow="hidden"
    >
      <HStack fontSize="smm" fontWeight="bold" spacing={6} px={6} py={4} borderBottomWidth="1px" w="calc(100% - 10px)">
        <ProjectsListHeader
          header='Project'
          styles={{ width: "10%" }}
          icon={sortType === 'reference' ? <ChevronDown
            ml={2}
            h="12px"
            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
            w="12px"
          /> : undefined}
          onClick={() => handleColumnClick('reference')}
        />
        <ProjectsListHeader
          header='Client'
          styles={{ width: "22%" }}
          icon={sortType === 'clientName' ? <ChevronDown
            ml={2}
            h="12px"
            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
            w="12px"
          /> : undefined}
          onClick={() => handleColumnClick('clientName')}
        />
        <ProjectsListHeader
          header='Lab number'
          styles={{ width: "22%" }}
          icon={sortType === 'projectTemplate.category' ? <ChevronDown
            ml={2}
            h="12px"
            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
            w="12px"
          /> : undefined}
          onClick={() => handleColumnClick('projectTemplate.category')}
        />
        <ProjectsListHeader
          header='Test type'
          styles={{ width: "22%" }}
          icon={sortType === 'projectTemplate.name' ? <ChevronDown
            ml={2}
            h="12px"
            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
            w="12px"
          /> : undefined}
          onClick={() => handleColumnClick('projectTemplate.name')}
        />
        <ProjectsListHeader
          header='Project team'
          styles={{ width: "14%", cursor: 'default' }}
        />
        <ProjectsListHeader
          header='Status'
          styles={{ width: "10%" }}
          icon={sortType === 'status' ? <ChevronDown
            ml={2}
            h="12px"
            transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
            w="12px"
          /> : undefined}
          onClick={() => handleColumnClick('status')}
        />
      </HStack>

      <Stack overflow="auto" h="calc(100% - 50px)">
        <InfiniteScroll
          loadMore={loadMoreProjects}
          hasMore={(projects || []).length < totalProjects}
          loader={<Loader size='md' />}
          useWindow={false}
        >
          {projects.map((project) => (
            <HStack
              spacing={6}
              px={6}
              py={4}
              key={project._id}
              fontSize="smm"
              cursor={project.status !== 'Not started' || isPermitted({ user, action: 'projects.start', data: { project } }) ? 'pointer' : 'default'}
              onClick={() => {
                if (project.status === 'Not started') {
                  if (isPermitted({ user, action: 'projects.start', data: { project } })) {
                    setupProject(project._id);
                  }
                } else {
                  setCurrentProject(null)
                  setSearch((state: ISearch) => ({
                    ...state,
                    value: "",
                  }));
                  history.push(`/project/${project?._id}/1`);
                }
              }}
              borderBottomWidth="1px"
            >
              <Box w="10%">{project.reference}</Box>
              <Box w="22%">{project.clientName}</Box>
              <Box w="22%">{project.projectTemplate?.category || 'TBC'}</Box>
              <Box w="22%">{project.projectTemplate?.name || 'TBC'}</Box>
              <Box w="14%">
                <AvatarGroup size='xs' max={5}>
                  {[project?.projectLead, ...(project?.testers || []), ...(project?.trainees || [])].map(person => (
                    <Box as={Avatar} key={person?._id}>
                      <Tooltip hasArrow label={person?.displayName}>
                        <Avatar size='sm' cursor='pointer' key={person?._id} name={person?.displayName} src={person?.imgUrl} />
                      </Tooltip>
                    </Box>
                  ))}
                </AvatarGroup>
              </Box>
              <Box w="10%"
                color={project.status === 'Completed' ? 'breSuccess' : project.status === 'Not started' ? 'breAmber' : 'breNavy'}
                fontWeight="900"
              >{project.status}</Box>
            </HStack>
          ))}
        </InfiniteScroll>
      </Stack>
    </Box>
  );
};

export default ProjectsList;
