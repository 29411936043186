import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { IProjectsContext } from "../interfaces/IProjectsContext";
import { IProject } from "../interfaces/IProject";
import { IProjectTemplate } from "../interfaces/IProjectTemplate"
import { IFormPage } from "../interfaces/IFormPage";
import { IForm } from "../interfaces/IForm";
import { IFormTemplate } from "../interfaces/IFormTemplate";
import { EOperationType } from "../interfaces/EOperationType";

export const ProjectsContext = createContext({} as IProjectsContext);

export const useProjectsContext = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjectsContext must be used within the ProjectsProvider');
  }
  return context;
};

const ProjectsProvider = (props: any) => {
  const [autoSaving, setAutoSaving] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[] | null>(null);
  const [totalProjects, setTotalProjects] = useState(0);
  const [currentProject, setCurrentProject] = useState<IProject | null>(null);
  const [currentProjectTemplate, setCurrentProjectTemplate] = useState<IProjectTemplate | null>(null);
  const [currentProjectForm, setCurrentProjectForm] = useState<IForm | null>(null);
  const [currentProjectFormTemplate, setCurrentProjectFormTemplate] = useState<IFormTemplate | null>(null);
  const [selectedPage, setSelectedPage] = useState<number>(-1);
  const [formStates, setFormStates] = useState<any>([]);
  const [formIterationNumber, setFormIterationNumber] = useState<number | null>(null);
  const [pages, setPages] = useState<IFormPage[]>([]);
  const [fieldsInEdit, setFieldsInEdit] = useState<string[]>([]); // IDs of fields that are edited in template builder
  const [operationType, setOperationType] = useState<EOperationType>(EOperationType.EMPTY);
  // const row

  const usedDatabaseNames = useMemo(() => {
    const databaseNames: string[] = [];
    (currentProjectTemplate?.formTemplates || []).forEach(formTemplate => {
      (formTemplate?.pages || []).forEach(page => {
        (page?.sections || []).forEach(section => {
          (section?.rows || []).forEach(row => {
            (row?.fields || []).forEach(field => {
              databaseNames.push(field.name);
            });
          });
        });
      });
    });
    return databaseNames;
  }, [currentProjectTemplate]);

  useEffect(() => {
    setFieldsInEdit([]);
  }, [currentProjectFormTemplate?._id, selectedPage]);

  const value = useMemo(() => (
    {
      autoSaving,
      setAutoSaving,

      projects,
      setProjects,

      totalProjects,
      setTotalProjects,

      currentProject,
      setCurrentProject,

      currentProjectTemplate,
      setCurrentProjectTemplate,

      currentProjectForm,
      setCurrentProjectForm,

      currentProjectFormTemplate,
      setCurrentProjectFormTemplate,

      selectedPage,
      setSelectedPage,

      formStates,
      setFormStates,

      formIterationNumber,
      setFormIterationNumber,

      pages,
      setPages,

      operationType,
      setOperationType,

      usedDatabaseNames,

      fieldsInEdit,
      setFieldsInEdit,
    }), [ // eslint-disable-line react-hooks/exhaustive-deps
    autoSaving,
    projects,
    currentProject,
    currentProjectTemplate,
    currentProjectForm,
    currentProjectFormTemplate,
    selectedPage,
    formStates,
    formIterationNumber,
    pages,
    operationType,
    usedDatabaseNames,
    fieldsInEdit,
  ]);

  return (
    <ProjectsContext.Provider value={value}>
      {props.children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsProvider;
