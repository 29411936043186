import { createIcon } from '@chakra-ui/icons';

const LaptopFileIcon = createIcon({
  path: (
    <>
      <path fill='currentColor' d='M128 0C92.7 0 64 28.7 64 64V288H19.2C8.6 288 0 296.6 0 307.2C0 349.6 34.4 384 76.8 384H320V288H128V64H448V96h64V64c0-35.3-28.7-64-64-64H128zM512 128H400c-26.5 0-48 21.5-48 48V464c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V256H544c-17.7 0-32-14.3-32-32V128zm32 0v96h96l-96-96z' />
    </>
  ),
  viewBox: '0 0 640 512',
});

export default LaptopFileIcon;
