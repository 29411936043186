import { createIcon } from '@chakra-ui/icons';

const TightenNutIcon = createIcon({
  path: (
    <path
      d='M4.26866 17.5003C4.58366 17.8153 5.00199 17.9887 5.44699 17.9887C5.89199 17.9887 6.31033 17.8153 6.62533 17.5003L10.2387 13.887C10.8371 14.0716 11.4599 14.1651 12.0862 14.1645C12.907 14.167 13.7202 14.0066 14.4786 13.6926C15.237 13.3787 15.9256 12.9173 16.5045 12.3353C17.3784 11.4639 17.9741 10.3527 18.2162 9.14256C18.4583 7.93238 18.3359 6.67758 17.8645 5.537L17.3895 4.38033L13.8528 7.91616L12.0853 6.14783L15.6212 2.612L14.4637 2.13616C13.7085 1.82528 12.8995 1.66587 12.0828 1.667C10.4137 1.667 8.84449 2.317 7.66533 3.497C6.85948 4.30017 6.28887 5.30861 6.01535 6.413C5.74182 7.51738 5.77582 8.67556 6.11366 9.762L2.50033 13.3753C2.18808 13.688 2.0127 14.1118 2.0127 14.5537C2.0127 14.9955 2.18808 15.4193 2.50033 15.732L4.26866 17.5003ZM8.05866 10.1745L7.847 9.66117C7.50088 8.8248 7.41084 7.90451 7.58827 7.01691C7.7657 6.12932 8.20262 5.31437 8.84366 4.67533C9.32005 4.19584 9.89728 3.82852 10.5334 3.60004C11.1695 3.37157 11.8486 3.28769 12.5212 3.3545L9.72699 6.14866L13.8512 10.2737L16.647 7.47783C16.7122 8.15084 16.6274 8.82993 16.3987 9.46625C16.1701 10.1026 15.8032 10.6803 15.3245 11.1578C14.0328 12.4495 12.0212 12.847 10.3378 12.1545L9.82533 11.942L5.44699 16.322H5.44783L5.44699 17.1553V16.322L3.67866 14.5537L8.05866 10.1745Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 20 20',
});

export default TightenNutIcon;
