import {
  Accordion,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState, ReactElement } from 'react';
import {
  DeleteIcon,
  DotsHorizontal,
  DuplicatePageIcon,
  EditIcon,
  PlusIcon,
} from '../../icons';
import { useParams } from 'react-router-dom';

import { IFormTemplate } from '../../interfaces/IFormTemplate';
import { IReportFormSection } from '../../models/reportform';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import UPDATE_FORM_TEMPLATE from '../../gql/mutation/UPDATE_FORM_TEMPLATE';
import ConfirmationModal from '../ConfirmationModal';
import DividerButton from '../DividerButton';
import Modal from '../Modal';
import TemplateSection from './TemplateSection';
import useTemplateSections from '../../hooks/useTemplateSections';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import useGetProjectTemplate from '../../hooks/useGetProjectTemplate';
import Can from '../Can';
import Loader from '../Loader';
import { EOperationType } from '../../interfaces/EOperationType';
import { unsavedChangesMessage } from '../../utils/defaults';

interface ITemplateContent {
  form?: IFormTemplate | null;
  loading?: boolean;
  header?: ReactElement<any, any>;
}

const TemplateContent = ({ form, loading, header }: ITemplateContent) => {
  const { id } = useParams<{ id: string }>();
  const { refetchProjectTemplate } = useGetProjectTemplate({ _id: id }, true);
  const { fieldsInEdit, currentProjectTemplate, pages, setPages, operationType, setAutoSaving } = useProjectsContext();
  const [edit, setEdit] = useState<boolean>(false);
  const [sections, setSections] = useState<IReportFormSection[]>([]);
  const {
    createPage,
    insertSection,
    removeSection,
    removePage,
    editPageName,
    isOpen,
    onOpen,
    onClose,
    isConfirmationOpen,
    onConfirmationOpen,
    onConfirmationClose,
    selectedPage,
    setSelectedPage,
    itemToRemove,
    setItemToRemove,
    duplicatePage,
    movePages,
    moveSections,
    pageName,
    setPageName,
  } = useTemplateSections();

  const [updateFormTemplate] = useMutation(UPDATE_FORM_TEMPLATE);

  const saveForm = async () => {
    if (form?._id) {
      await updateFormTemplate({
        variables: {
          formTemplateInput: { _id: form?._id, pages },
          operationType
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveFormDebounced = useCallback(
    debounce(async () => {
      if (operationType === EOperationType.EMPTY) {
        return;
      }
      setAutoSaving(true);
      await saveForm();
      if (!form?.isCommonForm) {
        await refetchProjectTemplate();
      }
      setAutoSaving(false);
    }, 1000),
    [JSON.stringify(pages), updateFormTemplate]
  );

  useEffect(() => {
    if (selectedPage !== -1) {
      if (pages.length === 0) {
        setSections([]);
        saveFormDebounced();
      } else if (pages.length > 0 && selectedPage < pages.length) {
        setSections(pages[selectedPage].sections);
        saveFormDebounced();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, saveFormDebounced]);

  useEffect(() => {
    if (selectedPage !== -1) {
      if (pages.length === 0) {
        setSections([]);
      } else if (pages.length > 0 && selectedPage < pages.length) {
        setSections(pages[selectedPage].sections);
      }
    }
  }, [pages, selectedPage]);

  useEffect(() => {
    setPages(form?.pages || []);
    setSelectedPage((form?.pages || []).length > -1 ? 0 : -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const renderSections = () => {
    if (pages.length === 0) {
      return <></>;
    }
    return pages[selectedPage]?.sections.map((section) => (
      <TemplateSection
        key={section._id}
        selectedPage={selectedPage}
        section={section}
        onRemove={() => {
          setItemToRemove({ type: 'section', _id: section._id });
          onConfirmationOpen();
        }}
      />
    ));
  };

  const remove = () => {
    switch (itemToRemove?.type) {
      case 'page':
        removePage();
        break;
      case 'section':
        removeSection();
        break;
      default:
        return;
    }
  };

  if (loading) {
    return (
      <Box bg='white' w='full' p='5' rounded="6px">
        <Loader />
      </Box>
    );
  }
  if (!form) {
    return (
      <Box bg='white' w='full' p='5' rounded="6px">
        <Text fontWeight='900'>No form selected</Text>
      </Box>
    );
  }
  return (
    <Box bg='white' w='full' p='5' rounded='6px'>
      {header}
      <>
        <Box overflowY='auto' h='calc(100vh - 225px)'>
          <DragDropContext onDragEnd={movePages}>
            <Flex alignItems='center' mb='5'>
              <Droppable
                droppableId='formPagesDroppable'
                direction='horizontal'
              >
                {(provided) => (
                  <Flex
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    alignItems='center'
                    flexFlow='no-wrap'
                    overflowY='auto'
                    maxW='full'
                    pt={3}
                    pb={1}
                    onWheel={(e) => {
                      // Custom code to enable horizontal scrolling
                      const findElement = (el) => {
                        if (el.dataset.rbdDroppableId === "formPagesDroppable") {
                          return el;
                        }
                        return findElement(el.parentElement);
                      }
                      const element = findElement(e.target);
                      element.scrollLeft = element.scrollLeft + e.deltaY;
                    }}
                  >
                    {pages.map((page, index) => (
                      <Draggable
                        key={page._id}
                        draggableId={`page-${page.name}-${page.index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Flex
                            mr={3}
                            bg={
                              pages[selectedPage]?.index === page.index
                                ? 'breNavy'
                                : 'admin.badge.bg'
                            }
                            px='2'
                            py='1'
                            borderRadius={4}
                            w='max-content'
                            maxW='300px'
                            alignItems='center'
                            cursor='pointer'
                            onClick={() => {
                              if (fieldsInEdit.length) {
                                const confirm = window.confirm(unsavedChangesMessage);
                                if (!confirm) return;
                              }
                              setSelectedPage(index);
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Can
                              action="formTemplates.edit"
                              data={{ projectTemplate: currentProjectTemplate, formTemplate: form }}
                              yes={() =>
                                <Box
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  {...provided.dragHandleProps}
                                >
                                  <DotsHorizontal
                                    color='templateContent.verticalDotsColor'
                                    mr={1}
                                    ml="-1px"
                                    mt="-3px"
                                  />
                                </Box>
                              }
                            />

                            <Text
                              color={
                                pages[selectedPage]?.index === page.index
                                  ? 'white'
                                  : 'breBlack'
                              }
                              fontSize='smm'
                              whiteSpace='nowrap'
                              isTruncated
                              textOverflow='ellipsis'
                            >
                              {page.name || 'Main'}
                            </Text>
                            {pages[selectedPage]?.index === page.index && (
                              <Can
                                action="formTemplates.edit"
                                data={{ projectTemplate: currentProjectTemplate, formTemplate: form }}
                                yes={() =>
                                  <Flex cursor='pointer' ml='1' alignItems='center'>
                                    <Popover placement='bottom-start'>
                                      <PopoverTrigger>
                                        <DotsHorizontal color='white' transform="rotate(90deg)" />
                                      </PopoverTrigger>
                                      <PopoverContent w='fit-content'>
                                        <PopoverBody>
                                          <Flex flexDir='column'>
                                            <Flex
                                              alignItems='center'
                                              onClick={() => {
                                                onOpen();
                                                setEdit(true);
                                              }}
                                            >
                                              <EditIcon mr='2' /> Edit page
                                            </Flex>
                                            <Flex
                                              mt='2'
                                              alignItems='center'
                                              onClick={duplicatePage}
                                            >
                                              <DuplicatePageIcon mr='2' /> Duplicate page
                                            </Flex>
                                            <Divider marginY='2' />
                                            <Flex
                                              alignItems='center'
                                              color='brePink'
                                              onClick={() => {
                                                setItemToRemove({ type: 'page', _id: page._id });
                                                onConfirmationOpen();
                                              }}
                                            >
                                              <DeleteIcon
                                                mr='2'
                                                w='22'
                                                stroke='brePink'
                                              />
                                              Delete page
                                            </Flex>
                                          </Flex>
                                        </PopoverBody>
                                      </PopoverContent>
                                    </Popover>
                                  </Flex>
                                }
                              />
                            )}
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <Can
                      action="formTemplates.edit"
                      data={{ projectTemplate: currentProjectTemplate, formTemplate: form }}
                      yes={() =>
                        <>
                          {!!pages?.length && (
                            <Divider
                              opacity={1}
                              orientation='vertical'
                              border='1.5px solid'
                              borderColor='admin.badge.bg'
                              h='27px'
                              mr={3}
                            />
                          )}

                          <Box>
                            <Button
                              borderRadius='26px'
                              height='24px'
                              fontSize='12px'
                              bg='admin.dividerButton.bg'
                              color='admin.dividerButton.color'
                              _hover={{
                                bg: 'admin.dividerButton.bgHover',
                                color: 'admin.dividerButton.hoverColor',
                              }}
                              onClick={() => {
                                setEdit(false);
                                onOpen();
                              }}
                            >
                              + Add a page
                            </Button>
                          </Box>
                        </>
                      }
                    />
                  </Flex>
                )}
              </Droppable>
            </Flex>
          </DragDropContext>
          {Boolean(sections.length) && (
            <DragDropContext onDragEnd={moveSections}>
              <Droppable droppableId='sectionsDroppable'>
                {(provided) => (
                  <Accordion {...provided.droppableProps} ref={provided.innerRef} allowMultiple mt='5' >
                    {renderSections()}
                  </Accordion>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {Boolean(pages.length) && selectedPage !== -1 && selectedPage < pages.length && (
            <Can
              action="formTemplates.edit"
              data={{ projectTemplate: currentProjectTemplate, formTemplate: form }}
              yes={() => <DividerButton text='+ Add Section' onClick={insertSection} />}
            />
          )}
        </Box>
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          onClose={onConfirmationClose}
          modalText={`Do you want to delete ${itemToRemove?.type}`}
          confirmAction={remove}
        />
        <Modal
          onClose={() => {
            setPageName('');
            onClose();
          }}
          isOpen={isOpen}
          heading={edit ? 'Edit page' : 'Add new page'}
          saveButtonType='button'
          saveButtonText={
            edit ? (
              'Save page'
            ) : (
              <>
                <PlusIcon stroke='white' mr='2' /> Create page
              </>
            )
          }
          onSave={edit ? editPageName : createPage}
        >
          <Text fontWeight='bold' fontSize='ssm' mb='2'>
            Page title
          </Text>
          <Input
            defaultValue={edit ? pages[selectedPage].name : pageName}
            placeholder='Page title'
            onChange={(e) => setPageName(e.target.value)}
          />
        </Modal>
      </>
    </Box>
  );
};

export default TemplateContent;

export const templateContentStyles = {
  templateContent: {
    verticalDotsColor: "#D6CFDC",
  },
};
