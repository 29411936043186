import { createIcon } from "@chakra-ui/icons";

const DrawingIcon = createIcon({
  displayName: "Drawing",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path d="M10.3283 10.7082L14.2082 6.84054C14.7181 6.33081 15.055 5.67376 15.1712 4.9622C15.2874 4.25065 15.1771 3.52056 14.8559 2.87509V2.87509C14.4492 2.0599 13.7357 1.43933 12.8721 1.14953C12.0084 0.859721 11.065 0.924352 10.2489 1.32924V1.32924C9.60333 1.65059 9.07531 2.16702 8.73971 2.8053C8.40412 3.44358 8.27799 4.17132 8.37921 4.88531L9.14297 10.2866C9.15641 10.4176 9.2067 10.5422 9.28803 10.6457C9.36936 10.7493 9.47839 10.8277 9.60248 10.8719C9.72656 10.916 9.86061 10.9241 9.98908 10.8951C10.1176 10.8661 10.2352 10.8013 10.3283 10.7082V10.7082Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.61328 10.8793L11.373 5.61853"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.46875 7.3905C3.36288 4.98313 5.1348 6.09516 5.1348 7.29885C5.1348 8.59419 2.75798 10.3539 2.75798 12.303C2.76722 12.6361 2.84329 12.9639 2.98169 13.267C3.12009 13.5701 3.31798 13.8423 3.56363 14.0674C3.80927 14.2925 4.09765 14.466 4.41163 14.5775C4.72562 14.689 5.05882 14.7363 5.39143 14.7165C6.66384 14.64 7.86108 14.0883 8.74587 13.1706"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.7094 5.67354C11.5003 5.67354 11.296 5.61155 11.1221 5.4954C10.9483 5.37925 10.8128 5.21416 10.7328 5.02101C10.6528 4.82786 10.6319 4.61532 10.6727 4.41027C10.7134 4.20523 10.8141 4.01688 10.9619 3.86905C11.1098 3.72122 11.2981 3.62055 11.5032 3.57976C11.7082 3.53897 11.9208 3.55991 12.1139 3.63991C12.3071 3.71992 12.4721 3.8554 12.5883 4.02923C12.7044 4.20306 12.7664 4.40743 12.7664 4.61649C12.7664 4.89684 12.6551 5.1657 12.4568 5.36394C12.2586 5.56217 11.9897 5.67354 11.7094 5.67354Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default DrawingIcon;
