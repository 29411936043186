import { createIcon } from "@chakra-ui/icons";

const AdminUserIcon = createIcon({
  displayName: "AdminUser",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path d="M15.3327 6.65507C15.333 8.73489 14.5966 10.7476 13.2542 12.3362C11.9118 13.9248 10.0501 14.9865 7.99935 15.3332C5.94862 14.9865 4.08693 13.9248 2.74452 12.3362C1.40211 10.7476 0.665735 8.73489 0.666016 6.65507V1.64428C0.666016 1.38496 0.769031 1.13626 0.9524 0.952888C1.13577 0.76952 1.38447 0.666504 1.64379 0.666504H14.3549C14.6142 0.666504 14.8629 0.76952 15.0463 0.952888C15.2297 1.13626 15.3327 1.38496 15.3327 1.64428V6.65507Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.79883 5.79961C5.79883 6.38309 6.03061 6.94266 6.44319 7.35524C6.85577 7.76782 7.41535 7.99961 7.99883 7.99961C8.5823 7.99961 9.14188 7.76782 9.55446 7.35524C9.96704 6.94266 10.1988 6.38309 10.1988 5.79961C10.1988 5.21613 9.96704 4.65655 9.55446 4.24397C9.14188 3.83139 8.5823 3.59961 7.99883 3.59961C7.41535 3.59961 6.85577 3.83139 6.44319 4.24397C6.03061 4.65655 5.79883 5.21613 5.79883 5.79961V5.79961Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5113 11.096C11.1837 10.4457 10.6819 9.89912 10.0619 9.51722C9.44189 9.13532 8.728 8.93311 7.99981 8.93311C7.27161 8.93311 6.55773 9.13532 5.93771 9.51722C5.31769 9.89912 4.81591 10.4457 4.48828 11.096" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.1999 5.87216C9.47378 6.08125 8.70612 6.10011 7.97063 5.92694C7.23514 5.75377 6.55653 5.39438 6 4.8833" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default AdminUserIcon;
