import { createIcon } from '@chakra-ui/icons';

const RollerIcon = createIcon({
  path: (
    <>
      <path
        d='M15.0003 1.66699H5.83366C4.91449 1.66699 4.16699 2.41449 4.16699 3.33366V5.83366C4.16699 6.75283 4.91449 7.50033 5.83366 7.50033H15.0003C15.9195 7.50033 16.667 6.75283 16.667 5.83366V3.33366C16.667 2.41449 15.9195 1.66699 15.0003 1.66699ZM5.83366 5.83366V3.33366H15.0003L15.002 5.83366H5.83366Z'
        fill='currentColor'
      />
      <path
        d='M10.8337 12.5003V10.8337C10.8337 9.91449 10.0862 9.16699 9.16699 9.16699H3.33366V4.16699C2.41449 4.16699 1.66699 4.91449 1.66699 5.83366V9.16699C1.66699 10.0862 2.41449 10.8337 3.33366 10.8337H9.16699V12.5003C8.94598 12.5003 8.73402 12.5881 8.57774 12.7444C8.42146 12.9007 8.33366 13.1126 8.33366 13.3337V17.5003C8.33366 17.7213 8.42146 17.9333 8.57774 18.0896C8.73402 18.2459 8.94598 18.3337 9.16699 18.3337H10.8337C11.0547 18.3337 11.2666 18.2459 11.4229 18.0896C11.5792 17.9333 11.667 17.7213 11.667 17.5003V13.3337C11.667 13.1126 11.5792 12.9007 11.4229 12.7444C11.2666 12.5881 11.0547 12.5003 10.8337 12.5003Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default RollerIcon;
