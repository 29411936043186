import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import useValidate from '../../../hooks/useValidate';
import TableHeader from '../TableHeader';
import AmslerChecklistTableRow from './AmslerChecklistTableRow';
import { useEffect, useState } from 'react';

interface IAmslerChecklistTable extends IField {
  data?: any;
  description?: string;
  variant?: string;
  help?: string;
  styles?: {

    font?: string
  };
}

interface IDataValues {
  checkNumber: string;
  description: string;
  projectLeader: string;
  technican: string;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const AmslerChecklistTable = ({ control, name, label, description, data, validations = {}, disabled = false, help = '', required, styles }: IAmslerChecklistTable) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);
  const [dataValues, setdataValues] = useState<IDataValues[]>(JSON.parse(JSON.stringify(data)));

  useEffect(() => {
    if (control._formValues[name].some(data => 'checkNumber' in data)) setdataValues(control._formValues[name]);
  }, [control._formValues, name]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        const updatePeopleValue = (value, index, type) => {
          let updatedData = dataValues;
          updatedData[index][type] = value;
          onChange({ target: { name, value: updatedData } });
        }

        return (
          <Box w='full' id={name} mt='none' maxW='980px'>
            {label && (
              <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
                <Box
                  color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                  fontWeight='bold'
                  fontSize='md'
                  position='static'
                  left='none'
                  zIndex={2}
                >
                  {label}
                  <Text
                    pt='5px'
                    color='textInput.labelFont.normal'
                    fontWeight='semi_medium'
                    fontSize='smm'
                  >{description}</Text>
                </Box>
              </Flex>
            )}
            <Table fontSize='smm'>
              <Thead>
                <Tr>
                  <TableHeader header='Check No.' styles={{}}></TableHeader>
                  <TableHeader header='Description' styles={{}}></TableHeader>
                  <TableHeader header='Project Lead' styles={{ paddingInline: "20px" }}></TableHeader>
                  <TableHeader header='Technician' styles={{ paddingInline: "20px" }}></TableHeader>
                </Tr>
              </Thead>
              <Tbody left='-10px'>
                {dataValues?.map((dataValues, index) =>
                  <AmslerChecklistTableRow
                    name={name}
                    key={index}
                    indexValue={index}
                    index={dataValues.checkNumber}
                    description={dataValues.description}
                    disabled={disabled}
                    control={control}
                    updatePeopleValue={updatePeopleValue}
                  />)
                }
              </Tbody>
            </Table>
            {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
          </Box >
        );
      }}
    />
  );
};

export default AmslerChecklistTable;

export const amslerChecklistTableStyles = {
  signatureTable: {

  }
};
