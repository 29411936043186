import { useEffect } from "react";

import { Flex, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toastFailed, toastSuccess } from "../../../bootstrap/config";
import AdminModal from "../../AdminModal";
import TextInput from "./../TextInput";
import SignatureApproval from "./../SignatureApproval";
import { format } from "date-fns";

const ClientContractorTableModal = ({
  modalState,
  setModalState,
  dataValues,
  setdataValues,
  selectedDataValue,
  name,
  onChange,
  formIndex,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      _id: undefined,
      name: selectedDataValue.name,
      signature: selectedDataValue.signature,
      company: selectedDataValue.company,
      supervisor: selectedDataValue.supervisor,
      supervisorSignature: selectedDataValue.supervisorSignature,
      date: selectedDataValue.date,
    },
  });
  const toast = useToast();

  useEffect(() => {
    if (selectedDataValue !== "") {
      reset(selectedDataValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataValue]);

  const handleAddClientContractor = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        const currentDate = format(new Date(), "d MMM yyyy")
        let val: object = {
          name: values.name,
          signature: values.signature,
          company: values.company,
          supervisor: values.supervisor,
          supervisorSignature: values.supervisorSignature,
          date: values.supervisorSignature != null ? currentDate : undefined,
        };
        dataValues instanceof Array
          ? dataValues.push(val)
          : (dataValues = [val]);
        setdataValues(dataValues);
        onChange({ target: { name, value: dataValues } });
        toast({ ...toastSuccess, description: "Client / Contractor added" });
        reset({
          _id: undefined,
          name: "",
          signature: undefined,
          company: "",
          supervisor: "",
          supervisorSignature: undefined,
          date: "",
        });
      } else {
        toast({
          ...toastFailed,
          description: "Please complete all the required fields",
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
    }
  };

  const handleUpdateClientContractor = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        let updatedData = [...dataValues];
        const currentDate = format(new Date(), "d MMM yyyy")
        updatedData[selectedDataValue.index] = {
          name: values.name,
          signature: values.signature,
          company: values.company,
          supervisor: values.supervisor,
          supervisorSignature: values.supervisorSignature,
          date: values.supervisorSignature != null ? currentDate : undefined,
        };
        setdataValues(updatedData);
        onChange({ target: { name, value: updatedData } });
        toast({ ...toastSuccess, description: "Client / Contractor updated" });
        reset({
          _id: undefined,
          name: "",
          signature: undefined,
          company: "",
          supervisor: "",
          supervisorSignature: undefined,
          date: "",
        });
      } else {
        toast({
          ...toastFailed,
          description: "Please complete all the required fields",
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
    }
  };

  const handleDeleteClientContractor = async () => {
    try {
      let updatedData = dataValues.filter(
        (p, index) => index !== selectedDataValue.index
      );
      setdataValues(updatedData);
      onChange({ target: { name, value: updatedData } });
      toast({ ...toastSuccess, description: "Client / Contractor deleted" });
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    if (["add", "edit"].includes(action) && !isFormValid) {
      return toast({
        ...toastFailed,
        description: "Please complete all the required fields",
      });
    }
    switch (action) {
      case "add":
        handleAddClientContractor();
        break;
      case "edit":
        handleUpdateClientContractor();
        break;
      case "delete":
        handleDeleteClientContractor();
        break;
      default:
        setModalState("closed");
    }
  };
  return (
    <AdminModal
      isOpenModal={modalState !== "closed"}
      modalType={modalState}
      onAction={handleAction}
      collection={"client / contractor"}
    >
      <Flex align="flex-start" direction="column" w="full">
        <TextInput
          control={control}
          name="name"
          placeholder=""
          label="Name"
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />
        <SignatureApproval
          control={control}
          name="signature"
          label="Signature"
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />
        <TextInput
          control={control}
          name="company"
          placeholder=""
          label="Company"
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />
        <TextInput
          control={control}
          name="supervisor"
          placeholder=""
          label="Supervisor"
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />
        <SignatureApproval
          control={control}
          name="supervisorSignature"
          label="Supervisor Signature"
          validations={{ notEmpty: true }}
        />
      </Flex>
    </AdminModal>
  );
};

export default ClientContractorTableModal;
