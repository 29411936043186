import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  ListItem,
  OrderedList,
} from '@chakra-ui/react';

import { IField } from '../../../interfaces/IField';
import TableHeader from '../TableHeader';
import PeerReviewTableRow from './PeerReviewTableRow';
import { useEffect, useState } from 'react';
import useGetForm from '../../../hooks/useGetForm';
import { Asterisk } from '../../../icons';

interface IPeerReviewTable extends IField {
  data?: any;
  description?: string;
  variant?: string;
  help?: string;
  styles?: {
    font?: string;
  };
}

interface IDataValues {
  iso_standard: string;
  '17065': string;
  '17025': string;
  '17020': string;
  '17021': string;
  '17024': string;
  '9001': string;
  '14001': string;
  '27001': string;
  '45001': string;
}

const PeerReviewTable = ({
  name,
  label,
  description,
  data,
  styles,
}: IPeerReviewTable) => {
  const [dataValues, setDataValues] = useState<IDataValues[]>(
    JSON.parse(JSON.stringify(data))
  );

  useEffect(() => {
    setDataValues(data);
  }, [data]);

  return (
    <Box w='full' id={name} mt='none' maxW='980px'>
      {label && (
        <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
          <Box
            color={styles ? styles?.font : 'textInput.labelFont.normal'}
            fontWeight='bold'
            fontSize='md'
            position='static'
            left='none'
            zIndex={2}
          >
            {label}
            <Text
              pt='5px'
              color='textInput.labelFont.normal'
              fontWeight='semi_medium'
              fontSize='smm'
            >
              {description}
            </Text>
          </Box>
        </Flex>
      )}
      <Table>
        <Thead>
          <Tr>
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='ISO Standard:'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='17065'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='17025'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='17020'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='17021'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='17024'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='9001'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='14001'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='27001'
            />
            <TableHeader
              styles={{ fontSize: '14px', fontWeight: 'bold' }}
              header='45001'
            />
          </Tr>
        </Thead>
        <Tbody>
          {dataValues?.map((dataValues, index) => (
            <PeerReviewTableRow
              key={`peer-review-instruction-${index}`}
              data={dataValues}
            />
          ))}
        </Tbody>
      </Table>
      <Box mt='4' lineHeight={1.35}>
        <Box>
          <Flex fontSize='md' fontWeight='bold'>
            1. <Text ml='3'>Scope</Text>
          </Flex>
          <Text fontSize='smm' mt='1'>
            Positioning of thermocouples has been identified as an area that
            could result in inconsistencies between Project Leaders. To ensure
            consistency across the team, regular peer review is essential.
          </Text>
        </Box>
        <Box my='3'>
          <Flex fontSize='md' fontWeight='bold'>
            2. <Text ml='3'>Requirement</Text>
          </Flex>
          <Text fontSize='smm' mt='1'>
            Each Project Leader is to undertake (and document in the below
            table) a minimum of 10 peer reviews per year covering a range of
            different test types. Peer review is to be undertaken prior to
            thermocouple installation and test (to allow for easy adjustment if
            required) and should be conducted against the requirements of the
            relevant Test Standard. Any changes are to be discussed and noted as
            comments in the below table. Any differences of opinion are to be
            escalated for wider discussion and agreement.
          </Text>
        </Box>
        <Flex fontSize='smm'>
          <Text fontWeight='bold'>Record keeping: </Text>
          <Text ml='1'>
            in addition to maintaining a personal record of the table below the
            following will also be recorded:
          </Text>
        </Flex>
        <OrderedList fontSize='smm' my='2'>
          <ListItem>Sketch/photo of thermocouple locations* will be annotated “Reviewed for routine QA work, signed, date”, scanned and saved to SharePoint location specified by Passive Compliance Manager.</ListItem>
          <ListItem>Competence record will be kept up-to-date with this recorded as a training activity.</ListItem>
        </OrderedList>
        <Text fontSize='smm'>
          *Note: this should be included in test pack by Project Leader as
          routine record-keeping
        </Text>
      </Box>
    </Box>
  );
};

export default PeerReviewTable;

export const peerReviewTableStyles = {
  signatureTable: {},
};
