import { Button, IconButton, Tr, Td } from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "../../../icons";
import { format } from "date-fns";
import TableData from "../TableData";
import { useEffect, useState } from "react";

const TechSignatureTableRow = ({ dataValue, onRemove, disabled, onEdit }) => {
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (dataValue.name) {
      const getName = async () => {
        // const name = (await getUserDetails(dataValue.name))?.displayName;
        setName(name);
      };
      getName();
    }
  }, [dataValue.name]);

  return (
    <Tr>
      <TableData
        data={dataValue.name ? name : "-"}
        dataType="text"
        styles={{ paddingInline: "0px" }}
      />
      <TableData
        data={dataValue.signature ? true : false}
        dataType="check"
        styles={{ paddingInline: "10px" }}
      />
      <TableData
        data={
          dataValue.date ? format(new Date(dataValue.date), "dd MMM yyyy") : "-"
        }
        dataType="text"
      />
      {!disabled && (
        <>
          <Td fontWeight="semi_medium" paddingInline="0px">
            <Button
              disabled={disabled}
              _disabled={{ opacity: 0.75, cursor: "not-allowed" }}
              bg="TechSignatureTableRow.button.bg"
              h="30px"
              color="TechSignatureTableRow.button.color"
              pl="5px"
              pr="10px"
              fontSize="ssm"
              borderRadius="10px"
              borderWidth="1px"
              borderColor="TechSignatureTableRow.button.color"
              onClick={onEdit}
            >
              <EditIcon
                w="12px"
                h="12px"
                ml="5px"
                mr="8px"
                stroke="TechSignatureTableRow.editIcon.stroke"
              />
              Edit
            </Button>
          </Td>
          <Td fontWeight="semi_medium" paddingInline="20px">
            <IconButton
              bg="TechSignatureTableRow.deleteIcon.bg"
              aria-label="delete"
              size="xs"
              isRound={true}
              disabled={disabled}
              _disabled={{ opacity: 0.75, cursor: "not-allowed" }}
              icon={
                <DeleteIcon
                  h="15"
                  w="15"
                  stroke="TechSignatureTableRow.deleteIcon.stroke"
                  onClick={onRemove}
                />
              }
            />
          </Td>
        </>
      )}
    </Tr>
  );
};

export default TechSignatureTableRow;

export const techSignatureTableRowStyles = {
  techSignatureTableRow: {
    button: {
      bg: "#FFFFFF",
      color: "#818197",
    },
    editIcon: {
      stroke: "#FFFFFF",
    },
    deleteIcon: {
      bg: "#F0F0F0",
      stroke: "#818197",
    },
  },
};
