import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, Divider, Flex, Icon, Image, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { leftNavItems, toastSuccess } from "../../bootstrap/config";
import { ChevronDown, ConformeSmall } from "../../icons";
import { useProjectsContext } from "../../contexts/ProjectsProvider";
import NavigationLeftItem from "./NavigationLeftItem";
import logo from "../../images/bre-logo.svg"
import ProjectLeftItem from "./ProjectLeftItem";
import { IForm } from "../../interfaces/IForm";
import { useMemo } from "react";
import ConfirmationModal from "../ConfirmationModal";
import { useMutation } from "@apollo/client";
import RESET_PROJECT from "../../gql/mutation/RESET_PROJECT";

const NavigationLeft = () => {
  const history = useHistory();
  const location = useLocation()
  const toast = useToast();
  const { formStates, currentProject, currentProjectTemplate } = useProjectsContext();
  const { pathname } = location;
  const {
    isOpen: isResetConfirmationOpen,
    onOpen: onResetConfirmationOpen,
    onClose: onResetConfirmationClose,
  } = useDisclosure();
  const [resetProjectFnc] = useMutation(RESET_PROJECT);

  const canResetProject = useMemo(() => {
    if (currentProjectTemplate?.isUkasRelated) {
      const hasAnyValues = (formStates || []).some(form =>
        form.iterations.every(iteration =>
          Object.keys(iteration.values || {}).length !== 0));
      return !hasAnyValues;
    }
    return true;
  }, [formStates, currentProjectTemplate]);

  const getIconBGColor = (form: IForm) => {
    if (form?.iterations.every(iteration => iteration.status === "completed")) {
      return "navigationLeft.completeIconBg";
    }
    return "navigationLeft.pendingIconBg";
  }

  const showParentSideNav = (): boolean => {
    if (['/', '/admin/templates', '/admin/common-forms', '/admin/users', '/admin/audit-logs'].includes(pathname)) {
      return true;
    }
    else if (pathname.includes('/admin/templates') || pathname.includes('/admin/common-forms')) {
      return true;
    }
    return false;
  };

  const resetProject = async () => {
    const { data } = await resetProjectFnc({
      variables: {
        projectInput: {
          projectId: currentProject?._id,
        },
      },
    });
    if (data.resetProject?._id) {
      toast({ ...toastSuccess, description: 'Project reset successfully' });
      history.push('/');
    }
  }

  return (
    <>
      <ConfirmationModal
        isOpen={isResetConfirmationOpen}
        onClose={onResetConfirmationClose}
        modalTitle='Do you want to reset this project?'
        modalText='All project data will be cleared and status changed to "Not started".'
        confirmAction={resetProject}
      />
      <Box h="full" bg="white" fontWeight="semibold" w="250px" overflow="hidden" mr="20px">
        <Box
          display="flex"
          alignItems="center"
          h="80px"
          onClick={() => { history.push('/') }}
          cursor="pointer"
          pl={5}
          pt={4}
        >
          <Image src={logo} w="46px" />
          <Box
            fontWeight="700"
            pl={4}
            w="150px"
            fontSize="14px"
            color="breNavy"
          >
            Testing Process Tracker
          </Box>
        </Box>
        <Flex
          direction="column"
          justify="space-between"
          h="calc(100% - 85px)"
          pt={["0px", "10px"]}
        >
          <Box pt={3} h="calc(100vh - 155px)" >
            {showParentSideNav() &&
              leftNavItems.map((leftNavItem) =>
                <NavigationLeftItem
                  key={leftNavItem.label}
                  {...leftNavItem}
                />
              )
            }
            {pathname.includes('project') && formStates &&
              <>
                <Flex
                  align="center"
                  fontSize="14px"
                  fontWeight="normal"
                  color="navigationLeft.backText"
                  ml="35px"
                  mb="15px"
                  cursor="pointer"
                  onClick={() => history.push('/')}
                >
                  <ChevronDown transform="rotate(90deg)" mr="15px" />
                  Back to projects
                </Flex>
                <Box h="calc(100% - 35px)" overflowY="auto" overflowX="hidden">
                  {formStates?.map((form, i) =>
                    <ProjectLeftItem
                      key={form._id}
                      form={form}
                      index={i + 1}
                      iconBackground={getIconBGColor(form)}
                      iconColor='navigationLeft.iconColorLight'
                    />
                  )}
                  <Box
                    my="8px"
                    ml="25px"
                    display="flex"
                    pos="relative"
                    alignItems="center"
                    fontSize="smm"
                    fontWeight="normal"
                  >
                    <Divider orientation='horizontal' bg='#131535' opacity='0.1' h='1px' />
                  </Box>
                  <ProjectLeftItem
                    form={{
                      name: "Audit log",
                      url: "audit-log",
                      icon: "AuditLogIcon"
                    }}
                    index={0}
                    iconBackground={location.pathname.split('/').splice(-1)[0] === "audit-log" ? 'navigationLeft.auditIconBg' :
                      'navigationLeft.unSelectedIconBg'}
                    iconColor={location.pathname.split('/').splice(-1)[0] === "audit-log" ? 'navigationLeft.iconColorLight' :
                      'breNavy'}
                    isDisabled={false}
                  />
                  <ProjectLeftItem
                    form={{
                      name: "Generate report",
                      icon: "SaveIcon"
                    }}
                    index={-1}
                    iconBackground='navigationLeft.unSelectedIconBg'
                    iconColor='breNavy'
                    isDisabled={false}
                  />
                  {canResetProject && (
                    <Flex
                      justify="center"
                      w='full'
                    >
                      <Button
                        bg="brePink"
                        color="white"
                        fontSize="sm"
                        h="32px"
                        onClick={onResetConfirmationOpen}
                      >Reset project</Button>
                    </Flex>
                  )}
                </Box>
              </>
            }
          </Box>
          <Flex position="absolute" bottom={0} flexDir="column" mb="15px" ml="20px" opacity={.5}>
            <Text fontSize="11px" fontWeight="700" pb="2px">Powered by</Text>
            <Flex align="center" fontSize="14px" fontWeight="700"><Icon as={ConformeSmall} w="27px" h="30px" mr="10px" />Conforme</Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default NavigationLeft;

export const navigationLeftStyles = {
  navigationLeft: {
    bg: "#E5E5E5",
    unSelectedIconBg: "#ffffff",
    pendingIconBg: "#FF9A00",
    completeIconBg: "#41B916",
    iconColorLight: "#ffffff",
    iconColorDark: "#818197",
    auditIconBg: "#131535",

    backText: "#818197"
  }
}
