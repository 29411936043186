import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { IField } from '../components/TemplateBuilder/defaults';
import { useProjectsContext } from '../contexts/ProjectsProvider';
import { EOperationType } from '../interfaces/EOperationType';
import { IFormRow, IReportFormSection } from '../models/reportform';

const useTemplateFields = (
  section: IReportFormSection,
  selectedPage: number,
  row: IFormRow
) => {
  const { setPages, setOperationType } = useProjectsContext();
  const [fields, setFields] = useState<IField[]>(row.fields || []);

  useEffect(() => {
    setFields(row.fields);
  }, [row]);

  const createField = (field: IField) => {
    setOperationType(EOperationType.ADDED_FIELD);
    setPages((prevPages) => {
      return prevPages.map((page, index) => {
        if (index === selectedPage) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const updatedRows = sec.rows.map((r) => {
                if (r.index === row.index) {
                  const updatedFields = [
                    ...r.fields,
                    {
                      ...field,
                      _id: uuidv4(),
                    },
                  ];
                  return { ...r, fields: updatedFields };
                }
                return r;
              });
              return { ...sec, rows: updatedRows };
            }
            return sec;
          });
          return { ...page, sections: updatedSections };
        }
        return page;
      });
    });
  };

  const updateField = (index: number, updatedProperties: Partial<IField>) => {
    setOperationType(EOperationType.UPDATED_FIELD);
    setPages((prevPages) => {
      return prevPages.map((page) => {
        if (page.index === selectedPage + 1) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const updatedRows = sec.rows.map((r) => {
                if (r.index === row.index) {
                  const updatedFields = r.fields.map((field, fieldIndex) => {
                    if (fieldIndex === index) {
                      return { ...field, ...updatedProperties };
                    }
                    return field;
                  });
                  return { ...r, fields: updatedFields };
                }
                return r;
              });
              return { ...sec, rows: updatedRows };
            }
            return sec;
          });
          return { ...page, sections: updatedSections };
        }
        return page;
      });
    });
  };

  const removeField = (index: number) => {
    setOperationType(EOperationType.DELETED_FIELD);
    setPages((prevPages) => {
      return prevPages.map((page) => {
        if (page.index === selectedPage + 1) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const updatedRows = sec.rows.map((r) => {
                if (r.index === row.index) {
                  const updatedFields = r.fields.filter(
                    (_, fieldIndex) => fieldIndex !== index
                  );
                  return { ...r, fields: updatedFields };
                }
                return r;
              });
              return { ...sec, rows: updatedRows };
            }
            return sec;
          });
          return { ...page, sections: updatedSections };
        }
        return page;
      });
    });
  };

  const moveFields = (event) => {
    setOperationType(EOperationType.REORDERED_FIELDS);
    if (!event.source || !event.destination) {
      return;
    }
    setPages((prevPages) => {
      return prevPages.map((page) => {
        if (page.index === selectedPage + 1) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const updatedRows = sec.rows.map((r) => {
                if (r.index === row.index) {
                  const updatedFields = Array.from(row.fields || []);
                  const [removed] = updatedFields.splice(event.source.index, 1);
                  updatedFields.splice(event.destination.index, 0, removed);
                  return { ...r, fields: updatedFields };
                }
                return r;
              });
              return { ...sec, rows: updatedRows };
            }
            return sec;
          });
          return { ...page, sections: updatedSections };
        }
        return page;
      });
    });
  };

  return {
    fields,
    createField,
    updateField,
    removeField,
    moveFields,
  };
};

export default useTemplateFields;
