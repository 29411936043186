import { createIcon } from "@chakra-ui/icons";

const PeopleIcon = createIcon({
  path: (
    <path 
      d="M2.0806 3.0556c0 .5672.2479 1.1113.689 1.5124.4413.401 1.0397.6264 1.6637.6264.624 0 1.2225-.2253 1.6637-.6264.4412-.4011.6891-.9452.6891-1.5124 0-.5673-.2479-1.1114-.689-1.5125C5.6557 1.142 5.0572.9167 4.4332.9167c-.624 0-1.2224.2253-1.6636.6264-.4412.4011-.6891.9452-.6891 1.5125v0ZM.5833 9.4722c0-.9283.4056-1.8185 1.1276-2.4749.722-.6563 1.7013-1.0251 2.7224-1.0251 1.021 0 2.0003.3688 2.7223 1.0251.722.6564 1.1277 1.5466 1.1277 2.4749M8.3413 4.2222c0 .4642.2028.9093.5638 1.2375.361.3281.8507.5125 1.3612.5125s1.0002-.1844 1.3612-.5125c.361-.3282.5638-.7733.5638-1.2375 0-.4641-.2028-.9092-.5638-1.2374-.361-.3282-.8507-.5126-1.3612-.5126s-1.0002.1844-1.3612.5126c-.361.3282-.5638.7733-.5638 1.2374v0ZM9.1748 6.7852a3.4474 3.4474 0 0 1 1.4915-.1534c.5035.0588.9839.2272 1.4004.491.4165.2639.7567.6153.9917 1.0243.2351.409.358.8636.3582 1.3251" 
      stroke="currentColor" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      fill="none"
    />
  ),
  viewBox: "0 0 14 10",
});

export default PeopleIcon;
