import { createIcon } from "@chakra-ui/icons";

const EditIcon = createIcon({
  displayName: "Edit",
  viewBox: "0 0 12 12",
  path: (
    <>
      <path d="M6.51072 7.04447L4.69604 7.30406L4.95514 5.48889L9.62187 0.82217C9.82815 0.615888 10.1079 0.5 10.3997 0.5C10.5441 0.5 10.6871 0.528451 10.8206 0.583729C10.954 0.639007 11.0753 0.72003 11.1774 0.82217C11.2796 0.92431 11.3606 1.04557 11.4159 1.17902C11.4712 1.31247 11.4996 1.45551 11.4996 1.59996C11.4996 1.74441 11.4712 1.88744 11.4159 2.02089C11.3606 2.15435 11.2796 2.2756 11.1774 2.37774L6.51072 7.04447Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.10303 1.34082L10.6586 2.89639"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.2996 7.1V10.7665C9.2996 10.961 9.22235 11.1475 9.08482 11.285C8.9473 11.4225 8.76079 11.4998 8.5663 11.4998H1.2333C1.03882 11.4998 0.852299 11.4225 0.714779 11.285C0.577258 11.1475 0.5 10.961 0.5 10.7665V3.4335C0.5 3.23901 0.577258 3.05249 0.714779 2.91497C0.852299 2.77745 1.03882 2.7002 1.2333 2.7002H4.8998"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />


    </>
  ),
});

export default EditIcon;
