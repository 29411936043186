import { IField } from '../../interfaces/IField';
import {
  Checkbox,
  AmslerChecklistTable,
  ClientContractorTable,
  Datepicker,
  Dropdown,
  FreeHandInput,
  ImageCapture,
  MinutesObservationTable,
  MinutesTable,
  PeerReviewTable,
  PeoplePicker,
  RecordKeepingTable,
  RichTextEditor,
  SignatureApproval,
  StaticImage,
  TechSignatureTable,
  TextInput,
  Toggle,
  StaticTable,
  TableInput,
} from "../FormControls";
import RadioButton from './RadioButton';
import StaticText from './StaticText';

const Field = ({ control, field, formIndex }) => {
  const { type, name, label, tooltip, disabled, options, validations, headings, variant, placeholder, help, styles, required,
    defaultvalue, data, secondarylabel, canSelectFutureDate, clearAfterEdit, image, columns } = field;
  const props: IField = {
    control,
    name,
    label,
    secondarylabel,
    disabled,
    options,
    validations,
    headings,
    placeholder,
    variant,
    tooltip,
    help,
    styles,
    required,
    defaultvalue,
    data,
    formIndex,
    canSelectFutureDate: !!canSelectFutureDate,
    clearAfterEdit: !!clearAfterEdit,
    image,
    columns,
  };

  switch (type) {
    case 'amslerChecklistTable': { // migrated to static table
      return <AmslerChecklistTable key={name} {...props} />;
    }
    case 'checkbox': {
      return <Checkbox key={name} {...props} />;
    }
    case 'clientContractorTable': { // migrated to dynamic table
      return <ClientContractorTable key={name} {...props} />;
    }
    case 'datepicker': {
      return <Datepicker key={name} {...props} />;
    }
    case 'dropdown': {
      return <Dropdown key={name} {...props} />;
    }
    case 'freeHandInput': {
      return <FreeHandInput key={name} {...props} />;
    }
    case 'imageCapture': {
      return <ImageCapture key={name} {...props} />;
    }
    case 'minutesObservationTable': { // migrated to dynamic table
      return <MinutesObservationTable key={name} {...props} />;
    }
    case 'minutesTable': {  // migrated to static table
      return <MinutesTable key={name} {...props} />;
    }
    case 'peerReviewTable': {  // migrated to static table
      return <PeerReviewTable key={name} {...props} />
    }
    case 'peoplePicker': {
      return <PeoplePicker key={name} {...props} />
    }
    case 'radio': {
      return <RadioButton key={name} {...props} />
    }
    case 'recordKeepingTable': {  // migrated to dynamic table
      return <RecordKeepingTable key={name} {...props} />;
    }
    case 'signatureApproval': {
      return <SignatureApproval key={name} {...props} />
    }
    case 'techSignatureTable': { // migrated to dynamic table
      return <TechSignatureTable key={name} {...props} />;
    }
    case 'textInput': {
      return <TextInput key={name} {...props} />;
    }
    case 'toggle': {
      return <Toggle key={name} {...props} />;
    }
    case 'richTextInput': {
      return <RichTextEditor key={name} {...props} />
    }
    case 'staticText': {
      return <StaticText key={name} {...props} />
    }
    case 'staticImage': {
      return <StaticImage key={name} {...props} />
    }
    case 'staticTableInput': {
      return <StaticTable key={name} {...props} />
    }
    case 'tableInput': {
      return <TableInput key={name} {...props} />
    }
    case 'projectData': {
      return <TextInput key={name} {...props} disabled />;
    }
    default:
      return <div>Field "{name}" of type "{type}" is not supported</div>;
  }
};

export default Field;
