import { useEffect, useRef } from 'react';

import { Box, Button, Flex, useOutsideClick } from '@chakra-ui/react';

import { useFiltersContext } from '../../contexts/FiltersProvider';
import useDevice from '../../hooks/useDevice';
import { CrossIcon } from '../../icons';
import FiltersPanelItem from './FiltersPanelItem';

const availableFilters = [{
  name: 'status',
  label: 'Status',
}, {
  name: 'clientId',
  label: 'Client',
}, {
  name: 'projectTemplateId',
  label: 'Template',
}, {
  name: 'category',
  label: 'Lab number',
}, {
  name: 'projectLeadId',
  label: 'Project lead',
}, {
  name: 'testersIds',
  label: 'Tester',
}, {
  name: 'traineesIds',
  label: 'Trainee',
}];

const FiltersPanel = () => {
  const { showFiltersPanel, setShowFiltersPanel, defaultFilters, setFilters, refetchFiltersOptions } = useFiltersContext();
  const panelRef = useRef(null);
  const device = useDevice();
  useOutsideClick({
    ref: panelRef,
    handler: () => setShowFiltersPanel(false),
  });
  useEffect(() => {
    refetchFiltersOptions();

    if (showFiltersPanel && device === 'mobile') document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [device, refetchFiltersOptions, showFiltersPanel]);

  if (!showFiltersPanel) return null;

  return (
    (<Flex
      bg="filterPanel.bg"
      borderBottomStartRadius={['0px', '20px']}
      boxShadow="md"
      direction="column"
      h="100vh"
      position={['relative', 'absolute']}
      ref={panelRef}
      right="0"
      shrink={0}
      w={['full', '320px']}
      zIndex="10">
      <Flex
        align="center"
        basis={['55px', '65px']}
        justify="space-between"
        px="4"
        shrink={0}>
        <Box
          color="brand.darkGrey"
          fontSize="16px"
          fontWeight="700">
          Filter items by
        </Box>
        <CrossIcon
          cursor="pointer"
          onClick={() => setShowFiltersPanel(false)}
          stroke="filterPanel.closeIconColor" />
      </Flex>
      <Flex
        direction="column"
        grow={1}
        overflowY="auto"
        px="4">
        {availableFilters.map(({ name, label }) => <FiltersPanelItem label={label} key={name} name={name} />)}
      </Flex>
      <Flex
        align="center"
        basis={['60px', '70px']}
        bg="filterPanel.bg"
        borderBottomStartRadius={['0px', '20px']}
        bottom={0}
        boxShadow={['0px 0px 80px rgba(49, 50, 51, 0.15)', 'none']}
        justify="center"
        position={['sticky', 'relative']}
        shrink={0}
        w="full">
        <Button
          _hover={{ opacity: 0.9 }}
          color="filterPanel.resetButtonColor"
          fontSize="14px"
          h="35px"
          onClick={() => setFilters(defaultFilters)}
          w={['40%', '115px']}>
          Reset all
        </Button>
        <Button
          _hover={{ opacity: 0.9 }}
          bg="filterPanel.doneButtonBg"
          color="filterPanel.doneButtonColor"
          fontSize="14px"
          h="35px"
          ml="10px"
          onClick={() => setShowFiltersPanel(false)}
          w={['40%', '115px']}>
          Done
        </Button>
      </Flex>
    </Flex>)
  );
};

export default FiltersPanel;

export const filtersPanelStyles = {
  filterPanel: {
    bg: 'white',
    closeIconColor: '#000',
    doneButtonBg: '#462AC4',
    doneButtonColor: '#ffffff',
    resetButtonBg: '#F0F2F5',
    resetButtonColor: '#818197',
    searchBoxBordercolor: '#81819750',
  },
};
