import { createIcon } from "@chakra-ui/icons";

const ShareIcon = createIcon({
  path: (
    <>
      <path fill="none" d="M10.8281 5.63037H11.7448C11.9879 5.63037 12.2211 5.72695 12.393 5.89886C12.5649 6.07076 12.6615 6.30392 12.6615 6.54704V13.8804C12.6615 14.1235 12.5649 14.3566 12.393 14.5286C12.2211 14.7005 11.9879 14.797 11.7448 14.797H3.49479C3.25168 14.797 3.01852 14.7005 2.84661 14.5286C2.6747 14.3566 2.57812 14.1235 2.57812 13.8804V6.54704C2.57812 6.30392 2.6747 6.07076 2.84661 5.89886C3.01852 5.72695 3.25168 5.63037 3.49479 5.63037H4.41146"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M7.61963 1.04688V7.46354"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M5.32812 3.33854L7.61979 1.04688L9.91146 3.33854"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
  viewBox: "0 0 15 16",
});

export default ShareIcon;
