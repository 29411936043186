import { createIcon } from "@chakra-ui/icons";

const FurnaceClosureIcon = createIcon({
  displayName: "FurnaceClosure",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M4.84131 9.06635L5.41284 3.06274H8.03583L8.51736 8.1149"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.5 13.4167H9.85039V8.6456C9.85039 8.48318 9.78587 8.32742 9.67103 8.21257C9.55618 8.09772 9.40041 8.0332 9.23799 8.0332C9.18618 8.03318 9.13458 8.03986 9.08448 8.05308L3.91416 9.30825C3.79565 9.33898 3.69072 9.40821 3.61586 9.50507C3.54099 9.60193 3.50043 9.72092 3.50055 9.84334L3.5 13.4167Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.39911 1.20119C6.96904 1.19102 7.53407 1.30817 8.05297 1.54411C9.98846 1.96377 10.8168 0.583252 10.8168 0.583252"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.53717 11.2078H5.70886"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default FurnaceClosureIcon;
