import {
  Flex,
  Stack,
  Heading,
  Badge,
  Button,
  useDisclosure,
  Box,
  AvatarGroup,
  Avatar,
  Tooltip,
} from "@chakra-ui/react";

import { useProjectsContext } from "../../../contexts/ProjectsProvider";
import ProjectHeaderStatus from "../ProjectHeaderStatus";
import GlossaryModal from "../GlossaryModal";
import { GlossaryIcon, SaveIcon } from "../../../icons";
import Can from "../../Can";
import ProjectTeamModal from "./ProjectTeamModal";
import { useMemo } from "react";
import { useAppContext } from "../../../contexts/AppProvider";

const ProjectFormHeader = ({
  completed,
}) => {
  const { settings } = useAppContext();
  const { currentProject, autoSaving } = useProjectsContext();
  const {
    isOpen: isOpenTeam,
    onOpen: onOpenTeam,
    onClose: onCloseTeam,
  } = useDisclosure();
  const {
    isOpen: isOpenGlossary,
    onOpen: onOpenGlossary,
    onClose: onCloseGlossary,
  } = useDisclosure();

  const teamMembers = [
    ...(currentProject?.projectLead ? [currentProject?.projectLead] : []),
    ...(currentProject?.testers || []),
    ...(currentProject?.trainees || []),
  ];

  const additionalProperties = useMemo(() => {
    return settings?.projectsProperties?.map(property => {
      let value = '';
      currentProject?.forms?.forEach((form) => {
        if (form.iterations[0]?.values[property.key]) {
          value = form.iterations[0]?.values[property.key].toString();
        }
      }, '');
      return {
        label: property.label,
        value,
      };
    }) || [];
  }, [currentProject?.forms, settings?.projectsProperties])

  return (
    <>
      <ProjectTeamModal isOpen={isOpenTeam} onClose={onCloseTeam} />
      <GlossaryModal isOpen={isOpenGlossary} onClose={onCloseGlossary} />
      <Flex
        direction="column"
        pl="35px"
        w="full"
        minH="120px"
        zIndex={1}
      >
        <Stack
          direction="row"
          justify="space-between"
          spacing={4}
          alignItems="center"
          w="full"
          h="40px"
          mb="15px"
          pos="relative"
        >
          <Flex>
            <Heading
              color="projectFormHeader.heading"
              fontSize="xxl"
              fontWeight="bold"
              alignItems={["flex-start", "center"]}
            >
              {currentProject?.reference}
            </Heading>
            <Badge
              ml="13"
              bg="projectFormHeader.badgeBg"
              padding="0px 15px"
              borderRadius="10px"
              fontSize="11px"
              lineHeight="28px"
              fontWeight="bold"
              colorScheme="projectFormHeader.badgeColorScheme"
              color="projectFormHeader.badge"
              textTransform="unset"
              h="28px"
            >
              {currentProject?.status}
            </Badge>
          </Flex>
        </Stack>

        <>
          <Box mb={15} mr={5}>
            <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
              <Box display={"flex"}>
                <ProjectHeaderStatus
                  heading="Client"
                  status={currentProject?.clientName!}
                />
                <ProjectHeaderStatus
                  heading="Project team"
                  status={
                    <AvatarGroup size='xs' max={5} onClick={onOpenTeam}>
                      {teamMembers.length > 0 ?
                        teamMembers.map(person => (
                          <Box as={Avatar} key={person?._id}>
                            <Tooltip hasArrow label={person?.displayName} key={person?._id}>
                              <Avatar size='xs' cursor='pointer' title={person?.displayName} name={person?.displayName} src={person?.imgUrl} />
                            </Tooltip>
                          </Box>
                        )) : (
                          <Button size='xs' bg='brePink' color='white'>Set team</Button>
                        )
                      }
                    </AvatarGroup>
                  }
                />
                {additionalProperties.map(property => (
                  <ProjectHeaderStatus
                    key={property.label}
                    heading={property.label}
                    status={property.value}
                  />
                ))}
              </Box>

              <Flex alignItems={"center"}>
                <Stack direction={"row"} spacing={7}>
                  <Box color="white" h="40px" display={"flex"}>
                    {!completed && (
                      <>
                        <Can
                          action='projects.edit'
                          data={{ project: currentProject }}
                          yes={() =>
                            <Flex
                              color="breNavy"
                              lineHeight="40px"
                              fontSize="14px"
                              h="full"
                              mr={6}
                            >
                              {autoSaving ? (
                                <Flex>Saving...</Flex>
                              ) : (
                                <Flex align="center">
                                  Up to date{" "}
                                  <SaveIcon stroke="breNavy" h="20px" ml="10px" />
                                </Flex>
                              )}
                            </Flex>
                          }
                        />
                        <Button
                          mr={3}
                          bg="white"
                          color="black"
                          onClick={onOpenGlossary}
                          _hover={{}}
                          _active={{}}
                        >
                          Glossary
                          <GlossaryIcon
                            ml="10px"
                            stroke="projectFormHeader.glossaryIconStroke"
                          />
                        </Button>
                      </>
                    )}
                  </Box>
                </Stack>
              </Flex>
            </Flex>
          </Box>
        </>
      </Flex>
    </>
  );
};

export default ProjectFormHeader;

export const projectFormHeaderStyles = {
  projectFormHeader: {
    bg: "#E5E5E5",
    heading: "#282F36",
    badge: "#131535",
    badgeBg: "#FFFFFF",
    badgeColorScheme: "white",
    buttonDarkBg: "#DC0043",
    buttonDarkColor: "#FFFFFF",
    buttonDarkBgHover: "#DC0043",
    buttonDarkColorHover: "#FFFFFF",
    buttonLightBg: "#FFFFFF",
    buttonLightColor: "#131535",
    buttonLightBgHover: "#DC0043",
    buttonLightColorHover: "#FFFFFF",
    buttonDisableBg: "rgba(19, 21, 53, 0.15)",
    buttonDisableColor: "#FFFFFF",
    glossaryIconStroke: "#131535",
    snapshot: {
      color: "#ff7000",
    },
    divider: "#282F36",
  },
};
