import { createIcon } from "@chakra-ui/icons";

const InstrumentationIcon = createIcon({
  displayName: "Instrumentation",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M6.99999 12.7557V3.12479C6.99999 3.12479 5.64217 1.61653 1.06428 1.55569C1.0267 1.55521 0.989401 1.56231 0.954622 1.57657C0.919843 1.59082 0.888291 1.61194 0.86185 1.63865C0.807901 1.69286 0.777666 1.76626 0.777781 1.84274V10.8995C0.77717 10.974 0.805782 11.0458 0.857479 11.0995C0.909176 11.1531 0.979842 11.1844 1.05432 11.1866C5.64106 11.2485 6.99999 12.7557 6.99999 12.7557Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.34075 6.70216C4.39954 6.38558 3.42201 6.18952 2.43152 6.11865"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.34075 9.02223C4.39954 8.70565 3.42201 8.50959 2.43152 8.43872"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.65924 6.70216C9.60045 6.38558 10.578 6.18952 11.5685 6.11865"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.65924 9.02223C9.60045 8.70565 10.578 8.50959 11.5685 8.43872"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 12.7557V3.12479C7 3.12479 8.35783 1.61653 12.9357 1.55569C12.9733 1.55521 13.0106 1.56231 13.0454 1.57657C13.0802 1.59082 13.1117 1.61194 13.1381 1.63865C13.1921 1.69286 13.2223 1.76626 13.2222 1.84274V10.8995C13.2228 10.974 13.1942 11.0458 13.1425 11.0995C13.0908 11.1531 13.0202 11.1844 12.9457 11.1866C8.35893 11.2485 7 12.7557 7 12.7557Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default InstrumentationIcon;
