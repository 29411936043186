import { gql } from '@apollo/client';

const DELETE_PROJECT_TEMPLATE = gql`
  mutation ($formTemplateInput: FormTemplateInput!) {
    deleteFormTemplate(formTemplateInput: $formTemplateInput) {
      _id
    }
  }
`;

export default DELETE_PROJECT_TEMPLATE;
