import { useState, useEffect } from 'react';
import { Flex, Text, HStack, Spinner, Stack, Box, useDisclosure } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

import useGetProjects from '../hooks/useGetProjects';
import ProjectCard from '../components/Home/ProjectCard';
import { useProjectsContext } from '../contexts/ProjectsProvider';
import { TViewMode } from '../interfaces/TViewMode';
import ChangeViewButton from '../components/Home/ChangeViewButton';
import ProjectsList from '../components/Home/ProjectsList';
import useSort from '../hooks/useSort';
import SortButton from '../components/SortButton';
import FilterButton from '../components/Filters/FilterButton';
import QuickFilterButton from '../components/Filters/QuickFilterButton';
import { useAppContext } from '../contexts/AppProvider';
import NewProjectModal from '../components/Home/NewProjectModal';
import Loader from '../components/Loader';

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { user } = useAppContext();
  const { projects, totalProjects } = useProjectsContext();
  const [viewMode, setViewMode] = useState<TViewMode>('list');
  const [projectId, setProjectId] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setupProject = (projectId: string) => {
    setProjectId(projectId);
    onOpen();
  };

  useEffect(() => {
    const newProjectId = queryParams.get('newProjectId');

    if (newProjectId) {
      setupProject(newProjectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(projects), queryParams]);

  const closeProjectSetupModal = () => {
    setProjectId(undefined);
    onClose();

    if (queryParams.get('newProjectId')) {
      queryParams.delete('newProjectId');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }

  const { sortOrder, sortType, setSortType, setSortOrder } = useSort(projects || [], 'reference');
  const sortBy = [
    { label: 'Project', key: 'reference' },
    { label: 'Client', key: 'clientName' },
    { label: 'Sample', key: 'sample' },
    { label: 'Project lead', key: 'projectLead.displayName' },
    { label: 'Status', key: 'status' },
  ];

  const { loading, loadMoreProjects } = useGetProjects({
    sortBy: sortType,
    sortDirection: sortOrder,
  });

  const getFiltersToSet = (userId?: string) => {
    return [
      { name: 'projectLeadId', value: userId ? [userId] : [] },
      { name: 'testersIds', value: userId ? [userId] : [] },
      { name: 'traineesIds', value: userId ? [userId] : [] },
    ];
  };

  return (
    <>
      {projectId && <NewProjectModal isOpen={isOpen} onClose={closeProjectSetupModal} projectId={projectId} />}
      <Stack
        w="full"
        h="full"
        pr={4}
        textColor="breNavy"
      >
        <Text
          fontWeight="bold"
          fontSize="24px"
        >
          Projects
        </Text>
        <HStack justify="space-between">
          <HStack>
            <QuickFilterButton label="My projects" filtersToSet={getFiltersToSet(user?._id)} />
            <QuickFilterButton label="All projects" filtersToSet={getFiltersToSet()} />
          </HStack>
          <HStack>
            <ChangeViewButton
              setViewMode={setViewMode}
              viewMode={viewMode}
              views={['grid', 'list']}
            />
            <SortButton
              setSortOrder={setSortOrder}
              setSortType={setSortType}
              sortBy={sortBy}
              sortOrder={sortOrder}
              sortType={sortType}
            />
            <FilterButton />
          </HStack>
        </HStack>
        {loading ? (
          <Flex w="full" h="full" align="center" justify="center"><Spinner size="xl" thickness="4px" color="breNavy" /></Flex>
        ) : (
          projects?.length ? (
            <Flex w="full" h="full" pb={4} align="flex-start" overflow="auto">
              {viewMode === 'grid'
                ? (
                  <InfiniteScroll
                    loadMore={loadMoreProjects}
                    hasMore={(projects || []).length < totalProjects}
                    loader={<Loader size='md' />}
                    useWindow={false}
                  >
                    <Flex flexWrap="wrap">
                      {(projects || []).map((project, i) => <ProjectCard project={project} setupProject={setupProject} key={i} />)}
                    </Flex>
                  </InfiniteScroll>
                ) : <ProjectsList
                  projects={projects || []}
                  totalProjects={totalProjects}
                  setSortOrder={setSortOrder}
                  setSortType={setSortType}
                  sortOrder={sortOrder}
                  sortType={sortType}
                  setupProject={setupProject}
                  loadMoreProjects={loadMoreProjects}
                />
              }
            </Flex>
          ) : (
            <Box p={2}>No projects to show</Box>
          )
        )}
      </Stack>
    </>
  )
};

export default Home;
