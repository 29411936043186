import { createIcon } from '@chakra-ui/icons';

const TvIcon = createIcon({
  path: (
    <>
      <path
        d='M16.667 2.5H3.33366C2.41449 2.5 1.66699 3.2475 1.66699 4.16667V13.3333C1.66699 14.2525 2.41449 15 3.33366 15H6.66699L5.16699 17L6.50033 18L8.75033 15H11.2503L13.5003 18L14.8337 17L13.3337 15H16.667C17.5862 15 18.3337 14.2525 18.3337 13.3333V4.16667C18.3337 3.2475 17.5862 2.5 16.667 2.5ZM3.33366 13.3333V4.16667H16.667L16.6678 13.3333H3.33366Z'
        fill='#282F36'
      />
      <path d='M5 10H8.33333V11.6667H5V10Z' fill='black' />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default TvIcon;
