import { createIcon } from "@chakra-ui/icons";

const AmslerChecklistIcon = createIcon({
  displayName: "AmslerChecklist",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M12.5611 1.43872H1.43887C0.966358 1.43872 0.583313 1.82177 0.583313 2.29428V12.5609C0.583313 13.0335 0.966358 13.4165 1.43887 13.4165H12.5611C13.0336 13.4165 13.4166 13.0335 13.4166 12.5609V2.29428C13.4166 1.82177 13.0336 1.43872 12.5611 1.43872Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.14996 0.583252V2.29436"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.85 0.583252V2.29436"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.42773 10.4221H9.9944"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.42773 6.14429H9.9944"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.80223 8.88208L4.00556 11.2776L2.72223 9.9943"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.80223 4.43335L4.00556 6.8289L2.72223 5.54557"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default AmslerChecklistIcon;
