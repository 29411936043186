import { gql } from '@apollo/client';

const UPDATE_PROJECT = gql`
  mutation ($projectInput: ProjectInput!) {
    updateProject(projectInput: $projectInput) {
      _id
    }
  }
`;

export default UPDATE_PROJECT;
