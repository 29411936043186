import { gql } from '@apollo/client';

const CREATE_PROJECT_TEMPLATE = gql`
  mutation ($formTemplateInput: FormTemplateInput!) {
    createFormTemplate(formTemplateInput: $formTemplateInput) {
      _id
      name
      icon
    }
  }
`;

export default CREATE_PROJECT_TEMPLATE;
