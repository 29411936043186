import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Flex,
  Box,
  Wrap,
  WrapItem,
  useDisclosure,
  Spinner,
  Divider,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import {
  Dropdown,
  PeoplePicker,
  SearchableDropdown,
} from '../../components/FormControls';
import { CheckIcon, PlusIcon } from '../../icons';
import { useEffect, useState } from 'react';
import SETUP_PROJECT from '../../gql/mutation/SETUP_PROJECT';
import GET_PROJECT_TEMPLATES_CATEGORIES from '../../gql/queries/GET_PROJECT_TEMPLATES_CATEGORIES';
import { roleDecorated } from '../../utils/helpers';
import { IUser } from '../../interfaces/IUser';
import RemovableAvatar from '../RemovableAvatar';
import GET_OR_CREATE_USER from '../../gql/queries/GET_OR_CREATE_USER';

const NewProjectModal = ({
  isOpen,
  onClose,
  projectId,
}: {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
}) => {
  const [processing, setProcessing] = useState(false);
  const [searchUsersIn, setSearchUsersIn] = useState<string>('widerTeam');
  const [team, setTeam] = useState<IUser[]>([]);
  const toast = useToast();
  const history = useHistory();
  const { data } = useQuery(GET_PROJECT_TEMPLATES_CATEGORIES);
  const [setupProject] = useMutation(SETUP_PROJECT);
  const [getOrCreate] = useMutation(GET_OR_CREATE_USER);
  const categories = data?.projectTemplatesCategories;
  const {
    isOpen: isRolesModalOpen,
    onClose: onRolesModalClose,
    onOpen: onRolesModalOpen,
  } = useDisclosure();

  const {
    control,
    reset,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      templateId: '',
    },
  });

  const {
    control: rolesControl,
    getValues: getRolesValues,
    reset: resetRoles,
    watch: watchRoles,
    formState: { isValid: isValidRole },
  } = useForm({
    mode: 'all',
    defaultValues: {
      role: '',
      id: '',
    },
  });

  const completeSetup = async () => {
    if (!isValid || team.length === 0) {
      toast({ ...toastFailed, description: 'Please fill all the fields' });
      return;
    }
    if (!hasProjectLead()) {
      toast({
        ...toastFailed,
        description: 'Project must have a project lead',
      });
      return;
    }
    setProcessing(true);

    const projectTeam = team.map(({ _id, role }) => ({ _id, role }));

    try {
      const { data } = await setupProject({
        variables: {
          projectInput: {
            projectId,
            projectTeam,
            templateId: getValues().templateId,
          },
        },
      });

      if (data.setupProject?._id) {
        toast({ ...toastSuccess, description: 'Project created successfully' });
        closeProjectModal();
        history.push(`/project/${projectId}/1`);
      }
    } catch (error) {
      console.error('Error creating project:', error);
      toast({ ...toastFailed, description: 'Failed to create project' });
    } finally {
      setProcessing(false);
    }
  };

  const closeProjectModal = () => {
    onClose();
    reset({});
    resetRoles({});
    setTeam([]);
    setValue('templateId', '');
  };

  const hasProjectLead = () => {
    return Boolean(team.find((obj) => obj.role === 'projectLead'));
  };

  useEffect(() => {
    const subscription = watchRoles((value) => {
      if (value.role?.length) {
        setSearchUsersIn(value.role === 'projectLead' ? 'projectLeads' : 'projectMembers');
      }
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRoles, isOpen]);

  const addMember = async () => {
    if (!isValidRole) {
      toast({ ...toastFailed, description: 'Please fill all the fields' });
      return;
    }
    const { id, role } = getRolesValues();
    if (!id) {
      toast({ ...toastFailed, description: 'Please select a member' });
      return;
    }
    setProcessing(true);
    const userResponse = await getOrCreate({ variables: { userId: id } });
    const user = userResponse?.data?.getOrCreate;
    if (user) {
      setTeam((prevTeam) => [...prevTeam, { ...user, role }]);
    }
    setProcessing(false);
    resetRoles({});
    onRolesModalClose();
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      <Modal onClose={closeProjectModal} closeOnOverlayClick={false} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight='900'>New project setup</ModalHeader>
          <ModalCloseButton />
          <Divider opacity={1} />
          <ModalBody w='full'>
            <Text fontSize='md' fontWeight='500' mt='1'>
              Each new project requires a form template to be assigned to it
            </Text>
            <SearchableDropdown
              control={control}
              name='templateId'
              label='Select form template'
              placeholder='Template'
              validations={{ notEmpty: true }}
              styles={{ fullWidth: true }}
              options={categories}
              groupedBy={{
                groupBy: 'templates',
                value: '_id',
                label: 'name',
              }}
            />

            <Flex fontSize='smm' alignItems='center' mt='2'>
              Or{' '}
              <Text
                color='underlineColor'
                ml='1'
                textDecoration='underline'
                cursor='pointer'
                onClick={() => history.push('/admin/templates')}
              >
                create new template
              </Text>
            </Flex>
            <Box>
              <Text fontSize='smm' fontWeight='700' my='3'>
                Project Team
              </Text>
              <Flex>
                <Wrap m='0'>
                  {team.map((user, index) => (
                    <WrapItem
                      key={`${user.role}-${user._id}`}
                      m='0'
                      w='75px'
                      justifyContent='center'
                    >
                      <Flex
                        flexDir='column'
                        justifyContent='center'
                        borderRadius='50%'
                        alignItems='center'
                        w='full'
                      >
                        <RemovableAvatar
                          h='70px'
                          w='70px'
                          userId={user._id}
                          onDelete={() => setTeam((prevTeam) => prevTeam.filter((_, i) => i !== index))}
                        />
                        <Box textAlign='center' mt='1' w='full'>
                          <Tooltip hasArrow label={user.displayName}>
                            <Text fontSize='ssm' fontWeight='600' isTruncated textOverflow='ellipsis'>
                              {user.displayName}
                            </Text>
                          </Tooltip>
                          <Text fontSize='ssm' color='breBlack'>
                            {roleDecorated(user.role)}
                          </Text>
                        </Box>
                      </Flex>
                    </WrapItem>
                  ))}
                  <WrapItem m='0' w='75px' justifyContent='center'>
                    <Flex
                      cursor='pointer'
                      w='70px'
                      h='70px'
                      borderRadius='50%'
                      bg='breBlack'
                      alignItems='center'
                      justifyContent='center'
                      onClick={onRolesModalOpen}
                    >
                      <PlusIcon />
                    </Flex>
                  </WrapItem>
                </Wrap>
              </Flex>
            </Box>
          </ModalBody>
          <Divider opacity={1} mt='2' />
          <ModalFooter>
            <Button onClick={closeProjectModal} mr='2'>
              Discard
            </Button>
            <Button
              onClick={completeSetup}
              bg='brePink'
              color='white'
              _hover={{}}
              _focus={{}}
              _active={{}}
              disabled={processing}
            >
              {processing ? (
                <>
                  <Spinner mr='2' /> Saving
                </>
              ) : (
                <>
                  <CheckIcon mr='2' />
                  Complete Setup
                </>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        onClose={() => {
          onRolesModalClose();
          resetRoles({});
        }}
        isOpen={isRolesModalOpen}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight='900'>Add team member</ModalHeader>
          <ModalCloseButton />
          <ModalBody w='full'>
            <Dropdown
              control={rolesControl}
              name='role'
              required={true}
              label='Role'
              validations={{ notEmpty: true }}
              styles={{ fullWidth: true }}
              options={[
                {
                  label: 'Trainee',
                  value: 'trainee',
                },
                {
                  label: 'Tester',
                  value: 'tester',
                },
                ...(hasProjectLead()
                  ? []
                  : [
                    {
                      label: 'Project Lead',
                      value: 'projectLead',
                    },
                  ]),
              ]}
              placeholder="Select role"
            />
            {rolesControl._formValues.role !== '' && (
              <PeoplePicker
                label='Select member'
                placeholder='Select Member'
                control={rolesControl}
                name='id'
                styles={{ fullWidth: true }}
                variant={searchUsersIn}
                validations={{ notEmpty: true }}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onRolesModalClose();
                resetRoles({});
              }}
              mr='2'
            >
              Discard
            </Button>
            <Button
              onClick={addMember}
              bg='brePink'
              color='white'
              _hover={{}}
              _focus={{}}
              _active={{}}
              disabled={processing}
            >
              {processing ? (
                <>
                  <Spinner mr='2' /> Saving
                </>
              ) : (
                <>
                  <PlusIcon mr='2' />
                  Add Member
                </>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewProjectModal;
