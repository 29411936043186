import { useEffect, useRef, useState } from "react";
import { ChevronRightIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Icon,
  Avatar,
  useOutsideClick,
  Tooltip,
} from "@chakra-ui/react";

import { searchBarFilterItems } from "../../bootstrap/config";
import { useSearchContext } from "../../contexts/SearchProvider";
import useSearch from "../../hooks/useSearch";
import { IProject } from "../../interfaces/IProject";
import ISearch from "../../interfaces/ISearch";
import { useHistory } from "react-router-dom";
import { useFiltersContext } from "../../contexts/FiltersProvider";
import usePeoplePickerUsers from "../../hooks/usePeoplePickerUsers";
import { getProjectLeadName } from "../../utils/helpers";
const groupBy = require("lodash.groupby");

const SearchBar = (): JSX.Element => {
  const searchRef = useRef<any>();
  const history = useHistory();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { defaultSearchParams, search, setSearch, results } = useSearchContext();
  const { setFilters } = useFiltersContext();
  const { data: usersData } = usePeoplePickerUsers();

  useEffect(() => {
    return () => {
      setSearch(defaultSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSearch((state: ISearch) => ({
      ...state,
      isSearchEnabled: isFocused,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useOutsideClick({
    ref: searchRef,
    handler: () => setIsFocused(false),
  });

  const redirect = (project: IProject): void => {
    if (project.status === "Not started") {
      history.push(`/?newProjectId=${project._id}`);
    } else {
      history.push(`/project/${project._id}/1`);
    }
    setIsFocused(false);
  };

  const renderText = (
    text: string,
    fontWeight: "light" | "bold" = "light"
  ): JSX.Element => {
    return (
      <Box
        fontSize="14px"
        textAlign="left"
        fontWeight={fontWeight}
        textColor="breNavy"
      >
        {text}
      </Box>
    );
  };

  const renderResults = (): JSX.Element => {
    if (search.searchFor === "clients") {
      let clients: [string, IProject[]][] = Object.entries<IProject[]>(
        groupBy(results, "clientId")
      );

      return (
        <>
          {clients?.map((result) => {
            let clientData: IProject = result[1][0];
            return (
              <Flex
                key={result[0]}
                bg="rgba(255,255,255,0.7)"
                rounded="20px"
                my="5"
                cursor="pointer"
                align="center"
                pl={5}
                onClick={() => {
                  setFilters({ clientId: [result[0]] });
                  history.push("/");
                  setIsFocused(false);
                }}
              >
                <Avatar
                  h="24px"
                  w="24px"
                  bg="rgba(255,255,255, 0.8)"
                  color="breNavy"
                  mr={5}
                  boxShadow="0px 4px 15px rgba(0, 0, 0, .05)"
                  name={clientData.clientName}
                />
                <Flex h="full" w="70%" direction="column" justify="center">
                  <Flex>
                    {clientData.clientName.includes(search.value) ? (
                      <>
                        {renderText(
                          clientData.clientName.substring(
                            0,
                            clientData.clientName.indexOf(search.value)
                          )
                        )}
                        {renderText(search.value, "bold")}
                        {renderText(
                          clientData.clientName.substring(
                            clientData.clientName.indexOf(search.value) +
                            search.value.length,
                            clientData.clientName.length
                          )
                        )}
                      </>
                    ) : (
                      renderText(clientData.clientName, "bold")
                    )}
                  </Flex>
                  <Box top={4} fontSize="11px" opacity="0.8">
                    # {clientData.clientId}
                  </Box>
                </Flex>

                <Flex justify={"flex-end"} mr="5" w={"30%"}>
                  <Box top={4} fontSize="11px">
                    {`${result[1].length} Project${result[1].length > 1 ? "s" : ""
                      }`}
                    <Icon w="15px" h="15px" as={ChevronRightIcon} />
                  </Box>
                </Flex>
              </Flex>
            );
          })}
        </>
      );
    }
    return (
      <>
        {results?.map((result: IProject) => (
          <Flex
            key={result._id}
            bg="rgba(255,255,255,0.7)"
            rounded="20px"
            my="5"
            cursor="pointer"
            align="center"
            pl={5}
            onClick={() => {
              setSearch((state: ISearch) => ({
                ...state,
                value: "",
              }));
              redirect(result);
            }}
          >
            <Tooltip hasArrow label={getProjectLeadName(usersData, null, result.projectLeadId)}>
              <Avatar
                h="24px"
                w="24px"
                mr={5}
                src={
                  result?.projectLeadId &&
                  `${process.env.REACT_APP_API_URL}/files/photo/${result.projectLeadId}`
                }
              />
            </Tooltip>
            <Flex h="full" w="45%" direction="column" justify="center">
              <Flex>
                {result.reference.includes(search.value) ? (
                  <>
                    {renderText(
                      result.reference.substring(
                        0,
                        result.reference.indexOf(search.value)
                      )
                    )}
                    {renderText(search.value, "bold")}

                    {renderText(
                      result.reference.substring(
                        result.reference.indexOf(search.value) +
                        search.value.length,
                        result.reference.length
                      )
                    )}
                  </>
                ) : (
                  renderText(result.reference, "bold")
                )}
              </Flex>
              <Box top={4} fontSize="11px" opacity="0.8">
                {result.projectTemplate?.category}
              </Box>
            </Flex>

            <Flex justify={"flex-end"} mr="5" w="55%">
              <Box top={4} fontSize="11px">
                {result.clientName}
                <Icon w="15px" h="15px" as={ChevronRightIcon} />
              </Box>
            </Flex>
          </Flex>
        ))}
      </>
    );
  };

  const { loading } = useSearch();
  return (
    <>
      <Flex ref={searchRef}>
        <InputGroup
          display={["none", "block"]}
          w={["100%", "650px"]}
        >
          <InputLeftElement
            pointerEvents="none"
            color="navigationTop.inputIconColor"
            children={
              <SearchIcon
                fill="navigationTop.searchBarIcon"
                stroke="brand.outerSpace"
                opacity="1"
              />
            }
          />
          <Input
            onChange={(e) =>
              setSearch((state: ISearch) => ({
                ...state,
                value: e.target.value,
              }))
            }
            autoComplete="none"
            onFocus={() => setIsFocused(true)}
            bg="navigationTop.inputBg"
            rounded="10px"
            placeholder="Search"
            fontWeight="semi_medium"
            fontSize="smm"
          />
        </InputGroup>
        {search.isSearchEnabled && (
          <>
            <Flex
              w={["100%", "650px"]}
              position="absolute"
              boxShadow="0px 0px 80px rgba(49, 50, 51, 0.15)"
              mt="10"
              bg="rgba(255,255,255)"
              rounded="10px"
              h="310px"
            >
              <Box
                width="25%"
                pl="5"
                bg="rgba(240, 240, 240, 0.5)"
                pt="2"
                borderRadius={"10px"}
              >
                {searchBarFilterItems.map((item, i) => (
                  <Box
                    key={i}
                    h="30px"
                    w="120px"
                    mt="20px"
                    display="flex"
                    alignItems="center"
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearch((state) => ({
                        ...state,
                        searchFor: item.searchFor,
                      }));
                    }}
                  >
                    <Flex h="100%" align="center">
                      <Flex
                        w="30px"
                        h="30px"
                        alignItems="center"
                        justifyContent="center"
                        bg={
                          item.searchFor === search.searchFor
                            ? "breNavy"
                            : "#FFF"
                        }
                        rounded="8px"
                      >
                        <Icon
                          w="15px"
                          h="15px"
                          as={item.icon}
                          color={
                            item.searchFor === search.searchFor
                              ? "#FFF"
                              : "#818197"
                          }
                        />
                      </Flex>
                    </Flex>
                    <Box
                      ml="2"
                      fontSize="14px"
                      color={
                        item.searchFor === search.searchFor
                          ? "#breNavy"
                          : "#818197"
                      }
                    >
                      {item.label}
                    </Box>
                  </Box>
                ))}
              </Box>
              {results?.length ? (
                <Box width="75%" pl="15" overflow="auto">
                  {!loading && renderResults()}
                </Box>
              ) : (
                <Box
                  p={5}
                  fontSize="14px"
                  fontWeight="light"
                  textColor="rgba(40, 47, 54, 0.5)"
                >
                  No results for "{search.value}"
                </Box>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default SearchBar;
