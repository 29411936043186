import { Stack, Text } from "@chakra-ui/react";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProjectsContext } from "../../contexts/ProjectsProvider";


interface IFormIterationChanger {
  name: string;
  numberOfIterations: number;
}

const FormIterationChanger = ({
  name,
  numberOfIterations,
}: IFormIterationChanger) => {
  const { formIterationNumber, setFormIterationNumber } = useProjectsContext();
  if (formIterationNumber === null) return null;

  return (
    <Stack direction="row" spacing={3} align="center">
      <Text fontWeight={600} userSelect="none">{name} {formIterationNumber + 1}</Text>
      {numberOfIterations > 1 && (
        <>
          <FontAwesomeIcon
            icon={faChevronLeft}
            cursor={formIterationNumber > 0 ? "pointer" : "default"}
            opacity={formIterationNumber > 0 ? 1 : 0.5}
            color="breBlack"
            size="sm"
            onClick={() => formIterationNumber > 0 && setFormIterationNumber(formIterationNumber - 1)}
          />
          <FontAwesomeIcon
            icon={faChevronRight}
            cursor={formIterationNumber < numberOfIterations - 1 ? "pointer" : "default"}
            opacity={formIterationNumber < numberOfIterations - 1 ? 1 : 0.5}
            size="sm"
            onClick={() => formIterationNumber < numberOfIterations - 1 && setFormIterationNumber(formIterationNumber + 1)}
          />
        </>
      )}
    </Stack>
  );
};

export default FormIterationChanger;
