export const userConvertibles = [
  "quoteAcceptance",
  "sampleReceived",
  "reportApproved",
  "testUndertaken",
  "clientSafetyInfoReceived",
  "reportPrepared",
  "reportSent",
  "invoiced",
  "archived",
  "officeInCharge",
  "breCompetentPerson",
  "furnaceOperator",
  "reportSent",
  "projectLead",
  "contactName",
  "competentPersonName"
];

export const dateConvertibles = [
  "quoteAcceptanceDate",
  "sampleReceivedDate",
  "clientSafetyInfoReceivedDate",
  "testUndertakenDate",
  "reportPreparedDate",
  "reportApprovedDate",
  "otherFurnaceDate",
  "testDate",
  "pretestDate",
  "reportSentDate",
  "invoicedDate",
  "archivedDate",
  "dateOfTest",
  "signedDate"
];

export const tableFieldsToAutoSave = [
  'amslerChecklistTable1',
  'amslerChecklistTable2',
  'technicianRepresentatives',
  'contractorClientRepresentatives',
  'recordKeepingTableData',
  'completedMinutes'
];

export const unsavedChangesMessage = 'You have fields open in edit, you will lose all of your changes. Are you sure you want to navigate away?';