import { useEffect } from 'react';

import {
    Flex,
    useToast,
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { toastFailed, toastSuccess } from '../../../bootstrap/config';
import AdminModal from '../../AdminModal';
import TextInput from '../TextInput';

const MinutesObservationTableModal = ({ modalState, setModalState, dataValues, setdataValues, selectedDataValue, name, onChange,formIndex }) => {
    const {
        control,
        formState: { errors },
        getValues,
        trigger,
        reset,
    } = useForm({
        mode: "all",
        defaultValues: {
            _id: undefined,
            minutes: selectedDataValue.name,
            secsPerItem: selectedDataValue.signature,
            observation: selectedDataValue.company,
        },
    });
    const toast = useToast();

    useEffect(() => {
        if (selectedDataValue !== '') {
            reset(selectedDataValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataValue]);

    const handleAddMinutesObservation = async () => {
        try {
            if (Object.keys(errors).length === 0) {
                const values = getValues();
                const val = {
                    minutes: values.minutes, secsPerItem: values.secsPerItem,
                    observation: values.observation,
                }
                dataValues instanceof Array
                    ? dataValues.push(val)
                    : (dataValues = [val]);

                setdataValues(dataValues);
                onChange({ target: { name, value: dataValues } });
                toast({ ...toastSuccess, description: 'Observation added' });
                reset({
                    _id: undefined,
                    minutes: '',
                    secsPerItem: undefined,
                    observation: '',
                });
            } else {
                toast({
                    ...toastFailed,
                    description: 'Please complete all the required fields',
                });
            }
        } catch (e: any) {
            toast({ ...toastFailed, description: e.message });
        } finally {
            setModalState('closed');
        }
    };

    const handleUpdateMinutesObservation = async () => {
        try {
            if (Object.keys(errors).length === 0) {
                const values = getValues();
                let updatedData = [...dataValues];
                updatedData[selectedDataValue.index] = {
                    minutes: values.minutes, secsPerItem: values.secsPerItem,
                    observation: values.observation,
                };
                setdataValues(updatedData);
                onChange({ target: { name, value: updatedData } });
                toast({ ...toastSuccess, description: 'Observation updated' });
                reset({
                    _id: undefined,
                    minutes: '',
                    secsPerItem: undefined,
                    observation: '',
                });
            } else {
                toast({
                    ...toastFailed,
                    description: 'Please complete all the required fields',
                });
            }
        } catch (e: any) {
            toast({ ...toastFailed, description: e.message });
        } finally {
            setModalState('closed');
        }
    };

    const handleDeleteMinutesObservation = async () => {
        try {
            let updatedData = dataValues.filter((p, index) => index !== selectedDataValue.index);
            setdataValues(updatedData);
            onChange({ target: { name, value: updatedData } });
            toast({ ...toastSuccess, description: 'Observation deleted' });
        } catch (e: any) {
            toast({ ...toastFailed, description: e.message });
        } finally {
            setModalState('closed');
        }
    };

    const handleAction = async (action) => {
        const isFormValid = await trigger();
        if (['add', 'edit'].includes(action) && !isFormValid) {
            return toast({
                ...toastFailed,
                description: 'Please complete all the required fields',
            });
        }
        switch (action) {
            case 'add':
                handleAddMinutesObservation();
                break;
            case 'edit':
                handleUpdateMinutesObservation();
                break;
            case 'delete':
                handleDeleteMinutesObservation();
                break;
            default:
                setModalState('closed');
        }
    };
    return (
        <AdminModal
            isOpenModal={modalState !== 'closed'}
            modalType={modalState}
            onAction={handleAction}
            collection={'observation'}
        >
            <Flex align='flex-start' direction='column' w='full'>
                <TextInput
                    control={control}
                    name="minutes"
                    placeholder="00"
                    label="Minutes"
                    validations={{ notEmpty: true, isNumber: true }}
                    formIndex={formIndex}
                    type={'number'}
                />
                <TextInput
                    control={control}
                    name="secsPerItem"
                    placeholder="00"
                    label="Secs/Item"
                    validations={{ notEmpty: true, isNumber: true }}
                    formIndex={formIndex}
                    type={'number'}
                />
                <TextInput
                    control={control}
                    name="observation"
                    placeholder=""
                    label="Observation"
                    validations={{ notEmpty: true }}
                    variant="multiline"
                    formIndex={formIndex}
                />
            </Flex>
        </AdminModal>
    );
};


export default MinutesObservationTableModal;
