import { createIcon } from '@chakra-ui/icons';

const BookIcon = createIcon({
  path: (
    <>
      <path
        d='M5 18.3337H17.5V16.667H5.01C4.625 16.657 4.16667 16.5045 4.16667 15.8337C4.16667 15.1628 4.625 15.0103 5.01 15.0003H17.5V3.33366C17.5 2.41449 16.7525 1.66699 15.8333 1.66699H5C3.995 1.66699 2.5 2.33283 2.5 4.16699V15.8337C2.5 17.6678 3.995 18.3337 5 18.3337ZM4.16667 6.66699V4.16699C4.16667 3.49616 4.625 3.34366 5 3.33366H15.8333V13.3337H4.16667V6.66699Z'
        fill='currentColor'
      />
      <path d='M6.66699 5H14.167V6.66667H6.66699V5Z' fill='currentColor' />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default BookIcon;
