import { useParams } from 'react-router-dom';
import { useProjectsContext } from '../contexts/ProjectsProvider';
import useGetFormStatuses from '../hooks/useGetFormStatus';
import useGetProjects from '../hooks/useGetProjects';
import useGetProjectTemplate from '../hooks/useGetProjectTemplate';
import IAuditFilters from '../interfaces/IAuditFilters';
import AuditLogs from './admin/auditlogs';

const Audit = () => {
  const { _id }: { _id: string; } = useParams();
  useGetProjects({ projectId: _id, withForms: true });
  useGetProjectTemplate({ projectId: _id });
  useGetFormStatuses(_id);

  const { currentProject } = useProjectsContext();

  const auditLogFilters: IAuditFilters = {
    elementsIds: currentProject ? [
      currentProject._id,
      ...(currentProject.forms || []).map((ft) => ft._id),
    ] : [],
  };

  return <AuditLogs predefinedFilters={auditLogFilters} />;
};

export default Audit;
