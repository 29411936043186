import { useRef } from 'react';
import {
  Box,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowRight, CrossIcon } from '../icons';

import { IModalState } from '../interfaces/IModalContext';
import useIsIpad from "../hooks/useIsIpad";
interface IModalInterface {
  isOpenModal: boolean;
  modalType: IModalState;
  onAction: (modalType?: any) => void;
  collection: string;
  children: JSX.Element | JSX.Element[];
}

const AdminModal = ({
  isOpenModal,
  modalType,
  onAction,
  collection,
  children,
}: IModalInterface) => {
  const { onClose } = useDisclosure();
  const ipad = useIsIpad();
  const initialRef = useRef(null);

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={onClose}
      onEsc={onAction}
      variant={collection ? 'adminModal' : 'conformeModal'}
      initialFocusRef={initialRef}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {modalType !== 'delete' && (
        <ModalContent
          bg='adminModal.content.bg'
          h={['100vh']}
          position='absolute'
          top='-60px'
          right='0'
          rounded='0'
          overflow="none"
        >
          <ModalHeader
            pl='18px'
          >
            <Flex pt='10px' justifyContent='space-between' alignItems='center'>
              <Flex>
                <Box fontSize='xxl' fontWeight='bold' >{modalType === 'edit' ? `Edit ${collection}` : `Add ${collection}`}</Box>
              </Flex>
              <CrossIcon w='15px' h='15px' stroke='adminModal.closeIcon' onClick={onAction} cursor='pointer' />
            </Flex>
          </ModalHeader>
          <ModalBody bg='adminModal.body.bg' >
            <Box borderRadius={['0', '20px']} bgColor='#F0F2F5' h={'98%'} p={25} >
              {children}
              <Button
                ref={initialRef}
                mt='15px'
                mb='5px'
                mr='25px'
                bg='adminModal.button.bg'
                color='adminModal.button.color'
                fontSize='smm'
                fontWeight='bold'
                _hover={{}}
                onClick={() => onAction(modalType)}
                onTouchEnd={() => ipad && onAction(modalType)}
              >
                {modalType === 'edit' ? 'Update' : 'Add'}
                <ArrowRight h='20px' w='12px' ml='10px' mt='5px' />
              </Button>

            </Box>
          </ModalBody>
        </ModalContent>
      )}
      {modalType === 'delete' && (
        <ModalContent
          bg='adminModal.delete.bg'
          top='calc(50vh/2)'
          borderRadius='20px'
          p='20px'
          w='330px'
        >
          <Flex
            h='100%'
            alignItems='left'
            flexDirection='column'
            justifyContent='center'
          >
            <Flex>
              <Box
                fontSize='smm'
                fontWeight='bold'
                color='adminModal.header.color'
                textAlign='left'
                mb='15px'
              >
                Remove Item
              </Box>
              <Spacer />
              <CrossIcon w='15px' h='15px' stroke='adminModal.closeIcon' onClick={onAction} cursor='pointer' />
            </Flex>
            <Box
              fontSize='smm'
              fontWeight='semi_medium'
              whiteSpace='pre'
              color='adminModal.text.color'
              textAlign='left'
            >
              {`This action cannot be undone`}
            </Box>
            <Flex mt='35px'>
              <Button
                color='adminModal.button.keep.color'
                p='10px 20px'
                bg='adminModal.button.keep.bg'
                _hover={{ bg: 'reportFormHeader.buttonDisableBg', color: 'reportFormHeader.buttonDisableColor', }}
                borderRadius='10px'
                mr='22px'
                onClick={onAction}
                onTouchEnd={() => {
                  if (ipad) {
                    onAction();
                  }
                }}
              >
                Keep
              </Button>
              <Spacer />
              <Button
                bg='adminModal.button.remove.bg'
                color='adminModal.button.remove.color'
                p='10px 20px'
                _hover={{ bg: 'reportFormHeader.buttonDisableBg', color: 'reportFormHeader.buttonDisableColor', }}
                borderRadius='10px'
                onClick={() => onAction(modalType)}
                onTouchEnd={() => ipad && onAction(modalType)}
              >
                Remove
              </Button>
            </Flex>
          </Flex>
        </ModalContent>
      )
      }
    </Modal >
  );
};

export default AdminModal;

export const adminModalStyles = {
  adminModal: {
    content: {
      bg: '#FFFFFF',
    },
    body: {
      bg: '#FFFFFF',
    },
    closeIcon: '#282F36',
    button: {
      bg: '#DC0043',
      hoverbg: 'rgba(19, 21, 53, 0.15)',
      hoverColor: '#818197',
      keep: {
        bg: '#F0F2F5',
        hover: 'rgba(19, 21, 53, 0.15)',
        color: '#818197'
      },
      remove: {
        bg: '#DC0043',
        color: '#ffffff',

      },
      color: '#ffffff',

    },
    header: {
      color: '#313233'
    },
    text: {
      color: '#818197',
    },
    delete: {
      bg: '#ffffff',
    },
  }
}
