import { gql } from '@apollo/client';

const GET_SETTINGS = gql`
  query ($settingSearchQuery: SettingSearchQuery!) {
    settings(settingSearchQuery: $settingSearchQuery) {
      _id
      spSiteUrl
      spDocumentLibrary
      spClientFolder
      labNumber
      generatePDFs
      projectsProperties {
        label
        key
      }
      projectsPerPage
    }
  }
`;

export default GET_SETTINGS;
