import { createIcon } from "@chakra-ui/icons";

const ChevronDown = createIcon({
  displayName: "Chevron down",
  viewBox: "0 0 16 16",
  path: (
    <path
      fill="none"
      d="M15 5L8.2239 11.7761C8.17993 11.8201 8.12771 11.8551 8.07022 11.8789C8.01274 11.9027 7.95112 11.915 7.88889 11.915C7.82666 11.915 7.76504 11.9027 7.70755 11.8789C7.65007 11.8551 7.59785 11.8201 7.55388 11.7761L0.777777 5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export default ChevronDown;
