import { createIcon } from "@chakra-ui/icons";

const ArrowRight = createIcon({
  displayName: "Arrow right",
  viewBox: "0 0 8 15",
  path: (
    <path d="m.9493 1.2778 5.9291 5.929a.4143.4143 0 0 1 0 .5863l-5.929 5.9291"
    fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
  ),
});

export default ArrowRight;
