import { createIcon } from "@chakra-ui/icons";

const PhotoIcon = createIcon({
  displayName: "Photo",
  viewBox: "0 0 15 16",
  path: (
    <>
      <path d="M13.125 4.875H11.25L10 2.375H5L3.75 4.875H1.875C1.54348 4.875 1.22554 5.0067 0.991117 5.24112C0.756696 5.47554 0.625 5.79348 0.625 6.125V12.375C0.625 12.7065 0.756696 13.0245 0.991117 13.2589C1.22554 13.4933 1.54348 13.625 1.875 13.625H13.125C13.4565 13.625 13.7745 13.4933 14.0089 13.2589C14.2433 13.0245 14.375 12.7065 14.375 12.375V6.125C14.375 5.79348 14.2433 5.47554 14.0089 5.24112C13.7745 5.0067 13.4565 4.875 13.125 4.875Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11.125C7.99445 11.125 8.4778 10.9784 8.88893 10.7037C9.30005 10.429 9.62048 10.0385 9.8097 9.58171C9.99892 9.12489 10.0484 8.62223 9.95196 8.13728C9.8555 7.65232 9.6174 7.20687 9.26777 6.85723C8.91814 6.5076 8.47268 6.2695 7.98773 6.17304C7.50277 6.07657 7.00011 6.12608 6.54329 6.3153C6.08648 6.50452 5.69603 6.82495 5.42133 7.23608C5.14662 7.6472 5 8.13055 5 8.625C5 9.28804 5.26339 9.92393 5.73223 10.3928C6.20107 10.8616 6.83696 11.125 7.5 11.125Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

    </>
  ),
});

export default PhotoIcon;
