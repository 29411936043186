import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Flex, HStack, Stack, useDisclosure, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { isEqual, debounce } from 'lodash';
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useProjectsContext } from '../contexts/ProjectsProvider';
import ProjectFormHeader from '../components/ProjectForm/ProjectHeader/ProjectFormHeader';
import useGetProjectTemplate from '../hooks/useGetProjectTemplate';
import useGetProjects from '../hooks/useGetProjects';
import useUpdateForm from '../hooks/useUpdateForm';
import useGetFormStatuses from '../hooks/useGetFormStatus';
import Loader from '../components/Loader';
import { tableFieldsToAutoSave } from '../utils/defaults';
import TemplateSection from '../components/TemplateSection';
import FormIterationChanger from '../components/ProjectForm/FormIterationChanger';
import MarkAsCompleteModal from '../components/ProjectForm/MarkAsCompleteModal';
import { getDefaultFieldValue } from '../utils/helpers';
import ConfirmationModal from '../components/ConfirmationModal';
import BinIcon from '../icons/BinIcon';
import Can, { isPermitted } from '../components/Can';
import { useAppContext } from '../contexts/AppProvider';

const CREATE_FORM_ITERATION = gql`
  mutation ($formId: ID!) {
    createFormIteration(formId: $formId)
  }
`;
const DELETE_FORM_ITERATION = gql`
  mutation ($formIterationInput: FormIterationInput!) {
    deleteFormIteration(formIterationInput: $formIterationInput)
  }
`;

const Project = () => {
  const { user } = useAppContext();
  const {
    _id,
    page,
    subsection
  }: { _id: string; page: string; subsection?: string } = useParams();
  const formNumber = parseInt(page) - 1;
  const pageNumber = parseInt(subsection || '1');
  const {
    currentProject,
    currentProjectForm,
    setCurrentProjectForm,
    autoSaving,
    setAutoSaving,
    formIterationNumber,
    setFormIterationNumber,
  } = useProjectsContext();
  const { loading, refetch: refetchProject } = useGetProjects({ projectId: _id, withForms: true });
  const { refetch: refetchAllProjects } = useGetProjects({});
  const { projectTemplateLoading } = useGetProjectTemplate({ projectId: _id });
  const { formLoading, refetch } = useGetFormStatuses(_id);
  const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(true);
  const { updateFunction } = useUpdateForm();
  const history = useHistory();
  const scrollRef = useRef<any>();
  const { isOpen: isCompleteModalOpen, onOpen: openCompleteModal, onClose: closeCompleteModal } = useDisclosure();
  const { isOpen: isRunDeleteModalOpen, onOpen: openRunDeleteModal, onClose: closeRunDeleteModal } = useDisclosure();

  const isPermittedToEdit = isPermitted({ user, action: 'projects.edit', data: { project: currentProject } });

  const [createFormIteration] = useMutation(CREATE_FORM_ITERATION);
  const [deleteFormIteration] = useMutation(DELETE_FORM_ITERATION);

  const formIteration = useMemo(() => currentProjectForm?.iterations[formIterationNumber || 0], [currentProject?._id, currentProjectForm?.iterations, formIterationNumber]);
  const savedValues: any = useRef();

  const formFields = useMemo(() => {
    const formFields: { name: string, type: string, validation: boolean }[] = [];
    if (currentProject) {
      currentProjectForm?.pages.forEach(page =>
        page.sections.forEach(section =>
          section.rows.forEach(row =>
            row.fields.forEach(field => {
              const formField: { name: string, type: string, validation: boolean } = { name: field.name, type: field.type, validation: false };
              const validations = field?.validations || {};
              if (validations.notEmpty) {
                formField.validation = true;
              }
              formFields.push(formField);
            }),
          ),
        ),
      );
    }
    return formFields;
  }, [currentProject, currentProjectForm?.pages]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
  });

  // Checks for empty fields to disable 'mark as complete' button
  const emptyFieldCheck = (formData) => {
    if (formFields.filter(({ validation }) => validation).length > 0) {
      setIsFieldEmpty(!formFields.filter(({ validation }) => validation).every(({ name }) => formData[name] !== undefined && formData[name].length !== 0))
    } else {
      setIsFieldEmpty(false)
    }
  }

  useEffect(() => {
    setFormIterationNumber(0);
    return () => {
      setFormIterationNumber(null);
      reset({});
      refetchAllProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentFormIterationStatus = currentProject?.forms?.[formNumber]?.iterations[formIterationNumber || 0]?.status;
  const currentFormIterationsNumber = currentProject?.forms?.[formNumber]?.iterations?.length;
  useEffect(() => {
    // refetch form data on page and iteration change
    if (formNumber > -1 && currentProject) {
      refetch();
      refetchProject();
      setCurrentProjectForm(currentProject?.forms?.[formNumber] || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formNumber,
    formIterationNumber,
    currentProject?._id,
    currentFormIterationStatus,
    currentFormIterationsNumber,
  ]);

  useEffect(() => {
    setFormIterationNumber(0);
    if (![3, 6, 9, 10].includes(formNumber)) {
      if (formNumber === 4 && pageNumber === 3) {
        return;
      }
      scrollRef?.current?.scrollTo(0, 0);
    }
  }, [formNumber, pageNumber, setFormIterationNumber]);

  useEffect(() => {
    if (currentProjectForm && currentProjectForm.index === formNumber) {
      const newData = formFields.reduce((acc, { name, type }) => {
        if (type === 'projectData') {
          return {
            ...acc,
            [name]: currentProject?.[name] || '',
          }
        }
        return {
          ...acc,
          [name]: formIteration?.values[name] === undefined ? getDefaultFieldValue(type) : formIteration?.values[name],
        };
      }, {});
      reset(newData);
    } else {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectForm?.index, formNumber, formIterationNumber, JSON.stringify(formIteration), JSON.stringify(formFields)]);

  useEffect(() => {
    // On initial load of the page and template check if section one contains subsections and if it does, redirect to projectId/1/1
    if (
      currentProject?.forms &&
      currentProject.forms[formNumber]?.pages?.length > 1 &&
      !pageNumber
    ) {
      history.push(`/project/${_id}/${formNumber + 1}/1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject]);

  useEffect(() => {
    if (formFields.filter(({ validation }) => validation).length > 0) {
      emptyFieldCheck(watch());
    } else {
      setIsFieldEmpty(false);
    }
    const subscription = watch(async (formData) => {
      if (currentProjectForm) {
        emptyFieldCheck(formData);
        const newData = formFields.reduce((acc, { name, type }) => {
          if (name in formData) {
            return { ...acc, [name]: formData[name] === undefined ? getDefaultFieldValue(type) : formData[name] };
          }
          return acc;
        }, {});
        let fieldsToSaveDirectly = tableFieldsToAutoSave.filter(function (n) { return Object.keys(savedValues.current ?? []).indexOf(n) !== -1; });
        if (
          currentProjectForm && formIteration &&
          (!isEqual(newData, savedValues.current) || fieldsToSaveDirectly.length > 0) &&
          (currentProject !== null) &&
          currentProjectForm.index === formNumber &&
          formIteration.status !== 'completed' &&
          isPermittedToEdit
        ) {
          setAutoSaving(true);
          savedValues.current = JSON.parse(JSON.stringify(newData)); // Make a copy of an object to remove references
          autoSave();
        }
      }
    })
    return () => {
      subscription.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, currentProject, formFields, formIteration])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const autoSave = useCallback(
    debounce(async () => {
      // Check values have changed and currentProject has loaded
      await updateFunction({
        variables: {
          values: {
            _id: currentProjectForm?._id,
            projectId: _id,
            status: 'inProgress',
            values: savedValues.current,
            autoSave: true,
            iteration: formIterationNumber,
          },
        },
      });
      refetch();
      refetchProject();
    }, 2000)
    , [currentProject, currentProjectForm?._id, _id, formIterationNumber]);

  const validateSections = (): boolean => {
    const f = currentProject?.forms;
    if (f !== undefined) {
      if (f.length > 0 && f[formNumber]?.pages.length > 0 && f[formNumber].pages[pageNumber - 1].sections) {
        return true;
      }
    }
    return false
  }

  const renderLoader = () => {
    <Loader />;
  };

  const isFormCompleted = formIteration?.status === 'completed';
  return (
    <>
      {loading || projectTemplateLoading || formLoading ? (
        renderLoader()
      ) : (
        <>
          <MarkAsCompleteModal
            control={control}
            errors={errors}
            trigger={trigger}
            isOpen={isCompleteModalOpen}
            onClose={closeCompleteModal}
            getValues={getValues}
            formIndex={formNumber}
            handleSubmit={handleSubmit}
            iteration={formIterationNumber}
            refetch={refetchProject}
          />
          <ConfirmationModal
            modalTitle="Delete test run"
            modalText={`Are you sure you want to delete test run "<b>${currentProjectForm?.name} ${(formIterationNumber || 0) + 1}</b>"?`}
            confirmButtonText="Remove"
            confirmButtonIcon={<BinIcon />}
            cancelButtonText="Discard"
            isOpen={isRunDeleteModalOpen}
            onClose={closeRunDeleteModal}
            confirmAction={async () => {
              const res = await deleteFormIteration({
                variables: {
                  formIterationInput: {
                    formId: currentProjectForm?._id,
                    iteration: formIterationNumber,
                  },
                },
              });
              if (res.data?.deleteFormIteration) {
                await refetchProject();
                await refetch();
                setFormIterationNumber((formIterationNumber || 0) > 0 ? (formIterationNumber || 0) - 1 : 0);
              }
            }}
          />
          <Flex w='full' direction='column' textColor='breNavy' overflow='hidden'>
            <ProjectFormHeader completed={formIteration?.status === 'completed'} />
            <Flex
              w="full"
              h="full"
              p="30px"
              pt="0px"
              overflow="hidden"
            >
              <Stack
                rounded="6px"
                w="full"
                p="20px"
                bg="#FFF"
              >
                <Flex justify="space-between" maxW='full' mb='2'>
                  <Stack>
                    {currentProjectForm?.allowMultipleIterations && (
                      <FormIterationChanger
                        name={currentProjectForm?.name || ''}
                        numberOfIterations={(currentProjectForm?.iterations || []).length}
                      />
                    )}
                    {(currentProjectForm?.pages || []).length > 1 && (
                      <HStack>
                        {currentProjectForm?.pages.map(page => (
                          <Button
                            key={page._id}
                            bg={page.index === pageNumber ? 'breBlack' : "breGray"}
                            color={page.index === pageNumber ? 'breWhite' : "breBlack"}
                            fontSize="md"
                            _hover={{}}
                            onClick={() => history.push(`/project/${_id}/${formNumber + 1}/${page.index}`)}
                            maxW="300px"
                          >
                            <Text
                              whiteSpace='nowrap'
                              isTruncated
                              textOverflow='ellipsis'
                            >{page.name}</Text>
                          </Button>
                        ))}
                      </HStack>
                    )}
                  </Stack>
                  <HStack>
                    {currentProjectForm?.allowMultipleIterations && (currentProjectForm?.iterations || []).length > 1 && (
                      <Can
                        action='projects.edit'
                        data={{ project: currentProject }}
                        yes={() =>
                          <Button
                            rightIcon={<BinIcon />}
                            bg="breGray"
                            color="breBlack"
                            fontSize="sm"
                            h="32px"
                            disabled={currentProjectForm?.iterations.length === 100}
                            _hover={{}}
                            _active={{}}
                            onClick={openRunDeleteModal}
                          >Delete test run</Button>
                        }
                      />
                    )}
                    {currentProjectForm?.allowMultipleIterations && (
                      <Can
                        action='projects.edit'
                        data={{ project: currentProject }}
                        yes={() =>
                          <Button
                            rightIcon={<FontAwesomeIcon icon={faPlus} />}
                            mr={2}
                            bg="breGray"
                            color="breBlack"
                            fontSize="sm"
                            h="32px"
                            disabled={currentProjectForm?.iterations.length === 100}
                            _hover={{}}
                            _active={{}}
                            onClick={async () => {
                              const res = await createFormIteration({
                                variables: {
                                  formId: currentProjectForm?._id,
                                }
                              });
                              if (res.data?.createFormIteration) {
                                await refetchProject();
                                await refetch();
                                setFormIterationNumber(currentProjectForm?.iterations?.length || 0);
                              }
                            }}
                          >Add test run</Button>
                        }
                      />
                    )}
                    {formIteration?.values?.completedSignedDate && isFormCompleted && (
                      <Box color="breNavy" pr="20px">
                        <Flex
                          flexDirection="column"
                          fontSize="sm"
                          justify="center"
                          h="full"
                        >
                          <Flex
                            fontWeight="700"
                            lineHeight="12px"
                          >
                            Completed at
                          </Flex>
                          {new Date(formIteration?.values?.completedSignedDate as Date).toLocaleDateString("en-uk", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour12: true,
                            hour: "numeric",
                            minute: "numeric",
                            hourCycle: "h12",
                          })}
                        </Flex>
                      </Box>
                    )}
                    <Can
                      action='projects.edit'
                      data={{ project: currentProject }}
                      yes={() =>
                        <Button
                          rightIcon={<FontAwesomeIcon icon={faCheck} />}
                          bg="brePink"
                          color="white"
                          fontSize="sm"
                          h="32px"
                          _disabled={autoSaving && !isFieldEmpty ?
                            {
                              opacity: 0.15,
                              cursor: 'not-allowed',
                            } : {
                              color: "breWhite",
                              bg: '#131535',
                              opacity: 0.15,
                              cursor: 'not-allowed'
                            }
                          }
                          disabled={autoSaving || (!isFormCompleted && isFieldEmpty)}
                          _hover={{}}
                          _active={{}}
                          onClick={async () => {
                            if (isFormCompleted) {
                              const values = {
                                iteration: formIterationNumber,
                                _id: currentProjectForm?._id,
                                status: "inProgress",
                              };
                              await updateFunction({ variables: { values } });
                              refetchProject();
                              refetch();
                            } else {
                              openCompleteModal();
                            }
                          }}
                          isLoading={autoSaving}
                          loadingText="Saving..."
                        >
                          {isFormCompleted ? "Edit" : "Mark as complete"}
                        </Button>
                      }
                    />
                  </HStack>
                </Flex>
                <Stack
                  id="parent-container"
                  overflow="auto"
                  ref={scrollRef}
                  mt="0px !important"
                >
                  <Box w="full" minH="calc(100vh - 310px)">
                    {validateSections() && currentProjectForm?.pages[pageNumber - 1]?.sections?.map((section) =>
                      <TemplateSection
                        key={section._id + formIterationNumber}
                        name={section.name}
                        description={section.description}
                        rows={section.rows}
                        formIteration={formIteration}
                        formIterationNumber={formIterationNumber || 0}
                        savedValues={savedValues}
                        control={control}
                        setValue={setValue}
                      />
                    )}
                  </Box>
                </Stack>
              </Stack>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default Project;
