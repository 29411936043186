import { Box, HStack, Text } from "@chakra-ui/react";
import { useProjectsContext } from "../../contexts/ProjectsProvider";

const ProjectSubSection = ({ index, name, status }: { index: number; name: string; status: string; }) => {
  const { formIterationNumber, setFormIterationNumber } = useProjectsContext();
  const active = index === formIterationNumber;
  return (
    <HStack
      ml="40px"
      minW="215px"
      fontSize="14px"
      fontWeight="400"
      lineHeight="36px"
      alignItems="center"
      onClick={() => setFormIterationNumber(index)}
      opacity={active ? 1 : 0.7}
      borderRadius="10px"
      cursor="pointer"
    >
      <Box w="6px" h="6px" m={2} rounded="100%" bg={status === 'completed' ? "breGreen" : "breAmber"} flexShrink={0} />
      <Text ml="15px" isTruncated>{name}</Text>
    </HStack>
  );
};

export default ProjectSubSection;

export const projectSubSectionStyles = {
  subSection: {
    selectedFontColor: "#131535",
    unselectedFontColor: "#131535",
  }
};
