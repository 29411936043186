import { ApolloQueryResult, useMutation } from '@apollo/client';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { AuditLogIcon, EditIcon, PreviewIcon, SaveIcon } from '../../icons';
import { IProjectTemplate } from '../../interfaces/IProjectTemplate';
import FormPreviewModal from '../FormPreviewModal';
import UPDATE_PROJECT_TEMPLATE from '../../gql/mutation/UPDATE_PROJECT_TEMPLATE';
import { useEffect } from 'react';
import Can from '../Can';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import TemplateEditModal from './TemplateEditModal';
import TemplateAuditLogModal from './TemplateAuditLogModal';

interface ITemplateHeaderProps {
  refetchTemplate: () => Promise<
    ApolloQueryResult<{ searchProjectTemplate: IProjectTemplate }>
  >;
}

const TemplateHeader: React.FC<ITemplateHeaderProps> = ({
  refetchTemplate,
}: ITemplateHeaderProps) => {
  const toast = useToast();
  const history = useHistory();
  const { selectedPage, currentProjectTemplate, currentProjectFormTemplate, pages, autoSaving } = useProjectsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFormPreviewOpen,
    onOpen: onFormPreviewOpen,
    onClose: onFormPreviewClose,
  } = useDisclosure();
  const {
    isOpen: isAuditLogOpen,
    onOpen: onAuditLogOpen,
    onClose: onAuditLogClose,
  } = useDisclosure();
  const { control, getValues, reset } = useForm({
    mode: 'all',
    defaultValues: {
      name: currentProjectTemplate?.name,
      category: currentProjectTemplate?.category,
      isUkasRelated: currentProjectTemplate?.isUkasRelated ? [true] : [],
    },
  });

  useEffect(() => {
    reset({
      name: currentProjectTemplate?.name,
      category: currentProjectTemplate?.category,
      isUkasRelated: currentProjectTemplate?.isUkasRelated ? [true] : [],
    });
  }, [reset, currentProjectTemplate]);

  const [updateTemplate] = useMutation(UPDATE_PROJECT_TEMPLATE);

  const updateTemplateTitle = async () => {
    let values = getValues();
    if (Boolean(values.name?.length) && currentProjectTemplate) {
      await updateTemplate({
        variables: {
          projectTemplateInput: {
            _id: currentProjectTemplate._id,
            ...values,
            isUkasRelated: values.isUkasRelated[0] || false,
          },
        },
      });
      toast({ ...toastSuccess, description: 'Template updated successfully' });
      onClose();
      refetchTemplate();
    } else {
      toast({ ...toastFailed, description: 'Enter template title' });
    }
  };

  return (
    <>
      <TemplateEditModal
        control={control}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          reset({});
        }}
        updateTemplateTitle={updateTemplateTitle}
      />
      <TemplateAuditLogModal
        isOpen={isAuditLogOpen}
        onClose={onAuditLogClose}
      />
      {currentProjectFormTemplate?.pages && (currentProjectFormTemplate.pages.length || 0) > 0 && currentProjectFormTemplate.pages[0] && (
        <FormPreviewModal
          isOpen={isFormPreviewOpen}
          onClose={onFormPreviewClose}
          form={pages[selectedPage]}
        />
      )}
      <Flex w='full' pb='4' justifyContent={'space-between'}>
        <Flex alignItems='center'>
          <Button
            h='32px'
            bg='white'
            paddingX='1'
            onClick={() => history.goBack()}
          >
            <ChevronLeftIcon stroke='black' fontSize='20px' />
          </Button>
          <Text fontSize='24px' fontWeight='700' mx='3'>
            {currentProjectTemplate?.name}
          </Text>
          <Can
            action="projectTemplates.edit"
            data={{ projectTemplate: currentProjectTemplate }}
            yes={() => <EditIcon cursor='pointer' onClick={onOpen} />}
          />
        </Flex>
        <Flex alignItems='center'>
          <Button
            h='32px'
            fontSize='smm'
            bg='white'
            fontWeight='700'
            alignItems='center'
            onClick={onAuditLogOpen}
          >
            Audit Log
            <AuditLogIcon ml='2' />
          </Button>
          <Button
            fontSize='smm'
            mx='4'
            h='32px'
            bg='white'
            fontWeight='700'
            alignItems='center'
            onClick={onFormPreviewOpen}
          >
            Preview
            <PreviewIcon ml='2' />
          </Button>
          <Can
            action="projectTemplates.edit"
            data={{ projectTemplate: currentProjectTemplate }}
            yes={() =>
              <Text fontSize='smm' alignItems='center' display='flex'>
                {autoSaving ? 'Saving...' : 'Up to date'}
                <SaveIcon ml='2' stroke='black' />
              </Text>
            }
          />
        </Flex>
      </Flex>
    </>
  );
};

export default TemplateHeader;
