import { createIcon } from "@chakra-ui/icons";

const BinIcon = createIcon({
  displayName: "Bin",
  viewBox: "0 0 13 16",
  path: (
    <>
      <path d="M10.1879 14.8748H3.77117C3.52805 14.8748 3.29489 14.7782 3.12298 14.6063C2.95107 14.4344 2.85449 14.2012 2.85449 13.9581V5.70801H11.1046V13.9581C11.1046 14.2012 11.008 14.4344 10.8361 14.6063C10.6642 14.7782 10.431 14.8748 10.1879 14.8748Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.60449 12.1252V8.4585" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.35449 12.1252V8.4585" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5625 4.46282L12.2189 1.98535" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.35481 1.14503L4.66589 1.71643C4.54787 1.74133 4.43592 1.78929 4.33646 1.85754C4.23701 1.92579 4.152 2.013 4.08632 2.11417C4.02063 2.21534 3.97556 2.32848 3.95368 2.4471C3.9318 2.56572 3.93355 2.68749 3.95883 2.80544L4.15194 3.70073L8.6351 2.74738L8.44198 1.85087C8.39139 1.61312 8.24843 1.4052 8.04455 1.27283C7.84068 1.14047 7.59257 1.0945 7.35481 1.14503V1.14503Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default BinIcon;
