import { createIcon } from "@chakra-ui/icons";

const EyeIcon = createIcon({
  displayName: "Eye",
  viewBox: "0 0 14 10",
  path: (
    <>
      <path d="M6.99784 1.20918C4.69865 1.1704 2.32075 2.77715 0.825789 4.42269C0.668869 4.5969 0.582031 4.82306 0.582031 5.05752C0.582031 5.29198 0.668869 5.51814 0.825789 5.69235C2.28824 7.3031 4.6593 8.94692 6.99784 8.90757C9.33639 8.94692 11.708 7.3031 13.1716 5.69235C13.3285 5.51814 13.4154 5.29198 13.4154 5.05752C13.4154 4.82306 13.3285 4.5969 13.1716 4.42269C11.6749 2.77715 9.29703 1.1704 6.99784 1.20918Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.13721 5.05843C9.13709 5.48144 9.01155 5.89493 8.77646 6.24659C8.54136 6.59826 8.20727 6.87232 7.81642 7.03412C7.42558 7.19593 6.99553 7.2382 6.58066 7.15561C6.16579 7.07301 5.78472 6.86926 5.48565 6.5701C5.18657 6.27095 4.98292 5.88983 4.90043 5.47493C4.81795 5.06004 4.86034 4.63 5.02225 4.2392C5.18415 3.8484 5.4583 3.51438 5.81003 3.27938C6.16176 3.04438 6.57528 2.91895 6.99829 2.91895C7.27925 2.91887 7.55746 2.97416 7.81704 3.08166C8.07661 3.18916 8.31246 3.34676 8.5111 3.54545C8.70974 3.74415 8.86728 3.98003 8.97471 4.23964C9.08214 4.49924 9.13736 4.77748 9.13721 5.05843Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default EyeIcon;
