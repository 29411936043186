import { useQuery } from "@apollo/client"
import { Avatar, HStack, Text } from "@chakra-ui/react";

import GET_USERS from "../gql/queries/GET_USERS"
import Loader from "./Loader";

const UserAvatar = ({ userId }) => {
  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const user = (data?.users || [])[0];

  if (loading) {
    return <Loader size="sm" />;
  }

  if (!user) {
    return <Text>Could not load user</Text>;
  }

  return (<HStack>
    <Avatar bg="breNavy" src={`${process.env.REACT_APP_API_URL}/files/photo/${user._id}`} size='sm'></Avatar>
    <Text>{user.displayName}</Text>
  </HStack>);
};

export default UserAvatar;
