import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import useValidate from '../../../hooks/useValidate';
import TableHeader from '../TableHeader';
import { useEffect, useState, useMemo } from 'react';
import Dropdown from '../Dropdown';
import PeoplePicker from '../PeoplePicker';
import TextInput from '../TextInput';
import Datepicker from '../Datepicker';
import SignatureTableControl from './SignatureTableControl';

interface IStaticTable extends IField {
  data?: any;
  description?: string;
  variant?: string;
  help?: string;
  styles?: {
    font?: string
  };
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const StaticTable = ({ control, name, label, description, data, validations = {}, disabled = false, help = '', required, styles, columns }: IStaticTable) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);
  const [dataValues, setdataValues] = useState(data ? JSON.parse(JSON.stringify(data)) : undefined);

  const rowsNumber = useMemo(() => {
    const staticColumnsNumber = (columns || []).filter(({ type }) => type === 'staticText').map(({ options }) => options.length);
    return Math.max(...staticColumnsNumber, 0);
  }, [columns]);

  useEffect(() => {
    setdataValues(control._formValues[name] || []);
    return () => {
      setdataValues(undefined);
    }
  }, [control._formValues, name]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        const updateValue = (value, index, type) => {
          let updatedData = dataValues || [];
          updatedData[index][type] = value;
          onChange({ target: { name, value: updatedData } });
        }

        return (
          <Box w='full' id={name} mt='none'>
            {label && (
              <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
                <Box
                  color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                  fontWeight='bold'
                  fontSize='ssm'
                  position='static'
                  left='none'
                  zIndex={2}
                >
                  {label}
                  <Text
                    pt='5px'
                    color='textInput.labelFont.normal'
                    fontWeight='semi_medium'
                    fontSize='smm'
                  >{description}</Text>
                </Box>
              </Flex>
            )}
            <TableContainer
              border={'1px solid'}
              borderColor='#F0F0F0'
              borderRadius='6'
              style={{ overflow: 'auto !important' }}
            >
              <Table fontSize='smm' variant='simple'>
                <Thead>
                  <Tr>
                    {columns?.map(({ label }) =>
                      <TableHeader header={label} key={`col-${label}`} styles={{ paddingInline: "0.5rem" }} />
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.from(Array(rowsNumber).keys()).map((_, index) => (
                    <Tr key={`row-${index}`}>
                      {dataValues && columns?.map(({ type, value, options, variant, placeholder }) => {
                        const fieldName = `${name}.${index}.${value}`;
                        if (type === 'staticText') {
                          return <Td key={fieldName} px={2}>{options[index]?.label || ''}</Td>;
                        } else if (type === 'textInput') {
                          return (
                            <Td key={fieldName} px={2}>
                              <TextInput
                                control={control}
                                name={fieldName}
                                options={options}
                                disabled={disabled}
                                inTable={true}
                                variant={variant}
                                placeholder={placeholder}
                              />
                            </Td>
                          )
                        } else if (type === 'datepicker') {
                          return (
                            <Td key={fieldName} px={2}>
                              <Datepicker
                                control={control}
                                name={fieldName}
                                options={options}
                                disabled={disabled}
                                canSelectFutureDate={true}
                                inTable={true}
                                variant={variant}
                              />
                            </Td>
                          )
                        } else if (type === 'dropdown') {
                          return (
                            <Td key={fieldName} px={2}>
                              <Dropdown
                                control={control}
                                name={fieldName}
                                options={options}
                                disabled={disabled}
                                inTable={true}
                                placeholder="Select"
                              />
                            </Td>
                          )
                        } else if (type === 'peoplePicker') {
                          return (
                            <Td key={fieldName} px={2}>
                              <PeoplePicker
                                control={control}
                                disabled={disabled}
                                name={fieldName}
                                validations={{ notEmpty: true }}
                                onChange={(v) => updateValue(v, index, value)}
                                variant={variant}
                                inTable={true}
                              />
                            </Td>
                          )
                        } else if (type === 'signature') {
                          return (
                            <Td key={fieldName} px={2}>
                              <SignatureTableControl
                                control={control}
                                name={fieldName}
                                disabled={disabled}
                              />
                            </Td>
                          )
                        }
                        return null;
                      })}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
          </Box >
        );
      }}
    />
  );
};

export default StaticTable;

export const amslerChecklistTableStyles = {
  signatureTable: {

  }
};
