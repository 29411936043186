import { createIcon } from '@chakra-ui/icons';

const TrashIcon = createIcon({
  path: (
    <>
      <path
        d='M10.4422 13.417H4.45322C4.22631 13.417 4.00869 13.3268 3.84824 13.1664C3.6878 13.0059 3.59766 12.7883 3.59766 12.5614V4.86133H11.2977V12.5614C11.2977 12.7883 11.2076 13.0059 11.0471 13.1664C10.8867 13.3268 10.6691 13.417 10.4422 13.417Z'
        stroke='currentColor'
        fill='none'
      />
      <path d='M6.16406 10.85V7.42773' stroke='currentColor' />
      <path d='M8.73047 10.85V7.42773' stroke='currentColor' />
      <path d='M1.45898 3.69853L12.3383 1.38623' stroke='currentColor' />
      <path
        d='M7.79795 0.602194L5.28829 1.1355C5.17814 1.15874 5.07365 1.2035 4.98083 1.2672C4.888 1.3309 4.80866 1.4123 4.74736 1.50672C4.68605 1.60115 4.64398 1.70674 4.62356 1.81745C4.60315 1.92817 4.60478 2.04182 4.62837 2.15191L4.80861 2.98751L8.99289 2.09772L8.81265 1.26098C8.76542 1.03907 8.632 0.845014 8.44171 0.721472C8.25143 0.597931 8.01986 0.555026 7.79795 0.602194V0.602194Z'
        stroke='currentColor'
        fill='none'
      />
    </>
  ),
  viewBox: '0 0 14 14',
});

export default TrashIcon;
