import { createIcon } from '@chakra-ui/icons';

const TrafficConeIcon = createIcon({
  path: (
    <>
      <path
        d='M11.6195 2.22283C11.5621 2.06027 11.4557 1.91953 11.3149 1.81998C11.1742 1.72043 11.006 1.66698 10.8337 1.66699H9.16699C8.9946 1.66698 8.82645 1.72043 8.68571 1.81998C8.54497 1.91953 8.43856 2.06027 8.38116 2.22283L3.57699 15.8337H1.66699V17.5003H18.3337V15.8337H16.4237L11.6195 2.22283ZM12.892 10.8337H7.10866L7.99199 8.33366H12.0087L12.892 10.8337ZM9.75616 3.33366H10.2445L11.4212 6.66699H8.57949L9.75616 3.33366ZM6.52116 12.5003H13.4795L14.6562 15.8337H5.34449L6.52116 12.5003Z'
        fill='#282F36'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default TrafficConeIcon;
