import { useEffect, useCallback } from 'react';
import {
  As,
  Box,
  Flex,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getIcon, InfinityIcon } from '../../icons';
import { useForm } from 'react-hook-form';
import { TextInput } from '../../components/FormControls';
import UPDATE_FORM_TEMPLATE from '../../gql/mutation/UPDATE_FORM_TEMPLATE';
import IconPickerControl from '../../components/FormControls/IconPickerControl';
import TemplateContent from '../../components/TemplateBuilder/TemplateContent';
import GET_COMMON_FORM from '../../gql/queries/GET_COMMON_FORM';
import CommonFormHeader from '../../components/CommonForms/CommonFormHeader';
import { debounce } from 'lodash';
import { isPermitted } from '../../components/Can';
import { useAppContext } from '../../contexts/AppProvider';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import { EOperationType } from '../../interfaces/EOperationType';
import { unsavedChangesMessage } from '../../utils/defaults';
import usePrompt from '../../hooks/usePrompt';

const CommonForm = () => {
  const { user } = useAppContext();
  const { id } = useParams<{ id: string }>();
  const { setCurrentProjectFormTemplate, setAutoSaving, fieldsInEdit } = useProjectsContext();
  usePrompt(fieldsInEdit.length > 0, unsavedChangesMessage);

  const [updateFormTemplate] = useMutation(UPDATE_FORM_TEMPLATE);
  const { data, refetch } = useQuery(
    GET_COMMON_FORM,
    {
      variables: {
        formTemplatesQueryInput: {
          _id: id,
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  const formTemplate = (data?.getFormTemplates || [])[0];

  useEffect(() => {
    setCurrentProjectFormTemplate(formTemplate);
    return () => {
      setCurrentProjectFormTemplate(null);
    }
  }, [formTemplate, setCurrentProjectFormTemplate]);

  const { control, setValue, watch } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      icon: 'InfinityIcon',
    },
  });
  const formValues = watch();

  useEffect(() => {
    setValue('name', formTemplate?.name);
    setValue('icon', formTemplate?.icon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formTemplate)]);

  const isPermittedToEdit = isPermitted({ user, action: "commonForms.edit" });

  const saveForm = async () => {
    if (!isPermittedToEdit) return;
    setAutoSaving(true);
    try {
      const formValues = watch();
      await updateFormTemplate({
        variables: {
          formTemplateInput: {
            _id: formTemplate?._id,
            name: formValues.name,
            icon: formValues.icon,
          },
          operationType: EOperationType.EMPTY,
        },
      });
    } catch (e) { }
    refetch();
    setAutoSaving(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveFormDebounced = useCallback(
    debounce(() => {
      saveForm();
    }, 2000),
    [JSON.stringify(formTemplate), watch]
  );

  useEffect(() => {
    if (formTemplate) {
      saveFormDebounced();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formValues)]);

  const getDefaultIcon = (): As<any> => {
    if (control._formValues.icon) {
      return getIcon[control._formValues.icon];
    }
    return InfinityIcon;
  };

  return (
    <Box p='6'>
      <CommonFormHeader formTemplate={formTemplate} />
      {formTemplate &&
        <TemplateContent
          form={formTemplate}
          header={
            <>
              <Flex alignItems='center'>
                <Flex flexDirection='column' mr='4'>
                  <Text
                    fontWeight='bold'
                    fontSize='11px'
                    position='static'
                    left='none'
                    zIndex={1}
                    color='textInput.labelFont.normal'
                    pb='1'
                    pl='1'
                  >
                    Icon
                  </Text>
                  <Popover placement='bottom-start'>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Box
                            px='3'
                            py='2'
                            borderRadius='6px'
                            borderColor='textInput.border.normal'
                            borderWidth='1px'
                            borderStyle='solid'
                            cursor={!isPermittedToEdit ? 'not-allowed' : 'pointer'}
                            onClick={(e) => {
                              if (!isPermittedToEdit) e.preventDefault();
                            }}
                            opacity={!isPermittedToEdit ? 0.5 : 1}
                          >
                            <Icon as={getDefaultIcon()} />
                          </Box>
                        </PopoverTrigger>
                        <PopoverContent w='fit-content'>
                          <PopoverBody>
                            <IconPickerControl
                              onClick={(value: string) => {
                                setValue('icon', value);
                                onClose();
                              }}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </>
                    )}
                  </Popover>
                </Flex>
                <TextInput
                  control={control}
                  name='name'
                  required={true}
                  label='Title'
                  placeholder='Title'
                  validations={{ notEmpty: true }}
                  disabled={!isPermittedToEdit}
                />
              </Flex>
            </>
          }
        />
      }
    </Box>
  );
};

export default CommonForm;
