import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { CrossIcon } from '../icons';
interface IConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalTitle?: string;
  modalText: string;
  confirmAction: () => void;
  confirmButtonText?: string;
  confirmButtonIcon?: ReactElement;
  cancelButtonText?: string;
}

const ConfirmationModal = ({
  isOpen,
  onClose,
  modalTitle,
  modalText,
  confirmAction,
  confirmButtonText,
  confirmButtonIcon,
  cancelButtonText,
}: IConfirmationModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        {modalTitle && (
          <ModalHeader px={4}>
            <HStack justify="space-between">
              <Text>
                {modalTitle}
              </Text>
              <CrossIcon color="breNavy" onClick={onClose} cursor="pointer" />
            </HStack>
          </ModalHeader>
        )}
        <ModalBody borderTopWidth="1px" borderBottomWidth="1px" borderColor="breLightGray" py={6}>
          <Text fontSize="md" dangerouslySetInnerHTML={{ __html: modalText }}></Text>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button px={4} fontSize="smm" onClick={onClose}>
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button px={4}
              fontSize="smm"
              onClick={() => {
                confirmAction();
                onClose();
              }}
              _hover={{ opacity: 0.6 }}
              bg={'brePink'}
              color={'white'}
              leftIcon={confirmButtonIcon}
            >
              {confirmButtonText || 'Confirm'}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal >
  );
};

export default ConfirmationModal;
