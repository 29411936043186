import { Redirect } from "react-router-dom";
import { isPermitted } from "../components/Can";

import { useAppContext } from "../contexts/AppProvider";

const Admin = () => {
  const { user } = useAppContext();

  if (isPermitted({ user, action: 'projectTemplates.view' })) {
    return <Redirect to="/admin/templates" />;
  }

  if (isPermitted({ user, action: 'commonForms.view' })) {
    return <Redirect to="/admin/common-forms" />;
  }

  if (isPermitted({ user, action: 'users.view' })) {
    return <Redirect to="/admin/users" />;
  }

  return <Redirect to="/" />;
};

export default Admin;
