import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Flex,
  Box,
  Image,
  useToast,
  VStack,
  Avatar,
} from "@chakra-ui/react";

import { toastFailed } from "../bootstrap/config";
import { ArrowRight } from "../icons";
import breLogo from "../images/bre-logo.svg";
import tptLaptop from "../images/tpt-laptop.png";
import { useLocation } from "react-router-dom";

type StateProps = {
  redirectUrl: string;
};

const Login = () => {
  const toast = useToast();
  const [refresh, setRefresh] = useState(false);
  const { state } = useLocation<StateProps>();
  const params = window.location.search.split("&");
  const redirectUrl = params
    .find((str) => str.includes("redirectUrl"))
    ?.split("=")[1];
  const errorMessage = params
    .find((str) => str.includes("errorMessage"))
    ?.split("=")[1];

  useEffect(() => {
    if (state && state.redirectUrl !== "/" && state.redirectUrl) {
      localStorage.setItem("redirectUrl", state.redirectUrl);
      state.redirectUrl = "/";
    }
  }, [state]);

  const user = useMemo(() => {
    const logOutUser = localStorage.getItem("logOutUser");

    if (!logOutUser) {
      return null;
    }
    try {
      const expiresAt = new Date(JSON.parse(logOutUser)?.expiresAt).getTime();
      if (expiresAt < new Date().getTime()) {
        localStorage.removeItem("logOutUser");
        return null;
      }
      return JSON.parse(logOutUser);
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (errorMessage) {
      toast({
        ...toastFailed,
        title: "Couldn't sign in",
        description: decodeURI(errorMessage),
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loginWithAzureAD = async () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/aad${
        redirectUrl ? `?redirect=${redirectUrl}` : ""
      }`,
      "_self"
    );
  };

  const removeUser = () => {
    localStorage.removeItem("logOutUser");
    setRefresh(!refresh);
  };

  return (
    <Flex
      w="full"
      h="100vh"
      flexDir={["row-reverse", "row-reverse", "row"]}
      bg="F4F4F4"
    >
      {user ? (
        <Flex
          w={["full", "full", "30%"]}
          align="center"
          order={[2, 2, 1]}
          justify={["center", "center", "flex-end"]}
          h="full"
        >
          <VStack spacing={5} align="center" textAlign="center">
            <Image src={breLogo} h="75px" w="75px" mb={2}/>
            <Flex
              bg="white"
              rounded="full"
              borderWidth="12px"
              borderColor="rgba(109, 100, 152, 0.1)"
            >
              <Avatar
                h="75px"
                w="75px"
                borderWidth="4px"
                borderColor="white"
                src={user?.imgUrl}
                name={user?.displayName}
              />
            </Flex>
            <Button
              w="204px"
              bg="brePink"
              color="#FFF"
              onClick={loginWithAzureAD}
              borderRadius="10px"
              fontSize="14px"
              lineHeight="18px"
              h="40px"
              _hover={{ opacity: 0.8 }}
            >
              Login as {user?.firstName || user?.displayName}
            </Button>
            <Flex
              flexDir="column"
              color="loginPage.descriptionColor"
              align="center"
              fontSize="11px"
            >
              <Flex>Not {user?.firstName || user?.displayName}?</Flex>
              <Flex
                _hover={{ opacity: 0.8 }}
                cursor="pointer"
                onClick={removeUser}
              >
                Login as someone else
              </Flex>
            </Flex>
          </VStack>
        </Flex>
      ) : (
        <Flex
          w={["full", "full", "30%"]}
          align="center"
          order={[2, 2, 1]}
          justify={["center", "center", "flex-end"]}
          h="full"
        >
          <Flex flexDir="column" textAlign={["center", "center", "start"]}>
            <Button
              w="240px"
              bg="brePink"
              color="#FFF"
              _hover={{ opacity: 0.8 }}
              onClick={loginWithAzureAD}
              borderRadius="10px"
              fontSize="14px"
              lineHeight="18px"
              h="40px"
              rightIcon={<ArrowRight />}
            >
              Login with Azure AD
            </Button>
          </Flex>
        </Flex>
      )}
      <Flex
        w={["full", "full", "70%"]}
        h="full"
        align="center"
        order={[1, 1, 2]}
        justify={["center", "center", "flex-end"]}
      >
        <Box h={["30vh", "70vh", "80vh"]} overflow="hidden">
          <Image h="full" maxW="max-content" src={tptLaptop} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;
