import { Button, HStack, Stack, Text, Switch, FormLabel, VStack } from '@chakra-ui/react';
import { useEffect, useState, useMemo } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import pluralize from 'pluralize';
import { useAppContext } from '../../contexts/AppProvider';
import { TrashIcon } from '../../icons';
import { IFormField, IFormFieldOption, IFormFieldOptionWType } from '../../interfaces/IFormField';
import Can, { isPermitted } from '../Can';
import { Dropdown, TextInput } from '../FormControls';
import TemplateFieldOptionsInput from './TemplateFieldOptionsInput';
import { firstCharacterCapitalize as capitalize } from '../../utils/helpers';
import { useProjectsContext } from '../../contexts/ProjectsProvider';

interface ITemplateFieldColumnsInput {
  options: IFormFieldOptionWType[];
  name;
  isStatic?: boolean;
  setValue: UseFormSetValue<IFormField>
}

const TemplateFieldColumnsInput = ({
  options,
  name,
  isStatic,
  setValue,
}: ITemplateFieldColumnsInput) => {
  const { user } = useAppContext();
  const { currentProjectTemplate } = useProjectsContext();
  const { control, watch, setValue: setLocalValue, getValues, reset } = useForm<{
    label: string[],
    value: string[],
    type: string[],
    variant: string[],
    options: IFormFieldOption[][],
  }>({
    mode: 'all',
    defaultValues: {
      label: [],
      value: [],
      type: [],
      variant: [],
      options: [],
    },
  });
  const values = watch();

  const [fieldOptions, setFieldOptions] = useState<IFormFieldOption[]>(options || []);

  const isPermittedToEdit = isPermitted({ user, action: 'projectTemplates.edit', data: { projectTemplate: currentProjectTemplate } });

  useEffect(() => {
    const subscription = watch((value) => {
      if (Array.isArray(value.label)) {
        const options: IFormFieldOption[] = value.label
          .map((l: string | undefined, i: number) => ({
            label: l || '',
            value: value.value?.[i] || '',
            type: value.type?.[i] || '',
            variant: value.variant?.[i] || '',
            options: value.options?.[i] || [],
          }));
        setValue(name, options);
        setFieldOptions(options);
      }
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    setFieldOptions(options);
    reset({
      label: options.map((o) => o.label),
      value: options.map((o) => o.value),
      type: options.map((o) => o.type),
      variant: options.map((o) => o.variant),
      options: options.map((o) => o.options),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  const nameSingular = pluralize(name, 1);
  const typeOptions = useMemo(() => {
    const options = [{
      label: 'Text input',
      value: 'textInput',
    }, {
      label: 'Dropdown',
      value: 'dropdown',
    }, {
      label: 'Date picker',
      value: 'datepicker',
    }, {
      label: 'People picker',
      value: 'peoplePicker',
    }, {
      label: 'Signature',
      value: 'signature',
    }];
    if (isStatic) {
      options.unshift({
        label: 'Static text',
        value: 'staticText',
      });
    }
    return options;
  }, [isStatic]);

  return (
    <>
      <Text mt={4} fontSize="sm" fontWeight="bold" textTransform="capitalize">{name}</Text>
      {fieldOptions.map((option: IFormFieldOption, index: number) => (
        <Stack key={`option-field-column-${index}`} spacing="none" mt={2}>
          <HStack>
            <Text fontSize="sm" mt={2} fontWeight="bold">{capitalize(nameSingular)} # {index + 1}</Text>
            <Can
              action="projectTemplates.edit"
              data={{ projectTemplate: currentProjectTemplate }}
              yes={() =>
                <TrashIcon
                  w="12px"
                  cursor='pointer'
                  onClick={() => {
                    const delElemAtIndex = (array: any[], index: number) => {
                      const copy = [...array];
                      copy.splice(index, 1);
                      return copy;
                    };
                    const optionIndex = options.findIndex(({ value }) => value === option.value); // find by unique database name
                    reset({
                      label: delElemAtIndex(getValues().label, optionIndex),
                      value: delElemAtIndex(getValues().value, optionIndex),
                      type: delElemAtIndex(getValues().type, optionIndex),
                      variant: delElemAtIndex(getValues().variant, optionIndex),
                      options: delElemAtIndex(getValues().options, optionIndex),
                    });
                  }}
                />
              }
            />
          </HStack>
          <TextInput
            label={`${capitalize(nameSingular)} # ${index + 1} title`}
            control={control}
            name={`label[${index}]`}
            validations={{ notEmpty: true }}
            disabled={!isPermittedToEdit}
          />
          <TextInput
            label={`${capitalize(nameSingular)} # ${index + 1} database name`}
            control={control}
            name={`value[${index}]`}
            validations={{ notEmpty: true }}
            disabled={!isPermittedToEdit}
          />
          <Dropdown
            label={`${capitalize(nameSingular)} # ${index + 1} type`}
            control={control}
            name={`type[${index}]`}
            validations={{ notEmpty: true }}
            options={typeOptions}
            disabled={!isPermittedToEdit}
            placeholder="Select type"
          />
          {values.type[index] === 'textInput' && (
            <VStack align="left">
              <HStack>
                <Switch
                  id={`variant.${index}.numeric`}
                  isChecked={values.variant[index] === 'numeric'}
                  onChange={(e) => setLocalValue(`variant.${index}`, e.target.checked ? 'numeric' : '')}
                  disabled={!isPermittedToEdit}
                />
                <FormLabel htmlFor={`variant.${index}.numeric`}>Numeric field</FormLabel>
              </HStack>
              <HStack>
                <Switch
                  id={`variant.${index}.multiline`}
                  isChecked={values.variant[index] === 'multiline'}
                  onChange={(e) => setLocalValue(`variant.${index}`, e.target.checked ? 'multiline' : '')}
                  disabled={!isPermittedToEdit}
                />
                <FormLabel htmlFor={`variant.${index}.multiline`}>Multiline field</FormLabel>
              </HStack>
            </VStack>
          )}
          {(values.type[index] === 'staticText' || values.type[index] === 'dropdown') && (
            <TemplateFieldOptionsInput
              setValue={(name, value) => setLocalValue(`options.${index}`, value)}
              options={(values.options[index] || []) as IFormFieldOption[]}
              name="options"
              label={`${capitalize(nameSingular)} # ${index + 1} options`}
            />
          )}
          {values.type[index] === 'datepicker' && (
            <Dropdown
              label={`${capitalize(nameSingular)} # ${index + 1} variant`}
              control={control}
              name={`variant[${index}]`}
              validations={{ notEmpty: true }}
              options={[
                { value: 'date', label: 'Date only' },
                { value: 'datetime', label: 'Date and time' },
                { value: 'time', label: 'Time only' },
              ]}
              disabled={!isPermittedToEdit}
              placeholder="Select variant"
            />
          )}
          {values.type[index] === 'peoplePicker' && (
            <Dropdown
              label={`${capitalize(nameSingular)} # ${index + 1} group`}
              control={control}
              name={`variant[${index}]`}
              validations={{ notEmpty: true }}
              options={[{
                label: 'Wider team',
                value: 'widerTeam',
              }, {
                label: 'Project leads',
                value: 'projectLeads',
              }]}
              disabled={!isPermittedToEdit}
              placeholder="Select group"
            />
          )}
        </Stack>
      ))}
      <Can
        action="projectTemplates.edit"
        data={{ projectTemplate: currentProjectTemplate }}
        yes={() =>
          <Button
            mt='2'
            w='full'
            h='28px'
            fontSize='smm'
            onClick={() => {
              const labelsArray = getValues().label;
              setLocalValue('label', [...labelsArray, '']);
            }}
          >
            Add {nameSingular}
          </Button>
        }
      />
    </>
  );
};

export default TemplateFieldColumnsInput;
