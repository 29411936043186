import React, { createContext, useContext, useMemo, useState } from "react";

import { IModalState, IModalContext } from "../interfaces/IModalContext";

export const ModalContext = createContext({} as IModalContext);

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within the ModalProvider');
  }
  return context;
};

const ModalProvider = (props: any) => {
  const [modalState, setModalState] = useState<IModalState>('closed');
  const [modalScope, setModalScope] = useState<string>();

  const value = useMemo(() => ({
    modalState, setModalState,
    modalScope, setModalScope,
  }), [ // eslint-disable-line react-hooks/exhaustive-deps
    modalState,
    modalScope,
  ]);

  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider;
