import {
  Box,
  Tooltip,
  Checkbox as ChakraCheckbox,
  Flex,
} from '@chakra-ui/react';

import { Controller } from 'react-hook-form';
import { DefinedValidations } from '../../interfaces/Validations';
import { IField } from '../../interfaces/IField';
import useValidate from '../../hooks/useValidate';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Asterisk } from '../../icons';
import { useEffect, useState } from 'react';

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `${label} cannot be empty`;
    }
  },
};

const Checkbox = ({
  control,
  name,
  label,
  tooltip = '',
  validations = {},
  disabled = false,
  options = [],
}: IField) => {
  const validate = useValidate(
    label || name,
    validations || {},
    definedValidations
  );
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setSelectedValues(control._formValues[name] || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control._formValues, name]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState, formState }) => {
        const { onChange, onBlur, value } = field;
        const { error } = fieldState;
        return (
          <Box
            id={name}
            mt='none'
            fontSize='smm'
            color='checkListTableRow.td.color'
            fontWeight='semi_medium'
          >
            {label && (
              <Flex pt={2} align='center' justify='space-between' mb='none'>
                <Box
                  color={
                    error
                      ? 'dropdown.labelFont.error'
                      : 'dropdown.labelFont.normal'
                  }
                  fontWeight='bold'
                  fontSize='ssm'
                  position='static'
                  left='none'
                  zIndex={1}
                  minH="16px"
                >
                  {label}
                  {!disabled && validations?.notEmpty && (
                    <Asterisk
                      h='8px'
                      ml='5px'
                      mb='8px'
                      fill='textInput.iconAsterisk'
                      stroke='textInput.iconAsterisk'
                    />
                  )}
                  {tooltip && (
                    <Tooltip hasArrow label={tooltip} placement='top'>
                      <InfoOutlineIcon mb={1} h='14px' />
                    </Tooltip>
                  )}
                </Box>
              </Flex>
            )}
            {options?.map((option, index) => (
              <ChakraCheckbox
                mr='5'
                key={`checkbox-${option.value}-${index}`}
                borderRadius='5px'
                borderColor='checkListTableRow.checkBox.borderColor'
                colorScheme='checkListTableRow.checkBox.colorScheme'
                css={{
                  '.chakra-checkbox__control': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                    width: '21px',
                    height: '21px',
                    '&[data-checked]': {
                      background: '#131535',
                      borderColor: 'rgba(129, 129, 151, 0.5)',
                      '&[data-hover]': {
                        background: '#131535',
                        borderColor: 'rgba(129, 129, 151, 0.5)',
                      },
                    },
                  },
                }}
                py={3}
                isChecked={selectedValues.includes(option.value)}
                isDisabled={disabled}
                onChange={() => {
                  const updatedValues = [...selectedValues];
                  if (selectedValues.includes(option.value)) {
                    const index = updatedValues.indexOf(option.value);
                    updatedValues.splice(index, 1);
                  } else {
                    updatedValues.push(option.value);
                  }
                  setSelectedValues(updatedValues);
                  onChange(updatedValues);
                  onBlur();
                }}
              >
                {option.label}{' '}
              </ChakraCheckbox>
            ))}
            {error && (
              <Box fontSize='ssm' ml={1} mt={1} color='form.checkbox.error'>
                {error.message}
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};

export default Checkbox;
