import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  Image,
  Spinner,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../contexts/AppProvider';

import { loadSavedImageByID } from '../../../utils/helpers';
import Loader from '../../Loader';

const StaticImageRenderer = ({ photo, formIndex, tooltip }): JSX.Element => {
  const [image, setImage] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
  const [fetchingImageTimeout, setFetchingImageTimeout] = useState<any>(null);
  const { settings } = useAppContext();

  useEffect(() => {
    return () => {
      setImage('');
    };
  }, [formIndex]);

  useEffect(() => {
    clearTimeout(fetchingImageTimeout);
    let temp = setTimeout(() => getImageFromSP(), 1000);
    setFetchingImageTimeout(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo, settings]);

  const getImageFromSP = async () => {
    setLoading(true);
    try {
      let url = await loadSavedImageByID(
        photo.fileId,
        settings?.spSiteUrl || '',
        settings?.spDocumentLibrary || '',
      );
      setImage(url);
    } catch {
      setLoadingFailed(true);
    }
    setLoading(false);
  };

  return (
    <Flex>
      <Box w='full' maxW='345px' h='200px' mt='20px' position='relative'>
        <Text
          mt='10px'
          mb='4px'
          color='switch.label.normal'
          fontWeight='bold'
          fontSize='ssm'
          left='none'
          zIndex={1}
        />
        <Box
          maxW='345px'
          h='161px'
          p='5px'
          border='1px'
          borderColor='textInput.border.normal'
          borderRadius='8px'
          borderWidth='1px'
        >
          {{ photo } ? (
            <>
              <Box position='absolute' top='12px' right='5px' zIndex={2}>
                {tooltip && (
                  <IconButton
                    bg='white'
                    aria-label='delete'
                    size='xs'
                    isRound={true}
                    borderWidth={1}
                    borderColor='textInput.border.normal'
                    icon={
                      <Tooltip hasArrow label={tooltip} placement='top'>
                        <InfoOutlineIcon h='15' w='15' />
                      </Tooltip>
                    }
                  />
                )}
              </Box>
              <Flex
                w='full'
                h='full'
                alignContent='center'
                justifyContent='center'
              >
                {loading ? (
                  <Flex w='full' h='full' align='center' justify='center'>
                    <Spinner size='xl' thickness='4px' />
                  </Flex>
                ) : loadingFailed ? (
                  <Flex w='full' h='full' align='center' justify='center'>
                    Failed to load photo
                  </Flex>
                ) : (
                  <Image
                    h='151px'
                    maxW='335px'
                    cursor={'not-allowed'}
                    fit='contain'
                    src={image}
                  />
                )}
              </Flex>
            </>
          ) : (
            <Loader size='sm' />
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default StaticImageRenderer;
