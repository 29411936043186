import { createContext, useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { IAuditFiltersContext } from '../interfaces/IAuditFiltersContext';
import IAuditFilters from '../interfaces/IAuditFilters';
import GET_USERS from '../gql/queries/GET_USERS';
import { EAuditLogAction } from '../interfaces/IAuditLogs';

export const AuditFiltersContext = createContext({} as IAuditFiltersContext);

export const useAuditFiltersContext = () => {
  const context = useContext(AuditFiltersContext);
  if (!context) {
    throw new Error(
      'useAuditFiltersContext must be used within the AuditFiltersProvider'
    );
  }
  return context;
};

const AuditFiltersProvider = (props: any) => {
  const { data } = useQuery(GET_USERS);
  const [showAuditFiltersPanel, setShowAuditFiltersPanel] = useState<boolean>(false);
  const [openedAuditFilterPanel, setOpenedAuditFilterPanel] = useState<string | null>(null);
  const defaultAuditFilters = useMemo(
    () => ({
      actions: [
        EAuditLogAction.ADDED,
        EAuditLogAction.UPDATED,
        EAuditLogAction.DELETED,
        EAuditLogAction.COMPLETED,
        EAuditLogAction.DUPLICATED,
        EAuditLogAction.STARTED
      ],
      collections: ['forms', 'form-templates', 'projects', 'project-templates'],
    }),
    []
  );
  const [auditFilters, setAuditFilters] = useState<IAuditFilters>(defaultAuditFilters);

  const updateAuditFilters = (filterName: string, value) => {
    if (value.length) {
      setAuditFilters((filters) => ({ ...filters, [filterName]: value }));
    } else {
      setAuditFilters((filters) => {
        const newFilters = { ...filters };
        delete newFilters[filterName];
        return newFilters;
      });
    }
  };

  const prepareUsersData = () => {
    return [
      {
        filterName: 'userIds',
        values:
          data?.users.map((u) => ({
            label: u.displayName,
            value: u._id,
          })) || [],
      },
    ];
  };

  const value = useMemo(
    () => ({
      defaultAuditFilters,
      auditFilters,
      setAuditFilters,
      auditFiltersOptions: prepareUsersData(),
      showAuditFiltersPanel,
      setShowAuditFiltersPanel,
      openedAuditFilterPanel,
      setOpenedAuditFilterPanel,
      updateAuditFilters,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      defaultAuditFilters,
      auditFilters,
      showAuditFiltersPanel,
      openedAuditFilterPanel,
      data
    ]
  );

  return (
    <AuditFiltersContext.Provider value={value}>
      {props.children}
    </AuditFiltersContext.Provider>
  );
};

export default AuditFiltersProvider;
