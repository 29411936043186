import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Divider,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DotsVertical, getIcon, DotsHorizontal } from '../../icons';
import DividerButton from '../DividerButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IFormTemplate } from '../../interfaces/IFormTemplate';
import Can from '../Can';
import EyeIcon from '../../icons/Eye';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import ConfirmationModal from '../ConfirmationModal';
import { unsavedChangesMessage } from '../../utils/defaults';

interface ITemplateForms {
  onDragEnd: (event: any) => void;
  onDragStart?: () => void;
  onEditForm: (form: IFormTemplate) => void;
  deleteForm: (val: string) => void;
  addForm: () => void;
}

const TemplateForms = ({
  onDragEnd,
  onDragStart,
  onEditForm,
  deleteForm,
  addForm,
}: ITemplateForms) => {
  const {
    fieldsInEdit,
    currentProjectTemplate,
    currentProjectFormTemplate, setCurrentProjectFormTemplate,
  } = useProjectsContext();
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Box w='20%' flexShrink={0} mr={4} bg='white' p='5' rounded="6px">
        <Text fontWeight='900'>Forms</Text>
        <Droppable droppableId='formsDroppable'>
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {currentProjectTemplate?.formTemplates?.map((form, index) => {
                if (form !== null) {
                  return (
                    <Draggable
                      key={form._id}
                      draggableId={form._id}
                      index={index}
                    >
                      {(provided) => (
                        <Flex
                          fontSize='smm'
                          fontWeight='normal'
                          rounded='4px'
                          p='2'
                          bg={
                            currentProjectFormTemplate?._id === form._id ? 'admin.badge.bg' : ''
                          }
                          onClick={() => {
                            if (fieldsInEdit.length) {
                              const confirm = window.confirm(unsavedChangesMessage);
                              if (!confirm) return;
                            }
                            setCurrentProjectFormTemplate(form);
                          }}
                          cursor='pointer'
                          key={form._id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Can
                            action="projectTemplates.edit"
                            data={{ projectTemplate: currentProjectTemplate }}
                            yes={() =>
                              <Box mr='2' {...provided.dragHandleProps}>
                                <DotsVertical />
                              </Box>
                            }
                          />
                          <Flex
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                          >
                            <Box
                              display='flex'
                              alignItems='center'
                              opacity='0.4'
                            >
                              {form.icon && <Icon as={getIcon[form.icon]} />}
                              <Box ml='2' fontWeight={'bold'} color={'breNavy'}>
                                {form.name}
                              </Box>
                            </Box>
                            <HStack>
                              {form.commonFormId && (
                                <EyeIcon color="black" opacity={.4} fill="none" />
                              )}
                              {currentProjectFormTemplate?._id === form._id && (
                                <Can
                                  action="projectTemplates.edit"
                                  data={{ projectTemplate: currentProjectTemplate }}
                                  yes={() => (
                                    <Box cursor='pointer'>
                                      <Popover placement='bottom-end'>
                                        <PopoverTrigger>
                                          <DotsHorizontal mt="-1px" />
                                        </PopoverTrigger>
                                        <PopoverContent w='fit-content'>
                                          <PopoverBody>
                                            <Flex flexDir='column'>
                                              {!currentProjectFormTemplate.commonFormId && (
                                                <>
                                                  <Flex
                                                    alignItems='center'
                                                    onClick={() => onEditForm(form)}
                                                  >
                                                    <EditIcon mr='2' /> Edit Form
                                                  </Flex>
                                                  <Divider marginY='2' />
                                                </>
                                              )}
                                              <Flex
                                                alignItems='center'
                                                color='brePink'
                                                onClick={onConfirmationOpen}
                                              >
                                                <DeleteIcon
                                                  mr='2'
                                                  w='22'
                                                  stroke='brePink'
                                                />
                                                Delete Form
                                              </Flex>
                                              <ConfirmationModal modalText={`Do you wish to delete ${form.name} form?`} isOpen={isConfirmationOpen} onClose={onConfirmationClose} confirmAction={() => deleteForm(form._id)} />
                                            </Flex>
                                          </PopoverBody>
                                        </PopoverContent>
                                      </Popover>
                                    </Box>
                                  )}
                                />
                              )}
                            </HStack>
                          </Flex>
                        </Flex>
                      )}
                    </Draggable>
                  );
                }
                return null;
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Can
          action="projectTemplates.edit"
          data={{ projectTemplate: currentProjectTemplate }}
          yes={() =>
            <Box my='3'>
              <DividerButton onClick={addForm} text='+ Add a form' />
            </Box>
          }
        />
      </Box>
    </DragDropContext>

  );
};

export default TemplateForms;
