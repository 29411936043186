import { Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { IFormPage } from "../interfaces/IFormPage";
import TemplateSection from "./TemplateSection";

interface IFormPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  form: IFormPage;
}

const FormPreviewModal = ({
  isOpen,
  onClose,
  form,
}: IFormPreviewModalProps) => {
  const {
    control,
    setValue,
  } = useForm();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="full">
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Text fontSize="14px">Form preview - {form?.name}</Text>
            <Button px={3} py={0} fontSize="14px" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody p={5} mr={6} mb={6} overflowY="auto">
          <Stack ml={6} mb={6} justify="center">
            {(form?.sections || []).map((section) =>
              <TemplateSection
                key={section.name}
                name={section.name}
                description={section.description}
                rows={section.rows}
                control={control}
                setValue={setValue}
              />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
};

export const formPreviewModalStyles = {};

export default FormPreviewModal;
