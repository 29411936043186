import { createIcon } from "@chakra-ui/icons";

const TestObservationsIcon = createIcon({
  displayName: "TestObservations",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M6.99912 3.20894C4.69993 3.17015 2.32203 4.77691 0.827071 6.42245C0.670151 6.59666 0.583313 6.82281 0.583313 7.05728C0.583313 7.29174 0.670151 7.51789 0.827071 7.69211C2.28952 9.30285 4.66058 10.9467 6.99912 10.9073C9.33767 10.9467 11.7093 9.30285 13.1729 7.69211C13.3298 7.51789 13.4166 7.29174 13.4166 7.05728C13.4166 6.82281 13.3298 6.59666 13.1729 6.42245C11.6762 4.77691 9.29831 3.17015 6.99912 3.20894Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.13806 7.05843C9.13795 7.48144 9.01241 7.89492 8.77731 8.24659C8.54222 8.59826 8.20812 8.87232 7.81728 9.03412C7.42643 9.19592 6.99639 9.2382 6.58151 9.15561C6.16664 9.07301 5.78558 8.86925 5.4865 8.5701C5.18743 8.27094 4.98377 7.88983 4.90129 7.47493C4.8188 7.06004 4.86119 6.63 5.0231 6.2392C5.18501 5.8484 5.45916 5.51438 5.81089 5.27938C6.16262 5.04438 6.57613 4.91895 6.99915 4.91895C7.2801 4.91887 7.55832 4.97416 7.81789 5.08166C8.07747 5.18916 8.31332 5.34676 8.51196 5.54545C8.71059 5.74415 8.86813 5.98003 8.97556 6.23964C9.08299 6.49924 9.13821 6.77748 9.13806 7.05843Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

    </>
  ),
});

export default TestObservationsIcon;
