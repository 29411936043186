import { useQuery } from '@apollo/client';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { isEqual } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import GET_AUDIT_LOGS from '../../gql/queries/GET_AUDIT_LOGS';
import { IAuditLog } from '../../interfaces/IAuditLogs';
import AuditLogComponent from '../../components/AuditLog/AuditLog';
import Filter from '../../icons/Filter';
import { useAuditFiltersContext } from '../../contexts/AuditFiltersProvider';
import IAuditFilters from '../../interfaces/IAuditFilters';

const AuditLogs = ({ predefinedFilters }: {
  predefinedFilters?: IAuditFilters;
}) => {
  const limit = useMemo(() => 50, []);
  const [auditLogs, setAuditLogs] = useState<IAuditLog[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [totalAuditLogs, setTotalAuditLogs] = useState<Number>(0);
  const [countAuditLogs, setCountAuditLogs] = useState<number>(0);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const { auditFilters, showAuditFiltersPanel, setShowAuditFiltersPanel } = useAuditFiltersContext();
  const dateTo = useMemo(() => {
    setAuditLogs([]);
    return new Date();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(auditFilters)]);
  const { data, loading, refetch } = useQuery(GET_AUDIT_LOGS, {
    variables: {
      auditLogsQuery: {
        skip,
        limit,
        dateTo,
        dateFrom: auditFilters.dateFrom,
        collections: predefinedFilters?.collections || auditFilters.collections,
        elementsIds: predefinedFilters?.elementsIds,
        actions: auditFilters.actions,
        userIds: auditFilters.userIds,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetch({
      auditLogsQuery: {
        skip,
        limit,
        dateTo,
        dateFrom: auditFilters.dateFrom,
        actions: auditFilters.actions,
        collections: predefinedFilters?.collections || auditFilters.collections,
        elementsIds: predefinedFilters?.elementsIds,
        userIds: auditFilters.userIds,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(auditFilters), dateTo, limit, refetch, skip, predefinedFilters]);

  useEffect(() => {
    if (data) {
      if (data.auditLog.auditLogs[0] === undefined) {
        setIsLoadingMore(false);
      }
      setAuditLogs((currentLogs) =>
        data.auditLog.auditLogs.reduce((acc, curr) => {
          const newAcc = [...acc];
          const currentLog = newAcc.find(({ _id }) => _id === curr._id);
          if (currentLog) {
            curr.records.forEach((record) => {
              if (!currentLog.records.some(({ metatags: { addedAt } }) => isEqual(new Date(record.metatags.addedAt), new Date(addedAt)))) {
                setCountAuditLogs((prevValue) => prevValue + 1);
                currentLog.records.push(record);
              }
            });
            setIsLoadingMore(false);
          } else {
            newAcc.push({
              _id: curr._id,
              records: curr.records.map((record, index) => {
                setCountAuditLogs(index + 1);
                return {
                  action: record.action,
                  coll: record.coll,
                  element: record.element,
                  values: record.values,
                  metatags: record.metatags,
                };
              }),
            });
            setIsLoadingMore(false);
          }
          return newAcc;
        }, currentLogs),
      );
      setTotalAuditLogs(data?.auditLog?.totalAuditLogs > 0 ? data?.auditLog?.totalAuditLogs : 0);
    }
  }, [data]);

  return (
    <Box overflowY='auto' overflowX='hidden' pr={4}>
      {!predefinedFilters && (
        <Flex justifyContent='space-between'>
          <Text fontSize='24px' fontWeight='700' pb='6'>
            Audit Logs
          </Text>
          <HStack
            align='center'
            bg='breNavy'
            rounded='6px'
            color='brand.primaryFont'
            cursor='pointer'
            flexShrink={0}
            fontSize='smm'
            h='34px'
            justify='space-between'
            onClick={() => setShowAuditFiltersPanel(!showAuditFiltersPanel)}
            p={4}
          >
            <Flex color='white' fontSize='smm' fontWeight='semi_medium'>
              Filters
            </Flex>
            <Filter h='18px' />
          </HStack>
        </Flex>
      )}
      <Flex
        rounded='20px'
        w='calc(100vw - 306px)'
        ml='5'
        p='30px'
        bg='#FFF'
        mb='30px'
        overflow='auto'
      >
        <Box w='full' minH='calc(100vh - 230px)'>
          <AuditLogComponent
            auditLogs={auditLogs}
            isLoadingMore={isLoadingMore}
            loading={loading && !isLoadingMore}
          />
          {!loading &&
            (totalAuditLogs === countAuditLogs ? (
              <Text color='auditLog.noLogs' mb={4}>
                No more logs
              </Text>
            ) : (
              <Text
                color='auditLog.loadMore'
                cursor='pointer'
                mb={4}
                onClick={() => {
                  setSkip((prev) => prev + limit);
                  setIsLoadingMore(true);
                }}
              >
                Load more audit logs
              </Text>
            ))}
        </Box>
      </Flex>
    </Box >
  );
};

export default AuditLogs;
