import { IBase } from './IBase';

export enum EAuditLogAction {
  ADDED = 'added',
  UPDATED = 'updated',
  DELETED = 'deleted',
  COMPLETED = 'completed',
  DUPLICATED = 'duplicated',
  STARTED = 'started'
}

export interface IAuditLogElement {
  _id: string;
  name: string;
  self_id?: string; // Used if element is foreign element
}

export interface IAuditFieldValue {
  value: string | string[];
  label: string;
}

export interface IAuditValue {
  old?: IAuditFieldValue;
  new?: IAuditFieldValue;
}

export interface IAuditValues {
  [field: string]: IAuditValue;
}

export interface IAuditLogRecord extends IBase {
  action: EAuditLogAction;
  element: IAuditLogElement;
  coll: string;
  values: IAuditValues;
}

export interface IAuditLog {
  _id: string;
  totalAuditLogs: number;
  records: IAuditLogRecord[];
}
