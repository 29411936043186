import { Flex } from "@chakra-ui/react";
import AuditFiltersPanel from "../components/AuditLog/Filter/AuditFilterPanel";
import FiltersPanel from "../components/Filters/FiltersPanel";

import NavigationLeft from "../components/NavigationLeft/NavigationLeft";
import NavigationTop from "../components/NavigationTop/NavigationTop";
import Overlay from "../components/Overlay";

const DefaultLayout = ({ component: Component }: { component: any }) => {
  return (
    <Flex h="100vh" bg="breLightGray">
      <Overlay />
      <NavigationLeft />
      <Flex
        direction="column"
        flexBasis="auto"
        flexGrow={1}
        position="relative"
      >
        <NavigationTop />
        <Flex
          flexDirection="column"
          position="absolute"
          top="80px" w="full"
          overflow="hidden"
          h="calc(100vh - 80px)"
        >
          <Component />
        </Flex>
      </Flex>
      {<FiltersPanel />}
      {<AuditFiltersPanel />}
    </Flex>
  );
};

export default DefaultLayout;
