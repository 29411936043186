import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ProjectVersions } from '../../interfaces/IProjectVersions';
import { dateDecorated } from '../../utils/helpers';

const ProjectVersionModal = ({ isOpen, onClose, versions }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent rounded='20px' minW='60vw' pb={'25px'}>
        <ModalHeader color='markAsCompleteModal.headerColor' fontSize='lg'>
          App Version History
        </ModalHeader>
        <ModalCloseButton onTouchEnd={onClose} />
        <ModalBody overflow='hidden' pt={0} maxH={'400px'} overflowY={'auto'}>
          <Flex h='calc(100% - 45px)' overflow='auto' flexDirection='column'>
            <TableContainer>
              <Table variant='striped' size='md'>
                <Thead>
                  <Tr bg={'breNavy'}>
                    <Th
                      color={'white'}
                      fontWeight={'bold'}
                      fontSize={'13px'}
                      pr={0}
                      textAlign={'left'}
                    >
                      Version
                    </Th>
                    <Th color={'white'} fontWeight={'bold'} fontSize={'13px'}>
                      Description
                    </Th>
                    <Th color={'white'} fontWeight={'bold'} fontSize={'13px'}>
                      Date
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {versions.map((version: ProjectVersions) => (
                    <Tr key={`version-${version.version}`}>
                      <Td
                        fontSize={'14px'}
                        py={'8px'}
                        pr={0}
                        textAlign={'left'}
                      >
                        {version.version}
                      </Td>
                      <Td
                        fontSize={'14px'}
                        py={'8px'}
                        whiteSpace={'pre-wrap'}
                        dangerouslySetInnerHTML={{
                          __html: version.description,
                        }}
                      />
                      <Td fontSize={'14px'} py={'8px'}>
                        {dateDecorated(version.published_at)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectVersionModal;
