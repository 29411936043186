import { gql } from '@apollo/client';

const GET_OR_CREATE_USER = gql`
  mutation getOrCreate($userId: ID!) {
    getOrCreate(userId: $userId) {
      _id
      displayName
      jobTitle
      email
      role
      lastLogin
      imgUrl
      metatags {
        updatedAt
      }
    }
  }
`;

export default GET_OR_CREATE_USER;
