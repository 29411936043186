import { createIcon } from '@chakra-ui/icons';

const CommentIcon = createIcon({
  path: (
    <>
      <path
        d='M16.667 1.66699H3.33366C2.41449 1.66699 1.66699 2.41449 1.66699 3.33366V18.3337L6.11116 15.0003H16.667C17.5862 15.0003 18.3337 14.2528 18.3337 13.3337V3.33366C18.3337 2.41449 17.5862 1.66699 16.667 1.66699ZM16.667 13.3337H5.55616L3.33366 15.0003V3.33366H16.667V13.3337Z'
        fill='currentColor'
      />
      <path
        d='M12.4997 10.0003C13.4201 10.0003 14.1663 9.25413 14.1663 8.33366C14.1663 7.41318 13.4201 6.66699 12.4997 6.66699C11.5792 6.66699 10.833 7.41318 10.833 8.33366C10.833 9.25413 11.5792 10.0003 12.4997 10.0003Z'
        fill='currentColor'
      />
      <path
        d='M7.49967 10.0003C8.42015 10.0003 9.16634 9.25413 9.16634 8.33366C9.16634 7.41318 8.42015 6.66699 7.49967 6.66699C6.5792 6.66699 5.83301 7.41318 5.83301 8.33366C5.83301 9.25413 6.5792 10.0003 7.49967 10.0003Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default CommentIcon;
