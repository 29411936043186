import { gql } from '@apollo/client';

const GET_USERS = gql`
  query users($userId: ID) {
    users(userId: $userId) {
      _id
      displayName
      jobTitle
      email
      role
      lastLogin
      imgUrl
      metatags {
        updatedAt
      }
    }
  }
`;

export default GET_USERS;
