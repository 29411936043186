import React, { useEffect } from "react";

import { Flex, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { toastFailed, toastSuccess } from "../../../bootstrap/config";
import AdminModal from "../../AdminModal";
import SignatureApproval from "./../SignatureApproval";
import { format } from "date-fns";
import PeoplePicker from "../PeoplePicker";

const TechSignatureTableModal = ({
  modalState,
  setModalState,
  dataValues,
  setdataValues,
  selectedDataValue,
  name,
  onChange,
  formIndex,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      _id: undefined,
      name: selectedDataValue.name,
      signature: selectedDataValue.signature,
      date: selectedDataValue.date,
    },
  });
  const toast = useToast();

  useEffect(() => {
    if (selectedDataValue !== "") {
      reset(selectedDataValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataValue]);

  const handleAddTechSignature = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        const currentDate = format(new Date(), "d MMM yyyy");
        let val: object = {
          name: values.name,
          signature: values.signature,
          date: values.signature != null ? currentDate : undefined,
        };
        dataValues instanceof Array
          ? dataValues.push(val)
          : (dataValues = [val]);
        setdataValues(dataValues);
        onChange({ target: { name, value: dataValues } });
        toast({ ...toastSuccess, description: "Tech Signature added" });
        reset({
          _id: undefined,
          name: "",
          signature: undefined,
          date: "",
        });
      } else {
        toast({
          ...toastFailed,
          description: "Please complete all the required fields",
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
    }
  };

  const handleUpdateTechSignature = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        let updatedData = [...dataValues];
        const currentDate = format(new Date(), "d MMM yyyy");
        updatedData[selectedDataValue.index] = {
          name: values.name,
          signature: values.signature,
          date: values.signature != null ? currentDate : undefined,
        };
        setdataValues(updatedData);
        onChange({ target: { name, value: updatedData } });
        toast({ ...toastSuccess, description: "Tech Signature updated" });
        reset({
          _id: undefined,
          name: "",
          signature: undefined,
          date: "",
        });
      } else {
        toast({
          ...toastFailed,
          description: "Please complete all the required fields",
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
    }
  };

  const handleDeleteTechSignature = async () => {
    try {
      let updatedData = dataValues.filter(
        (p, index) => index !== selectedDataValue.index
      );
      setdataValues(updatedData);
      onChange({ target: { name, value: updatedData } });
      toast({ ...toastSuccess, description: "Tech Signature deleted" });
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState("closed");
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    if (["add", "edit"].includes(action) && !isFormValid) {
      return toast({
        ...toastFailed,
        description: "Please complete all the required fields",
      });
    }
    switch (action) {
      case "add":
        handleAddTechSignature();
        break;
      case "edit":
        handleUpdateTechSignature();
        break;
      case "delete":
        handleDeleteTechSignature();
        break;
      default:
        setModalState("closed");
    }
  };
  return (
    <AdminModal
      isOpenModal={modalState !== "closed"}
      modalType={modalState}
      onAction={handleAction}
      collection={"Tech Signature"}
    >
      <Flex align="flex-start" direction="column" w="full">
        <PeoplePicker
          control={control}
          name="name"
          label="Name"
          validations={{ notEmpty: true }}
          variant="widerTeam"
          inTable={true}
        />
        <SignatureApproval
          control={control}
          name="signature"
          label="Signature"
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />
      </Flex>
    </AdminModal>
  );
};

export default TechSignatureTableModal;
