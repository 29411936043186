import { Box, Icon, Flex } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

import SubSection from "./ProjectSubSection";
import { getIcon } from "../../icons";
import { useProjectsContext } from "../../contexts/ProjectsProvider";

const ProjectLeftItem = (
  { form, iconBackground, iconColor, index, isDisabled }:
    { form: any, iconBackground: string, iconColor: string, index: number, isDisabled?: boolean }
) => {
  const { page }: { page: string } = useParams();
  const pageNo = parseInt(page || '0');
  const { currentProject, formStates } = useProjectsContext();
  const history = useHistory();
  const { icon, name } = form;

  return (
    <>
      <Box
        minW="215px"
        h="38px"
        mt="5px"
        display="flex"
        pos="relative"
        alignItems="center"
        fontSize="smm"
        fontWeight="normal"
        rounded="10px"
        ml="6"
        opacity={pageNo === index
          ? "1"
          : "0.7"}
        bg={
          form.pages?.length > 1 ? ''
            : pageNo === index
              ? ""
              : ""
        }
        _hover={{
          cursor: `
          ${isDisabled ? 'not-allowed'
              : 'pointer'}`,
          bg: `
            ${form.pages?.length > 1 ? ''
              : pageNo === index
                ? ""
                : "projectFormLeftItem.hoveredMenuItem"}`
        }}
        onClick={() => {
          if (!isDisabled) {
            if (form.pages && form.pages.length > 1) {
              history.push(`/project/${currentProject?._id}/${index}/1`);
            } else {
              if (index === -1) {
                window.open(`${process.env.REACT_APP_API_URL}/project/export?projectId=${currentProject?._id}`);
              } else if (index === 0) {
                history.push(`/project/${currentProject?._id}/audit-log`);
              } else {
                history.push(`/project/${currentProject?._id}/${index !== 0 ? index : 'audit-log'}`);
              }
            }
          }
        }}
      >
        <Flex h="100%" align="center">
          <Flex
            w="30px"
            h="30px"
            ml="10px"
            alignItems="center"
            justifyContent="center"
            rounded="8px"
            bg={iconBackground}
            color={iconColor}
            opacity='1'
          >
            <Icon
              w="15px"
              h="15px"
              as={getIcon[icon]}
              opacity={!isDisabled ? '1' : '0.4'}
              stroke={iconColor}
            />
          </Flex>
        </Flex>
        <Box
          ml="5"
          fontWeight={pageNo === index
            ? "bold"
            : "normal"}
          color={!isDisabled ? 'breNavy' : 'projectFormLeftItem.disabled'}
          opacity={!isDisabled ? '1' : '0.4'}
        >
          {name}
        </Box>
      </Box>
      <Box mt="10px">
        {pageNo === index &&
          (formStates[index - 1]?.allowMultipleIterations && currentProject) && (formStates[index - 1]?.iterations || []).map((iteration, i) => {
            return <SubSection key={i} index={i} name={`${name} ${i + 1}`} status={iteration.status} />;
          })
        }
      </Box>
    </>
  )
};

export default ProjectLeftItem;

export const projectFormLeftItemItemStyles = {
  projectFormLeftItem: {
    disabled: "#131535",
    hoveredMenuItem: "rgba(255,255,255,0.5)",

    selectedMenuItem: "#131535",
    unselectedMenuItem: "#131535",

    selectedLabelBg: "#ffffff",
    unselectedLabelBg: "#ffffff",

    selectedIconStroke: "#ffffff",
    unselectedIconStroke: "#818197",

    unSelectedIconBg: "#ffffff",
    pendingIconBg: "#41B916",
    completeIconBg: "#FF9A00",


  }
};
