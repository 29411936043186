import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { useProjectsContext } from '../contexts/ProjectsProvider';
import { EOperationType } from '../interfaces/EOperationType';
import { IFormRow } from '../interfaces/IFormRow';
import { IReportFormSection } from '../models/reportform';

const useTemplateRows = (selectedPage: number, section: IReportFormSection) => {
  const { setPages, setOperationType } = useProjectsContext();
  const [rows, setRows] = useState(section.rows);
  const [rowToRemove, setRowToRemove] = useState<string | null>();

  useEffect(() => {
    setRows(section.rows);
  }, [section]);

  const removeRow = (_id: string) => {
    setOperationType(EOperationType.DELETED_ROW);
    setPages((prevPages) => {
      const updatedPages = prevPages.map((page, index) => {
        if (index === selectedPage) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const updatedRows = sec.rows
                .filter((row) => row._id !== _id)
                .map((row, rowIndex) => ({
                  ...row,
                  index: rowIndex + 1,
                }));

              return {
                ...sec,
                rows: updatedRows,
              };
            }
            return sec;
          });

          return {
            ...page,
            sections: updatedSections,
          };
        }

        return page;
      });

      return updatedPages;
    });
    setRowToRemove(null);
  };

  const createRow = () => {
    setOperationType(EOperationType.ADDED_ROW);
    setPages((prevPages) => {
      const updatedPages = prevPages.map((page, index) => {
        if (index === selectedPage) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const updatedRows: IFormRow[] = [
                ...sec.rows,
                {
                  _id: uuidv4(),
                  index: sec.rows.length + 1,
                  fields: [],
                },
              ];
              return {
                ...sec,
                rows: updatedRows,
              };
            }
            return sec;
          });

          return {
            ...page,
            sections: updatedSections,
          };
        }

        return page;
      });

      return updatedPages;
    });
  };

  const moveRows = (event) => {
    setOperationType(EOperationType.REORDERED_ROWS);
    if (!event.source || !event.destination) {
      return;
    }
    setPages((prevPages) => {
      const updatedPages = prevPages.map((page, index) => {
        if (index === selectedPage) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              const tempRows = Array.from(sec.rows || []);
              const [removed] = tempRows.splice(event.source.index, 1);
              tempRows.splice(event.destination.index, 0, removed);
              const updatedRows = tempRows.map((row, index) => ({
                ...row,
                index: index + 1,
              }));
              return {
                ...sec,
                rows: updatedRows,
              };
            }
            return sec;
          });

          return {
            ...page,
            sections: updatedSections,
          };
        }

        return page;
      });

      return updatedPages;
    });
  };

  const updateSectionName = async (name) => {
    setOperationType(EOperationType.UPDATED_SECTION);
    setPages((prevPages) => {
      const updatedPages = prevPages.map((page, index) => {
        if (index === selectedPage) {
          const updatedSections = page.sections.map((sec) => {
            if (sec.index === section.index) {
              return {
                ...sec,
                name: name || '',
              };
            }
            return sec;
          });

          return {
            ...page,
            sections: updatedSections,
          };
        }

        return page;
      });

      return updatedPages;
    });
  };

  return {
    rows,
    setRows,
    rowToRemove,
    setRowToRemove,
    removeRow,
    createRow,
    moveRows,
    updateSectionName,
  };
};

export default useTemplateRows;
