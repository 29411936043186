
import { Box } from '@chakra-ui/react';
import { IField } from '../../interfaces/IField';

const StaticText = ({ name, label }: IField) => {

  return (
    <Box id={name} w='full' dangerouslySetInnerHTML={{ __html: label || '' }} />
  );
};

export default StaticText;
