import { gql } from '@apollo/client';

const GET_FILTERS_VALUES = gql`
  query {
    filtersValues {
      filterName
      values {
        label
        value
      }
    }
  }
`;

export default GET_FILTERS_VALUES;
