import React from "react";
import { Button, IconButton, Tr, Td } from "@chakra-ui/react";
import { format } from 'date-fns';

import { DeleteIcon, EditIcon } from '../../../icons';
import TableData from "../TableData";

const ClientContractorTableRow = ({ dataValue, onRemove, disabled, onEdit }) => {

    return (
        <Tr>
            <TableData data={dataValue.name ? dataValue.name : '-'} dataType='text' styles={{ paddingInline: '0px' }} ></TableData>
            <TableData data={dataValue.signature ? true : false} dataType='check' styles={{ paddingInline: '10px' }} ></TableData>
            <TableData data={dataValue.company ? dataValue.company : '-'} dataType='text' ></TableData>
            <TableData data={dataValue.supervisor ? dataValue.supervisor : '-'} dataType='text' ></TableData>
            <TableData data={dataValue.supervisorSignature ? true : false} dataType='check' ></TableData>
            <TableData data={dataValue.date ? format(new Date(dataValue.date), 'dd MMM yyyy') : '-'} dataType='text' ></TableData>
            {!disabled &&
                <>
                    <Td fontWeight='semi_medium' paddingInline='0px'>
                        <Button
                            disabled={disabled}
                            _disabled={{ opacity: 0.75, cursor: 'not-allowed' }}
                            bg='clientContractorTableRow.button.bg' h='30px' color='clientContractorTableRow.button.color' pl='5px' pr='10px' fontSize='ssm'
                            borderRadius='10px' borderWidth='1px' borderColor='clientContractorTableRow.button.color'
                            onClick={onEdit}>
                            <EditIcon w='12px' h='12px' ml='5px' mr='8px' stroke='clientContractorTableRow.editIcon.stroke' />
                            Edit
                        </Button>
                    </Td>
                    <Td fontWeight='semi_medium' paddingInline='20px'>
                        <IconButton
                            bg='clientContractorTableRow.deleteIcon.bg'
                            aria-label='delete'
                            size='xs'
                            isRound={true}
                            disabled={disabled}
                            _disabled={{ opacity: 0.75, cursor: 'not-allowed' }}
                            icon={<DeleteIcon h='15' w='15' stroke='clientContractorTableRow.deleteIcon.stroke' onClick={onRemove} />} />
                    </Td>
                </>
            }
        </Tr >
    );
};

export default ClientContractorTableRow;

export const clientContractorTableRowStyles = {
    clientContractorTableRow: {
        button: {
            bg: '#FFFFFF',
            color: '#818197',
        },
        editIcon: {
            stroke: '#FFFFFF'
        },
        deleteIcon: {
            bg: '#F0F0F0',
            stroke: '#818197'
        }

    }
}
