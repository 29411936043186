import React, { useState } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Text, useToast } from "@chakra-ui/react";
import { gql, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { pdfVersionControl } from "../../utils/helpers";
import { SignatureApproval } from "../FormControls";
import { toastSuccess, toastFailed } from "../../bootstrap/config";
import useIsIpad from "../../hooks/useIsIpad";
import { useProjectsContext } from "../../contexts/ProjectsProvider";
import useUpdateForm from "../../hooks/useUpdateForm";
import useGetFormStatuses from "../../hooks/useGetFormStatus";
import { useAppContext } from "../../contexts/AppProvider";

const GEN_PROJECT_PDF = gql`
  query ($values: GenProjectPdfInput!){
    genProjectPDF( genProjectPdfInput: $values){
      generated
    }
  }
`

const MarkAsCompleteModal = ({ control, errors, trigger, isOpen, onClose, getValues, handleSubmit, formIndex, iteration, refetch }) => {
  const { settings } = useAppContext();
  const { _id, }: { _id: string } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [isSignAndCompleteDisabled, setIsSignAndCompleteDisabled] = useState<boolean>(false)
  const toast = useToast();
  const { currentProject, currentProjectForm, formStates } = useProjectsContext();
  const { refetch: formStatusRefetch } = useGetFormStatuses(_id);
  const { updateFunction } = useUpdateForm();
  const ipad = useIsIpad();
  const [genProjectPDF] = useLazyQuery(GEN_PROJECT_PDF, {
    fetchPolicy: 'network-only'
  });

  const handleUpdateForm = () => {
    setTimeout(async () => {
      try {
        if (Object.keys(errors).length === 0) {
          const formValues = getValues();
          const values = {
            _id: currentProjectForm?._id,
            projectId: currentProject?._id,
            status: "completed",
            values: {
              ...formValues,
              "completedSignedDate": new Date(),
              "pdfFirstPublished": formValues.pdfFirstPublished || new Date()
            },
            iteration,
          };

          if (!settings?.generatePDFs) {
            await updateFunction({ variables: { values } });
          } else {
            const pdfVersion = formStates.find(form => form.index === formIndex)?.pdfVersion;
            const version = pdfVersion ? pdfVersionControl(pdfVersion) : "Not Generated";
            await updateFunction({ variables: { values: { ...values, pdfVersion: version } } });

            // request for generating pdf
            await genProjectPDF({
              variables: {
                values: {
                  index: formIndex,
                  projectId: currentProject?._id,
                  signatureFileLocation: "_formData",
                  pdfFileLocation: `${settings?.spClientFolder}/${(currentProject?.clientName || '').replace(/\//g, ' ')}/${currentProject?.reference}/App Forms`,
                  name: `${currentProject?.reference}-${currentProjectForm?.name}`,
                  spSiteUrl: settings?.spSiteUrl,
                  spDocumentLibrary: settings?.spDocumentLibrary
                }
              }
            })
          }
          toast({ ...toastSuccess, description: "Form completed" });
          refetch();
          formStatusRefetch();
        } else {
          toast({
            ...toastFailed,
            description: "Please complete all the required fields",
          });
        }
      } finally {
        onClose();
        setSubmitting(false);
      }
    }, 1500)
  };

  const handleAction = async (action) => {
    setSubmitting(true)
    const isFormValid = await trigger();
    if (["add", "edit"].includes(action) && !isFormValid) {
      return toast({
        ...toastFailed,
        description: "Please complete all the required fields",
      });
    }
    switch (action) {
      case "edit":
        handleUpdateForm();
        break;
      default:
        onClose();
    }
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="20px" w="330px" pb="20px">
        <ModalHeader color="markAsCompleteModal.headerColor" fontSize="smm">Mark as complete?</ModalHeader>
        <ModalCloseButton onTouchEnd={onClose} />
        <ModalBody>
          <Text color="markAsCompleteModal.descColor" fontSize="smm" mb="15px">Please sign this document before marking it as complete.</Text>
          <SignatureApproval control={control} name="completedBySignature" label="Signature" validations={{ notEmpty: true }} setIsSignAndCompleteDisabled={setIsSignAndCompleteDisabled} />
          <Button
            w="full"
            mt="15px"
            bg='brePink'
            color='markAsCompleteModal.buttonColor'
            pl='5px'
            pr='15px'
            fontSize='smm'
            disabled={!getValues().completedBySignature || isSignAndCompleteDisabled || submitting}
            borderRadius='10px'
            isLoading={isSignAndCompleteDisabled}
            loadingText="Saving..."
            _hover={{ bg: 'reportFormHeader.buttonDisableBg', color: 'reportFormHeader.buttonDisableColor' }}
            onClick={async () => { !ipad && handleSubmit(handleAction("edit")) }}
            onTouchEnd={async () => { ipad && handleSubmit(handleAction("edit")) }}
          >
            Sign and complete
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

}

export default MarkAsCompleteModal;

export const markAsCompleteModalStyles = {
  markAsCompleteModal: {
    headerColor: "#313233",
    descColor: "#818197",
    buttonColor: "#ffffff"

  },
};
