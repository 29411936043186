import AdminUserIcon from '../icons/AdminUserIcon';
import OpenBookIcon from '../icons/OpenBookIcon';
import PeopleGroupIcon from '../icons/PeopleGroupIcon';
import { INavigationLeftItem } from '../interfaces/INavigationLeftItem';

export const toastSuccess: any = {
  title: 'Success',
  status: 'success',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const toastWarning: any = {
  title: 'Warning',
  status: 'warning',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const toastFailed: any = {
  title: 'Failed',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const userMenus = [];

export const leftNavItems: INavigationLeftItem[] = [
  {
    label: 'My Projects',
    path: '/',
    icon: OpenBookIcon,
  },
  {
    label: 'Admin',
    path: '/admin',
    icon: AdminUserIcon,
    subMenus: [{
      label: 'Templates',
      path: '/admin/templates',
      permission: 'projectTemplates.view',
    }, {
      label: 'Common forms',
      path: '/admin/common-forms',
      permission: 'commonForms.view',
    }, {
      label: 'Users',
      path: '/admin/users',
      permission: 'users.view',
    }, {
      label: 'Audit logs',
      path: '/admin/audit-logs',
      permission: 'auditLogs.viewAll',
    }],
  },
];

export const searchBarFilterItems = [
  {
    label: 'Projects',
    searchFor: 'projects',
    icon: OpenBookIcon,
  },
  // {
  //   label: 'All Projects',
  //   searchFor: 'reference',
  //   icon: PeopleIcon,
  // },
  {
    label: 'Clients',
    searchFor: 'clients',
    icon: PeopleGroupIcon,
  },
];

export const userRolesLabels = {
  none: 'No role assigned',
  admin: 'Admin',
  reader: 'Viewer',
  teamMember: 'Team member',
  teamLead: 'Team leader',
};
