import { ReactElement } from "react";
import {
  Box,
} from "@chakra-ui/react";

interface ITableData {
  header: string;
  styles?: any;
  icon?: ReactElement;
  onClick?: () => void;
}

const ProjectsListHeader = ({ header, styles, onClick, icon }: ITableData) => {
  return (
    <Box
      fontFamily="body"
      whiteSpace="nowrap"
      fontWeight="semi_medium"
      textTransform="none"
      color="tableHeader.color"
      fontSize="ssm"
      paddingInline="0px"
      cursor="pointer"
      onClick={onClick}
      {...styles}
    >
      {header}
      {icon}
    </Box>
  );
};

export default ProjectsListHeader;

export const tableStyles = {
  tableHeader: {
    color: '#818197',
  }
}
