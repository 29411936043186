import {
  As,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  Box,
  Icon,
  PopoverContent,
  PopoverBody,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { TextInput } from '../FormControls';
import { getIcon, InfinityIcon, SaveIcon } from '../../icons';
import GET_COMMON_FORMS from '../../gql/queries/GET_COMMON_FORMS';
import CREATE_FORM_TEMPLATE from '../../gql/mutation/CREATE_FORM_TEMPLATE';
import IconPickerControl from '../FormControls/IconPickerControl';

const NewCommonFormModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const history = useHistory();
  const { refetch } = useQuery(GET_COMMON_FORMS, {
    variables: {
      formTemplatesQueryInput: {
        isCommonForm: true,
      },
    },
  });
  const [createFormTemplate] = useMutation(CREATE_FORM_TEMPLATE);

  const {
    control,
    getValues,
    setValue,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      icon: 'InfinityIcon',
    },
  });

  const submitForm = async () => {
    if (!isValid) {
      toast({ ...toastFailed, description: 'Please fill all the fields' });
      return;
    }
    let values = getValues();
    if (Boolean(values.name.length)) {
      let { data } = await createFormTemplate({
        variables: {
          formTemplateInput: {
            ...values,
            isCommonForm: true,
          },
        },
      });
      toast({ ...toastSuccess, description: 'Form created successfully' });
      await refetch();
      onClose();
      history.push(`/admin/common-forms/${data.createFormTemplate._id}`);
    } else {
      toast({ ...toastFailed, description: 'Fill all the fields' });
    }
    reset({});
  };

  const closeCreateFormModal = () => {
    onClose();
    reset({});
  };

  const getDefaultIcon = (): As<any> => {
    if (control._formValues.icon) {
      return getIcon[control._formValues.icon];
    }
    return InfinityIcon;
  };

  return (
    <Modal onClose={closeCreateFormModal} closeOnOverlayClick={false} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight='900'>Create new common form</ModalHeader>
        <ModalCloseButton />
        <ModalBody w='full'>
          <Flex alignItems='center'>
            <Flex flexDirection='column' mr='4'>
              <Text
                fontWeight='bold'
                fontSize='11px'
                position='static'
                left='none'
                zIndex={1}
                color='textInput.labelFont.normal'
                pb='1'
                pl='1'
              >
                Icon
              </Text>
              <Popover placement='bottom-start'>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Box
                        px='3'
                        py='2'
                        borderRadius='6px'
                        borderColor='textInput.border.normal'
                        borderWidth='1px'
                        borderStyle='solid'
                        cursor='pointer'
                      >
                        <Icon as={getDefaultIcon()} />
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent w='fit-content'>
                      <PopoverBody>
                        <IconPickerControl
                          onClick={(value: string) => {
                            setValue('icon', value);
                            onClose();
                          }}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            </Flex>
            <TextInput
              control={control}
              name='name'
              required={true}
              label='Title'
              placeholder='Title'
              validations={{ notEmpty: true }}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeCreateFormModal} mr='2'>
            Discard
          </Button>
          <Button
            onClick={submitForm}
            bg='brePink'
            color='white'
            _hover={{}}
            _focus={{}}
            _active={{}}
          >
            <SaveIcon stroke='white' mr='2' />
            Create form
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewCommonFormModal;
