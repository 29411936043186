import { gql } from '@apollo/client';

const SETUP_PROJECT = gql`
  mutation ($projectInput: ProjectInput!) {
    setupProject(projectInput: $projectInput) {
      _id
    }
  }
`;

export default SETUP_PROJECT;
