import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import { useSearchContext } from "../contexts/SearchProvider";

const SEARCHPROJECT = gql`
  query ($searchInput: SearchFilterInput!) {
    filterProjects(searchInput: $searchInput) {
      _id
      status
      clientId
      clientName
      reference
      projectLeadId
      projectTemplate {
        category
      }
    }
  }
`;

const useSearch = () => {
  const { search, setResults } = useSearchContext();
  const { loading, data, error } = useQuery(SEARCHPROJECT, {
    variables: {
      searchInput: {
        searchFor: search?.searchFor ?? "",
        value: search?.value ?? "",
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    // filters change, update the query
    search.isSearchEnabled && data && setResults(data.filterProjects);
  }, [search?.searchFor, search?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // data changes, update projects
    data && setResults(data.filterProjects);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return { loading, data, error };
};

export default useSearch;
