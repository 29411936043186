import React, { useRef } from 'react';

import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Controller } from 'react-hook-form';
import { IField } from '../../interfaces/IField';
import { DefinedValidations } from '../../interfaces/Validations';
import { Asterisk } from '../../icons';
import useValidate from '../../hooks/useValidate';

interface IToggle extends IField {
  secondarylabel?: string
  variant?: string;
  help?: string;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && (value === undefined || value === "")) {
      return `You must select an option`;
    }
  },
};

const Toggle = ({ control, name, label, secondarylabel, tooltip = '', validations = {}, disabled = false, help = '', required }: IToggle) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);
  const inputRef = useRef<any>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange, onBlur, value } = field;
        const { error } = fieldState;

        return (
          <Box w='full' id={name} mt='2.5px' mb={2}>
            <Box>
              {label && (
                <Flex align='center' justify='space-between' pt={2} mb='none' pr='30px'>
                  <Box
                    color={error ? 'switch.label.error' : 'switch.label.normal'}
                    fontWeight='bold'
                    fontSize='ssm'
                    position='static'
                    left='none'
                    zIndex={1}
                    minH="16px"
                  >
                    {label}
                    {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill='textInput.iconAsterisk' stroke='textInput.iconAsterisk' />}
                    {' '}
                    {help}
                  </Box>
                </Flex>
              )}
              {secondarylabel &&
                <>
                  <br />
                  <Text fontSize='sm' fontStyle='italic' color='switch.label.normal'>{secondarylabel}</Text>
                </>}
              <Stack direction='row' spacing={2} align='center' mt='5px'>
                <Button
                  ref={inputRef}
                  fontSize='smm'
                  h='40px' w='120px'
                  bg={value === undefined || value === "" ? 'toggle.no.bg' : value === true ? 'toggle.yes.bg' : 'toggle.no.bg'}
                  color={value === undefined || value === "" ? 'toggle.no.color' : value === true ? 'toggle.yes.color' : 'toggle.no.color'}
                  borderRadius='10px'
                  variant={value === undefined || value === "" ? 'outline' : value === true ? 'solid' : '40px'}
                  onClick={() => {
                    if (value === true)
                      onChange({ target: { name, value: "" } });
                    else
                      onChange({ target: { name, value: true } });

                  }}
                  onBlur={onBlur}
                  isDisabled={disabled}
                  name={name}
                  _hover={{ bg: disabled ? '' : 'toggle.yes.hoverBg', color: disabled ? '' : 'toggle.yes.hoverColor' }} >
                  Yes
                </Button>
                <Button
                  ref={inputRef}
                  fontSize='smm'
                  h='40px' w='120px'
                  bg={value === undefined || value === "" ? 'toggle.no.bg' : !value === true ? 'toggle.yes.bg' : 'toggle.no.bg'}
                  color={value === undefined || value === "" ? 'toggle.no.color' : !value === true ? 'toggle.yes.color' : 'toggle.no.color'}
                  borderRadius='10px'
                  variant={value === undefined || value === "" ? 'outline' : !value === true ? 'solid' : '40px'}
                  onClick={() => {
                    if (value === false)
                      onChange({ target: { name, value: "" } });
                    else
                      onChange({ target: { name, value: false } });
                  }}
                  onBlur={onBlur}
                  isDisabled={disabled}
                  name={name}
                  _hover={{ bg: disabled ? '' : 'toggle.yes.hoverBg', color: disabled ? '' : 'toggle.yes.hoverColor' }} >
                  No
                </Button>

              </Stack>
            </Box>
            {error && <Box fontSize='ssm' ml={1} mt={1} color='toggle.label.error'>{error.message}</Box>}
            {
              tooltip &&
              <Flex color='toggle.tooltipColor' align='center' mt={3}>
                <InfoOutlineIcon />
                <Box fontSize='11px' ml={2}>{tooltip}</Box>
              </Flex>
            }
          </Box >
        );
      }}
    />
  );
};

export default Toggle;

export const toggleStyles = {
  toggle: {
    label: {
      normal: '#282F36',
      error: '#E53E3E'
    },
    secondarylabel: "#9a9ea1",
    enableColor: '#282F36',
    disableColor: '#818197',
    color: {
      50: '#ede9ff',
      100: '#c9bff7',
      200: '#a596ea',
      300: '#816ce1',
      400: '#5d42d7',
      500: '#c9bff7',
      600: '#342094',
      700: '#24166b',
      800: '#150d42',
      900: '#07041c',
    },
    tooltipColor: '#9A9EA1',
    yes: {
      bg: '#DC0043',
      color: '#FFFFFF',
      hoverBg: '#DC0043',
      hoverColor: '#ffffff'

    },
    no: {
      bg: '#e5e5e5',
      color: '#131535',
    }
  }
};
