import { useContext, useEffect, useState } from "react";

import { Box, Button, Flex, Table, Thead, Tbody, Tr } from "@chakra-ui/react";
import { ModalContext } from "../../../contexts/ModalProvider";
import { Controller } from "react-hook-form";
import { IField } from "../../../interfaces/IField";
import { DefinedValidations } from "../../../interfaces/Validations";
import { AddIcon } from "../../../icons";
import TechSignatureTableRow from "./TechSignatureTableRow";
import TechSignatureTableModal from "./TechSignatureTableModal";
import TableHeader from "../TableHeader";
import useValidate from "../../../hooks/useValidate";

interface ITechSignatureTable extends IField {
  data?: any;
  description?: string;
  variant?: string;
  help?: string;
  styles?: {
    font?: string;
  };
  formIndex?: string;
}

interface ITechSignatureTableData {
  _id: undefined;
  name: string;
  signed: string;
  date: string;
  formIndex: string;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const TechSignatureTable = ({
  control,
  name,
  label,
  validations = {},
  disabled = false,
  help = "",
  styles,
  formIndex,
}: ITechSignatureTable) => {
  const validate = useValidate(
    label || name,
    validations || {},
    definedValidations
  );
  const [dataValues, setdataValues] = useState<ITechSignatureTableData[]>([]);
  const { modalState, setModalState } = useContext(ModalContext);
  const [currentDataValue, setCurrentDataValue] = useState<any>("");

  useEffect(() => {
    setdataValues(control._formValues.technicianRepresentatives);
  }, [control._formValues.technicianRepresentatives]);

  // Reset the form after closing
  useEffect(() => {
    if (modalState === "closed") {
      setCurrentDataValue("");
    }
  }, [modalState]);

  // If modal opened in edit or delete mode, reset the form and set values of edited element
  const openTechSignatureModal = (
    action: "add" | "edit" | "delete",
    dataValue?: any
  ) => {
    if (action !== "add") {
      setCurrentDataValue(dataValue);
    } else {
      setCurrentDataValue({
        name: "",
        signature: "",
      });
    }
    setModalState(action);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        return (
          <>
            <TechSignatureTableModal
              modalState={modalState}
              setModalState={setModalState}
              dataValues={dataValues}
              setdataValues={setdataValues}
              selectedDataValue={currentDataValue}
              onChange={onChange}
              formIndex={formIndex}
              name={name}
            />
            <Box w="full" id={name} mt="none" maxW="full">
              {label && (
                <Flex
                  pt="15px"
                  pb={1}
                  align="center"
                  justify="space-between"
                  mb="none"
                >
                  <Box
                    color={
                      error
                        ? "textInput.labelFont.error"
                        : styles
                        ? styles?.font
                        : "textInput.labelFont.normal"
                    }
                    fontWeight="bold"
                    fontSize="md"
                    position="static"
                    left="none"
                    zIndex={2}
                  >
                    {label}
                  </Box>
                </Flex>
              )}
              <Box maxW="full" overflowX="auto">
                <Table fontSize="smm">
                  <Thead>
                    <Tr>
                      <TableHeader
                        header="Name"
                        styles={{ paddingInline: "0px" }}
                      />
                      <TableHeader
                        header="Signed"
                        styles={{ paddingInline: "10px" }}
                      />
                      <TableHeader
                        header="Date"
                        styles={{ paddingInline: "20px" }}
                      />
                      <TableHeader header="" />
                      <TableHeader header="" />
                    </Tr>
                  </Thead>
                  <Tbody left="-10px">
                    {dataValues &&
                      dataValues?.map((dataValue, index) => (
                        <TechSignatureTableRow
                          key={index}
                          dataValue={dataValue}
                          disabled={disabled}
                          onEdit={() =>
                            openTechSignatureModal("edit", {
                              ...dataValue,
                              index: index,
                            })
                          }
                          onRemove={() =>
                            openTechSignatureModal("delete", {
                              ...dataValue,
                              index: index,
                            })
                          }
                        />
                      ))}
                  </Tbody>
                </Table>
              </Box>
              <Box>
                {!disabled && (
                  <Button
                    disabled={disabled}
                    bg="#FFFFFF"
                    color="#DC0043"
                    mt="05px"
                    pl="0px"
                    pr="15px"
                    fontSize="smm"
                    borderRadius="10px"
                    onClick={() => openTechSignatureModal("add")}
                    _hover={{
                      background: "clientContractorTable.button.hoverBg",
                    }}
                    _active={{
                      background: "clientContractorTable.button.activeBg",
                    }}
                  >
                    <AddIcon
                      w="12.5px"
                      h="12.5px"
                      mr="10px"
                      stroke="clientContractorTable.addIcon.stroke"
                    />
                    Add Row
                  </Button>
                )}
              </Box>
              {error && (
                <Box fontSize="ssm" ml={1} color="textInput.error">
                  {error.message}
                </Box>
              )}
            </Box>
          </>
        );
      }}
    />
  );
};

export default TechSignatureTable;

export const techSignatureTableStyles = {
  techSignatureTable: {
    button: {
      bg: "#FFFFFF",
      color: "#DC0043",
      hoverBg: "#FFFFFF",
      activeBg: "#FFFFFF",
    },
    addIcon: {
      stroke: "#DC0043",
    },
  },
};
