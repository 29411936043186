import { Avatar, Flex, Text, VStack, useMediaQuery, Box } from '@chakra-ui/react';

interface IReportHeaderStatus {
  heading: string;
  status: string | React.ReactNode;
  avatar?: string
}

const ProjectHeaderStatus = ({ heading, status, avatar }: IReportHeaderStatus) => {
  const [onDesktop] = useMediaQuery('(min-width: 1150px)');
  return (
    <Flex
      align="center"
      pr={onDesktop ? '10px' : '0px'}
    >
      {avatar ? <Avatar
        color="userMenu.avatar.color"
        bg="breNavy"
        rounded="full"
        src={(avatar !== 'blank') ? avatar : ""}
        mx={2}
        size="sm"
      /> : ''}
      <VStack
        direction='column'
        align='left'
        spacing='3px'
        pr='22px'
        w='max-content'
        alignItems='start'
      >
        <Text
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          maxW={onDesktop ? "110px" : '70px'}
          fontStyle='normal'
          fontWeight='bold'
          fontSize='ssm'
          lineHeight='16px'
          color='reportFormHeader.heading'
        >
          {heading}
        </Text>
        <Box
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          maxW={onDesktop ? '110px' : '70px'}
          fontStyle='normal'
          fontWeight='semi_medium'
          fontSize='smm'
          lineHeight='20px'
          color='reportFormHeader.heading'
        >
          {status || "TBC"}
        </Box>
      </VStack>
    </Flex>
  );
}

export default ProjectHeaderStatus;
