import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { SaveIcon } from '../icons';

interface IModal {
  onClose: () => void;
  onSave: () => void;
  isOpen: boolean;
  heading: string;
  children?: React.ReactNode;
  saveButtonType: 'none' | 'button' | 'buttonWithIcon';
  saveButtonText?: string | React.ReactNode;
}

const Modal = ({
  onClose,
  isOpen,
  children,
  saveButtonType,
  onSave,
  saveButtonText,
  heading,
}: IModal) => {
  const renderFooter = () => {
    if (saveButtonType === 'none') {
      return <></>;
    } else if (
      saveButtonType === 'button' ||
      saveButtonType === 'buttonWithIcon'
    ) {
      return (
        <ModalFooter>
          <Button onClick={onClose} mr='2'>
            Discard
          </Button>
          <Button
            onClick={onSave}
            bg='brePink'
            color='white'
            _hover={{}}
            _focus={{}}
            _active={{}}
          >
            {saveButtonType === 'buttonWithIcon' && (
              <SaveIcon stroke='white' mr='2' />
            )}
            {saveButtonText}
          </Button>
        </ModalFooter>
      );
    }
  };

  return (
    <ChakraModal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight='900'>{heading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody w='full'>{children}</ModalBody>
        {renderFooter()}
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
