import { createIcon } from '@chakra-ui/icons';

const PreviewIcon = createIcon({
  path: (
    <>
      <path
        d='M9.5 5.25L9.5 3.83333M8.5 11.1667L2.03333 11.1667C1.73878 11.1667 1.5 10.8483 1.5 10.4556V1.21111C1.5 0.818375 1.73878 0.5 2.03333 0.5H6.16667M6.16667 0.5V3.16667C6.16667 3.5 6.5 3.83333 6.83333 3.83333H9.5M6.16667 0.5L9.5 3.83333'
        stroke='#1E1836'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M3 8.5H5'
        stroke='#1E1836'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 7H5'
        stroke='#1E1836'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 5.5H6'
        stroke='#1E1836'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.20924 8.78638C6.41962 9.2814 6.81803 9.67257 7.31681 9.87384C7.8156 10.0751 8.37392 10.07 8.86893 9.85962C9.36395 9.64924 9.75512 9.25084 9.95639 8.75205C10.1577 8.25326 10.1525 7.69495 9.94217 7.19993C9.73179 6.70491 9.33339 6.31374 8.8346 6.11247C8.33581 5.9112 7.77749 5.91632 7.28248 6.12669C6.78746 6.33707 6.39629 6.73548 6.19502 7.23427C5.99375 7.73305 5.99887 8.29137 6.20924 8.78638V8.78638Z'
        stroke='#1E1836'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M9.50977 9.42725L11.0826 11.0003'
        stroke='#1E1836'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
  viewBox: '0 0 9 12',
});

export default PreviewIcon;
