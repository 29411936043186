import { useState } from 'react';

import { CloseIcon } from '@chakra-ui/icons';
import { Avatar, Flex } from '@chakra-ui/react';


const RemovableAvatar = ({
  userId,
  disabled,
  w = '70px',
  h = '70px',
  onDelete,
}: {
  userId: string;
  disabled?: boolean;
  w: string;
  h: string;
  onDelete: (userId: string) => void;
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    (<Flex
      align="center"
      flexDirection="column"
      position="relative"
      textAlign="center"
      w={w}
    >
      <Avatar
        cursor="default"
        h={h}
        onMouseEnter={() => !disabled && setShowOverlay(true)}
        src={`${process.env.REACT_APP_API_URL}/files/photo/${userId}`}
        w={w}
      />
      {!disabled && showOverlay && (
        <Flex
          alignItems="center"
          cursor="pointer"
          justifyContent="center"
          onClick={async () => {
            onDelete(userId);
            setShowOverlay(false);
          }}
          onMouseLeave={() => setShowOverlay(false)}
          pos="absolute">
          <Flex
            bg="removableAvatar.overlay"
            h={h}
            rounded="50%"
            w={w}
          />
          <CloseIcon
            color="removableAvatar.icon"
            h="20px"
            opacity="0.95"
            pos="absolute"
            w="20px"
          />
        </Flex>
      )}
    </Flex>)
  );
};

export default RemovableAvatar;

export const removableAvatarStyles = {
  removableAvatar: {
    overlay: 'linear-gradient(0deg, rgba(232, 60, 67, 0.8), rgba(232, 60, 67, 0.8)), url(.png)',
    icon: '#FFFFFF',
  },
};
