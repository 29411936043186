import { createIcon } from '@chakra-ui/icons';

const WarningIcon = createIcon({
  path: (
    <>
      <path
        d='M9.16783 8.33301H10.8345V12.4997H9.16783V8.33301ZM9.16699 13.333H10.8337V14.9997H9.16699V13.333Z'
        fill='#282F36'
      />
      <path
        d='M11.4731 3.50023C11.1831 2.9544 10.6181 2.61523 9.99979 2.61523C9.38146 2.61523 8.81646 2.9544 8.52646 3.50107L2.41146 15.0536C2.27577 15.3073 2.20855 15.5919 2.21644 15.8795C2.22433 16.1671 2.30706 16.4477 2.45646 16.6936C2.60371 16.9406 2.81283 17.1449 3.06317 17.2863C3.31351 17.4278 3.59642 17.5015 3.88396 17.5002H16.1156C16.7056 17.5002 17.2398 17.1986 17.544 16.6936C17.6931 16.4476 17.7757 16.1671 17.7836 15.8795C17.7915 15.592 17.7244 15.3073 17.589 15.0536L11.4731 3.50023ZM3.88396 15.8336L9.99979 4.28107L16.1198 15.8336H3.88396Z'
        fill='black'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default WarningIcon;
