import { Input, Td } from "@chakra-ui/react";
import { CSSProperties } from "react";
import { CheckIcon, CrossIcon } from '../../icons';

interface ITableData {
    data: any,
    dataType: string,
    onChange?: any,
    styles?: CSSProperties
}

const TableData = ({ data, dataType, onChange, styles }: ITableData) => {
    const renderIcon = () => {
        if (data === true)
            return <CheckIcon color='tableData.checkIcon.stroke' w="16px" />;
        else
            return <CrossIcon color='tableData.crossIcon.stroke' w="16px" />;
    }
    switch (dataType) {
        case 'textInput':
            return (
                <Td minW='80px' fontWeight='semi_medium' paddingInline='0px' color='#818197'>
                    <Input
                        my='-8px'
                        ml='-20px'
                        mr='-5px'
                        minW='80px'
                        borderRadius='8px'
                        borderWidth='1px'
                        h='42px'
                        type='text'
                        fontSize='smm'
                        color='textInput.font'
                        bg='textInput.bg'
                        name="minutes"
                        defaultValue={data}
                        borderColor='textInput.border.normal'
                        _focus={{ borderColor: 'textInput.border.focus.normal' }}
                        _hover={{ cursor: 'auto' }}
                        onChange={onChange}
                        cursor='pointer'
                        _disabled={{
                            bg: 'textInput.disabled.bg',
                            color: 'textInput.disabled.font',
                            borderColor: 'textInput.disabled.border',
                            cursor: 'not-allowed',
                        }}
                        placeholder="16"
                        _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
                        css={{
                            '.chakra-switch__thumb': {
                                '&[data-checked]': {
                                    background: '#462AC4'
                                }
                            }
                        }}
                    />
                </Td>
            );
        case 'text':
            return (
                <Td whiteSpace='nowrap' fontWeight='semi_medium' paddingInlineStart={styles?.paddingInline ? styles?.paddingInline : '20px'} style={styles} >{data}</Td>
            );
        case 'check':
            return (<Td paddingInline={styles?.paddingInline ? styles?.paddingInline : '0px'} style={styles}>{renderIcon()}</Td >);

        default:
            return (<></>)

    }

};

export default TableData;

export const tableDataStyles = {
    tableData: {
        checkIcon: {
            stroke: '#41B916'
        },
        crossIcon: {
            stroke: '#E93C44'
        }

    }
}
