import { Flex, Grid, GridItem, Icon } from '@chakra-ui/react';
import { icons } from './icons';

interface IIconPickerControl {
  onClick: (val: string) => void;
}

const IconPickerControl = ({ onClick }: IIconPickerControl) => {
  const rows = icons.length / 8;
  return (
    <Grid
      py='2'
      templateRows={`repeat(${rows}, 1fr)`}
      templateColumns='repeat(8, 1fr)'
      gap={4}
    >
      {icons.map(({ icon, value }, index) => (
        <GridItem key={`icon-control-icon-${index}`}>
          <Flex
            w="34px"
            h="34px"
            borderRadius='6px'
            borderColor='textInput.border.normal'
            borderWidth='1px'
            borderStyle='solid'
            cursor='pointer'
            justifyContent='center'
            align="center"
            onClick={() => {
              onClick(value);
            }}
            color='black'
          >
            <Icon as={icon} />
          </Flex>
        </GridItem>
      ))}
    </Grid>
  );
};

export default IconPickerControl;
