import { createIcon } from '@chakra-ui/icons';

const CircleCheckIcon = createIcon({
  path: (
    <>
      <path
        d='M10.0003 1.66699C5.40533 1.66699 1.66699 5.40533 1.66699 10.0003C1.66699 14.5953 5.40533 18.3337 10.0003 18.3337C14.5953 18.3337 18.3337 14.5953 18.3337 10.0003C18.3337 5.40533 14.5953 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32449 16.667 3.33366 13.6762 3.33366 10.0003C3.33366 6.32449 6.32449 3.33366 10.0003 3.33366C13.6762 3.33366 16.667 6.32449 16.667 10.0003C16.667 13.6762 13.6762 16.667 10.0003 16.667Z'
        fill='currentColor'
      />
      <path
        d='M8.33273 11.3228L6.4169 9.4103L5.24023 10.5903L8.3344 13.6778L13.9227 8.08947L12.7444 6.91113L8.33273 11.3228Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default CircleCheckIcon;
