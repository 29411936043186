import { createIcon } from '@chakra-ui/icons';

const ShieldIcon = createIcon({
  path: (
    <path
      d='M17.4959 5.74995C17.482 5.6099 17.4329 5.47566 17.353 5.35979C17.2731 5.24392 17.1652 5.15022 17.0392 5.08745L10.3725 1.75411C10.2568 1.69618 10.1291 1.66602 9.99962 1.66602C9.87016 1.66602 9.74248 1.69618 9.6267 1.75411L2.96003 5.08745C2.83443 5.15057 2.72679 5.24439 2.64711 5.36021C2.56743 5.47602 2.51827 5.61007 2.5042 5.74995C2.49503 5.83911 1.70337 14.7224 9.6617 18.2616C9.76804 18.3096 9.88337 18.3344 10 18.3344C10.1167 18.3344 10.232 18.3096 10.3384 18.2616C18.2967 14.7224 17.505 5.83995 17.4959 5.74995ZM10 16.5808V9.99994H4.5917C4.26813 8.81522 4.11548 7.59035 4.13837 6.36245L10 3.43161V9.99994H15.3834C14.7509 12.2833 13.3017 14.9824 10 16.5808Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default ShieldIcon;
