import { gql } from '@apollo/client';

const CREATE_PROJECT_TEMPLATE = gql`
  mutation ($projectTemplateInput: ProjectTemplateInput!) {
    createProjectTemplate(projectTemplateInput: $projectTemplateInput) {
      _id
      name
    }
  }
`;

export default CREATE_PROJECT_TEMPLATE;
