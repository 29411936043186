import axios from 'axios';

import { format, formatDistanceToNow, isValid, parseISO } from "date-fns";
import { dateConvertibles, userConvertibles } from "./defaults";
import { IProject } from "../interfaces/IProject";
import { IUser } from "../interfaces/IUser";
import { ISetting } from '../interfaces/ISetting';

export const getFieldEmptyValue = (fieldType: string) => {
  switch (fieldType) {
    case 'textInput': {
      return '';
    }
  }
};

export const pdfVersionControl = (version: string) => {
  if (version === 'Not Generated') return 'v1';
  const versionNumber = parseInt(`${version.match(/(\d+)/)}`);
  return `v${versionNumber + 1}`;
};
export const getFileExtension = (name: string): string => {
  const data = name.split('.');
  return data[data.length - 1];
};

export const firstCharacterCapitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const dateDecorated = (date) => {
  if (!date) {
    return '--';
  }
  return format(new Date(date), "dd MMM yyyy");
};

export const decoratedDifferentiatedValues = (
  value: any,
  key: string,
  users: IUser[]
) => {
  if (typeof value === 'object' && value !== null) {
    return key;
  } else if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  } else if (typeof value === 'string') {
    if (value.length > 0) {
      if (dateConvertibles.includes(key)) {
        return dateDecorated(value);
      } else if (userConvertibles.includes(key)) {
        let user = users.find((item: IUser) => item._id === value);
        if (user) {
          return user.displayName;
        } else {
          return value;
        }
      }
      return value;
    } else {
      return '--';
    }
  }
};

export const getTime = (val) => {
  let date = new Date(val);
  return date.toLocaleTimeString('en-GB', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });
};

export const loadSavedImageByID = async (
  fileId: string,
  spSiteUrl: string,
  spDocumentLibrary: string,
  thumbnail?: boolean
) => {
  const { data: savedImage } = await axios.get(
    `${process.env.REACT_APP_API_URL}/files/getFileByID`,
    {
      params: {
        fileId,
        spSiteUrl,
        spDocumentLibrary,
        thumbnail,
      },
      withCredentials: true,
    }
  );
  return savedImage;
};

export const loadSavedImage = async (
  fileLocation: string,
  spSiteUrl: string,
  spDocumentLibrary: string,
) => {
  const { data: savedImage } = await axios.get(
    `${process.env.REACT_APP_API_URL}/files/getFile`,
    {
      params: {
        fileLocation,
        spSiteUrl,
        spDocumentLibrary,
      },
      withCredentials: true,
    }
  );
  return savedImage;
};

export const updateFileContent = async (
  file: any,
  filePath: string,
  settings?: ISetting,
) => {
  const reportFilesData = new FormData();
  const response = await fetch(file);
  const blob = await response.blob();
  const newFile = new File([blob], 'example.png', { type: blob.type });
  const compressedImage = await prepareImage(newFile);
  const dataUrl = await blobToDataURL(compressedImage!!);
  reportFilesData.append('filePath', filePath);
  reportFilesData.append('spSiteUrl', settings?.spSiteUrl!);
  reportFilesData.append('spDocumentLibrary', settings?.spDocumentLibrary!);
  reportFilesData.append('file', dataUrl);

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/files/updateImage`,
    reportFilesData,
    { withCredentials: true }
  );
  return res.data;
};

export const imageToBase64 = async (image) => {
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const base64Data = await toDataURL(image);
  return base64Data;
};

export const base64ToBlob = async (image) => {
  const toBlob = (url) => fetch(url).then((response) => response.blob());
  const blobData = await toBlob(image);
  return blobData;
};

export function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (_e) => resolve(reader.result as string);
    reader.onerror = (_e) => reject(reader.error);
    reader.onabort = (_e) => reject(new Error('Read aborted'));
    reader.readAsDataURL(blob);
  });
}

// resizes the image to reduce file size
export const prepareImage = async (image: File) => {
  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });
  const imageSrc = URL.createObjectURL(image);
  const img: any = await createImage(imageSrc);
  let { width, height } = img;
  if (img.width > img.height) {
    width = img.width > 2000 ? 2000 : img.width;
    height = img.width > 2000 ? img.height / (img.width / 2000) : img.height;
  } else if (img.width < img.height) {
    width = img.height > 2000 ? img.width / (img.height / 2000) : img.width;
    height = img.height > 2000 ? 2000 : img.height;
  } else {
    width = height = img.height > 2000 ? 2000 : img.height;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  ctx.drawImage(img, 0, 0, width, height);
  const dataURI = canvas.toDataURL(image.type, 0.5);
  const res = await fetch(dataURI);
  const blob = await res.blob();

  return blob;
};

export const getProjectLeadName = (
  usersData,
  project: IProject | null,
  projectLeadId: string | undefined = undefined
): string => {
  let id: string = '';
  if (project?.projectLeadId) {
    id = project?.projectLeadId;
  } else if (projectLeadId) {
    id = projectLeadId;
  }
  let item: IUser = (usersData?.searchUsers || []).find(
    (item: IUser) => item._id === id
  );
  return item?.displayName ?? 'TBC';
};

export const isAdminRoute = (route: string): boolean => {
  return route.includes('admin');
};

export const getDefaultFieldValue = (type: string) => {
  switch (type) {
    case 'amslerChecklistTable':
    case 'clientContractorTable':
    case 'minutesObservationTable':
    case 'minutesTable':
    case 'peerReviewTable':
    case 'recordKeepingTable':
    case 'techSignatureTable':
    case 'freeHandInput':
    case 'imageCapture':
    case 'staticTableInput':
    case 'tableInput':
      return [];
    case 'checkbox':
      return false;
    default:
      return '';
  }
};

export const sanitize = (s: string) => {
  const sanitizedStr = s.replace(/[^\w\s]/g, '');
  // Replace consecutive spaces with a single hyphen
  const cleanedStr = sanitizedStr.replace(/\s+/g, '-');
  return cleanedStr.toLowerCase();
};

export const fieldNameDecorated = (key: string) => {
  const fields = {
    textInput: 'Text input',
    checkbox: 'Checkbox',
    datepicker: 'Date Picker',
    dropdown: 'Dropdown',
    toggle: 'Yes/No Question',
    peoplePicker: 'People picker',
    imageCapture: 'Image uploader',
    freeHandInput: 'Drawing',
    signatureApproval: 'Signature input',
    staticText: 'Information note',
    staticImage: 'Information image',
    tableInput: 'Tables',
    staticTableInput: 'Static table',
    radio: 'Radio button',
  };
  return fields[key];
};

export const roleDecorated = (key: string) => {
  const roles = {
    projectLead: 'Project Lead',
    trainee: 'Trainee',
    tester: 'Tester',
  };
  return roles[key];
};

export const diffForHumans = (dateString) => {
  const date = parseISO(dateString);

  if (!isValid(date)) {
    return '--';
  }

  return formatDistanceToNow(date, { addSuffix: true });
};

export const ucFirst = (action: string) => {
  return action[0].toUpperCase() + action.slice(1);
};

export const getCollectionDecorated = (collection: string) => {
  const ob = {
    'project-templates': 'project template',
    'form-templates': 'form template',
    forms: 'form',
    projects: 'project'
  };
  return ob[collection];
};
