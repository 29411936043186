import { createIcon } from '@chakra-ui/icons';

const ComputerIcon = createIcon({
  path: (
    <path
      d='M16.667 14.7683C17.1628 14.4792 17.5003 13.9475 17.5003 13.3333V4.16667C17.5003 3.2475 16.7528 2.5 15.8337 2.5H4.16699C3.24783 2.5 2.50033 3.2475 2.50033 4.16667V13.3333C2.50033 13.9467 2.83783 14.4792 3.33366 14.7683V15H1.66699V16.6667H18.3337V15H16.667V14.7683ZM4.16699 13.3333V4.16667H15.8337L15.8353 13.3333H4.16699Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default ComputerIcon;
