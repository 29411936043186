import { gql, useQuery } from '@apollo/client';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import format from 'date-fns/format';

import { IAuditLogRecord } from '../../interfaces/IAuditLogs';
import { ucFirst, getCollectionDecorated } from '../../utils/helpers';

const GET_USERS_BY_ID = gql`
  query ($userQueryInput: UserQueryInput) {
    userById(userQueryInput: $userQueryInput) {
      _id
      displayName
      imgUrl
    }
  }
`;

const AuditLogRecord = ({ audit }: { audit: IAuditLogRecord }) => {
  const { data: { userById } = {} } = useQuery(GET_USERS_BY_ID, {
    variables: {
      userQueryInput: { userId: audit.metatags?.addedBy },
    },
  });

  const renderDiff = (oldValue, newValue, element, i) => {
    if (!oldValue?.label && !newValue?.label) {
      return (
        <Box
          key={i}
          mt='10px'
          mb='10px'
          textAlign='center'
          w='full'
        >
          {element}
        </Box>
      );
    }

    if (oldValue?.value === newValue?.value) {
      return null;
    }

    return (
      <Flex
        flexDirection={['column', 'row', 'row']}
        key={i}
        mb='10px'
        w='full'
      >
        <Box
          mr='20px'
          mt='10px'
          textAlign={['center', 'right']}
          w={['100%', '40%']}
        >
          {element}
        </Box>
        <Box
          bg={oldValue?.value !== undefined && oldValue?.value !== '' && oldValue?.label ? '#FFDCD1' : '#fff'}
          mb={['2px', '0']}
          p='10px'
          w={['100%', element ? '40%' : '60%']}
        >
          {oldValue?.label || ''}
        </Box>
        <Box
          bg={newValue?.value !== undefined && newValue?.value !== '' && newValue?.label ? '#CFFED4' : '#fff'}
          ml={['0', '20px']}
          p='10px'
          w={['100%', element ? '40%' : '60%']}
        >
          {newValue?.label || ''}
        </Box>
      </Flex>
    );
  };

  if (audit.action === 'updated' && (!audit.values || Object.keys(audit.values).length === 0)) return null;

  return (
    <Flex flexDir='column' mb='18px' ml='20px'>
      <Text color='auditLogRecordStyles.info.color' fontSize='11px'>
        {format(new Date(audit?.metatags?.addedAt!), 'h:mm a')}
      </Text>
      <Flex align='center' mt='3'>
        <Avatar
          borderColor='auditLogRecordStyles.info.border'
          h='32px'
          rounded='full'
          src={userById?.imgUrl}
          w='32px'
        />
        <Flex flexDir='column' ml='3'>
          <Text
            color='auditLogRecordStyles.userInfo.color'
            fontSize='11px'
            opacity='0.5'
          >
            {userById ? `${userById?.displayName}` : 'Unknown user'}
          </Text>
          <Text
            color='auditLogRecordStyles.title.action'
            fontSize='14px'
            noOfLines={1}
          >
            {ucFirst(audit.action)} {getCollectionDecorated(audit.coll)}
            <Text as='span' pl={1}>
              {audit.element.name}{' '}
            </Text>
          </Text>
        </Flex>
      </Flex>
      <Flex direction='column' w='full'>
        {audit.action === 'updated' && (
          <Flex
            border='1px dashed'
            borderColor='auditLogRecordStyles.log.border'
            borderRadius='10px'
            flexDirection='column'
            ml='17px'
            mt='20px'
            p='15px 10px 10px 10px'
            w='calc(100% - 10px)'
          >
            <Box maxH='260px' overflow='auto'>
              {Object.keys(audit.values).map((element, i) =>
                renderDiff(audit.values[element].old, audit.values[element].new, element, i)
              )}
            </Box>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const auditLogRecordStyles = {
  auditLogRecordStyles: {
    info: {
      color: '#1F1F1F',
      border: '#816ce1',
    },
    userInfo: {
      color: '#282F36',
    },
    title: {
      color: '#282F36',
      action: '#000',
    },
    log: {
      border: '#9A9EA1',
    },
  },
};

export default AuditLogRecord;
