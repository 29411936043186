import { createIcon } from '@chakra-ui/icons';

const FolderIcon = createIcon({
  path: (
    <path
      d='M1.80449 16.2925C1.95949 16.5258 2.22033 16.6667 2.50033 16.6667H15.0003C15.3337 16.6667 15.6353 16.4683 15.7662 16.1617L18.2662 10.3283C18.3209 10.2016 18.3433 10.0632 18.3313 9.92572C18.3192 9.7882 18.2731 9.65583 18.1971 9.54057C18.1211 9.42531 18.0177 9.33078 17.896 9.26551C17.7744 9.20023 17.6384 9.16626 17.5003 9.16667H16.667V5.83333C16.667 4.91417 15.9195 4.16667 15.0003 4.16667H9.91699L8.01366 2.6775C7.86727 2.56249 7.68649 2.49998 7.50033 2.5H3.33366C2.41449 2.5 1.66699 3.2475 1.66699 4.16667V15.8333H1.67283C1.67097 15.996 1.71674 16.1556 1.80449 16.2925ZM14.4512 15H3.76449L5.55033 10.8333H16.237L14.4512 15ZM15.0003 5.83333V9.16667H5.00033C4.66699 9.16667 4.36533 9.365 4.23449 9.67167L3.33366 11.7742V5.83333H15.0003Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 20 20',
});

export default FolderIcon;
