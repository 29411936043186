import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import {
  Box,
  Button,
  Flex,
  Text,
  Spinner,
  Stack,
  VStack,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";

import { IField } from "../../../interfaces/IField";
import { Controller } from "react-hook-form";
import { DefinedValidations } from "../../../interfaces/Validations";
import { Asterisk, PhotoIcon } from "../../../icons";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import useValidate from "../../../hooks/useValidate";
import { useProjectsContext } from "../../../contexts/ProjectsProvider";

import PhotoRenderer from "./PhotoRenderer";
import AnnotationModal from "../../AnnotationModal";
import { prepareImage, updateFileContent } from "../../../utils/helpers";
import { useAppContext } from "../../../contexts/AppProvider";

interface IImageCapture extends IField {
  variant?: string;
  help?: string;
  setValue?: any;
  controlType?: 'builder' | 'form'
};

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      value.forEach((e) => { });
      return `Cannot be empty`;
    }
  },
};

const ImageCapture = ({
  control,
  name,
  label,
  tooltip = "",
  validations = {},
  disabled = false,
  formIndex,
  controlType = 'form'
}: IImageCapture) => {
  const validate = useValidate(
    label || name,
    validations || {},
    definedValidations
  );
  const { currentProject } = useProjectsContext();
  const hiddenFileInput = useRef<any>(null);
  const photosButtonRef = useRef<null | HTMLButtonElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPhoto, setSelectedPhoto] = useState<any>(null);
  const [uploadedImagesBase64, setUploadedImagesBase64] = useState<any>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { settings } = useAppContext();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setUploadedImagesBase64(control._formValues[name] || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control._formValues[name], settings]);

  useEffect(() => {
    return () => {
      setUploadedImagesBase64([])
    }
  }, [formIndex]);

  const uploadFilesForField = async (file: File, fileName) => {
    const reportFilesData = new FormData();
    const compressedImage = await prepareImage(file);
    reportFilesData.append("clientName", currentProject?.clientName!);
    reportFilesData.append("reference", currentProject?.reference!);
    reportFilesData.append("spSiteUrl", settings?.spSiteUrl! || '');
    reportFilesData.append("spDocumentLibrary", settings?.spDocumentLibrary! || '');
    reportFilesData.append("spClientFolder", settings?.spClientFolder! || '');
    reportFilesData.append("fileName", fileName);
    reportFilesData.append(file.name, compressedImage || '');

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/files/${controlType !== 'builder' ? 'savePhotos' : 'saveStaticPhoto'}`,
      reportFilesData,
      { withCredentials: true }
    );
    setIsUploading(false);
    return res.data;
  };

  const handleChange = async (event, onChange) => {
    let encodedBase64Files = uploadedImagesBase64;
    let files = [...event.target.files];
    files.forEach((file, index) => {
      setIsUploading(true);
      const uFileName: string = uuidv4();
      const fileTempName = file.name.split(".");
      const extension = fileTempName[fileTempName.length - 1];
      const fileName = `${uFileName}.${extension}`;
      Promise.resolve(uploadFilesForField(file, fileName)).then((data) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          encodedBase64Files = [
            ...encodedBase64Files,
            {
              title: "",
              description: "",
              backupFileName: fileName, // backup name to be used when when title is removed/empty
              fileName, // used to fetch image from SP
              fileId: data[0].fileId, // this id will be used to update file name on SP
            },
          ];
        };
        reader.onloadend = async () => {
          onChange({ target: { name, value: encodedBase64Files } });
          setUploadedImagesBase64(encodedBase64Files);
        };

        setTimeout(() => {
          photosButtonRef.current?.scrollIntoView();
        }, 200);
      });
    });
  };

  const renderPhoto = (photo, index, error, onChange) => {
    return isUploading ? (
      <>
        <Flex
          w='full'
          h='full'
          align='center'
          justify='center'
          direction={'column'}
        >
          <Spinner size="xl" thickness="4px" />
        </Flex>
      </>
    ) : (
      <PhotoRenderer
        key={`photo-${index}`}
        index={index}
        error={error}
        photo={photo}
        isUploading={isUploading}
        onOpen={onOpen}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        onChange={onChange}
        uploadedImagesBase64={uploadedImagesBase64}
        setUploadedImagesBase64={setUploadedImagesBase64}
        disabled={disabled}
        validations={validations}
        formIndex={formIndex}
      />
    );
  };

  const renderContent = (error, onChange) => {
    return (
      <VStack align="left">
        {isUploading ? (
          <>
            <Flex w="full" h="full" align="center" justify="center">
              <Spinner size="xl" thickness="4px" />
            </Flex>
          </>
        ) : (
          uploadedImagesBase64?.map((photo, index) =>
            renderPhoto(photo, index, error, onChange)
          )
        )}
      </VStack>
    );
  };

  if (isUploading) {
    return (
      <Flex w='full' h='full' align='center' justify='center' direction={'column-reverse'}>
        <Text fontWeight='bold' fontSize='16px' mt={2}>
          Please wait for uploads to complete
        </Text>
        <Spinner size='xl' thickness='4px' />
      </Flex>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;
        return (
          <Box w="full" id={name}>
            <Text
              color="switch.label.normal"
              fontWeight="bold"
              fontSize="ssm"
              position="static"
              left="none"
              zIndex={1}
            >
              Photos {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill='imageCapture.iconAsterisk' stroke='imageCapture.iconAsterisk' />}
              {tooltip && (
                <Tooltip hasArrow label={tooltip} placement='top'>
                  <InfoOutlineIcon mb={1} h='14px' />
                </Tooltip>
              )}
            </Text>
            <Stack align="left">
              {isOpen && <AnnotationModal
                isOpen={isOpen}
                onClose={onClose}
                selectedPhoto={selectedPhoto}
                onClickHandler={async (editedImage) => {
                  onClose();
                  let updatedUploadedImagesBase64;
                  if (selectedPhoto.index === -1) {
                    //save new
                    updatedUploadedImagesBase64 = [
                      ...uploadedImagesBase64,
                      {
                        index: uploadedImagesBase64.length,
                      },
                    ];
                    setUploadedImagesBase64(updatedUploadedImagesBase64);
                  } else {
                    setIsUploading(true);
                    //edit existing
                    await updateFileContent(
                      editedImage,
                      `${settings?.spClientFolder}/${(currentProject?.clientName || '').replace(/\//g, ' ')}/${currentProject?.reference}/Photos and Videos/${selectedPhoto.fileName}`,
                      settings
                    ).then(async (data) => {
                      updatedUploadedImagesBase64 = [...uploadedImagesBase64];
                      updatedUploadedImagesBase64[selectedPhoto.index] = {
                        index:
                          updatedUploadedImagesBase64[selectedPhoto.index]
                            .index,
                        title:
                          updatedUploadedImagesBase64[selectedPhoto.index]
                            .title,
                        description:
                          updatedUploadedImagesBase64[selectedPhoto.index]
                            .description,
                        backupFileName:
                          updatedUploadedImagesBase64[selectedPhoto.index]
                            .backupFileName,
                        fileId:
                          updatedUploadedImagesBase64[selectedPhoto.index]
                            .fileId,
                        fileName:
                          updatedUploadedImagesBase64[selectedPhoto.index]
                            .fileName,
                      };
                    });
                    setUploadedImagesBase64(updatedUploadedImagesBase64);
                    setSelectedPhoto(null);
                  }
                  onChange({
                    target: { name, value: updatedUploadedImagesBase64 },
                  });
                  setIsUploading(false);
                }}
              />}
              {renderContent(error, onChange)}
              <Box>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  ref={hiddenFileInput}
                  onChange={(e) => handleChange(e, onChange)}
                  style={{ display: "none" }}
                />
                {disabled ? uploadedImagesBase64.length === 0 && <Flex>No photos were added to the form.</Flex> :
                  <Button
                    w="130px"
                    bg="freeHandInput.addDrawingButton.bg"
                    color="freeHandInput.addDrawingButton.color"
                    mt="5px"
                    pl="5px"
                    pr="15px"
                    fontSize="smm"
                    borderRadius="10px"
                    _hover={{
                      bg: "reportFormHeader.buttonDisableBg",
                      color: "reportFormHeader.buttonDisableColor",
                    }}
                    onClick={handleClick}
                    ref={photosButtonRef}
                  >
                    <PhotoIcon
                      w="15px"
                      h="15px"
                      mx="10px"
                      stroke="freeHandInput.drawingIcon.stroke"
                    />
                    Add Photos
                  </Button>
                }
              </Box>
            </Stack>
            {error && (
              <Box
                fontSize="ssm"
                ml={1}
                mt={1}
                color="freeHandInput.label.error"
              >
                {error.message}
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};

export default ImageCapture;

export const imageCaptureStyles = {
  imageCapture: {
    label: {
      normal: "#282F36",
      error: "#E53E3E",
    },
    enableColor: "#282F36",
    disableColor: "#818197",
    color: {
      50: "#ede9ff",
      100: "#c9bff7",
      200: "#a596ea",
      300: "#816ce1",
      400: "#5d42d7",
      500: "#c9bff7",
      600: "#342094",
      700: "#24166b",
      800: "#150d42",
      900: "#07041c",
    },
    tooltipColor: "#9A9EA1",
    iconAsterisk: "#DC0043"
  },
};
