import { createIcon } from "@chakra-ui/icons";

const LabSetupIcon = createIcon({
  displayName: "LabSetup",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M3.8468 0.583252H9.83568"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.98011 4.86103V0.583252H4.70235V4.86103L0.859769 10.7729C0.692006 11.031 0.59697 11.3295 0.584679 11.6371C0.572383 11.9447 0.643285 12.2499 0.78991 12.5205C0.936535 12.7912 1.15345 13.0173 1.4178 13.175C1.68216 13.3327 1.98415 13.4161 2.29197 13.4166H11.3882C11.6962 13.4165 11.9985 13.3334 12.2632 13.1758C12.5279 13.0183 12.7451 12.7923 12.8921 12.5216C13.0389 12.2508 13.1101 11.9455 13.0978 11.6377C13.0856 11.3299 12.9906 11.0312 12.8227 10.7729L8.98011 4.86103Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.034 7.42773H10.6484"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default LabSetupIcon;
