import { createIcon } from '@chakra-ui/icons';

const GlobeIcon = createIcon({
  path: (
    <path
      d='M10.0003 1.66699C5.40533 1.66699 1.66699 5.40533 1.66699 10.0003C1.66699 14.5953 5.40533 18.3337 10.0003 18.3337C14.5953 18.3337 18.3337 14.5953 18.3337 10.0003C18.3337 5.40533 14.5953 1.66699 10.0003 1.66699ZM16.6095 9.16699H14.3062C14.2034 7.34303 13.6931 5.5653 12.8128 3.96449C13.833 4.4412 14.716 5.16816 15.3798 6.07775C16.0436 6.98735 16.4666 8.05004 16.6095 9.16699ZM10.442 3.35616C11.3045 4.49283 12.4645 6.50616 12.6312 9.16699H7.52533C7.64116 7.00366 8.35366 4.97699 9.56783 3.35533C9.71116 3.34699 9.85449 3.33366 10.0003 3.33366C10.1495 3.33366 10.2953 3.34699 10.442 3.35616ZM7.24033 3.93949C6.42033 5.51533 5.94699 7.30199 5.85866 9.16699H3.39116C3.53513 8.04019 3.96419 6.96874 4.63774 6.05401C5.31129 5.13927 6.20706 4.41148 7.24033 3.93949ZM3.39116 10.8337H5.86949C5.98283 12.8162 6.42366 14.5653 7.16616 16.0253C6.15132 15.5469 5.27343 14.8205 4.61363 13.9131C3.95382 13.0056 3.53338 11.9465 3.39116 10.8337ZM9.54199 16.6445C8.37449 15.2295 7.68533 13.247 7.53449 10.8337H12.6287C12.4553 13.1445 11.6978 15.1637 10.4595 16.6437C10.3078 16.6537 10.1562 16.667 10.0003 16.667C9.84533 16.667 9.69449 16.6537 9.54199 16.6445ZM12.8845 16.0012C13.6803 14.5062 14.1662 12.7503 14.2937 10.8337H16.6087C16.468 11.9372 16.0536 12.9881 15.4032 13.8906C14.7527 14.7931 13.8869 15.5187 12.8845 16.0012Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 20 20',
});

export default GlobeIcon;
