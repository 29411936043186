import { useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  AccordionItem,
  Text,
  AccordionButton,
  AccordionPanel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Accordion,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { toastWarning } from '../../bootstrap/config';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import useTemplateFields from '../../hooks/useTemplateFields';
import { DotsVertical, TrashIcon } from '../../icons';
import { IFormRow, IReportFormSection } from '../../models/reportform';
import { sanitize } from '../../utils/helpers';
import Can from '../Can';
import DividerButton from '../DividerButton';
import { fields as templateFields } from './defaults';
import TemplateField from './TemplateField';
import { IFormField } from '../../interfaces/IFormField';

interface ITemplateRow {
  section: IReportFormSection;
  selectedPage: number;
  row: IFormRow;
  number: number;
  removeRow: () => void;
}

const TemplateRow = ({
  section,
  selectedPage,
  row,
  number,
  removeRow,
}: ITemplateRow) => {
  const toast = useToast();
  const {
    currentProjectTemplate, currentProjectFormTemplate,
    fieldsInEdit, setFieldsInEdit,
  } = useProjectsContext();
  const [newField, setNewField] = useState<IFormField | null>(null);

  const setIsEditing = (fieldId: string, isEditing: boolean) => {
    setFieldsInEdit(fieldsIds => {
      if (isEditing) {
        return [...fieldsIds, fieldId];
      }
      return fieldsIds.filter(id => id !== fieldId);
    });
  }

  const firstFieldRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { fields, createField, removeField, updateField, moveFields } = useTemplateFields(
    section,
    selectedPage,
    row
  );

  return (
    <Draggable
      key={`draggable-${row._id}`}
      draggableId={row._id}
      index={row.index - 1}
    >
      {(provided) => (
        <AccordionItem
          borderWidth='1px'
          borderColor='admin.dividerButton.bg'
          borderRadius='10'
          mb='4'
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {({ isExpanded }) => (
            <Box bg='admin.rows.bg' borderRadius={10} p={3} position='relative'>
              <Text opacity={.3} position='absolute' bottom={1} right={2} fontSize='sm'>{row._id.slice(-4)}</Text>
              <Box display='flex' alignItems='center' cursor='pointer'>
                <Can
                  action="projectTemplates.edit"
                  data={{ projectTemplate: currentProjectTemplate }}
                  yes={() =>
                    <Box mr='2' {...provided.dragHandleProps}>
                      <DotsVertical />
                    </Box>
                  }
                />
                <AccordionButton
                  paddingX='0'
                  alignItems='center'
                  _focus={{}}
                  _hover={{}}
                  onClick={() => {
                    if (isExpanded && fieldsInEdit.length) {
                      toast({ ...toastWarning, description: 'You have unsaved fields in that row' });
                    }
                  }}
                >
                  {isExpanded ? (
                    <ChevronDownIcon h='24px' w='24px' />
                  ) : (
                    <ChevronRightIcon h='24px' w='24px' />
                  )}
                  <Text ml='2' textAlign='left' fontSize='md' fontWeight='bold'>
                    Row # {number}
                  </Text>
                </AccordionButton>
                <Can
                  action="formTemplates.edit"
                  data={{ projectTemplate: currentProjectTemplate, formTemplate: currentProjectFormTemplate }}
                  yes={() => <TrashIcon onClick={removeRow} />}
                />
              </Box>
              <AccordionPanel pb={4} px='2' className='row-accordion-panel'>
                <DragDropContext onDragEnd={moveFields}>
                  <Droppable droppableId='fieldsDroppable'>
                    {(provided) => (
                      <Accordion my='5' ref={provided.innerRef} {...provided.draggableProps}>
                        {fields?.map((pageField, index) => (
                          <TemplateField
                            key={pageField._id}
                            pageField={pageField}
                            number={index}
                            removeField={removeField}
                            updateField={updateField}
                            isEditing={fieldsInEdit.includes(pageField._id!)}
                            setIsEditing={setIsEditing}
                          />
                        ))}
                        {newField && (
                          <TemplateField
                            key={`new-field-${fields.length}`}
                            pageField={newField}
                            number={fields.length}
                            createField={(field) => {
                              createField(field);
                              setIsEditing('new', false);
                              setNewField(null);
                            }}
                            removeField={() => setNewField(null)}
                            isEditing={fieldsInEdit.includes('new')}
                          />
                        )}
                      </Accordion>
                    )}
                  </Droppable>
                </DragDropContext>
                {!newField ? (
                  <Can
                    action="formTemplates.edit"
                    data={{ projectTemplate: currentProjectTemplate, formTemplate: currentProjectFormTemplate }}
                    yes={() =>
                      <Popover
                        initialFocusRef={firstFieldRef}
                        placement='bottom'
                        isOpen={isOpen}
                        onOpen={onOpen}
                        onClose={onClose}
                      >
                        <PopoverTrigger>
                          <DividerButton text='+ Add field' />
                        </PopoverTrigger>
                        <PopoverContent px={0} py={5}>
                          <Box px='4' maxH='120px' overflowY='scroll' fontStyle='md'>
                            {templateFields.map((field) => (
                              <Text
                                key={`field-${sanitize(field.name)}`}
                                cursor='pointer'
                                onClick={() => {
                                  if (field.type === 'projectData') {
                                    createField({
                                      type: field.type,
                                      name: field.data,
                                      label: field.name,
                                    });
                                  } else {
                                    setNewField({
                                      type: field.type,
                                      name: field.data || '',
                                      label: field.data ? field.name : '',
                                      validations: {
                                        notEmpty: false,
                                      },
                                      variant: field.variant,
                                    });
                                    setIsEditing('new', true);
                                  }
                                  onClose();
                                }}
                              >
                                {field.name}
                              </Text>
                            ))}
                          </Box>
                        </PopoverContent>
                      </Popover>
                    }
                  />
                ) : (
                  <Box textAlign='center'>
                    <Text fontSize='sm' color='breDarkGray'>Save the field above to create a new one or change the order.</Text>
                  </Box>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      )}
    </Draggable>
  );
};

export default TemplateRow;
