import { createIcon } from '@chakra-ui/icons';

const PointsLineIcon = createIcon({
  path: (
    <>
      <path
        d='M12.3703 16.667H5.41699C4.26783 16.667 3.33366 15.7328 3.33366 14.5837C3.33366 13.4345 4.26783 12.5003 5.41699 12.5003H11.2503C12.8587 12.5003 14.167 11.192 14.167 9.58366C14.167 7.97533 12.8587 6.66699 11.2503 6.66699H7.19949C6.89345 7.26689 6.51447 7.82669 6.07116 8.33366H11.2503C11.9395 8.33366 12.5003 8.89449 12.5003 9.58366C12.5003 10.2728 11.9395 10.8337 11.2503 10.8337H5.41699C3.34949 10.8337 1.66699 12.5162 1.66699 14.5837C1.66699 16.6512 3.34949 18.3337 5.41699 18.3337H13.4112C13.0022 17.8193 12.6531 17.2602 12.3703 16.667ZM4.16699 1.66699C2.78866 1.66699 1.66699 2.78866 1.66699 4.16699C1.66699 6.82366 4.16699 8.33366 4.16699 8.33366C4.16699 8.33366 6.66699 6.82283 6.66699 4.16699C6.66699 2.78866 5.54533 1.66699 4.16699 1.66699ZM4.16699 5.41699C4.00278 5.41694 3.8402 5.38454 3.68851 5.32165C3.53682 5.25876 3.39901 5.16661 3.28294 5.05046C3.16686 4.93431 3.0748 4.79643 3.01202 4.6447C2.94923 4.49298 2.91694 4.33037 2.91699 4.16616C2.91705 4.00195 2.94944 3.83936 3.01233 3.68768C3.07522 3.53599 3.16737 3.39818 3.28353 3.2821C3.39968 3.16603 3.53755 3.07397 3.68928 3.01118C3.84101 2.94839 4.00362 2.9161 4.16783 2.91616C4.49946 2.91627 4.81746 3.04812 5.05188 3.28269C5.2863 3.51727 5.41794 3.83536 5.41783 4.16699C5.41771 4.49862 5.28587 4.81663 5.05129 5.05105C4.81672 5.28547 4.49862 5.4171 4.16699 5.41699Z'
        fill='#282F36'
      />
      <path
        d='M15.833 11.667C14.4547 11.667 13.333 12.7887 13.333 14.167C13.333 16.8237 15.833 18.3337 15.833 18.3337C15.833 18.3337 18.333 16.8228 18.333 14.167C18.333 12.7887 17.2113 11.667 15.833 11.667ZM15.833 15.417C15.6688 15.4169 15.5062 15.3845 15.3545 15.3217C15.2028 15.2588 15.065 15.1666 14.949 15.0505C14.8329 14.9343 14.7408 14.7964 14.678 14.6447C14.6152 14.493 14.583 14.3304 14.583 14.1662C14.5831 14.002 14.6155 13.8394 14.6784 13.6877C14.7412 13.536 14.8334 13.3982 14.9495 13.2821C15.0657 13.166 15.2036 13.074 15.3553 13.0112C15.507 12.9484 15.6696 12.9161 15.8338 12.9162C16.1655 12.9163 16.4835 13.0481 16.7179 13.2827C16.9523 13.5173 17.084 13.8354 17.0838 14.167C17.0837 14.4986 16.9519 14.8166 16.7173 15.051C16.4827 15.2855 16.1646 15.4171 15.833 15.417Z'
        fill='black'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default PointsLineIcon;
