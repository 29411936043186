import { createIcon } from '@chakra-ui/icons';

const MagnetIcon = createIcon({
  path: (
    <path
      d='M15.8333 2.5H13.3333C12.4142 2.5 11.6667 3.2475 11.6667 4.16667V10.8333C11.6667 11.7525 10.9192 12.5 10 12.5C9.08083 12.5 8.33333 11.7525 8.33333 10.8333V4.16667C8.33333 3.2475 7.58583 2.5 6.66667 2.5H4.16667C3.2475 2.5 2.5 3.2475 2.5 4.16667V10.8333C2.5 14.9692 5.86417 18.3333 10 18.3333C14.1358 18.3333 17.5 14.9692 17.5 10.8333V4.16667C17.5 3.2475 16.7525 2.5 15.8333 2.5ZM13.3333 4.16667H15.8333V6.66667H13.3333V4.16667ZM4.16667 4.16667H6.66667V6.66667H4.16667V4.16667ZM10 16.6667C6.78417 16.6667 4.16667 14.0492 4.16667 10.8333V8.33333H6.66667V10.8333C6.66667 12.6717 8.16167 14.1667 10 14.1667C11.8383 14.1667 13.3333 12.6717 13.3333 10.8333V8.33333H15.8333V10.8333C15.8333 14.0492 13.2158 16.6667 10 16.6667Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default MagnetIcon;
