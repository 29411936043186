import { CheckboxGroup, Stack, Text } from '@chakra-ui/react';

import { useFiltersContext } from '../../contexts/FiltersProvider';
import FilterCheckBox from './FilterCheckBox';

const StateChoiceFilter = ({ name, options }: { name: string; options?: { label: string; value: string }[]; }) => {
  const { filters, updateFilters } = useFiltersContext();

  const renderChoices = () => {
    switch (name) {
      case 'status':
        return ['Not started', 'In progress', 'Completed', 'Archived'].map((status) => <FilterCheckBox key={status} label={status} value={status} />);

      default:
        if (options && options.length > 0) {
          return [...options].sort((a, b) => a.label.localeCompare(b.label)).map(({ label, value }) => <FilterCheckBox key={value} label={label} value={value} />);
        } else {
          return <Text fontSize="sm">No options to show</Text>;
        }
    }
  };

  return (
    <CheckboxGroup
      onChange={(newValue) => updateFilters(name, newValue)}
      value={filters[name] || []}
    >
      <Stack direction="column" overflow="auto">
        {renderChoices()}
      </Stack>
    </CheckboxGroup>
  );
};

export default StateChoiceFilter;
