import { useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import { useProjectsContext } from '../contexts/ProjectsProvider';
import UPDATE_FORM_TEMPLATE from '../gql/mutation/UPDATE_FORM_TEMPLATE';
import { EOperationType } from '../interfaces/EOperationType';

const useTemplateSections = () => {
  const { pages, setPages, selectedPage, setSelectedPage, setOperationType } =
    useProjectsContext();
  const [pageName, setPageName] = useState('');
  const [itemToRemove, setItemToRemove] = useState<null | { type: 'page' | 'section', _id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();
  const [updateFormTemplate] = useMutation(UPDATE_FORM_TEMPLATE);

  const createPage = () => {
    setOperationType(EOperationType.ADDED_PAGE);
    setPages((pages) => [
      ...pages,
      {
        _id: uuidv4(),
        index: pages.length + 1,
        name: pageName || `Page ${pages.length + 1}`,
        sections: [],
      },
    ]);
    setPageName('');
    if (selectedPage === -1) {
      setSelectedPage(0);
    }
    onClose();
  };

  const removePage = () => {
    setOperationType(EOperationType.DELETED_PAGE);
    setPages((prevPages) => {
      const updatedPages = prevPages
        .filter((_, index) => index !== selectedPage)
        .map((page, index) => ({
          ...page,
          index: index + 1,
        }));
      if (selectedPage !== 0 && selectedPage === prevPages.length - 1) {
        setSelectedPage(selectedPage - 1);
      }
      return updatedPages;
    });
  };

  const duplicatePage = () => {
    setOperationType(EOperationType.DUPLICATED_PAGE);
    setPages((prevPages) => {
      const thisPage = {
        ...prevPages[selectedPage],
        _id: uuidv4(),
        index: prevPages.length + 1,
      };
      const updatedPages = [...prevPages];
      updatedPages.push(thisPage);
      return updatedPages;
    });
  };

  const editPageName = () => {
    setOperationType(EOperationType.UPDATED_PAGE);
    setPages((prevPage) => {
      const allPages = [...prevPage];
      const updatedPages = allPages.map((page) => {
        if (page.index === selectedPage + 1) {
          return {
            ...page,
            name: pageName,
          };
        }
        return page;
      });
      return updatedPages;
    });
    setPageName('');
    onClose();
  };

  const movePages = (event) => {
    setOperationType(EOperationType.REORDERED_PAGES);
    if (!event.source || !event.destination) {
      return;
    }
    setSelectedPage(event.destination.index);
    const tempPages = Array.from(pages || []);
    const [removed] = tempPages.splice(event.source.index, 1);
    tempPages.splice(event.destination.index, 0, removed);
    const updatedPages = tempPages.map((page, index) => ({
      ...page,
      index: index + 1,
    }));
    setPages(updatedPages);
  };

  const createSection = () => {
    setOperationType(EOperationType.ADDED_SECTION);
    setPages((prevPages) => {
      const newSectionIndex = prevPages[selectedPage].sections.length + 1;
      const newPageSection = {
        _id: uuidv4(),
        index: newSectionIndex,
        name: '',
        description: '',
        rows: [],
      };
      const thisPage = prevPages[selectedPage];
      const updatedSections = [...thisPage.sections, newPageSection];
      const updatedPages = [...prevPages];

      updatedPages[selectedPage] = {
        ...thisPage,
        sections: updatedSections,
      };

      return updatedPages;
    });
  };

  const removeSection = () => {
    setOperationType(EOperationType.DELETED_SECTION);
    setPages((prevPages) => {
      const updatedPages = prevPages.map((page, index) => {
        if (index === selectedPage) {
          const updatedSections = page.sections.filter((sec) => sec._id !== itemToRemove?._id);
          return {
            ...page,
            sections: updatedSections,
          };
        }
        return page;
      });
      return updatedPages;
    });
  };

  const moveSections = (event) => {
    setOperationType(EOperationType.REORDERED_SECTIONS);
    if (!event.source || !event.destination) {
      return;
    }
    setPages((prevPages) => {
      const updatedPages = prevPages.map((page, index) => {
        if (index === selectedPage) {
          const tempSections = Array.from(page.sections || []);
          const [removed] = tempSections.splice(event.source.index, 1);
          tempSections.splice(event.destination.index, 0, removed);
          const updatedSections = tempSections.map((section, index) => ({
            ...section,
            index: index + 1,
          }));
          return {
            ...page,
            sections: updatedSections,
          };
        }
        return page;
      });
      return updatedPages;
    });
  };

  return {
    selectedPage,
    setSelectedPage,
    pageName,
    setPageName,
    itemToRemove,
    setItemToRemove,
    isOpen,
    onOpen,
    onClose,
    isConfirmationOpen,
    onConfirmationOpen,
    onConfirmationClose,
    updateFormTemplate,
    createPage,
    insertSection: createSection,
    removeSection,
    duplicatePage,
    editPageName,
    removePage,
    movePages,
    moveSections,
  };
};

export default useTemplateSections;
