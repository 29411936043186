import { createIcon } from '@chakra-ui/icons';

const DuplicatePageIcon = createIcon({
  path: (
    <>
      <path
        d='M7.33333 7.1665H6V9.1665H4V10.4998H6V12.4998H7.33333V10.4998H9.33333V9.1665H7.33333V7.1665Z'
        fill='currentColor'
      />
      <path
        d='M2.66536 15.1667H10.6654C11.4007 15.1667 11.9987 14.5687 11.9987 13.8333V5.83333C11.9987 5.098 11.4007 4.5 10.6654 4.5H2.66536C1.93003 4.5 1.33203 5.098 1.33203 5.83333V13.8333C1.33203 14.5687 1.93003 15.1667 2.66536 15.1667ZM2.66536 5.83333H10.6654L10.6667 13.8333H2.66536V5.83333Z'
        fill='currentColor'
      />
      <path
        d='M13.332 1.8335H5.33203V3.16683H13.332V11.1668H14.6654V3.16683C14.6654 2.4315 14.0674 1.8335 13.332 1.8335Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 16 16',
});

export default DuplicatePageIcon;
