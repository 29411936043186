import { createIcon } from '@chakra-ui/icons';

const TrainIcon = createIcon({
  path: (
    <>
      <path
        d='M7.08301 13.333C7.77336 13.333 8.33301 12.7734 8.33301 12.083C8.33301 11.3927 7.77336 10.833 7.08301 10.833C6.39265 10.833 5.83301 11.3927 5.83301 12.083C5.83301 12.7734 6.39265 13.333 7.08301 13.333Z'
        fill='#282F36'
      />
      <path
        d='M12.917 13.333C13.6073 13.333 14.167 12.7734 14.167 12.083C14.167 11.3927 13.6073 10.833 12.917 10.833C12.2266 10.833 11.667 11.3927 11.667 12.083C11.667 12.7734 12.2266 13.333 12.917 13.333Z'
        fill='black'
      />
      <path
        d='M15.7247 2.78366C15.4738 2.4653 15.1601 2.2019 14.8032 2.00981C14.4462 1.81772 14.0536 1.70103 13.6497 1.66699H6.34967C5.9427 1.69689 5.54666 1.8126 5.18759 2.00649C4.82852 2.20039 4.51456 2.46809 4.26634 2.79199C3.71206 3.39985 3.38267 4.1792 3.33301 5.00033V15.0003C3.33301 15.2213 3.42081 15.4333 3.57709 15.5896C3.73337 15.7459 3.94533 15.8337 4.16634 15.8337H5.83301L4.16634 18.3337H6.09967L6.66634 17.5003H13.333L13.8997 18.3337H15.833L14.1663 15.8337H15.833C16.054 15.8337 16.266 15.7459 16.4223 15.5896C16.5785 15.4333 16.6663 15.2213 16.6663 15.0003V5.00033C16.6282 4.17248 16.294 3.38585 15.7247 2.78366ZM6.34967 3.33366H13.6163C13.9496 3.41454 14.2443 3.60902 14.4497 3.88366C14.5252 3.97349 14.5948 4.06813 14.658 4.16699H5.32467C5.38486 4.06348 5.45461 3.96583 5.53301 3.87533C5.72947 3.6006 6.02017 3.40779 6.34967 3.33366ZM4.99967 6.66699V5.83366H14.9997V8.33366H4.99967V6.66699ZM14.9997 14.167H4.99967V10.0003H14.9997V14.167Z'
        fill='black'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default TrainIcon;
