import { useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableHeader from '../components/FormControls/TableHeader';
import useSort from '../hooks/useSort';
import { ChevronDown } from '../icons';
import { diffForHumans } from '../utils/helpers';
import GET_USERS from '../gql/queries/GET_USERS';
import { IUser } from '../interfaces/IUser';
import { userRolesLabels } from '../bootstrap/config';

const Users = () => {
  const { data } = useQuery(GET_USERS);

  const users = data?.users || [];

  const { sortedData, sortOrder, sortType, setSortType, setSortOrder } =
    useSort(users || [], 'displayName', 'asc');

  const handleColumnClick = (key) => {
    setSortType(key);
    setSortOrder(sortOrder === 'asc' && key === sortType ? 'desc' : 'asc');
  };

  return (
    <Box overflowY='auto' overflowX='hidden' pr={4}>
      <Text fontSize='24px' fontWeight='700' pb='6'>
        Users
      </Text>
      <Flex>
        <TableContainer
          bg='white'
          w='100%'
          border={'1px solid'}
          borderColor='#F0F0F0'
          borderRadius='25'
        >
          <Table variant='simple'>
            <Thead fontSize='smm' fontWeight='bold'>
              <Tr>
                <TableHeader
                  header='Name'
                  styles={{
                    paddingInline: 6,
                    fontWeight: 'bold',
                    width: '25%',
                  }}
                  icon={
                    sortType === 'displayName' ? (
                      <ChevronDown
                        ml={2}
                        h='12px'
                        transform={sortOrder === 'asc' ? 'rotate(180deg)' : ''}
                        w='12px'
                      />
                    ) : undefined
                  }
                  onClick={() => handleColumnClick('displayName')}
                />

                <TableHeader
                  header='Job title'
                  styles={{
                    paddingInline: 6,
                    fontWeight: 'bold',
                    width: '25%',
                  }}
                  icon={
                    sortType === 'jobTitle' ? (
                      <ChevronDown
                        ml={2}
                        h='12px'
                        transform={sortOrder === 'asc' ? 'rotate(180deg)' : ''}
                        w='12px'
                      />
                    ) : undefined
                  }
                  onClick={() => handleColumnClick('jobTitle')}
                />

                <TableHeader
                  header='Role'
                  styles={{
                    paddingInline: 6,
                    fontWeight: 'bold',
                    width: '25%',
                  }}
                  icon={
                    sortType === 'role' ? (
                      <ChevronDown
                        ml={2}
                        h='12px'
                        transform={sortOrder === 'asc' ? 'rotate(180deg)' : ''}
                        w='12px'
                      />
                    ) : undefined
                  }
                  onClick={() => handleColumnClick('role')}
                />

                <TableHeader
                  header='Last login'
                  styles={{
                    paddingInline: 6,
                    fontWeight: 'bold',
                    width: '25%',
                  }}
                  icon={
                    sortType === 'lastLogin' ? (
                      <ChevronDown
                        ml={2}
                        h='12px'
                        transform={sortOrder === 'asc' ? 'rotate(180deg)' : ''}
                        w='12px'
                      />
                    ) : undefined
                  }
                  onClick={() => handleColumnClick('lastLogin')}
                />
              </Tr>
            </Thead>
            <Tbody>
              {sortedData.map((user: IUser) => (
                <Tr key={user._id}>
                  <Td w='25%'>
                    <Flex alignItems='center'>
                      <Avatar
                        bg='breNavy'
                        size='sm'
                        mr='2'
                        src={`${user.imgUrl}`}
                      />
                      {user.displayName}
                    </Flex>
                  </Td>
                  <Td w='25%' paddingInlineStart={4}>
                    {user.jobTitle}
                  </Td>
                  <Td w='25%'>{userRolesLabels[user.role] || user.role}</Td>
                  <Td w='25%'>{diffForHumans(user.lastLogin)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
};

export default Users;
