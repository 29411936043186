import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { AuditLogIcon, PreviewIcon, SaveIcon } from '../../icons';
import { IFormTemplate } from '../../interfaces/IFormTemplate';
import FormPreviewModal from '../FormPreviewModal';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import Can from '../Can';
import TemplateAuditLogModal from '../TemplateBuilder/TemplateAuditLogModal';

interface ICommonFormHeaderProps {
  formTemplate: IFormTemplate;
}

const CommonFormHeader: React.FC<ICommonFormHeaderProps> = ({
  formTemplate,
}: ICommonFormHeaderProps) => {
  const history = useHistory();
  const { selectedPage, pages, autoSaving } = useProjectsContext();
  const {
    isOpen: isFormPreviewOpen,
    onOpen: onFormPreviewOpen,
    onClose: onFormPreviewClose,
  } = useDisclosure();
  const {
    isOpen: isAuditLogOpen,
    onOpen: onAuditLogOpen,
    onClose: onAuditLogClose,
  } = useDisclosure();

  if (!formTemplate || !formTemplate.pages) return null;

  return (
    <>
      <FormPreviewModal
        isOpen={isFormPreviewOpen}
        onClose={onFormPreviewClose}
        form={pages[selectedPage]}
      />
      <TemplateAuditLogModal
        isOpen={isAuditLogOpen}
        onClose={onAuditLogClose}
      />
      <Flex pb={4} pr={1} justifyContent={'space-between'}>
        <Flex alignItems='center'>
          <Button
            h='32px'
            bg='white'
            paddingX='1'
            onClick={() => history.goBack()}
          >
            <ChevronLeftIcon stroke='black' fontSize='20px' />
          </Button>
          <Text fontSize='24px' fontWeight='700' mx='3'>
            {formTemplate.name}
          </Text>
        </Flex>
        <Flex alignItems='center'>
          <Button
            h='32px'
            fontSize='smm'
            bg='white'
            fontWeight='700'
            alignItems='center'
            onClick={onAuditLogOpen}
          >
            Audit Log
            <AuditLogIcon ml='2' />
          </Button>
          <Button
            fontSize='smm'
            mx='4'
            h='32px'
            bg='white'
            fontWeight='700'
            alignItems='center'
            onClick={onFormPreviewOpen}
          >
            Preview
            <PreviewIcon ml='2' />
          </Button>
          {/* <Button
            h='32px'
            bg='brePink'
            color='white'
            fontWeight='700'
            alignItems='center'
            onClick={saveForm}
          >
            <SaveIcon stroke='white' mr='2' />
            Save changes
          </Button> */}
          <Can
            action="commonForms.edit"
            yes={() => (
              <Text fontSize='smm' alignItems='center' display='flex'>
                {autoSaving ? 'Saving...' : 'Up to date'}
                <SaveIcon ml='2' stroke='black' />
              </Text>
            )}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default CommonFormHeader;
