import { Button } from "@chakra-ui/react";
import { useMemo } from "react";
import { useFiltersContext } from "../../contexts/FiltersProvider";

type QuickFilterButtonProps = {
  label: string;
  filtersToSet: { name: string; value: string[] }[];
};

const QuickFilterButton = ({ label, filtersToSet }: QuickFilterButtonProps) => {
  const { filters, updateFilters } = useFiltersContext();

  const isActive = useMemo(() => {
    return filtersToSet.every(filter => JSON.stringify((filters[filter.name] || []).sort()) === JSON.stringify((filter.value || []).sort()));
  }, [filters, filtersToSet]);

  const setFilters = () => {
    for (const filter of filtersToSet) {
      updateFilters(filter.name, filter.value);
    }
  };

  return (
    <Button
      _active={{}}
      _hover={{}}
      bg={isActive ? "breNavy" : "white"}
      color={isActive ? "white" : "breNavy"}
      rounded="6px"
      fontSize="smm"
      h="34px"
      onClick={setFilters}
      p={4}
    > {label}</Button >
  );
}

export default QuickFilterButton;
