import { EditIcon } from '@chakra-ui/icons';
import {
  AccordionItem,
  Box,
  Flex,
  Button,
  Divider,
  Text,
  Switch,
  useToast,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { Controller, useForm } from 'react-hook-form';
import { toastFailed } from '../../bootstrap/config';
import { useAppContext } from '../../contexts/AppProvider';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import { DotsVertical, TrashIcon } from '../../icons';
import EyeIcon from '../../icons/Eye';
import {
  IFormField,
  IFormFieldOption,
  IFormFieldOptionWType,
} from '../../interfaces/IFormField';
import { fieldNameDecorated } from '../../utils/helpers';
import Can, { isPermitted } from '../Can';
import { Dropdown, RichTextEditor, TextInput } from '../FormControls';
import { fields as fieldTemplates, IField, IFieldFormField } from './defaults';
import TemplateFieldImageInput from './TemplateFieldImageInput';
import TemplateFieldOptionsInput from './TemplateFieldOptionsInput';
import TemplateFieldColumnsInput from './TemplateFieldColumnsInput';
import ConfirmationModal from '../ConfirmationModal';

interface ITemplateField {
  pageField: IFormField;
  number: number;
  createField?: (field: IField) => void;
  updateField?: (n: number, field: IField) => void;
  removeField: (n: number) => void;
  isEditing: boolean;
  setIsEditing?: (fieldId: string, isEditing: boolean) => void;
}

const TemplateField = ({
  pageField,
  number,
  createField,
  updateField,
  removeField,
  isEditing,
  setIsEditing,
}: ITemplateField) => {
  const { user } = useAppContext();
  const { currentProjectTemplate, currentProjectFormTemplate, usedDatabaseNames } = useProjectsContext();
  const usedDatabaseNamesWithoutCurrent = usedDatabaseNames.filter((name) => name !== pageField.name);

  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const isPermittedToEdit = isPermitted({
    user,
    action: 'formTemplates.edit',
    data: {
      projectTemplate: currentProjectTemplate,
      formTemplate: currentProjectFormTemplate,
    },
  });

  const toast = useToast();

  const defaultValues: IFormField = {
    type: pageField.type,
    name: pageField.name,
    label: pageField.label,
    placeholder: pageField.placeholder,
    tooltip: pageField.tooltip,
    variant: pageField.variant,
    canSelectFutureDate: pageField.canSelectFutureDate,
    clearAfterEdit: pageField.clearAfterEdit,
    required: Boolean(pageField.required),
    options: pageField.options || [],
    image: pageField.image || undefined,
    columns: pageField.columns || [],
  };
  const {
    control,
    getValues,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const getInputField = (formField: IField) => {
    let fieldTemplate = fieldTemplates.find(
      ({ type }) => type === formField.type
    );
    return fieldTemplate?.formFields?.map((field) => (
      <Box key={field.control}>{renderInputField(field)}</Box>
    ));
  };

  const renderInputField = (formField: IFieldFormField) => {
    if (formField.fieldType === 'text') {
      return (
        <TextInput
          control={control}
          styles={{ width: '100%' }}
          name={formField.control}
          label={formField.label}
          placeholder={formField.placeholder}
          validations={{
            notEmpty: formField.required,
            unique: formField.unique ? usedDatabaseNamesWithoutCurrent : [],
          }}
          disabled={!isPermittedToEdit}
        />
      );
    } else if (formField.fieldType === 'richTextEditor') {
      return (
        <RichTextEditor
          control={control}
          styles={{ width: '100%' }}
          name={formField.control}
          label={formField.label}
          placeholder={formField.placeholder}
          validations={{ notEmpty: formField.required }}
          disabled={!isPermittedToEdit}
        />
      );
    } else if (formField.fieldType === 'switch') {
      return (
        <Flex alignItems='center' mt='4'>
          <Controller
            control={control}
            name={formField.control}
            render={() => (
              <Switch
                isChecked={Boolean(getValues()[formField.control])}
                onChange={() => {
                  setValue(
                    formField.control,
                    !control._formValues[formField.control]
                  );
                }}
                disabled={!isPermittedToEdit}
              />
            )}
          />
          <Text ml='3'>{formField.label}</Text>
        </Flex>
      );
    } else if (formField.fieldType === 'switchToVariant') {
      return (
        <Flex alignItems='center' mt='4'>
          <Controller
            control={control}
            name={formField.control}
            render={() => (
              <Switch
                isChecked={getValues()[formField.control] === formField.placeholder}
                onChange={(e) => {
                  setValue(
                    formField.control,
                    e.target.checked ? formField.placeholder : ''
                  );
                }}
                disabled={!isPermittedToEdit}
              />
            )}
          />
          <Text ml='3'>{formField.label}</Text>
        </Flex>
      );
    } else if (formField.fieldType === 'select') {
      return (
        <Flex alignItems='center' mt='4'>
          <Dropdown
            control={control}
            name={formField.control}
            label={formField.label}
            styles={{ fullWidth: true }}
            options={formField.options}
            validations={{ notEmpty: formField.required }}
            disabled={!isPermittedToEdit}
            placeholder={formField.placeholder}
          />
        </Flex>
      );
    } else if (formField.fieldType === 'options') {
      return (
        <TemplateFieldOptionsInput
          setValue={setValue}
          options={(getValues()[formField.control] || []) as IFormFieldOption[]}
          name={formField.control}
        />
      );
    } else if (formField.fieldType === 'columns') {
      return (
        <TemplateFieldColumnsInput
          setValue={setValue}
          options={
            (getValues()[formField.control] || []) as IFormFieldOptionWType[]
          }
          name={formField.control}
          isStatic={pageField.type === 'staticTableInput'}
        />
      );
    } else if (formField.fieldType === 'image') {
      return (
        <TemplateFieldImageInput
          control={control}
          name={formField.control}
          label={formField.label}
          validations={{ notEmpty: formField.required }}
        />
      );
    }
    return <></>;
  };

  const saveChanges = () => {
    if (!isValid) {
      toast({
        ...toastFailed,
        description: 'Please fill all the required fields',
      });
      return;
    }
    let {
      type,
      label,
      name,
      placeholder,
      tooltip,
      canSelectFutureDate,
      clearAfterEdit,
      required,
      variant,
      options,
      image,
      columns,
    } = getValues();

    const field = {
      type: type || '',
      label,
      name,
      placeholder: placeholder || '',
      variant: variant || '',
      tooltip: tooltip || '',
      validations: {
        notEmpty: required,
      },
      required,
      canSelectFutureDate,
      clearAfterEdit,
      options,
      image,
      columns,
    };

    if (pageField._id && updateField && setIsEditing) {
      updateField(number, field);
      setIsEditing(pageField._id, false);
    } else if (createField) {
      createField(field);
    }
  };

  return (
    <Draggable
      key={`draggable-${pageField._id}`}
      draggableId={pageField._id}
      index={number}
    >
      {(provided) => (
        <AccordionItem
          borderWidth='1px'
          borderColor='admin.dividerButton.bg'
          borderRadius='10'
          mb='5'
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onClose={onConfirmationClose}
            modalText={`Do you want to delete this field?`}
            confirmAction={() => removeField(number)}
          />
          <Box bg='opaqueWhite' borderRadius={10} p={3}>
            <Flex alignItems='center' justifyContent='space-between'>
              <HStack alignItems='flex-start'>
                <Can
                  action="projectTemplates.edit"
                  data={{ projectTemplate: currentProjectTemplate }}
                  yes={() =>
                    <Flex {...provided.dragHandleProps}>
                      <DotsVertical />
                    </Flex>
                  }
                />
                <Flex flexDir='column'>
                  <Text fontSize='ssm'>{fieldNameDecorated(pageField.type)}</Text>
                  {pageField.type !== 'staticText' && <Text fontSize='sm' fontWeight='700'>
                    {pageField.label}
                  </Text>}
                </Flex>
              </HStack>
              <Flex>
                <>
                  {isEditing ? (
                    <>
                      <Button
                        fontSize='sm'
                        bg='admin.badge.bg'
                        h='30px'
                        fontWeight='700'
                        mx='3'
                        onClick={() => {
                          if (pageField._id && setIsEditing) {
                            setIsEditing(pageField._id, false);
                          } else {
                            removeField(number);
                          }
                        }}
                      >
                        <Can
                          action='formTemplates.edit'
                          data={{
                            projectTemplate: currentProjectTemplate,
                            formTemplate: currentProjectFormTemplate,
                          }}
                          yes={() => <Text>Discard</Text>}
                          no={() => <Text>Close</Text>}
                        />
                      </Button>
                      <Can
                        action='formTemplates.edit'
                        data={{
                          projectTemplate: currentProjectTemplate,
                          formTemplate: currentProjectFormTemplate,
                        }}
                        yes={() => (
                          <Button
                            fontSize='sm'
                            bg='breNavy'
                            color='white'
                            h='30px'
                            fontWeight='700'
                            onClick={saveChanges}
                          >
                            Save changes
                          </Button>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      {pageField.type !== 'projectData' && (
                        <Can
                          action='formTemplates.edit'
                          data={{
                            projectTemplate: currentProjectTemplate,
                            formTemplate: currentProjectFormTemplate,
                          }}
                          yes={() => (
                            <EditIcon
                              mr='2'
                              cursor='pointer'
                              onClick={() => setIsEditing && setIsEditing(pageField._id || '', true)}
                            />
                          )}
                          no={() => (
                            <EyeIcon
                              mr='2'
                              color='white'
                              cursor='pointer'
                              onClick={() => setIsEditing && setIsEditing(pageField._id || '', true)}
                            />
                          )}
                        />
                      )}
                      <Can
                        action='formTemplates.edit'
                        data={{
                          projectTemplate: currentProjectTemplate,
                          formTemplate: currentProjectFormTemplate,
                        }}
                        yes={() => (
                          <TrashIcon
                            cursor='pointer'
                            onClick={onConfirmationOpen}
                          />
                        )}
                      />
                    </>
                  )}
                </>
              </Flex>
            </Flex>
            {isEditing && (
              <>
                <Divider
                  my={4}
                  borderColor={'blackAlpha.500'}
                  w={'100%'}
                  orientation='horizontal'
                />
                <Box>{getInputField(pageField)}</Box>
              </>
            )}
          </Box>
        </AccordionItem>
      )}
    </Draggable>
  );
};

export default TemplateField;
