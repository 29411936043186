import { createIcon } from '@chakra-ui/icons';

const HeadphoneIcon = createIcon({
  path: (
    <>
      <path
        d='M16.667 9.99977V8.57727C16.667 4.8756 13.7678 1.77643 10.2045 1.66893C8.36783 1.62643 6.66199 2.28227 5.35783 3.54893C4.71486 4.16837 4.20402 4.91162 3.85613 5.73387C3.50825 6.55611 3.33051 7.4403 3.33366 8.3331V9.99977C2.41449 9.99977 1.66699 10.7473 1.66699 11.6664V14.9998C1.66699 15.9189 2.41449 16.6664 3.33366 16.6664H5.00033V8.3331C4.99787 7.66351 5.13106 7.00036 5.39187 6.38364C5.65268 5.76693 6.0357 5.20943 6.51783 4.74477C6.99793 4.27742 7.56738 3.91173 8.19211 3.6696C8.81684 3.42747 9.484 3.31387 10.1537 3.3356C12.827 3.4156 15.0003 5.76727 15.0003 8.57727V16.6664H16.667C17.5862 16.6664 18.3337 15.9189 18.3337 14.9998V11.6664C18.3337 10.7473 17.5862 9.99977 16.667 9.99977Z'
        fill='#282F36'
      />
      <path
        d='M5.83301 10H7.49967V16.6667H5.83301V10ZM12.4997 10H14.1663V16.6667H12.4997V10Z'
        fill='black'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default HeadphoneIcon;
