import { Box, Stack, VStack } from '@chakra-ui/react';

import { IField } from '../../../interfaces/IField';
import StaticImageRenderer from './StaticImageRenderer';

const StaticImage = ({
  image: images,
  name,
  tooltip = '',
  formIndex,
}: IField) => {
  const renderPhoto = (photo: { fileId: string; fileName: string }) => {
    return (
      <StaticImageRenderer
        key={`information-photo-${photo.fileId}`}
        photo={photo}
        formIndex={formIndex}
        tooltip={tooltip}
      />
    );
  };
  return (
    <Box w='full' id={name}>
      <Stack align='left'>
        <VStack align='left'>{images?.map(renderPhoto)}</VStack>
      </Stack>
    </Box>
  );
};

export default StaticImage;
