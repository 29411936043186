import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Control } from "react-hook-form";
import { useAppContext } from "../../contexts/AppProvider";

import { SaveIcon } from "../../icons";
import Can from "../Can";
import { TextInput, Dropdown, Checkbox } from "../FormControls";

const TemplateEditModal = ({ control, isOpen, onClose, updateTemplateTitle }: {
  control: Control<any>;
  isOpen: boolean;
  onClose: () => void;
  updateTemplateTitle: () => void;
}) => {
  const { settings } = useAppContext();

  const labNumbersOptions = useMemo(() => {
    return [...(settings?.labNumber || [])].map((lab) => ({ value: lab, label: lab }));
  }, [settings?.labNumber]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight='900'>Edit template</ModalHeader>
        <ModalCloseButton />
        <ModalBody w='full'>
          <TextInput
            control={control}
            name='name'
            required={true}
            label='Template title'
            placeholder='Title'
            validations={{ notEmpty: true }}
            styles={{ fullWidth: true }}
          />
          <Dropdown
            control={control}
            name='category'
            required={true}
            label='Template category'
            validations={{ notEmpty: true }}
            styles={{ fullWidth: true }}
            options={labNumbersOptions}
          />
          <Can
            action="projectTemplates.create"
            data={{ projectTemplate: { isUkasRelated: true } }}
            yes={() => (
              <Checkbox
                control={control}
                name='isUkasRelated'
                options={[{ label: 'Template is UKAS related', value: true }]}
              />
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            mr='2'
          >
            Discard
          </Button>
          <Button
            bg='brePink'
            color='white'
            _hover={{}}
            _focus={{}}
            _active={{}}
            onClick={updateTemplateTitle}
          >
            <SaveIcon stroke='white' mr='2' />
            Update changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TemplateEditModal;
