import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useProjectsContext } from "../../contexts/ProjectsProvider";
import IAuditFilters from "../../interfaces/IAuditFilters";
import AuditLogs from "../../pages/admin/auditlogs";

const TemplateAuditLogModal = ({ isOpen, onClose }: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { currentProjectTemplate, currentProjectFormTemplate } = useProjectsContext();

  const auditLogFilters: IAuditFilters = {
    elementsIds: currentProjectTemplate ? [
      currentProjectTemplate._id,
      ...currentProjectTemplate.formTemplates.map((ft) => ft._id),
    ] : currentProjectFormTemplate ? [currentProjectFormTemplate._id] : [],
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size="full">
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader>
          <Flex justifyContent="space-between">
            <Text fontSize="14px">Audit log - {currentProjectTemplate?.name || currentProjectFormTemplate?.name}</Text>
            <Button px={3} py={0} fontSize="14px" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody p={5} mr={6} mb={6} overflowY="auto">
          <AuditLogs predefinedFilters={auditLogFilters} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TemplateAuditLogModal;
