import { createIcon } from '@chakra-ui/icons';

const LinesChecked = createIcon({
  path: (
    <path
      d='M3.33301 5.83301H12.4997V7.49967H3.33301V5.83301ZM3.33301 9.16634H12.4997V10.833H3.33301V9.16634ZM3.33301 12.4997H9.16634V14.1663H3.33301V12.4997ZM16.0822 10.243L12.4988 13.8188L11.4222 12.743L10.2438 13.9222L12.4988 16.1755L17.2588 11.423L16.0822 10.243Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default LinesChecked;
