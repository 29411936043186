import { Flex, Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Control, FieldValues, UseFormSetValue } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAppContext } from "../contexts/AppProvider";
import { useProjectsContext } from "../contexts/ProjectsProvider";

import { IFormField } from "../interfaces/IFormField";
import { IFormIteration } from "../interfaces/IFormIteration";
import { IFormRow } from "../models/reportform";
import { isPermitted } from "./Can";
import Field from "./FormControls/Field";

const TemplateSection = ({
  name,
  description,
  rows,
  formIteration,
  formIterationNumber = 0,
  savedValues,
  control,
  setValue,
}: {
  name: string;
  description: string;
  rows: IFormRow[];
  formIteration?: IFormIteration;
  formIterationNumber?: number;
  savedValues?: any;
  control?: Control<FieldValues, any>;
  setValue?: UseFormSetValue<FieldValues>;
}) => {
  const { user } = useAppContext();
  const { currentProjectTemplate } = useProjectsContext();
  const [disabledFields, setDisabledFields] = useState({});
  const { page }: { page: string } = useParams();

  const isPermittedToEdit = isPermitted({ user, action: "projectTemplates.edit", data: { projectTemplate: currentProjectTemplate } });

  const getCurrentFieldValue = (key: string) => {
    if (savedValues.current) {
      return savedValues.current[key];
    }
    return formIteration?.values[key];
  }

  return (
    <Box id={name.replace(/\s/g, "").toLowerCase()}>
      {name ? (
        <Text fontSize="md" fontWeight="bold" color="breNavy">
          {name}
        </Text>
      ) : (
        ""
      )}
      {description ? (
        <Text
          fontSize="smm"
          fontWeight="semi_medium"
          color="breNavy"
          mt="10px"
          mb="20px"
        >
          {description}
        </Text>
      ) : (
        ""
      )}
      <Box>
        {(rows || []).map((row: IFormRow, i) => (
          <Flex key={row._id + formIterationNumber} justify="flex-start" align="flex-start" width="full">
            {row.fields.map((field: IFormField) => {
              let shouldEnableField: boolean = Object.keys(field.requiredWhen || {})?.map(key => {
                return (field.requiredWhen && getCurrentFieldValue(key) === field.requiredWhen[key])
              }).every(item => !!item);

              if (setValue) {
                // setValue exist when rendering in project form
                if (!shouldEnableField && !disabledFields[field.name]) {
                  setDisabledFields((prevState) => ({ ...prevState, [field.name]: "N/A" }));
                  setValue(field.name, "N/A");
                }

                if (shouldEnableField && disabledFields[field.name] && disabledFields[field.name] !== "") {
                  setDisabledFields((prevState) => ({ ...prevState, [field.name]: "" }));
                  setValue(field.name, "");
                }
              }

              return (
                <Box
                  mb="10px"
                  mr="15px"
                  width={row.fields.length > 1 ? "360px" : "full"}
                  minH="90px"
                  key={field._id! + formIterationNumber}
                  display={shouldEnableField ? "" : "none"}
                >
                  <Field
                    control={control}
                    field={{
                      ...field,
                      disabled: formIteration?.status === "completed" || !isPermittedToEdit,
                      placeholder: field.type === 'dropdown' ? 'Select' : field.placeholder,
                    }}
                    formIndex={page}
                  />
                </Box>
              )
            })}
            { }
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default TemplateSection;
