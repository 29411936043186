import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { Checkbox, Dropdown, TextInput } from '../../components/FormControls';
import { SaveIcon } from '../../icons';
import CREATE_PROJECT_TEMPLATE from '../../gql/mutation/CREATE_PROJECT_TEMPLATE';
import GET_PROJECT_TEMPLATES_CATEGORIES from '../../gql/queries/GET_PROJECT_TEMPLATES_CATEGORIES';
import { useAppContext } from '../../contexts/AppProvider';
import Can from '../Can';
import { useMemo } from 'react';

const NewTemplateModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const history = useHistory();
  const { refetch } = useQuery(GET_PROJECT_TEMPLATES_CATEGORIES);
  const { settings } = useAppContext();
  const [createProjectTemplate] = useMutation(CREATE_PROJECT_TEMPLATE);

  const {
    control,
    getValues,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      category: '',
      isUkasRelated: [],
    },
  });

  const submitTemplate = async () => {
    if (!isValid) {
      toast({ ...toastFailed, description: 'Please fill all the fields' });
      return;
    }
    let values = getValues();
    if (Boolean(values.name.length)) {
      let { data } = await createProjectTemplate({
        variables: {
          projectTemplateInput: {
            ...values,
            isUkasRelated: values.isUkasRelated[0] || false,
          },
        },
      });
      toast({ ...toastSuccess, description: 'Template created successfully' });
      await refetch();
      onClose();
      history.push(`/admin/templates/${data.createProjectTemplate._id}`);
    } else {
      toast({ ...toastFailed, description: 'Fill all the fields' });
    }
    reset({});
  };

  const closeCreateTemplateModal = () => {
    onClose();
    reset({});
  };

  const labNumbersOptions = useMemo(() => {
    return [...(settings?.labNumber || [])].map((lab) => ({ value: lab, label: lab }));
  }, [settings?.labNumber]);

  return (
    <Modal onClose={closeCreateTemplateModal} isOpen={isOpen} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight='900'>Create new template</ModalHeader>
        <ModalCloseButton />
        <ModalBody w='full'>
          <TextInput
            control={control}
            name='name'
            required={true}
            label='Template title'
            placeholder='Title'
            validations={{ notEmpty: true }}
            styles={{ fullWidth: true }}
          />
          <Dropdown
            control={control}
            name='category'
            required={true}
            label='Template category'
            validations={{ notEmpty: true }}
            styles={{ fullWidth: true }}
            options={labNumbersOptions}
            placeholder="Select template"
          />
          <Can
            action="projectTemplates.create"
            data={{ projectTemplate: { isUkasRelated: true } }}
            yes={() => (
              <Checkbox
                control={control}
                name='isUkasRelated'
                options={[{ label: 'Template is UKAS related', value: true }]}
              />
            )}
          />
          {/* {displayCopyFromDropDown && (
        <SearchableDropdown
          control={control}
          name='copyFrom'
          placeholder='Select Template'
          options={categories?.map((category) => {
            return {
              label: category.name,
              value: category._id,
            };
          })}
        />
      )} */}
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeCreateTemplateModal} mr='2'>
            Discard
          </Button>
          <Button
            onClick={submitTemplate}
            bg='brePink'
            color='white'
            _hover={{}}
            _focus={{}}
            _active={{}}
          >
            <SaveIcon stroke='white' mr='2' />
            Create template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewTemplateModal;
