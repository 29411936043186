import { gql } from '@apollo/client';

const DELETE_PROJECT_TEMPLATE = gql`
  mutation ($deleteProjectTemplateInput: DeleteProjectTemplateInput!) {
    deleteProjectTemplate(deleteProjectTemplateInput: $deleteProjectTemplateInput) {
      _id
    }
  }
`;

export default DELETE_PROJECT_TEMPLATE;
