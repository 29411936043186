import { createIcon } from "@chakra-ui/icons";

const PostTestIcon = createIcon({
  displayName: "PostTest",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M12.5611 0.583252H1.43887C0.966358 0.583252 0.583313 0.966297 0.583313 1.43881V12.561C0.583313 13.0335 0.966358 13.4166 1.43887 13.4166H12.5611C13.0336 13.4166 13.4166 13.0335 13.4166 12.561V1.43881C13.4166 0.966297 13.0336 0.583252 12.5611 0.583252Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.56664 6.14439C10.5117 6.14439 11.2778 5.3783 11.2778 4.43328C11.2778 3.48826 10.5117 2.72217 9.56664 2.72217C8.62162 2.72217 7.85553 3.48826 7.85553 4.43328C7.85553 5.3783 8.62162 6.14439 9.56664 6.14439Z"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.41479 8.68759C2.99833 8.35503 3.64668 8.15215 4.3157 8.09277C4.98471 8.03338 5.65867 8.1189 6.29165 8.34348C6.92463 8.56806 7.50178 8.92643 7.98377 9.39419C8.46575 9.86194 8.84126 10.4281 9.08471 11.0541"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default PostTestIcon;
