import { createIcon } from '@chakra-ui/icons';

const Arrow = createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 11 11',
  path: (
    <>
      <path d="M1.33398 10.3115L10.4599 1.3335" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.459 6.46422V1.3335H5.24414" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default Arrow;
