import { createIcon } from "@chakra-ui/icons";

const PersonIcon = createIcon({
  path: (
    <>
      <path 
        fill="none" 
        d="M2.07495 3.07502C2.07495 3.58557 2.27776 4.0752 2.63877 4.4362C2.99978 4.79721 3.48941 5.00002 3.99995 5.00002C4.51049 5.00002 5.00012 4.79721 5.36113 4.4362C5.72214 4.0752 5.92495 3.58557 5.92495 3.07502C5.92495 2.56448 5.72214 2.07485 5.36113 1.71384C5.00012 1.35284 4.51049 1.15002 3.99995 1.15002C3.48941 1.15002 2.99978 1.35284 2.63877 1.71384C2.27776 2.07485 2.07495 2.56448 2.07495 3.07502V3.07502Z" 
        stroke="currentColor" 
        strokeLinecap="round" 
        strokeLinejoin="round"
        />
      <path 
        fill="none" d="M7.07242 7.70926C6.78574 7.14022 6.34668 6.66197 5.80417 6.32781C5.26165 5.99365 4.637 5.81671 3.99983 5.81671C3.36266 5.81671 2.73801 5.99365 2.1955 6.32781C1.65298 6.66197 1.21392 7.14022 0.927246 7.70926" 
        stroke="currentColor" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </>
  ),
  viewBox: "0 0 8 9",
});

export default PersonIcon;
