import { createIcon } from '@chakra-ui/icons';

const PencilIcon = createIcon({
  path: (
    <path
      d='M3.33288 17.4996C3.3993 17.5076 3.46645 17.5076 3.53288 17.4996L6.86621 16.6662C7.01408 16.6311 7.14952 16.5561 7.25788 16.4496L17.4995 6.17455C17.81 5.86228 17.9842 5.43986 17.9842 4.99955C17.9842 4.55924 17.81 4.13682 17.4995 3.82455L16.1829 2.49955C16.0281 2.34459 15.8443 2.22166 15.6419 2.13779C15.4396 2.05391 15.2227 2.01074 15.0037 2.01074C14.7847 2.01074 14.5678 2.05391 14.3655 2.13779C14.1631 2.22166 13.9793 2.34459 13.8245 2.49955L3.58288 12.7412C3.4752 12.8501 3.39767 12.985 3.35788 13.1329L2.52454 16.4662C2.49459 16.574 2.48666 16.6868 2.50123 16.7978C2.5158 16.9087 2.55257 17.0156 2.60934 17.112C2.66612 17.2085 2.74173 17.2925 2.83168 17.3591C2.92163 17.4256 3.02406 17.4734 3.13288 17.4996C3.1993 17.5076 3.26645 17.5076 3.33288 17.4996ZM14.9995 3.67455L16.3245 4.99955L14.9995 6.32455L13.6829 4.99955L14.9995 3.67455ZM4.92454 13.7579L12.4995 6.17455L13.8245 7.49955L6.24121 15.0829L4.48288 15.5162L4.92454 13.7579Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 20 20',
});

export default PencilIcon;
