import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import JSONfn from 'json-fn';

import { useAppContext } from "../contexts/AppProvider";
import { IRoles } from "../interfaces/IRoles";
import GET_SETTINGS from "../gql/queries/GET_SETTINGS";

const USERS = gql`
  query {
    session {
      user {
        _id
        displayName
        email
        jobTitle
        imgUrl
        groupId
        role
      }
    }
  }
`;
const ROLES = gql`
  query {
    roles
  }
`;

const useAuth = () => {
  const { loading, data, error } = useQuery(USERS);
  const { data: rolesData } = useQuery(ROLES);
  const { data: settingsData, refetch: refetchSettings } = useQuery(GET_SETTINGS, {
    variables: {
      settingSearchQuery: {
        groupId: data?.session?.user?.groupId || '',
      },
    },
    skip: !data?.session?.user,
  });
  const { setUser, setRoles, setSettings } = useAppContext();

  useEffect(() => {
    if (data) {
      setUser(data.session.user);
      refetchSettings();
    }
  }, [data?.session.user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (settingsData && settingsData.settings) {
      setSettings(settingsData.settings);
    }
  }, [settingsData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rolesData) {
      const parsedRoles = JSONfn.parse(rolesData.roles) as IRoles;
      globalThis.roles = parsedRoles;
      setRoles(parsedRoles);
    }
  }, [JSON.stringify(rolesData)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error?.message) {
      setUser(null);
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading;
};

export default useAuth;
