import { gql } from '@apollo/client';

const GET_COMMON_FORMS = gql`
  query ($formTemplatesQueryInput: FormTemplatesQueryInput) {
    getFormTemplates(formTemplatesQueryInput: $formTemplatesQueryInput) {
      _id
      name
      metatags {
        addedAt
        updatedAt
      }
    }
  }
`;

export default GET_COMMON_FORMS;
