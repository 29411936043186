import { createIcon } from '@chakra-ui/icons';

const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 12 12',
  path: (
    <>
      <path
        d='M5.85244 0.814551V11.1854'
        stroke='#F0F2F5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.666992 6H11.0379'
        stroke='#F0F2F5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </>
  ),
});

export default PlusIcon;
