import { createIcon } from '@chakra-ui/icons';

const BriefcaseIcon = createIcon({
  path: (
    <path
      d='M16.667 5.00033H14.167V3.33366C14.167 2.41449 13.4195 1.66699 12.5003 1.66699H7.50033C6.58116 1.66699 5.83366 2.41449 5.83366 3.33366V5.00033H3.33366C2.41449 5.00033 1.66699 5.74783 1.66699 6.66699V15.8337C1.66699 16.7528 2.41449 17.5003 3.33366 17.5003H16.667C17.5862 17.5003 18.3337 16.7528 18.3337 15.8337V6.66699C18.3337 5.74783 17.5862 5.00033 16.667 5.00033ZM12.5003 3.33366V5.00033H7.50033V3.33366H12.5003ZM6.66699 6.66699H16.667V9.16699H3.33366V6.66699H6.66699ZM3.33366 15.8337V10.8337H8.33366V12.5003H11.667V10.8337H16.667L16.6678 15.8337H3.33366Z'
      fill='currentColor'
    />
  ),
  viewBox: '0 0 20 20',
});

export default BriefcaseIcon;
