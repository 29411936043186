import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  HStack,
  AvatarGroup,
  Tooltip,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { IProject } from '../../interfaces/IProject';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import { useSearchContext } from '../../contexts/SearchProvider';
import ISearch from '../../interfaces/ISearch';
import Arrow from '../../icons/Arrow';
import { useAppContext } from '../../contexts/AppProvider';
import { isPermitted } from '../Can';

const ProjectCard = ({ project, setupProject }: {
  project: IProject;
  setupProject: (projectId: string) => void;
}) => {
  const { settings, user } = useAppContext();
  const history = useHistory();
  const { setCurrentProject } = useProjectsContext();
  const { setSearch } = useSearchContext();

  const spLink = `${settings?.spSiteUrl}/${settings?.spDocumentLibrary}/Forms/AllItems.aspx?id=${settings?.spClientFolder}/${project.clientName.replace(/\//g, ' ')}/${project.reference}`;
  const isPermittedToStartProject = isPermitted({ user, action: 'projects.start', data: { project } });

  return (
    <Box
      cursor={project.status !== 'Not started' || isPermittedToStartProject ? 'pointer' : 'default'}
      onClick={() => {
        if (project.status === 'Not started') {
          if (isPermittedToStartProject) {
            setupProject(project._id);
          }
        } else {
          setCurrentProject(null);
          setSearch((state: ISearch) => ({
            ...state,
            value: '',
          }));
          history.push(`/project/${project?._id}/1`);
        }
      }}
      boxShadow='sm'
      bg='white'
      rounded='6px'
      w='335px'
      h='230px'
      flexShrink={0}
      p={3}
      mr={4}
      my={2}
      color='breNavy'
    >
      <Flex
        direction='column'
        bg='breNavy'
        color='white'
        rounded='6px'
        px={4}
        py={3}
      >
        <Text fontSize='ssm' isTruncated textOverflow='ellipsis'>
          {project?.clientName}
        </Text>
        <Text
          fontSize='smm'
          fontWeight='bold'
          isTruncated
          textOverflow='ellipsis'
        >
          {project?.reference}
        </Text>
      </Flex>
      <Stack px={3} py={2}>
        <HStack h='50px'>
          <Box w='50%'>
            <Box opacity='0.6' fontSize='sm'>
              Lab number
            </Box>
            <Text fontSize='smm' isTruncated textOverflow='ellipsis'>
              {project?.projectTemplate?.category || 'TBC'}
            </Text>
          </Box>
          <Box w='50%'>
            <Box opacity='0.6' fontSize='sm'>
              Test type
            </Box>
            <Text fontSize='smm' isTruncated textOverflow='ellipsis'>
              {project?.projectTemplate?.name || 'TBC'}
            </Text>
          </Box>
        </HStack>
        <HStack h='50px'>
          <Box w='50%'>
            <Box opacity='0.6' fontSize='sm'>
              Project team
            </Box>
            <AvatarGroup size='xs' max={5}>
              {[project?.projectLead, ...(project?.testers || []), ...(project?.trainees || [])].map(person => (
                <Box as={Avatar} key={person?._id}>
                  <Tooltip hasArrow label={person?.displayName}>
                    <Avatar size='xs' key={person?._id} name={person?.displayName} src={person?.imgUrl} showBorder={false} />
                  </Tooltip>
                </Box>
              ))}
            </AvatarGroup>
          </Box>
          <Box w='50%'>
            <Box opacity='0.6' fontSize='sm'>
              Status
            </Box>
            <Text
              fontSize='smm'
              fontWeight='bold'
              isTruncated
              textOverflow='ellipsis'
              color={
                project.status === 'Completed'
                  ? 'breSuccess'
                  : project.status === 'Not started'
                    ? 'breAmber'
                    : 'breNavy'
              }
            >
              {project?.status}
            </Text>
          </Box>
        </HStack>
        {settings?.spClientFolder && (
          <HStack
            color='breBlue'
            fontSize='smm'
            alignItems='center'
            _hover={{ textDecoration: 'underline' }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(spLink, '_blank');
            }}
          >
            <Arrow />
            <Text>Open in SharePoint</Text>
          </HStack>
        )}
      </Stack>
    </Box>
  );
};

export default ProjectCard;
