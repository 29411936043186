import { createIcon } from "@chakra-ui/icons";

const BuildIcon = createIcon({
  displayName: "Build",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path d="M9.33022 9.13373C9.53637 9.13373 9.73408 9.05184 9.87985 8.90606C10.0256 8.76029 10.1075 8.56258 10.1075 8.35643V2.13688L8.12406 2.14144C8.03613 2.14288 7.95043 2.11376 7.88158 2.05905C7.81274 2.00435 7.765 1.92744 7.74653 1.84147C7.67773 1.48722 7.48783 1.16793 7.20939 0.938365C6.93095 0.708799 6.58131 0.583252 6.22044 0.583252C5.85957 0.583252 5.50993 0.708799 5.23149 0.938365C4.95305 1.16793 4.76315 1.48722 4.69435 1.84147C4.67562 1.92763 4.62758 2.00463 4.55842 2.05934C4.48927 2.11404 4.40327 2.14306 4.31511 2.14144H2.33336V3.73824C2.33201 3.82621 2.36115 3.91194 2.41583 3.98086C2.47051 4.04978 2.54736 4.09765 2.63333 4.11634C2.98759 4.18515 3.30687 4.37505 3.53644 4.65349C3.76601 4.93193 3.89155 5.28156 3.89155 5.64243C3.89155 6.00331 3.76601 6.35294 3.53644 6.63138C3.30687 6.90982 2.98759 7.09972 2.63333 7.16852C2.54736 7.187 2.47046 7.23473 2.41575 7.30358C2.36105 7.37243 2.33193 7.45813 2.33336 7.54605V9.14286H4.44343"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3656 13.4165L11.4226 12.7766C11.4602 12.3624 11.3457 11.9487 11.1004 11.6128C10.8551 11.277 10.4958 11.0419 10.0898 10.9517L7.71059 10.4219V7.00021C7.71059 6.77333 7.62047 6.55575 7.46004 6.39533C7.29962 6.2349 7.08204 6.14478 6.85516 6.14478C6.62829 6.14478 6.41071 6.2349 6.25028 6.39533C6.08986 6.55575 5.99973 6.77333 5.99973 7.00021V12.5605L5.03024 11.8317C4.862 11.7053 4.65381 11.6439 4.44393 11.6588C4.23405 11.6736 4.03657 11.7637 3.88779 11.9125C3.73901 12.0613 3.64892 12.2587 3.63408 12.4686C3.61923 12.6785 3.68062 12.8867 3.80698 13.0549L4.07729 13.4159"
        fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default BuildIcon;
