import { gql } from '@apollo/client';

const UPDATE_FORM_TEMPLATE = gql`
  mutation ($formTemplateInput: FormTemplateInput!, $operationType: String!) {
    updateFormTemplate(formTemplateInput: $formTemplateInput, operationType: $operationType) {
      _id
    }
  }
`;

export default UPDATE_FORM_TEMPLATE;
