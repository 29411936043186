import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useProjectsContext } from "../contexts/ProjectsProvider";

const UPDATE_FORM = gql`
mutation ($values: FormModifyInput!) {
  updateForm(formModifyInput: $values) {
    _id
  }
}
`;

const useUpdateForm = () => {
  const { setAutoSaving } = useProjectsContext();
    const [updateFunction, { error, loading }] = useMutation(UPDATE_FORM);

    useEffect(() => {
      if (!loading) {
        setAutoSaving(false);
      }
    }, [loading, setAutoSaving])
    return { updateFunction, error, loading };
}

export default useUpdateForm;
