import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useProjectsContext } from '../contexts/ProjectsProvider';

const GET_FORMS = gql`
query ($values: SearchFormInput!){
  getForms(searchFromInput: $values){
    _id
    name
    icon
    index
    allowMultipleIterations
    iterations {
      status
      values
    }
    pdfVersion
  }
}
`

const useGetFormStatuses = (projectId?: string) => {
  const { setFormStates } = useProjectsContext();
  const { data: formData, loading: formLoading, error: formError, refetch } = useQuery(GET_FORMS, {
    variables: { values: { projectId } },
  });

  useEffect(() => {
    // data changes, update forms
    if (formData) {
      setFormStates(formData.getForms);
    }
  }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

  return { formData, formLoading, formError, refetch };
}

export default useGetFormStatuses;
