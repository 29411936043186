import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Tooltip,
  Icon,
  InputGroup,
  InputRightElement,
  Text,
  Divider,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

import { IField } from '../../interfaces/IField';
import { ArrowRight, Asterisk, CrossIcon } from '../../icons';
import { DefinedValidations } from '../../interfaces/Validations';
import useValidate from '../../hooks/useValidate';

interface ISearchableDropdown extends IField {
  groupedBy?: {
    groupBy: string;
    value: string;
    label: string;
  };
  styles?: {
    fullWidth?: boolean;
    width?: string;
  };
  placeholder?: string;
  onChange?: (value: string) => void;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
};

const SearchableDropdown = ({
  control,
  name,
  label,
  placeholder = '',
  tooltip = '',
  validations = {},
  disabled,
  options,
  groupedBy,
  styles
}: ISearchableDropdown) => {
  const [showResults, setShowResults] = useState<Boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const validate = useValidate(
    label || name,
    validations || {},
    definedValidations
  );

  useEffect(() => {
    if (options) {
      let updatedOptions = options;

      if (groupedBy && searchText) {
        updatedOptions = options
          .map((opt) => ({
            ...opt,
            [groupedBy.groupBy]: opt[groupedBy.groupBy].filter((el) =>
              el[groupedBy.label]
                ?.toLowerCase()
                ?.includes(searchText.toLowerCase())
            ),
          }))
          .filter((obj) => obj.templates.length > 0);
      } else if (!groupedBy && searchText) {
        updatedOptions = options.filter((option) =>
          option.label.toLowerCase().includes(searchText.toLowerCase())
        );
      }

      setFilteredOptions(updatedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, searchText]);

  const displayResults = (onChange: (...event: any[]) => void) => {
    if (groupedBy) {
      return (
        <Flex
          position='absolute'
          bg='peoplePicker.bg'
          direction='column'
          boxShadow='lg'
          rounded='lg'
          zIndex={3}
          maxH='250px'
          overflowY='auto'
          w='100%'
          py='3'
        >
          {Boolean(filteredOptions?.length) ? (
            filteredOptions?.map((option) => (
              <Box
                key={option[groupedBy.value]}
                fontWeight='400'
                color='breBlack'
                fontSize='md'
              >
                <Flex alignItems='center'>
                  <Text
                    mx='3'
                    opacity={0.3}
                    w='fit-content'
                    whiteSpace='nowrap'
                  >
                    {(groupedBy ? option[groupedBy.value] : option.label) || 'Default'}
                  </Text>
                  <Divider
                    w='full'
                    borderColor={'blackAlpha.300'}
                    orientation='horizontal'
                  />
                </Flex>
                {option[groupedBy.groupBy].length > 0 &&
                  option[groupedBy.groupBy]?.map((el) => (
                    <Flex
                      alignItems='center'
                      my='1'
                      h='33px'
                      key={el[groupedBy.value]}
                      _hover={{
                        cursor: 'pointer',
                        bg: 'peoplePicker.hover.bg',
                      }}
                      onClick={() => {
                        setSearchText(el[groupedBy.label]);
                        onChange(() => {
                          onChange({
                            target: { name, value: el[groupedBy.value] },
                          });
                        });
                        setShowResults(false);
                      }}
                    >
                      <Text ml='3'>{el[groupedBy.label]}</Text>
                    </Flex>
                  ))}
              </Box>
            ))
          ) : (
            <Flex align='center' fontStyle='italic' pl={5} h='35px'>
              No results found
            </Flex>
          )}
        </Flex>
      );
    }
    return (
      <Flex
        position='absolute'
        bg='peoplePicker.bg'
        direction='column'
        boxShadow='lg'
        rounded='lg'
        zIndex={3}
        maxH='150px'
        overflowY='auto'
        w='100%'
      >
        {Boolean(filteredOptions?.length) ? (
          filteredOptions?.map((option) => (
            <Flex
              key={option.value}
              onClick={() => {
                setSearchText(option.label);
                onChange(() => {
                  onChange({ target: { name, value: option.value } });
                });
                setShowResults(false);
              }}
              pl={3}
              w='full'
              h='50px'
              fontWeight='400'
              rounded='md'
              align='center'
              justify='flex-start'
              color='peoplePicker.font'
              role='group'
              _hover={{
                cursor: 'pointer',
                bg: 'peoplePicker.hover.bg',
              }}
            >
              {groupedBy ? option._id : option.label}
            </Flex>
          ))
        ) : (
          <Flex align='center' fontStyle='italic' pl={5} h='35px'>
            No results found
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange } = field;
        const { error } = fieldState;

        return (
          <Box w='full' maxW={styles?.fullWidth ? '1109px' : styles?.width ? styles?.width : '360px'} id={name} mt='3px' position='relative'>
            <Box>
              {label && (
                <Flex
                  pt={2}
                  pb={1}
                  align='center'
                  justify='space-between'
                  mb='none'
                >
                  <Box
                    color={
                      error
                        ? 'peoplePicker.labelFont.error'
                        : 'peoplePicker.labelFont.normal'
                    }
                    fontWeight='bold'
                    fontSize={11}
                    position='static'
                    left='none'
                    zIndex={2}
                  >
                    {label}
                    {!disabled && validations?.notEmpty && (
                      <Asterisk
                        h='8px'
                        ml='5px'
                        mb='8px'
                        fill='textInput.iconAsterisk'
                        stroke='textInput.iconAsterisk'
                      />
                    )}{' '}
                    {tooltip && (
                      <Tooltip hasArrow label={tooltip} placement='top'>
                        <Icon name='info' mb={1} h='14px' />
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              )}
              <InputGroup>
                <Input
                  _active={{
                    bg: disabled
                      ? 'peoplePicker.disabled.bg'
                      : 'peoplePicker.activeBg',
                  }}
                  _focus={{
                    borderColor: error
                      ? 'peoplePicker.border.focus.error'
                      : 'peoplePicker.border.focus.normal',
                  }}
                  fontSize='smm'
                  borderRadius='8px'
                  autoComplete='none'
                  borderWidth='1px'
                  color={
                    disabled
                      ? 'peoplePicker.disabled.font'
                      : 'peoplePicker.font'
                  }
                  bg='peoplePicker.bg'
                  borderColor={
                    error
                      ? 'peoplePicker.border.error'
                      : disabled
                        ? 'peoplePicker.disabled.border'
                        : 'peoplePicker.border.normal'
                  }
                  h='42px'
                  mb={0}
                  zIndex={2}
                  value={searchText}
                  isDisabled={disabled}
                  _disabled={{ cursor: 'not-allowed', bg: '#f7f7f7' }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onBlur={() => setTimeout(() => setShowResults(false), 200)}
                  onFocus={() => setShowResults(true)}
                  placeholder={placeholder}
                  _placeholder={{}}
                />
                {!disabled && (
                  <InputRightElement
                    w='32px'
                    mt='2px'
                    justifyContent='flex-end'
                    cursor='pointer'
                    children={
                      <Flex
                        bg='#FFF'
                        mr='1px'
                        onClick={() => setShowResults(!showResults)}
                      >
                        {searchText && (
                          <CrossIcon
                            onClick={() => {
                              setSearchText('');
                              onChange({ target: { name, value: '' } });
                              setSearchText('');
                            }}
                            stroke='brePink'
                            transform='rotate(90deg)'
                          />
                        )}
                        <ArrowRight
                          mx={2}
                          stroke='peoplePicker.icon'
                          transform='rotate(90deg)'
                        />
                      </Flex>
                    }
                  />
                )}
              </InputGroup>
              {showResults && displayResults(onChange)}
              {error && (
                <Box fontSize='smm' pl={3} mt={1} color='peoplePicker.error'>
                  {error.message}
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default SearchableDropdown;
