import { theme } from "@chakra-ui/react";
import { adminModalStyles } from "../components/AdminModal";
import { amslerChecklistTableStyles } from "../components/FormControls/AmslerChecklistTable/AmslerChecklistTable";
import { amslerChecklistTableRowStyles } from "../components/FormControls/AmslerChecklistTable/AmslerChecklistTableRow";
import { clientContractorTableStyles } from "../components/FormControls/ClientContractorTable/ClientContractorTable";
import { clientContractorTableRowStyles } from "../components/FormControls/ClientContractorTable/ClientContractorTableRow";
import { datepickerStyles } from "../components/FormControls/Datepicker";
import { dropdownStyles } from "../components/FormControls/Dropdown";
import { freeHandInputStyles } from "../components/FormControls/Drawing/FreeHandInput";
import { imageCaptureStyles } from "../components/FormControls/Photos/ImageCapture";
import { minutesObservationTableStyles } from "../components/FormControls/MinutesObservationTable/MinutesObservationTable";
import { minutesObservationTableRowStyles } from "../components/FormControls/MinutesObservationTable/MinutesObservationTableRow";
import { minutesTableStyles } from "../components/FormControls/MinutesTable/MinutesTable";
import { minutesTableRowStyles } from "../components/FormControls/MinutesTable/MinutesTableRow";
import { peoplePickerStyles } from "../components/FormControls/PeoplePicker";
import { signatureApprovalStyles } from "../components/FormControls/SignatureApproval";
import { tableDataStyles } from "../components/FormControls/TableData";
import { tableStyles } from "../components/FormControls/TableHeader";
import { techSignatureTableStyles } from "../components/FormControls/TechSignatureTable/TechSignatureTable";
import { techSignatureTableRowStyles } from "../components/FormControls/TechSignatureTable/TechSignatureTableRow";
import { textInputStyles } from "../components/FormControls/TextInput";
import { toggleStyles } from "../components/FormControls/Toggle";
import { navigationLeftStyles } from "../components/NavigationLeft/NavigationLeft";
import { peerReviewTableStyles } from "../components/FormControls/PeerReviewTable/PeerReviewTable";
import { peerReviewTableRowStyles } from "../components/FormControls/PeerReviewTable/PeerReviewTableRow";
import { projectFormLeftItemItemStyles } from "../components/NavigationLeft/ProjectLeftItem";
import { projectSubSectionStyles } from "../components/NavigationLeft/ProjectSubSection";
import { userMenuStyles } from "../components/NavigationTop/UserMenu";
import { markAsCompleteModalStyles } from "../components/ProjectForm/MarkAsCompleteModal";
import { projectFormHeaderStyles } from "../components/ProjectForm/ProjectHeader/ProjectFormHeader";
import { logoutPageStyles } from "../pages/logout";
import { sortButtonStyles } from "../components/SortButton";
import { filtersPanelStyles } from "../components/Filters/FiltersPanel";
import { filtersPanelItemStyles } from "../components/Filters/FiltersPanelItem";
import { templateContentStyles } from "../components/TemplateBuilder/TemplateContent";
import { removableAvatarStyles } from "../components/RemovableAvatar";
import { auditLogStyles } from "../components/AuditLog/AuditLog";
import { auditLogDayStyles } from "../components/AuditLog/AuditLogDay";
import { auditLogRecordStyles } from "../components/AuditLog/AuditLogRecord";
import { signatureModalStyles } from "../components/FormControls/SignatureModal";

// http://chir.ag/projects/name-that-color - Get color names
// https://smart-swatch.netlify.app/#462AC4 - Get color schemes

const customTheme: any = {
  ...theme,
  breakpoints: {
    tablet: "768px",
    desktop: "1280px"
  },
  shadows: {
    ...theme.shadows,
    outline: "none",
  },
  fonts: {
    ...theme.fonts,
    body: "Neue Haas Grotesk Disp W01_45L, Neue Haas Grotesk Disp W01_65M, sans-serif",
    heading: "RecifeTextWeb-Light, sans-serif",
    mono: "'Lato', sans-serif",
  },
  fontSizes: {
    ...theme.fontSizes,
    xs: "10px",
    ssm: "11px",
    sm: "12px",
    smm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    xxl: "24px",
  },
  fontWeights: {
    ...theme.fontWeights,
    normal: 300,
    semi_medium: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    ...theme.colors,
    ...adminModalStyles,
    ...amslerChecklistTableStyles,
    ...amslerChecklistTableRowStyles,
    ...auditLogDayStyles,
    ...auditLogRecordStyles,
    ...auditLogStyles,
    ...clientContractorTableStyles,
    ...clientContractorTableRowStyles,
    ...datepickerStyles,
    ...dropdownStyles,
    ...freeHandInputStyles,
    ...imageCaptureStyles,
    ...logoutPageStyles,
    ...markAsCompleteModalStyles,
    ...minutesObservationTableStyles,
    ...minutesObservationTableRowStyles,
    ...minutesTableStyles,
    ...minutesTableRowStyles,
    ...navigationLeftStyles,
    ...peerReviewTableStyles,
    ...peerReviewTableRowStyles,
    ...peoplePickerStyles,
    ...projectFormHeaderStyles,
    ...projectFormLeftItemItemStyles,
    ...projectSubSectionStyles,
    ...removableAvatarStyles,
    ...signatureApprovalStyles,
    ...templateContentStyles,
    ...techSignatureTableStyles,
    ...techSignatureTableRowStyles,
    ...textInputStyles,
    ...tableStyles,
    ...tableDataStyles,
    ...toggleStyles,
    ...userMenuStyles,
    ...sortButtonStyles,
    ...filtersPanelStyles,
    ...filtersPanelItemStyles,
    ...signatureModalStyles,
    admin: {
      dividerButton: {
        color: '#7C7C8F',
        hoverColor: '#FFF',
        bg: '#E8E8F8',
        bgHover: '#131535',
        draggableButtonBg: '#5C586F',
      },
      rows: {
        bg: '#F7F7FB',
        iconStroke: '#817BA4'
      },
      badge: {
        bg: '#F4F3F5'
      }
    },
    breBlack: "#1E1836",
    breWhite: "#FFF",
    breNavy: "#131535",
    brePink: "#DC0043",
    breGray: '#1E18360A',
    breDarkGray: '#787486',
    breGreen: "#41B916",
    breLightGray: "#f3f3f6",
    breSuccess: "#41B916",
    breFail: "#A2171E",
    breAmber: "#FF9A00",
    breBlue: "#4C9AE2",
    opaqueWhite: 'rgba(255,255,255,0.7)',
    underlineColor: '#462AC4',
    homePage: {
      button: {
        bg: "#A2171E",
        hoverBg: "#CC242D",
      },
    },
    circularProgress: {
      text: "#9A9EA1",
      progress: "#A2171E",
    },
    layout: {
      bg: "#E5E5E5",
    },
    dashboardFilters: {
      active: "#FFFFFF",
      inActive: "#1F1F1F",
    },
  }
};

export default customTheme;
