import { createIcon } from '@chakra-ui/icons';

const InfinityIcon = createIcon({
  path: (
    <>
      <path
        d='M3.69366 12.3863C4.81296 12.3863 5.72033 11.479 5.72033 10.3597C5.72033 9.24038 4.81296 8.33301 3.69366 8.33301C2.57436 8.33301 1.66699 9.24038 1.66699 10.3597C1.66699 11.479 2.57436 12.3863 3.69366 12.3863Z'
        stroke='currentColor'
        fill='none'
      />
      <path
        d='M10.0003 12.3863C11.1196 12.3863 12.027 11.479 12.027 10.3597C12.027 9.24038 11.1196 8.33301 10.0003 8.33301C8.881 8.33301 7.97363 9.24038 7.97363 10.3597C7.97363 11.479 8.881 12.3863 10.0003 12.3863Z'
        stroke='currentColor'
        fill='none'
      />
      <path
        d='M16.3069 12.3863C17.4262 12.3863 18.3336 11.479 18.3336 10.3597C18.3336 9.24038 17.4262 8.33301 16.3069 8.33301C15.1876 8.33301 14.2803 9.24038 14.2803 10.3597C14.2803 11.479 15.1876 12.3863 16.3069 12.3863Z'
        stroke='currentColor'
        fill='none'
      />
      <path d='M5.7207 10.3594H7.9733' stroke='currentColor' />
      <path d='M12.0273 10.3594H14.2799' stroke='currentColor' />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default InfinityIcon;
