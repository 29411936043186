import { createIcon } from '@chakra-ui/icons';

const PencilBoxIcon = createIcon({
  path: (
    <>
      <path
        d='M5.83301 14.1778L9.51051 14.1653L17.5372 6.21533C17.8522 5.90033 18.0255 5.48199 18.0255 5.03699C18.0255 4.59199 17.8522 4.17366 17.5372 3.85866L16.2155 2.53699C15.5855 1.90699 14.4863 1.91033 13.8613 2.53449L5.83301 10.4862V14.1778ZM15.0372 3.71533L16.3613 5.03449L15.0305 6.35283L13.7088 5.03199L15.0372 3.71533ZM7.49967 11.1812L12.5247 6.20366L13.8463 7.52533L8.82217 12.5012L7.49967 12.5053V11.1812Z'
        fill='currentColor'
      />
      <path
        d='M4.16667 17.5H15.8333C16.7525 17.5 17.5 16.7525 17.5 15.8333V8.61L15.8333 10.2767V15.8333H6.79833C6.77667 15.8333 6.75417 15.8417 6.7325 15.8417C6.705 15.8417 6.6775 15.8342 6.64917 15.8333H4.16667V4.16667H9.8725L11.5392 2.5H4.16667C3.2475 2.5 2.5 3.2475 2.5 4.16667V15.8333C2.5 16.7525 3.2475 17.5 4.16667 17.5Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default PencilBoxIcon;
