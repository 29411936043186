import { Flex, HStack } from '@chakra-ui/react';

import { useFiltersContext } from '../../contexts/FiltersProvider';
import Filter from '../../icons/Filter';

const FilterButton = () => {
  const { showFiltersPanel, setShowFiltersPanel } = useFiltersContext();
  return (
    <HStack
      align="center"
      bg="breNavy"
      rounded="6px"
      color="brand.primaryFont"
      cursor="pointer"
      flexShrink={0}
      fontSize="smm"
      h="34px"
      justify="space-between"
      onClick={() => setShowFiltersPanel(!showFiltersPanel)}
      p={4}
    >
      <Flex
        color="white"
        fontSize="smm"
        fontWeight="semi_medium"
      >
        Filters
      </Flex>
      <Filter h="18px" />
    </HStack>
  );
};

export default FilterButton;
