import { Box, Button, Flex } from '@chakra-ui/react';
import { forwardRef, useState } from 'react';

interface IDividerButtonProps {
  text: string;
  onClick?: () => void;
};

const DividerButton = forwardRef(
  ({ text, onClick }: IDividerButtonProps, ref: any) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const hover = () => setIsHovered(true);
    const noHover = () => setIsHovered(false);
    return (
      <Box w='100%' onMouseEnter={hover} onMouseOut={noHover}>
        <Flex alignItems='center'>
          <Box
            className='divider'
            backgroundColor={
              isHovered
                ? 'admin.dividerButton.bgHover'
                : 'admin.dividerButton.bg'
            }
            flex='1'
            height='2px'
          />
          <Button
            ref={ref}
            borderRadius='26px'
            height='22px'
            fontSize='12px'
            onMouseEnter={hover}
            onMouseOut={noHover}
            onClick={onClick}
            backgroundColor={
              isHovered
                ? 'admin.dividerButton.bgHover'
                : 'admin.dividerButton.bg'
            }
            color={
              isHovered
                ? 'admin.dividerButton.hoverColor'
                : 'admin.dividerButton.color'
            }
            _hover={{}}
            _active={{}}
            _focus={{}}
          >
            {text}
          </Button>
          <Box
            className='divider'
            backgroundColor={
              isHovered
                ? 'admin.dividerButton.bgHover'
                : 'admin.dividerButton.bg'
            }
            flex='1'
            height='2px'
          />
        </Flex>
      </Box>
    );
  }
);

export default DividerButton;
