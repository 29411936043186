import { useContext, useEffect, useState } from 'react';

import {
    Box,
    Button,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
} from '@chakra-ui/react';
import { ModalContext } from '../../../contexts/ModalProvider';
import { Controller } from 'react-hook-form';
import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import { AddIcon } from '../../../icons';
import MinutesObservationTableRow from './MinutesObservationTableRow';
import TableHeader from '../TableHeader';
import useValidate from '../../../hooks/useValidate';
import MinutesObservationTableModal from './minutesObservationTableModal';


interface IMinutesObservationTable extends IField {
    data?: any;
    description?: string;
    variant?: string;
    help?: string;
    styles?: {
        font?: string
    };
    formIndex?: string;
}

const definedValidations: DefinedValidations = {
    notEmpty: (label, validationValue, value) => {
        if (validationValue && !value) {
            return `Cannot be empty`;
        }
    },
};

const MinutesObservationTable = ({ control, name, label, validations = {}, disabled = false, help = '', required, data, styles, formIndex }: IMinutesObservationTable) => {
    const validate = useValidate(label || name, validations || {}, definedValidations);
    const [dataValues, setdataValues] = useState<any>([]);
    const { modalState, setModalState } = useContext(ModalContext);
    const [currentDataValue, setCurrentDataValue] = useState<any>('');

    useEffect(() => {
        setdataValues(control._formValues.minutesObservation);
    }, [control._formValues.minutesObservation]);

    // Reset the form after closing
    useEffect(() => {
        if (modalState === 'closed') {
            setCurrentDataValue('');
        }
    }, [modalState]);

    // If modal opened in edit or delete mode, reset the form and set values of edited element
    const openObservationModal = (action: 'add' | 'edit' | 'delete', dataValue?: any) => {
        if (action !== 'add') {
            setCurrentDataValue(dataValue);
        }
        else {
            setCurrentDataValue({ minutes: undefined, secsPerItem: undefined, observation: "" });
        }
        setModalState(action);
    };


    return (
        <Controller
            name={name}
            control={control}
            rules={{ validate }}
            render={({ field, fieldState }) => {
                const { onChange } = field;
                const { error } = fieldState;

                const updateChangeMinutesValues = (event, index) => {
                    let updatedData = control._formValues.minutesObservation;
                    updatedData[index].minutes = parseInt(event.target.value)
                    onChange({ target: { name, value: updatedData } });
                }

                const updateSecsPerItemValues = (event, index) => {
                    let updatedData = control._formValues.minutesObservation;
                    updatedData[index].secsPerItem = parseInt(event.target.value)
                    onChange({ target: { name, value: updatedData } });
                }

                return (
                    <>
                        <MinutesObservationTableModal
                            modalState={modalState}
                            setModalState={setModalState}
                            dataValues={dataValues}
                            setdataValues={setdataValues}
                            selectedDataValue={currentDataValue}
                            onChange={onChange}
                            formIndex={formIndex}
                            name={name} />
                        <Box w='full' id={name} mt='none' maxW='700px'>
                            {label && (
                                <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
                                    <Box
                                        color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                                        fontWeight='bold'
                                        fontSize='md'
                                        position='static'
                                        left='none'
                                        zIndex={2}
                                    >
                                        {label}
                                    </Box>
                                </Flex>
                            )}
                            <Table fontSize='smm'>
                              <Thead>
                                <Tr>
                                  <TableHeader header='Minutes' styles={{ paddingInline: '0px' }} />
                                  <TableHeader header='Secs/Item' styles={{ paddingInline: '0px' }} />
                                  <TableHeader header='Observation' styles={{ paddingInline: '20px' }} />
                                  <TableHeader header='' styles={{ paddingInline: '20px' }} />
                                  <TableHeader header='' styles={{ paddingInline: '0px' }} />
                                </Tr>
                              </Thead>
                              <Tbody left='-10px'>
                                { dataValues && dataValues?.map((dataValue, index) =>
                                  <MinutesObservationTableRow
                                    key={index}
                                    observation={dataValue.observation}
                                    minutes={dataValue.minutes} secsPerItem={dataValue.secsPerItem}
                                    onChangeMinutes={(e) => updateChangeMinutesValues(e, index)}
                                    onChangeSecsPerItem={(e) => updateSecsPerItemValues(e, index)}
                                    onEdit={() => { openObservationModal('edit', { ...dataValue, index: index }) }}
                                    onRemove={() => openObservationModal('delete', { ...dataValue, index: index })}
                                    disabled={disabled}
                                  />
                                )}
                              </Tbody>
                            </Table>
                            <Box>
                              { !disabled &&
                                <Button
                                  disabled={disabled}
                                  bg='#FFFFFF'
                                  color='#DC0043'
                                  mt='05px'
                                  pl='0px'
                                  pr='15px'
                                  fontSize='smm'
                                  borderRadius='10px'
                                  onClick={() => openObservationModal('add')}
                                  _hover={{
                                      background: 'clientContractorTable.button.hoverBg',
                                  }}
                                  _active={{
                                      background: 'clientContractorTable.button.activeBg',
                                  }}
                                >
                                  <AddIcon w='12.5px' h='12.5px' mr='10px' stroke='clientContractorTable.addIcon.stroke' />
                                  Add Row
                                </Button>
                            }
                            </Box >
                            {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
                        </Box >
                    </>
                );
            }}
        />
    );
};

export default MinutesObservationTable;

export const minutesObservationTableStyles = {
    minutesObservationTable: {

    }
};