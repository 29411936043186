import React, { createContext, useContext, useMemo, useState } from "react";

import { IAppContext } from "../interfaces/IAppContext";
import { IRoles } from "../interfaces/IRoles";
import { ISetting } from "../interfaces/ISetting";
import { IUser } from "../interfaces/IUser";

export const AppContext = createContext({} as IAppContext);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within the AppProvider');
  }
  return context;
};

const AppProvider = (props: any) => {
  const [roles, setRoles] = useState<IRoles>();
  const [settings, setSettings] = useState<ISetting>();
  const [user, setUser] = useState<IUser | null>();

  const value = useMemo(() => ({
    user, setUser,
    roles, setRoles,
    settings, setSettings,
  }), [ // eslint-disable-line react-hooks/exhaustive-deps
    user,
    roles,
    settings,
  ]);

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider;
