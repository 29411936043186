import { Redirect } from "react-router-dom";
import { useAppContext } from "../contexts/AppProvider";

// import Audit from "../pages/audit";
import DefaultLayout from "../layouts/DefaultLayout";
import Home from "../pages/home";
import IRoute from "../interfaces/IRoute";
import Login from "../pages/login";
import Logout from "../pages/logout";
import Project from "../pages/project";
import PureLayout from "../layouts/PureLayout";
import Templates from "../pages/admin/templates";
import Template from "../pages/admin/template";
import Users from "../pages/users";
import Can from "../components/Can";
import CommonForms from "../pages/admin/common-forms";
import CommonForm from "../pages/admin/common-form";
import Admin from "../pages/admin";
import AuditLogs from "../pages/admin/auditlogs";
import Audit from "../pages/audit";

// Routes visible for not signed in
const openRoutes: Array<IRoute> = [
  {
    path: "/login",
    key: "login",
    exact: true,
    component: Login,
    layout: PureLayout,
  },
  {
    path: "/logout",
    key: "logout",
    exact: true,
    component: Logout,
    layout: PureLayout,
  },
  {
    path: "*",
    key: "not-allowed",
    component: () => <Redirect key="not-allowed" to={{ pathname: "/login", state: { redirectUrl: window.location.pathname } }} />,
    layout: PureLayout,
  },
];

// Routes visible for signed in
const protectedRoutes: Array<IRoute> = [
  {
    path: "/",
    key: "home",
    exact: true,
    component: Home,
    layout: DefaultLayout,
  },
  {
    path: "/admin/templates",
    key: "templates",
    exact: true,
    component: Templates,
    layout: DefaultLayout,
    permission: 'projectTemplates.view',
  },
  {
    path: "/admin",
    key: "admin",
    exact: true,
    component: Admin,
    layout: DefaultLayout,
  },
  {
    path: "/admin/templates/:id",
    key: "template",
    exact: true,
    component: Template,
    layout: DefaultLayout,
    permission: 'projectTemplates.view',
  },
  {
    path: "/admin/common-forms",
    key: "common-forms",
    exact: true,
    component: CommonForms,
    layout: DefaultLayout,
  },
  {
    path: "/admin/common-forms/:id",
    key: "common-form",
    exact: true,
    component: CommonForm,
    layout: DefaultLayout,
    permission: 'commonForms.view',
  },
  {
    path: "/admin/audit-logs",
    key: "audit-logs",
    exact: true,
    component: AuditLogs,
    layout: DefaultLayout,
    permission: 'auditLogs.viewAll',
  },
  {
    path: "/admin/users",
    key: "users",
    exact: true,
    component: Users,
    layout: DefaultLayout,
    permission: 'users.view',
  },
  {
    path: "/project/:_id/audit-log",
    key: "audit-log",
    exact: true,
    component: Audit,
    layout: DefaultLayout,
  },
  {
    path: '/project/:_id/:page(\\d+)/:subsection?',
    key: "project",
    exact: true,
    component: Project,
    layout: DefaultLayout,
  },
];

const useRoutes = () => {
  const { user } = useAppContext();

  if (!user) {
    return openRoutes.map(route => ({
      ...route,
      component: () => <route.layout key={route.key} component={route.component} />
    }))
  }
  return [
    ...protectedRoutes.map((route) => ({
      ...route,
      component: () => (
        <Can
          action={route.permission}
          no={() => <Redirect key="not-found" to={{ pathname: '/' }} />}
          yes={() => <route.layout component={route.component} key={route.key} />} />
      ),
    })),
    {
      path: '*',
      key: 'not-found',
      component: () => <Redirect key="not-found" to={{ pathname: '/' }} />,
      layout: DefaultLayout,
    },
  ];
}



export default useRoutes;
