import { gql } from '@apollo/client';

const RESET_PROJECT = gql`
  mutation ($projectInput: ProjectInput!) {
    resetProject(projectInput: $projectInput) {
      _id
    }
  }
`;

export default RESET_PROJECT;
