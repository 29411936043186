import { createIcon } from "@chakra-ui/icons";

const DeleteIcon = createIcon({
  displayName: "Delete",
  viewBox: "0 0 11 12",
  path: (
    <>
      <path fill="none" d="M7.95827 10.5833H3.68045C3.51837 10.5833 3.36293 10.519 3.24833 10.4043C3.13372 10.2897 3.06934 10.1343 3.06934 9.97222V4.47217H8.56939V9.97222C8.56939 10.1343 8.505 10.2897 8.3904 10.4043C8.27579 10.519 8.12035 10.5833 7.95827 10.5833Z"
        stroke="#currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M4.90283 8.74989V6.30542"
        stroke="#currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M6.73584 8.74989V6.30542"
        stroke="#currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M1.5415 3.64176L9.31247 1.99011"
        stroke="#currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M6.06954 1.42998L4.27693 1.81091C4.19825 1.82751 4.12362 1.85948 4.05732 1.90498C3.99101 1.95049 3.93434 2.00863 3.89055 2.07607C3.84676 2.14352 3.81671 2.21894 3.80213 2.29802C3.78754 2.37711 3.78871 2.45829 3.80556 2.53692L3.9343 3.13378L6.92307 2.49821L6.79433 1.90054C6.7606 1.74204 6.66529 1.60342 6.52938 1.51518C6.39346 1.42694 6.22806 1.39629 6.06954 1.42998V1.42998Z"
        stroke="#currentColor" strokeLinecap="round" strokeLinejoin="round" />

    </>
  ),
});

export default DeleteIcon;
