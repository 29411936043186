import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Flex,
  Box,
  Wrap,
  WrapItem,
  useDisclosure,
  Spinner,
  Divider,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CheckIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import { toastFailed, toastSuccess } from '../../../bootstrap/config';
import { PlusIcon } from '../../../icons';
import { IUser } from '../../../interfaces/IUser';
import { roleDecorated } from '../../../utils/helpers';
import { Dropdown, PeoplePicker } from '../../FormControls';
import RemovableAvatar from '../../RemovableAvatar';
import { useProjectsContext } from '../../../contexts/ProjectsProvider';
import GET_OR_CREATE_USER from '../../../gql/queries/GET_OR_CREATE_USER';
import UPDATE_PROJECT from '../../../gql/mutation/UPDATE_PROJECT';
import useGetProjects from '../../../hooks/useGetProjects';

const ProjectTeamModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const { currentProject } = useProjectsContext();
  const { refetch } = useGetProjects({ projectId: currentProject?._id, withForms: true });
  const [processing, setProcessing] = useState(false);
  const [searchUsersIn, setSearchUsersIn] = useState<string>('widerTeam');
  const [team, setTeam] = useState<IUser[] | undefined>();
  const [saveProject] = useMutation(UPDATE_PROJECT);
  const [getOrCreate] = useMutation(GET_OR_CREATE_USER);
  const {
    isOpen: isRolesModalOpen,
    onClose: onRolesModalClose,
    onOpen: onRolesModalOpen,
  } = useDisclosure();

  useEffect(() => {
    if (isOpen && !team) {
      // First run
      const parseUser = (user: IUser, role: 'projectLead' | 'tester' | 'trainee') => {
        return { ...user, role };
      };
      const defaultTeam: IUser[] = [];
      if (currentProject?.projectLead) {
        defaultTeam.push(parseUser(currentProject.projectLead, 'projectLead'));
      }
      (currentProject?.testers || []).forEach(user => defaultTeam.push(parseUser(user, 'tester')));
      (currentProject?.trainees || []).forEach(user => defaultTeam.push(parseUser(user, 'trainee')));
      setTeam(defaultTeam);
    }
  }, [currentProject, isOpen, team]);

  const {
    control: rolesControl,
    getValues: getRolesValues,
    reset: resetRoles,
    watch: watchRoles,
    formState: { isValid: isValidRole },
  } = useForm({
    mode: 'all',
    defaultValues: {
      role: '',
      id: '',
    },
  });

  const completeSetup = async () => {
    if (!hasProjectLead()) {
      toast({
        ...toastFailed,
        description: 'Project must have a project lead',
      });
      return;
    }
    setProcessing(true);

    const projectTeam = (team || []).map(({ _id, role }) => ({ _id, role }));

    try {
      const { data } = await saveProject({
        variables: {
          projectInput: {
            projectId: currentProject?._id,
            projectTeam,
          },
        },
      });

      if (data.updateProject?._id) {
        refetch();
        toast({ ...toastSuccess, description: 'Team saved successfully' });
        closeProjectModal();
      }
    } catch (error) {
      console.error('Error creating project:', error);
      toast({ ...toastFailed, description: 'Failed to save team changes' });
    } finally {
      setProcessing(false);
    }
  };

  const closeProjectModal = () => {
    onClose();
    resetRoles({});
    setTeam(undefined);
  };

  const hasProjectLead = () => {
    return Boolean((team || []).find((obj) => obj.role === 'projectLead'));
  };

  useEffect(() => {
    const subscription = watchRoles((value) => {
      if (value.role?.length) {
        setSearchUsersIn(value.role === 'projectLead' ? 'projectLeads' : 'projectMembers');
      }
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchRoles, isOpen]);

  const addMember = async () => {
    if (!isValidRole) {
      toast({ ...toastFailed, description: 'Please fill all the fields' });
      return;
    }
    const { id, role } = getRolesValues();
    if (!id) {
      toast({ ...toastFailed, description: 'Please select a member' });
      return;
    }
    setProcessing(true);
    const userResponse = await getOrCreate({ variables: { userId: id } });
    const user = userResponse?.data?.getOrCreate;
    if (user) {
      setTeam((prevTeam) => [...(prevTeam || []), { ...user, role }]);
    }
    setProcessing(false);
    resetRoles({});
    onRolesModalClose();
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      <Modal closeOnOverlayClick={false} onClose={closeProjectModal} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight='900'>Project setup</ModalHeader>
          <ModalCloseButton />
          <Divider opacity={1} />
          <ModalBody w='full'>
            <Box>
              <Text fontSize='smm' fontWeight='700' my='3'>
                Project Team
              </Text>
              <Flex>
                <Wrap m='0'>
                  {(team || []).map((user, index) => (
                    <WrapItem
                      key={`${user.role}-${user._id}`}
                      m='0'
                      w='75px'
                      justifyContent='center'
                    >
                      <Flex
                        flexDir='column'
                        justifyContent='center'
                        borderRadius='50%'
                        alignItems='center'
                        w='full'
                      >
                        <RemovableAvatar
                          h='70px'
                          w='70px'
                          userId={user._id}
                          onDelete={() => setTeam((prevTeam) => (prevTeam || []).filter((_, i) => i !== index))}
                        />
                        <Box textAlign='center' mt='1' w='full'>
                          <Tooltip hasArrow label={user.displayName}>
                            <Text fontSize='ssm' fontWeight='600' isTruncated textOverflow='ellipsis'>
                              {user.displayName}
                            </Text>
                          </Tooltip>
                          <Text fontSize='ssm' color='breBlack'>
                            {roleDecorated(user.role)}
                          </Text>
                        </Box>
                      </Flex>
                    </WrapItem>
                  ))}
                  <WrapItem m='0' w='75px' justifyContent='center'>
                    <Flex
                      cursor='pointer'
                      w='70px'
                      h='70px'
                      borderRadius='50%'
                      bg='breBlack'
                      alignItems='center'
                      justifyContent='center'
                      onClick={onRolesModalOpen}
                    >
                      <PlusIcon />
                    </Flex>
                  </WrapItem>
                </Wrap>
              </Flex>
            </Box>
          </ModalBody>
          <Divider opacity={1} mt='2' />
          <ModalFooter>
            <Button onClick={closeProjectModal} mr='2'>
              Discard
            </Button>
            <Button
              onClick={completeSetup}
              bg='brePink'
              color='white'
              _hover={{}}
              _focus={{}}
              _active={{}}
              disabled={processing}
            >
              {processing ? (
                <>
                  <Spinner mr='2' /> Saving
                </>
              ) : (
                <>
                  <CheckIcon mr='2' />
                  Complete Setup
                </>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        onClose={() => {
          onRolesModalClose();
          resetRoles({});
        }}
        isOpen={isRolesModalOpen}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight='900'>Add team member</ModalHeader>
          <ModalCloseButton />
          <ModalBody w='full'>
            <Dropdown
              control={rolesControl}
              name='role'
              required={true}
              label='Role'
              validations={{ notEmpty: true }}
              styles={{ fullWidth: true }}
              options={[
                {
                  label: 'Trainee',
                  value: 'trainee',
                },
                {
                  label: 'Tester',
                  value: 'tester',
                },
                ...(hasProjectLead()
                  ? []
                  : [
                    {
                      label: 'Project Lead',
                      value: 'projectLead',
                    },
                  ]),
              ]}
              placeholder="Select role"
            />
            {rolesControl._formValues.role !== '' && (
              <PeoplePicker
                label='Select member'
                placeholder='Select member'
                control={rolesControl}
                name='id'
                styles={{ fullWidth: true }}
                variant={searchUsersIn}
                validations={{ notEmpty: true }}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onRolesModalClose();
                resetRoles({});
              }}
              mr='2'
            >
              Discard
            </Button>
            <Button
              onClick={addMember}
              bg='brePink'
              color='white'
              _hover={{}}
              _focus={{}}
              _active={{}}
              disabled={processing}
            >
              {processing ? (
                <>
                  <Spinner mr='2' /> Saving
                </>
              ) : (
                <>
                  <PlusIcon mr='2' />
                  Add Member
                </>
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProjectTeamModal;
