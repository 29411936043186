import { useEffect, useState } from "react";
import iOS from "is-ios";

import useDevice from "./useDevice";

const useIsIpad = () => {
  const device = useDevice();
  const [ipad, setIpad] = useState(false);
  useEffect(() => {
    if (device==="tablet" && iOS) {
      setIpad(true);
    } else {
      setIpad(false);
    }
  }, [device]);

  return ipad;
}

export default useIsIpad;
