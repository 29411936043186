import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion,
  Box,
  AccordionItem,
  Text,
  AccordionButton,
  AccordionPanel,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { toastWarning } from '../../bootstrap/config';
import { useAppContext } from '../../contexts/AppProvider';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
import useTemplateRows from '../../hooks/useTemplateRows';
import { DotsVertical, TrashIcon } from '../../icons';
import { IFormSection } from '../../interfaces/IFormSection';
import Can, { isPermitted } from '../Can';
import ConfirmationModal from '../ConfirmationModal';
import DividerButton from '../DividerButton';
import TemplateRow from './TemplateRow';

interface ITemplateSection {
  selectedPage: number;
  section: IFormSection;
  onRemove: () => void;
}

const TemplateSection = ({
  selectedPage,
  section,
  onRemove,
}: ITemplateSection) => {
  const toast = useToast();
  const { user } = useAppContext();
  const [sectionName, setSectionName] = useState<string>(section.name);
  const { currentProjectTemplate, currentProjectFormTemplate, fieldsInEdit } = useProjectsContext();
  const {
    rows,
    rowToRemove,
    setRowToRemove,
    removeRow,
    createRow,
    moveRows,
    updateSectionName,
  } = useTemplateRows(selectedPage, section);
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const isPermittedToEdit = isPermitted({
    user,
    action: "formTemplates.edit",
    data: { projectTemplate: currentProjectTemplate, formTemplate: currentProjectFormTemplate }
  });

  const renderRows = () => {
    return section.rows.map((row, index) => (
      <TemplateRow
        selectedPage={selectedPage}
        section={section}
        row={row}
        number={index + 1}
        key={row._id}
        removeRow={() => {
          setRowToRemove(row._id);
          onConfirmationOpen();
        }}
      />
    ));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (sectionName !== section.name) {
        updateSectionName(sectionName);
      }
      // Send Axios request here
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionName]);

  return (
    <Draggable
      key={`draggable-${section._id}`}
      draggableId={section._id}
      index={section.index - 1}
    >
      {(provided) => (
        <>
          <AccordionItem
            borderWidth='1px'
            borderColor='admin.dividerButton.bg'
            borderRadius='10'
            mb='5'
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            {({ isExpanded }) => (
              <Box bg='opaqueWhite' borderRadius={10} p={3} position='relative'>
                <Text opacity={.3} position='absolute' bottom={1} right={2} fontSize='sm'>{section._id.slice(-4)}</Text>
                <Box display='flex' alignItems='center' cursor='pointer'>
                  <Can
                    action="projectTemplates.edit"
                    data={{ projectTemplate: currentProjectTemplate }}
                    yes={() =>
                      <Box mr='2' {...provided.dragHandleProps}>
                        <DotsVertical />
                      </Box>
                    }
                  />
                  <AccordionButton
                    _focus={{}}
                    _hover={{}}
                    paddingX='0'
                    alignItems='center'
                    onClick={() => {
                      if (isExpanded && fieldsInEdit.length) {
                        toast({ ...toastWarning, description: 'You have unsaved fields in that section' })
                      }
                    }}
                  >
                    {isExpanded ? (
                      <ChevronDownIcon h='24px' w='24px' />
                    ) : (
                      <ChevronRightIcon h='24px' w='24px' />
                    )}
                    <Text ml='2' textAlign='left' fontSize='lg' fontWeight='bold'>
                      {section.name || 'Section with no title'}
                    </Text>
                  </AccordionButton>
                  <Can
                    action='formTemplates.edit'
                    data={{
                      projectTemplate: currentProjectTemplate,
                      formTemplate: currentProjectFormTemplate,
                    }}
                    yes={() => <TrashIcon onClick={onRemove} />}
                  />
                </Box>
                <AccordionPanel pb={4} px='2'>
                  <Box mb='3'>
                    <Text fontWeight='bold' fontSize='smm' mb='2'>
                      Section title
                    </Text>
                    <Input
                      h='42px'
                      color='textInput.font'
                      bg='textInput.bg'
                      _hover={{ cursor: 'auto' }}
                      borderColor='textInput.border.normal'
                      value={sectionName}
                      placeholder='Section title'
                      onChange={(e) => {
                        setSectionName(e.target.value);
                      }}
                      w='360px'
                      css={{
                        '.chakra-switch__thumb': {
                          '&[data-checked]': {
                            background: '#462AC4',
                          },
                        },
                      }}
                      disabled={!isPermittedToEdit}
                    />
                  </Box>
                  {Boolean(rows.length) && (
                    <>
                      <Text fontWeight='bold'>Rows</Text>
                      <DragDropContext onDragEnd={moveRows}>
                        <Droppable droppableId='sectionsDroppable'>
                          {(provided) => (
                            <Accordion mt='2' allowMultiple  {...provided.droppableProps} ref={provided.innerRef}>
                              {renderRows()}
                            </Accordion>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  )}
                  <Can
                    action="formTemplates.edit"
                    data={{ projectTemplate: currentProjectTemplate, formTemplate: currentProjectFormTemplate }}
                    yes={() => <DividerButton text='+ Add row' onClick={createRow} />}
                  />
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onClose={onConfirmationClose}
            modalText={`Do you want to delete this row?`}
            confirmAction={() => rowToRemove && removeRow(rowToRemove)}
          />
        </>
      )}
    </Draggable>
  );
};

export default TemplateSection;
