import { createIcon } from "@chakra-ui/icons";

const ProjectSummaryIcon = createIcon({
  displayName: "ProjectSummary",
  viewBox: "0 0 14 14",
  path: (
    <>
      <path fill="none" d="M2.58535 8.67059C3.36886 8.67059 4.00402 8.03543 4.00402 7.25192C4.00402 6.46841 3.36886 5.83325 2.58535 5.83325C1.80185 5.83325 1.16669 6.46841 1.16669 7.25192C1.16669 8.03543 1.80185 8.67059 2.58535 8.67059Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M7.00003 8.67059C7.78353 8.67059 8.41869 8.03543 8.41869 7.25192C8.41869 6.46841 7.78353 5.83325 7.00003 5.83325C6.21652 5.83325 5.58136 6.46841 5.58136 7.25192C5.58136 8.03543 6.21652 8.67059 7.00003 8.67059Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M11.4147 8.67059C12.1982 8.67059 12.8334 8.03543 12.8334 7.25192C12.8334 6.46841 12.1982 5.83325 11.4147 5.83325C10.6312 5.83325 9.99603 6.46841 9.99603 7.25192C9.99603 8.03543 10.6312 8.67059 11.4147 8.67059Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M4.00452 7.25195H5.58133"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path fill="none" d="M8.4187 7.25195H9.99552"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />

    </>
  ),
});

export default ProjectSummaryIcon;
