import { createIcon } from '@chakra-ui/icons';

const WideArrowIcon = createIcon({
  path: (
    <path
      d='M5.83301 9.16634H11.6663V10.833H5.83301V9.16634ZM5.83301 5.83301H14.9747V7.49967H5.83301V5.83301ZM5.83301 12.4997H16.6663V14.1663H5.83301V12.4997ZM3.33301 3.33301H4.99967V16.6663H3.33301V3.33301Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default WideArrowIcon;
