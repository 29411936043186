import { createIcon } from '@chakra-ui/icons';

const FileLines = createIcon({
  path: (
    <>
      <path
        d='M16.5855 7.15533C16.546 7.06488 16.4907 6.9822 16.4222 6.91116L11.4222 1.91116C11.3511 1.84264 11.2685 1.78734 11.178 1.74783C11.153 1.73616 11.1263 1.72949 11.0997 1.72033C11.0299 1.6966 10.9574 1.6823 10.8838 1.67783C10.8663 1.67616 10.8505 1.66699 10.833 1.66699H4.99967C4.08051 1.66699 3.33301 2.41449 3.33301 3.33366V16.667C3.33301 17.5862 4.08051 18.3337 4.99967 18.3337H14.9997C15.9188 18.3337 16.6663 17.5862 16.6663 16.667V7.50033C16.6663 7.48283 16.6572 7.46699 16.6555 7.44866C16.6514 7.37509 16.6371 7.30245 16.613 7.23283C16.6047 7.20616 16.5972 7.18033 16.5855 7.15533ZM13.8213 6.66699H11.6663V4.51199L13.8213 6.66699ZM4.99967 16.667V3.33366H9.99967V7.50033C9.99967 7.72134 10.0875 7.9333 10.2438 8.08958C10.4 8.24586 10.612 8.33366 10.833 8.33366H14.9997L15.0013 16.667H4.99967Z'
        fill='currentColor'
      />
      <path
        d='M6.66699 10.0003H13.3337V11.667H6.66699V10.0003ZM6.66699 13.3337H13.3337V15.0003H6.66699V13.3337ZM6.66699 6.66699H8.33366V8.33366H6.66699V6.66699Z'
        fill='currentColor'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default FileLines;
