import { Flex, Tr, Td } from "@chakra-ui/react";
import PeoplePicker from "../PeoplePicker";

const AmslerChecklistTableRow = ({ name, index, description, indexValue, disabled, control, updatePeopleValue }) => {
  return (
    <Tr>
      <Td fontWeight='semi_medium' paddingInline='0px' minW='80px'><Flex justifyContent="center" w="full" pr="5">{index}</Flex></Td>
      <Td fontWeight='semi_medium' paddingInline='0px' w='35%'>{description}</Td>
      <Td fontWeight='semi_medium' paddingInline='20px' w='30%' color='checkListTableRow.td.color'>
        <PeoplePicker
          disabled={disabled}
          control={control}
          name={`${name}.${indexValue}.projectLeader`}
          validations={{ notEmpty: true }}
          onChange={(value) => updatePeopleValue(value, indexValue, "projectLeader")}
          variant="projectLeads"
          inTable={true}
        />
      </Td>
      <Td fontWeight='semi_medium' paddingInline='20px' w='30%' color='checkListTableRow.td.color'>
        <PeoplePicker
          control={control}
          disabled={disabled}
          name={`${name}.${indexValue}.technican`}
          validations={{ notEmpty: true }}
          onChange={(value) => updatePeopleValue(value, indexValue, "technican")}
          variant="widerTeam"
          inTable={true}
        />
      </Td>
    </Tr>
  );
};

export default AmslerChecklistTableRow;

export const amslerChecklistTableRowStyles = {
  signatureTableRow: {
    td: {
      color: '#818197'
    },
    checkBox: {
      borderColor: 'rgba(129, 129, 151, 0.5)',
      colorScheme: 'gray'
    }

  }
}
