import {
  BookIcon,
  BriefcaseIcon,
  BullhornIcon,
  CalculatorIcon,
  CircleCheckIcon,
  ClipboardIcon,
  CogIcon,
  CommentIcon,
  CompassIcon,
  FileLines,
  FolderIcon,
  GlobeIcon,
  HammerIcon,
  HelmetIcon,
  LaptopFileIcon,
  ListCheck,
  MovieReelIcon,
  PencilBoxIcon,
  PencilIcon,
  PersonChalkboard,
  PersonDiggingIcon,
  RollerIcon,
  RotateIcon,
  ScaleBalanced,
  ScaleIcon,
  ScrewDriverWrenchIcon,
  SignatureIcon,
  SquarePollVerticalIcon,
  TagsIcon,
  TightenNutIcon,
  TruckPickupIcon,
  WandMagicSparklesIcon,
} from '../../../icons';

export const icons = [
  { icon: BookIcon, value: 'BookIcon' },
  { icon: BriefcaseIcon, value: 'BriefcaseIcon' },
  { icon: BullhornIcon, value: 'BullhornIcon' },
  { icon: CalculatorIcon, value: 'CalculatorIcon' },
  { icon: ClipboardIcon, value: 'ClipboardIcon' },
  { icon: FileLines, value: 'FileLines' },
  { icon: FolderIcon, value: 'FolderIcon' },
  { icon: GlobeIcon, value: 'GlobeIcon' },
  { icon: LaptopFileIcon, value: 'LaptopFileIcon' },
  { icon: ListCheck, value: 'ListCheck' },
  { icon: PencilIcon, value: 'PencilIcon' },
  { icon: PencilBoxIcon, value: 'PencilBoxIcon' },
  { icon: PersonChalkboard, value: 'PersonChalkboard' },
  { icon: ScaleBalanced, value: 'ScaleBalanced' },
  { icon: SignatureIcon, value: 'SignatureIcon' },
  { icon: SquarePollVerticalIcon, value: 'SquarePollVerticalIcon' },
  { icon: TagsIcon, value: 'TagsIcon' },
  { icon: CompassIcon, value: 'CompassIcon' },
  { icon: HammerIcon, value: 'HammerIcon' },
  { icon: HelmetIcon, value: 'HelmetIcon' },
  { icon: RollerIcon, value: 'RollerIcon' },
  { icon: PersonDiggingIcon, value: 'PersonDiggingIcon' },
  { icon: ScaleIcon, value: 'ScaleIcon' },
  { icon: ScrewDriverWrenchIcon, value: 'ScrewDriverWrenchIcon' },
  { icon: TruckPickupIcon, value: 'TruckPickupIcon' },
  { icon: TightenNutIcon, value: 'TightenNutIcon' },
  { icon: CircleCheckIcon, value: 'CircleCheckIcon' },
  { icon: CogIcon, value: 'CogIcon' },
  { icon: RotateIcon, value: 'RotateIcon' },
  { icon: WandMagicSparklesIcon, value: 'WandMagicSparklesIcon' },
  { icon: CommentIcon, value: 'CommentIcon' },
  { icon: MovieReelIcon, value: 'MovieReelIcon' },
] as const;
