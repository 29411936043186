import { createIcon } from '@chakra-ui/icons';

const DoubleSquareIcon = createIcon({
  displayName: 'Edit',
  viewBox: '0 0 20 20',
  path: (
    <path
      d='M17.5 15.8333V8.33333C17.5 7.41417 16.7525 6.66667 15.8333 6.66667H13.3333V4.16667C13.3333 3.2475 12.5858 2.5 11.6667 2.5H4.16667C3.2475 2.5 2.5 3.2475 2.5 4.16667V11.6667C2.5 12.5858 3.2475 13.3333 4.16667 13.3333H6.66667V15.8333C6.66667 16.7525 7.41417 17.5 8.33333 17.5H15.8333C16.7525 17.5 17.5 16.7525 17.5 15.8333ZM4.16667 11.6667V4.16667H11.6667V6.66667H8.33333C7.41417 6.66667 6.66667 7.41417 6.66667 8.33333V11.6667H4.16667ZM11.6675 11.6667H8.33333V8.33333H11.6675V11.6667ZM8.33333 13.3333H11.6667C12.5858 13.3333 13.3333 12.5858 13.3333 11.6667V8.33333H15.8333L15.8342 15.8333H8.33333V13.3333Z'
      fill='#282F36'
    />
  ),
});

export default DoubleSquareIcon;
