import { useMemo } from 'react';

import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { ChevronDown } from '../../../icons';
import StateChoiceFilter from './StateChoiceFilter';
import { useAuditFiltersContext } from '../../../contexts/AuditFiltersProvider';

const AuditLogFiltersPanelItem = ({ name, label }: { name: string; label: string }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { auditFilters, auditFiltersOptions } = useAuditFiltersContext();

  const filtersLength = useMemo(
    () => (Array.isArray(auditFilters?.[name]?.value) ? auditFilters?.[name]?.value?.length : 0),
    [auditFilters, name],
  );

  const options = auditFiltersOptions.find(({ filterName }) => filterName === name)?.values;

  return (
    <Flex
      bg={isOpen ? 'filtersPanelItem.openBg' : 'filtersPanelItem.closeBg'}
      borderRadius="10px"
      flexDir="column"
      justify="center"
      key={name}
      my={2}
      p="3"
      w="full">
      <Flex
        align="center"
        cursor="pointer"
        justify="space-between"
        mb={isOpen ? '4' : '0'}
        w="full">
        <Text
          color="filtersPanelItem.fontColor"
          fontSize="14px"
          onClick={onToggle}
          w="full">
          {label}
        </Text>
        <Flex >
          {filtersLength > 0 && (
            <Box
              bg="filtersPanelItem.countBg"
              borderRadius="10px"
              color="filtersPanelItem.countColor"
              fontSize="12px"
              fontWeight="400"
              mr="3"
              px="10px">
              {filtersLength}
            </Box>
          )}
          {isOpen ? <ChevronDown onClick={onToggle} transform="rotate(180deg)" /> : <ChevronDown onClick={onToggle} />}
        </Flex>
      </Flex>
      {isOpen && <StateChoiceFilter name={name} options={options} />}
    </Flex>
  );
};

export default AuditLogFiltersPanelItem;

export const filtersPanelItemStyles = {
  filtersPanelItem: {
    openBg: '#F0F2F5',
    closeBg: '#F0F2F595',
    fontColor: '#282F36',
    countColor: '#818197',
    countBg: 'white',
  },
};
