import { Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import pluralize from 'pluralize';
import { useAppContext } from '../../contexts/AppProvider';
import { TrashIcon } from '../../icons';
import { IFormField, IFormFieldOption } from '../../interfaces/IFormField';
import Can, { isPermitted } from '../Can';
import { TextInput } from '../FormControls';
import { firstCharacterCapitalize as capitalize } from '../../utils/helpers';
import { useProjectsContext } from '../../contexts/ProjectsProvider';
interface ITemplateFieldOptionsInput {
  options: IFormFieldOption[];
  name;
  label?: string;
  setValue: UseFormSetValue<IFormField>;
}

const TemplateFieldOptionsInput = ({
  options,
  name,
  label,
  setValue,
}: ITemplateFieldOptionsInput) => {
  const { user } = useAppContext();
  const { currentProjectTemplate } = useProjectsContext();
  const {
    control,
    watch,
    getValues,
    setValue: setLabelValue,
  } = useForm<{ label: string[] }>({
    mode: 'all',
    defaultValues: {
      label: options.map((o) => o.label),
    },
  });
  const [fieldOptions, setFieldOptions] = useState<IFormFieldOption[]>(options);

  const isPermittedToEdit = isPermitted({
    user,
    action: 'projectTemplates.edit',
    data: { projectTemplate: currentProjectTemplate }
  });

  useEffect(() => {
    const subscription = watch((value) => {
      if (Array.isArray(value.label)) {
        const options: IFormFieldOption[] = value.label
          .map((l) => ({ label: l || '', value: l || '' }));
        setValue(name, options);
      }
    });

    return () => {
      subscription.unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    setFieldOptions(options);
  }, [options]);

  const nameSingular = pluralize(name, 1);

  return (
    <>
      <Text mt={2} fontSize='sm' fontWeight='bold' textTransform='capitalize'>
        {label || name}
      </Text>
      {fieldOptions.map((option: IFormFieldOption, index: number) => (
        <Flex
          alignItems='center'
          key={`option-field-${fieldOptions.length}-${index}`}
        >
          <TextInput
            label={`${capitalize(nameSingular)} # ${index + 1}`}
            control={control}
            name={`label[${index}]`}
            validations={{ notEmpty: true }}
            disabled={!isPermittedToEdit}
          />
          <Can
            action='projectTemplates.edit'
            data={{ projectTemplate: currentProjectTemplate }}
            yes={() => (
              <TrashIcon
                mt='5'
                ml='4'
                cursor='pointer'
                onClick={() => {
                  let values = getValues().label;
                  let filtered = values.filter((_, i) => i !== index);
                  setLabelValue('label', filtered);
                  const newOptions = filtered.map((v) => ({
                    label: v,
                    value: v,
                  }));
                  setFieldOptions(() => {
                    setValue(name, newOptions);
                    return newOptions;
                  });
                }}
              />
            )}
          />
        </Flex>
      ))}
      <Can
        action='projectTemplates.edit'
        data={{ projectTemplate: currentProjectTemplate }}
        yes={() => (
          <Button
            mt='2'
            w='150px'
            h='28px'
            fontSize='smm'
            onClick={() => {
              let values = getValues().label;
              setLabelValue('label', [...values, '']);
              setFieldOptions(() => {
                const newVal = [
                  ...values.map((v) => ({ label: v, value: v })),
                  { label: '', value: '' },
                ];
                setValue(name, newVal);
                return newVal;
              });
            }}
          >
            Add {nameSingular}
          </Button>
        )}
      />
    </>
  );
};

export default TemplateFieldOptionsInput;
