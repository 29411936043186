import { createIcon } from "@chakra-ui/icons";

const OpenBookIcon = createIcon({
  displayName: "OpenBook",
  viewBox: "0 0 16 15",
  path: (
    <>
      <path d="M7.99978 13.5774V2.57063C7.99978 2.57063 6.44798 0.8469 1.21611 0.777369C1.17316 0.776828 1.13053 0.784944 1.09079 0.801234C1.05104 0.817524 1.01498 0.841654 0.984762 0.872184C0.923106 0.934137 0.888552 1.01802 0.888683 1.10543V11.456C0.887985 11.5412 0.920684 11.6232 0.979766 11.6845C1.03885 11.7459 1.11961 11.7816 1.20473 11.7841C6.44672 11.8549 7.99978 13.5774 7.99978 13.5774Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.10413 6.65905C5.02846 6.29725 3.91129 6.07318 2.7793 5.99219" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.10413 9.31042C5.02846 8.94862 3.91129 8.72454 2.7793 8.64355" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.89648 6.65905C10.9722 6.29725 12.0893 6.07318 13.2213 5.99219" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.89648 9.31042C10.9722 8.94862 12.0893 8.72454 13.2213 8.64355" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 13.5774V2.57063C8 2.57063 9.5518 0.8469 14.7837 0.777369C14.8266 0.776828 14.8693 0.784944 14.909 0.801234C14.9487 0.817524 14.9848 0.841654 15.015 0.872184C15.0767 0.934137 15.1112 1.01802 15.1111 1.10543V11.456C15.1118 11.5412 15.0791 11.6232 15.02 11.6845C14.9609 11.7459 14.8802 11.7816 14.7951 11.7841C9.55306 11.8549 8 13.5774 8 13.5774Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default OpenBookIcon;
