import { createContext, useContext, useMemo, useState } from "react";

import { ISearchContext } from "../interfaces/ISearchContext";
import ISearch from "../interfaces/ISearch";
import { IProject } from "../interfaces/IProject";

export const SearchContext = createContext({} as ISearchContext);

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearchContext must be used within the SearchProvider");
  }
  return context;
};

const SearchProvider = (props: any) => {
  const [results, setResults] = useState<IProject[] | null>(null);
  const defaultSearchParams = useMemo<ISearch>(() => ({
    searchFor: "projects",
    value: "",
    isSearchEnabled: false,
  }), []);
  const [search, setSearch] = useState<ISearch>(defaultSearchParams);

  const value = useMemo(
    () => ({ defaultSearchParams, search, setSearch, results, setResults }),
    [defaultSearchParams, search, results]
  );

  return (
    <SearchContext.Provider value={value}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
