import { createIcon } from '@chakra-ui/icons';

const CloudIcon = createIcon({
  path: (
    <path
      d='M15.787 9.26033C15.4228 6.39199 12.967 4.16699 10.0003 4.16699C7.70366 4.16699 5.70866 5.50949 4.79783 7.62533C3.00783 8.16033 1.66699 9.85033 1.66699 11.667C1.66699 13.9645 3.53616 15.8337 5.83366 15.8337H15.0003C16.8387 15.8337 18.3337 14.3387 18.3337 12.5003C18.3324 11.7533 18.0809 11.0283 17.6192 10.441C17.1576 9.85373 16.5125 9.43804 15.787 9.26033ZM15.0003 14.167H5.83366C4.45533 14.167 3.33366 13.0453 3.33366 11.667C3.33366 10.497 4.33283 9.37033 5.56116 9.15449L6.04533 9.06949L6.20533 8.60449C6.79116 6.89533 8.24616 5.83366 10.0003 5.83366C12.2978 5.83366 14.167 7.70283 14.167 10.0003V10.8337H15.0003C15.9195 10.8337 16.667 11.5812 16.667 12.5003C16.667 13.4195 15.9195 14.167 15.0003 14.167Z'
      fill='#282F36'
    />
  ),
  viewBox: '0 0 20 20',
});

export default CloudIcon;
