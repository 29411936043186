import { Button, IconButton, Input, Tr, Td } from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from '../../../icons';

const MinutesObservationTableRow = ({ observation, minutes, secsPerItem, onChangeMinutes, onChangeSecsPerItem, onRemove, onEdit, disabled }) => {
    return (
        <Tr>
            <Td fontWeight='semi_medium' paddingInline='20px' color='#818197'>
                <Input
                    my='-8px'
                    ml='-20px'
                    mr='-5px'
                    minW='80px'
                    disabled={true}
                    borderRadius='8px'
                    borderWidth='1px'
                    h='42px'
                    type='text'
                    fontSize='smm'
                    color='textInput.font'
                    bg='textInput.bg'
                    name="minutes"
                    defaultValue={minutes}
                    borderColor='textInput.border.normal'
                    _focus={{ borderColor: 'textInput.border.focus.normal' }}
                    _hover={{ cursor: 'auto' }}
                    onChange={onChangeMinutes}
                    cursor='pointer'
                    _disabled={{
                        bg: 'textInput.disabled.bg',
                        color: 'textInput.disabled.font',
                        borderColor: 'textInput.disabled.border',
                    }}
                    placeholder="16"
                    _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
                    css={{
                        '.chakra-switch__thumb': {
                            '&[data-checked]': {
                                background: '#462AC4'
                            }
                        }
                    }}
                />
            </Td>
            <Td fontWeight='semi_medium' paddingInline='10px' color='#818197'>
                <Input
                    ml='-10px'
                    my='-10px'
                    minW='80px'
                    disabled={true}
                    borderRadius='8px'
                    borderWidth='1px'
                    h='42px'
                    type='text'
                    fontSize='smm'
                    color='textInput.font'
                    bg='textInput.bg'
                    name="minutes"
                    defaultValue={secsPerItem}
                    borderColor='textInput.border.normal'
                    _focus={{ borderColor: 'textInput.border.focus.normal' }}
                    _hover={{ cursor: 'auto' }}
                    onChange={onChangeSecsPerItem}
                    cursor='pointer'
                    _disabled={{
                        bg: 'textInput.disabled.bg',
                        color: 'textInput.disabled.font',
                        borderColor: 'textInput.disabled.border',
                    }}
                    placeholder="16"
                    _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
                    css={{
                        '.chakra-switch__thumb': {
                            '&[data-checked]': {
                                background: '#462AC4'
                            }
                        }
                    }}
                />
            </Td>
            <Td fontWeight='semi_medium' paddingInline='20px' w='full'>{observation}</Td>
            { !disabled &&
              <>
                <Td fontWeight='semi_medium' paddingInline='0px'>
                    <Button
                        bg='clientContractorTableRow.button.bg' h='30px' color='clientContractorTableRow.button.color' pl='5px' pr='10px' fontSize='ssm'
                        borderRadius='10px' borderWidth='1px' borderColor='clientContractorTableRow.button.color'
                        onClick={onEdit}>
                        <EditIcon w='12px' h='12px' ml='5px' mr='8px' stroke='clientContractorTableRow.editIcon.stroke' />
                        Edit
                    </Button>
                </Td>
                <Td fontWeight='semi_medium' paddingInline='20px'>
                    <IconButton bg='clientContractorTableRow.deleteIcon.bg' aria-label='delete' size='xs' isRound={true}
                        icon={<DeleteIcon h='15' w='15' stroke='clientContractorTableRow.deleteIcon.stroke' onClick={onRemove} />} />
                </Td>
              </>
            }
        </Tr >
    );
};

export default MinutesObservationTableRow;

export const minutesObservationTableRowStyles = {
    minutesObservationTableRow: {

    }
}
