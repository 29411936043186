import { createIcon } from '@chakra-ui/icons';

const DotsVertical = createIcon({
  displayName: 'Edit',
  viewBox: '0 0 14 14',
  path: (
    <>
      <path d='M10.5311 5.82583C9.90427 5.82583 9.39615 6.33396 9.39615 6.96077C9.39615 7.58758 9.90427 8.0957 10.5311 8.0957C11.1579 8.0957 11.666 7.58758 11.666 6.96077C11.666 6.33396 11.1579 5.82583 10.5311 5.82583Z' />
      <path d='M6.99983 5.82583C6.37302 5.82583 5.8649 6.33396 5.8649 6.96077C5.8649 7.58758 6.37302 8.0957 6.99983 8.0957C7.62664 8.0957 8.13477 7.58758 8.13477 6.96077C8.13477 6.33396 7.62664 5.82583 6.99983 5.82583Z' />
      <path d='M3.46858 5.82583C2.84177 5.82583 2.33365 6.33396 2.33365 6.96077C2.33365 7.58758 2.84177 8.0957 3.46858 8.0957C4.09539 8.0957 4.60352 7.58758 4.60352 6.96077C4.60352 6.33396 4.09539 5.82583 3.46858 5.82583Z' />
    </>
  ),
});

export default DotsVertical;
