import { createIcon } from "@chakra-ui/icons";

const GlossaryIcon = createIcon({
  displayName: "Glossary",
  viewBox: "0 0 15 16",
  path: (
    <>
      <path d="M0.833252 8.00004C0.833252 8.87552 1.00569 9.74243 1.34072 10.5513C1.67575 11.3601 2.16682 12.095 2.78587 12.7141C3.40493 13.3331 4.13986 13.8242 4.9487 14.1592C5.75753 14.4943 6.62444 14.6667 7.49992 14.6667C8.3754 14.6667 9.2423 14.4943 10.0511 14.1592C10.86 13.8242 11.5949 13.3331 12.214 12.7141C12.833 12.095 13.3241 11.3601 13.6591 10.5513C13.9941 9.74243 14.1666 8.87552 14.1666 8.00004C14.1666 7.12456 13.9941 6.25765 13.6591 5.44882C13.3241 4.63998 12.833 3.90505 12.214 3.286C11.5949 2.66694 10.86 2.17588 10.0511 1.84084C9.2423 1.50581 8.3754 1.33337 7.49992 1.33337C6.62444 1.33337 5.75753 1.50581 4.9487 1.84084C4.13986 2.17588 3.40493 2.66694 2.78587 3.286C2.16682 3.90505 1.67575 4.63998 1.34072 5.44882C1.00569 6.25765 0.833252 7.12456 0.833252 8.00004V8.00004Z"
        fill="none" stroke="#131535" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.38892 6.88892H7.50003V11.3334"
        fill="none" stroke="#131535" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.38892 11.3334H8.61114"
        fill="none" stroke="#131535" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.49995 4.1134C7.44501 4.1134 7.3913 4.12969 7.34562 4.16022C7.29994 4.19074 7.26434 4.23412 7.24331 4.28488C7.22229 4.33564 7.21679 4.39149 7.22751 4.44537C7.23822 4.49926 7.26468 4.54875 7.30353 4.5876C7.34237 4.62645 7.39187 4.6529 7.44575 4.66362C7.49964 4.67434 7.55549 4.66884 7.60625 4.64782C7.657 4.62679 7.70039 4.59119 7.73091 4.54551C7.76143 4.49983 7.77772 4.44612 7.77772 4.39118C7.77772 4.31751 7.74846 4.24686 7.69636 4.19476C7.64427 4.14267 7.57362 4.1134 7.49995 4.1134Z"
        fill="none" stroke="#282F36" strokeLinecap="round" strokeLinejoin="round" />

    </>
  ),
});

export default GlossaryIcon;
