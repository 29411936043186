import React from 'react';

import { Box, Flex, Input, Textarea, Tooltip } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { IField } from '../../interfaces/IField';
import { DefinedValidations } from '../../interfaces/Validations';
import { Asterisk } from '../../icons';
import useValidate from '../../hooks/useValidate';
import { InfoOutlineIcon } from '@chakra-ui/icons';


interface ITextInput extends IField {
  placeholder?: string;
  variant?: string;
  styles?: {
    font?: string
    fullWidth?: boolean
    width?: string
  };
  formIndex?: string;
  type?: 'text' | 'number';
  inTable?: boolean;
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `Cannot be empty`;
    }
  },
  maxLength: (label, validationValue, value = '') => {
    if (value.length < validationValue) {
      return `${label} can be maximum ${validationValue} characters length`;
    }
  },
  isEmail: (label, validationValue, value) => {
    const regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    if (!value.match(regexEmail)) {
      return 'Invalid Email';
    }
  },
  isNumber: (label, validationValue, value) => {
    const regexNumber = /^\d+$/;
    if (value && !value.match(regexNumber)) {
      return 'Invalid Number';
    }
  },
  unique: (label, validationValue, value) => {
    let uniqueArray: string[] = [];
    if (validationValue && Array.isArray(validationValue)) {
      uniqueArray = validationValue;
    }
    if (value && uniqueArray.includes(value)) {
      return `${label} must be unique`;
    }
  }
};

const TextInput = ({ control, name, label, placeholder = '', tooltip = '', validations = {}, disabled, required, styles, variant, formIndex, type = 'text', inTable = false }: ITextInput) => {
  const validate = useValidate(label || name, validations || {}, definedValidations);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate }}
      render={({ field, fieldState }) => {
        const { onChange, onBlur, value } = field;
        const handleChange = (newValue) => {
          if (variant === 'numeric') {
            onChange((newValue || '').replaceAll(/([^0-9])*/g, ''));
          } else {
            onChange(newValue);
          }
        };
        const { error } = fieldState;
        return (
          <Box id={name} mt={inTable ? '3px' : '2px'} w='full' minH={inTable ? "0px" : "80px"}>
            <Box>{label && (
              <Flex pt={2} pb={1} align='center' justify='space-between' mb='none'>
                <Box
                  color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                  fontWeight='bold'
                  fontSize='11px'
                  position='static'
                  left='none'
                  zIndex={1}
                  minH="16px"
                >
                  {label}
                  {!disabled && validations?.notEmpty && <Asterisk h="8px" ml='5px' mb='8px' fill='textInput.iconAsterisk' stroke='textInput.iconAsterisk' />}
                  {' '}
                  {tooltip && <Tooltip hasArrow label={tooltip} placement='top'><InfoOutlineIcon mb={1} h='14px' /></Tooltip>}
                </Box>
              </Flex>
            )}
            </Box>
            {variant === 'multiline' ? <Textarea
              maxW={styles?.fullWidth ? '1109px' : styles?.width ? styles?.width : '360px'}
              borderRadius='8px'
              borderWidth='1px'
              h={inTable ? '60px' : '200px'}
              // maxWidth='700px'
              fontSize='smm'
              color='textInput.font'
              bg='textInput.bg'
              name={name}
              value={value || ''}
              borderColor={error ? 'textInput.border.error' : 'textInput.border.normal'}
              _active={{ bg: disabled ? 'textInput.disabled.bg' : 'textInput.activeBg' }}
              _focus={{ borderColor: error ? 'textInput.border.focus.error' : 'textInput.border.focus.normal' }}
              _hover={{ cursor: 'auto' }}
              onChange={onChange}
              onBlur={onBlur}
              isDisabled={disabled}
              cursor='pointer'
              _disabled={{
                bg: 'textInput.disabled.bg',
                color: 'textInput.disabled.font',
                borderColor: 'textInput.disabled.border',
                cursor: 'not-allowed',
              }}
              maxLength={validations && validations.forceMaxLength ? validations.maxLength as number : undefined}
              placeholder={placeholder}
              _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
              css={{
                '.chakra-switch__thumb': {
                  '&[data-checked]': {
                    background: '#462AC4'
                  }
                }
              }}
            /> : <Input
              maxW={styles?.fullWidth ? '1109px' : styles?.width ? styles?.width : '360px'}
              borderRadius='8px'
              borderWidth='1px'
              h='42px'
              type='text'
              pattern={variant === 'numeric' ? "[0-9]*" : ""}
              fontSize='smm'
              color='textInput.font'
              bg='textInput.bg'
              name={name}
              value={value || ''}
              borderColor={error ? 'textInput.border.error' : 'textInput.border.normal'}
              _active={{ bg: disabled ? 'textInput.disabled.bg' : 'textInput.activeBg' }}
              _focus={{ borderColor: error ? 'textInput.border.focus.error' : 'textInput.border.focus.normal' }}
              _hover={{ cursor: 'auto' }}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={onBlur}
              isDisabled={disabled}
              cursor='pointer'
              _disabled={{
                bg: 'textInput.disabled.bg',
                color: 'textInput.disabled.font',
                borderColor: 'textInput.disabled.border',
                cursor: 'not-allowed',
              }}
              maxLength={validations && validations.forceMaxLength ? validations.maxLength as number : undefined}
              placeholder={placeholder}
              _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
              css={{
                '.chakra-switch__thumb': {
                  '&[data-checked]': {
                    background: '#462AC4'
                  }
                }
              }}
            />}

            {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
          </Box>
        );
      }}
    />
  );
};

export default TextInput;

export const textInputStyles = {
  textInput: {
    font: '#777777',
    bg: '#FFFFFF',
    labelFont: {
      normal: '#131535',
      error: '#E53E3E',
    },
    border: {
      normal: 'rgba(129, 129, 151, 0.4)',
      error: '#E53E3E',
      focus: {
        normal: '#777777',
        error: '#E53E3E',
      },
    },
    activeBg: '#FFFFFF',
    disabled: {
      font: '#2B3236',
      border: '#EEEEEE',
      bg: '#f7f7f7',
    },
    placeholder: '#CBCCCD',
    error: '#E53E3E',
    iconAsterisk: '#DC0043'
  },
};

