import { createIcon } from "@chakra-ui/icons";

const PeopleGroupICon = createIcon({
  displayName: "PeopleGroup",
  viewBox: "0 0 16 16",
  path: (
    <>
      <path d="M12.4993 9.52979C13.1126 9.52979 13.6099 9.03256 13.6099 8.41919C13.6099 7.80583 13.1126 7.30859 12.4993 7.30859C11.8859 7.30859 11.3887 7.80583 11.3887 8.41919C11.3887 9.03256 11.8859 9.52979 12.4993 9.52979Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.3146 10.9159C14.1003 10.6396 13.8257 10.416 13.5117 10.2621C13.1977 10.1083 12.8527 10.0283 12.503 10.0283C12.1533 10.0283 11.8083 10.1083 11.4943 10.2621C11.1803 10.416 10.9057 10.6396 10.6914 10.9159" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.49927 9.52979C4.11264 9.52979 4.60987 9.03256 4.60987 8.41919C4.60987 7.80583 4.11264 7.30859 3.49927 7.30859C2.8859 7.30859 2.38867 7.80583 2.38867 8.41919C2.38867 9.03256 2.8859 9.52979 3.49927 9.52979Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.68555 10.9156C1.89947 10.6393 2.17374 10.4155 2.4874 10.2614C2.80105 10.1072 3.14579 10.0269 3.49527 10.0264C3.84475 10.0259 4.18971 10.1053 4.5038 10.2585C4.81789 10.4118 5.09278 10.6348 5.30747 10.9106" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.8328 11.7924C10.8328 11.0412 10.5344 10.3207 10.0032 9.78956C9.47205 9.25838 8.75161 8.95996 8.0004 8.95996C7.2492 8.95996 6.52875 9.25838 5.99757 9.78956C5.46638 10.3207 5.16797 11.0412 5.16797 11.7924" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.97067 8.18646C8.89509 8.18646 9.64447 7.43708 9.64447 6.51266C9.64447 5.58825 8.89509 4.83887 7.97067 4.83887C7.04626 4.83887 6.29688 5.58825 6.29688 6.51266C6.29688 7.43708 7.04626 8.18646 7.97067 8.18646Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.99978 15.1109C11.9271 15.1109 15.1109 11.9271 15.1109 7.99978C15.1109 4.07242 11.9271 0.888672 7.99978 0.888672C4.07242 0.888672 0.888672 4.07242 0.888672 7.99978C0.888672 11.9271 4.07242 15.1109 7.99978 15.1109Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
});

export default PeopleGroupICon;
