import React, { useEffect } from 'react';

import { Flex, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toastFailed, toastSuccess } from '../../../bootstrap/config';
import AdminModal from '../../AdminModal';
import TextInput from './../TextInput';
import { format } from 'date-fns';
import { Datepicker, PeoplePicker } from '..';

const RecordKeepingTableModal = ({
  modalState,
  setModalState,
  dataValues,
  setDataValues,
  selectedDataValue,
  name,
  onChange,
  formIndex,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      _id: undefined,
      pN: selectedDataValue?.pN || '',
      testStandard: selectedDataValue?.testStandard || '',
      projectLeader: selectedDataValue?.projectLeader || '',
      reviewer: selectedDataValue?.reviewer || '',
      reviewDate: selectedDataValue?.reviewDate || '',
      testDate: selectedDataValue?.testDate || '',
      comments: selectedDataValue?.comments || '',
    },
  });
  const toast = useToast();

  useEffect(() => {
    if (selectedDataValue !== '') {
      reset(selectedDataValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataValue]);

  const handleAddRecordContractor = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        let val: object = {
          pN: values.pN,
          testStandard: values.testStandard,
          projectLeader: values.projectLeader,
          reviewer: values.reviewer,
          reviewDate: values.reviewDate
            ? format(new Date(values.reviewDate), 'dd MMM yyyy')
            : undefined,
          testDate: values.testDate
            ? format(new Date(values.testDate), 'dd MMM yyyy')
            : undefined,
          comments: values.comments,
        };
        dataValues instanceof Array
          ? dataValues.push(val)
          : (dataValues = [val]);
        setDataValues(dataValues);
        onChange({ target: { name, value: dataValues } });
        toast({ ...toastSuccess, description: 'Record added' });
        reset({
          _id: undefined,
          pN: '',
          testStandard: '',
          projectLeader: '',
          reviewer: '',
          reviewDate: '',
          testDate: '',
          comments: '',
        });
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState('closed');
    }
  };

  const handleUpdateRecordContractor = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        let updatedData = [...dataValues];
        updatedData[selectedDataValue?.index] = {
          pN: values.pN,
          testStandard: values.testStandard,
          projectLeader: values.projectLeader,
          reviewer: values.reviewer,
          reviewDate: values.reviewDate
            ? format(new Date(values.reviewDate), 'dd MMM yyyy')
            : undefined,
          testDate: values.testDate
            ? format(new Date(values.testDate), 'dd MMM yyyy')
            : undefined,
          comments: values.comments,
        };
        setDataValues(updatedData);
        onChange({ target: { name, value: updatedData } });
        toast({ ...toastSuccess, description: 'Record updated' });
        reset({
          _id: undefined,
          pN: '',
          testStandard: '',
          projectLeader: '',
          reviewer: '',
          reviewDate: '',
          testDate: '',
          comments: '',
        });
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState('closed');
    }
  };

  const handleDeleteRecordContractor = async () => {
    try {
      let updatedData = dataValues.filter(
        (p, index) => index !== selectedDataValue?.index
      );
      setDataValues(updatedData);
      onChange({ target: { name, value: updatedData } });
      toast({ ...toastSuccess, description: 'Record deleted' });
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setModalState('closed');
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    if (['add', 'edit'].includes(action) && !isFormValid) {
      return toast({
        ...toastFailed,
        description: 'Please complete all the required fields',
      });
    }
    switch (action) {
      case 'add':
        handleAddRecordContractor();
        break;
      case 'edit':
        handleUpdateRecordContractor();
        break;
      case 'delete':
        handleDeleteRecordContractor();
        break;
      default:
        setModalState('closed');
    }
  };
  return (
    <AdminModal
      isOpenModal={modalState !== 'closed'}
      modalType={modalState}
      onAction={handleAction}
      collection={'Records'}
    >
      <Flex align='flex-start' direction='column' w='full'>
        <TextInput
          control={control}
          name='pN'
          placeholder='P/N'
          label='P/N'
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />

        <TextInput
          control={control}
          name='testStandard'
          placeholder='Test Standard'
          label='Test Standard'
          validations={{ notEmpty: true }}
          formIndex={formIndex}
        />

        <PeoplePicker
          control={control}
          name='projectLeader'
          label='Project Leader'
          validations={{ notEmpty: true }}
          variant='widerTeam'
          inTable={true}
          formIndex={formIndex}
        />

        <PeoplePicker
          control={control}
          name='reviewer'
          label='Review'
          validations={{ notEmpty: true }}
          variant='widerTeam'
          inTable={true}
          formIndex={formIndex}
        />

        <Datepicker
          control={control}
          name='reviewDate'
          label='Review Date'
          validations={{ notEmpty: true }}
          canSelectFutureDate={true}
          formIndex={formIndex}
        />

        <Datepicker
          control={control}
          name='testDate'
          label='Test Date'
          validations={{ notEmpty: true }}
          canSelectFutureDate={true}
          formIndex={formIndex}
        />

        <TextInput
          control={control}
          name='comments'
          placeholder='Comments'
          label='Comments'
          variant={'multiline'}
          formIndex={formIndex}
        />
      </Flex>
    </AdminModal>
  );
};

export default RecordKeepingTableModal;
