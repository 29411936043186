import { gql } from '@apollo/client';

const GET_PROJECT_TEMPLATES_CATEGORIES = gql`
  query {
    projectTemplatesCategories {
      _id
      templates {
        _id
        name
        isUkasRelated
        owner {
          displayName
          jobTitle
          imgUrl
        }
        projects
        metatags {
          addedAt
          updatedAt
        }
      }
    }
  }
`;

export default GET_PROJECT_TEMPLATES_CATEGORIES;
