import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { useState } from 'react';
import { SearchIcon, CrossIcon } from "../../icons";

import UserMenu from "./UserMenu";
import SearchBar from "./SearchBar";

const NavigationTop = () => {
  const [displaySearch, setDisplaySearch] = useState(false);

  return (
    <Flex
      justify="space-between"
      align="center"
      w={["100vw", "full"]}
      position={["fixed", "relative"]}
      zIndex={10}
      bg="F4F4F4"
      pt={4}
    >
      <SearchBar />
      <Flex
        align="center"
        display={displaySearch ? "none" : "flex"}
      >
        <IconButton
          mr="27.5px"
          bg="navigationTop.searchIconBackground"
          aria-label='Search database'
          borderRadius="20px"
          icon={<SearchIcon h="22px" w="18px" fill="navigationTop.searchBarIcon" stroke="brand.outerSpace" opacity="1" />}
          display={["block", "none"]}
          onClick={() => { setDisplaySearch(true) }}
        />
        {/* <NotificationIcon
          _hover={{ color: "navigationTop.notificationIconHover", opacity: 0.7, cursor: "pointer" }}
          _active={{}}
          h="20px"
          w="22px"
        />
        <Badge variant="solid" bg="navigationTop.notificationColorScheme" border="2px solid" borderColor="navigationTop.notificationBadgeBorder" borderRadius="5px" cursor="pointer">3</Badge> */}
        <UserMenu />
      </Flex>

      <Stack
        spacing={4}
        direction="row"
        align="center"
        fontWeight="semi_medium"
        fontSize="md"
        w="full"
        mr={["0", "20px"]}
        ml={5}
        display={displaySearch ? "block" : "none"}
      >
        <Flex>
          <InputGroup display={["block", "none"]} w={["calc(100vw - 50px)"]}>
            <InputLeftElement
              pointerEvents="none"
              color="navigationTop.inputIconColor"
              children={<SearchIcon fill="navigationTop.searchBarIcon" stroke="brand.outerSpace" opacity="1" />}
            />
            <InputRightElement width='10px'>
              <CrossIcon
                _hover={{ color: "navigationTop.notificationIconHover", opacity: 0.7, cursor: "pointer" }}
                _active={{}}
                h="13.5px"
                w="13.5px"
                onClick={() => { setDisplaySearch(false) }}
                stroke="navigationTop.searchCrossIconStroke"
              />
            </InputRightElement>
            <Input bg="navigationTop.inputBg" rounded="20px" placeholder="Search" fontWeight="semi_medium" fontSize="smm"></Input>
          </InputGroup>
        </Flex>
      </Stack>
    </Flex >
  );
};

export default NavigationTop;
