import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDown, EditIcon, PlusIcon } from '../../icons';
import DeleteIcon from '../../icons/DeleteIcon';
import { useQuery, useMutation } from '@apollo/client';
import Loader from '../../components/Loader';
import GET_COMMON_FORMS from '../../gql/queries/GET_COMMON_FORMS';
import { IFormTemplate } from '../../interfaces/IFormTemplate';
import NewCommonFormModal from '../../components/CommonForms/NewCommonFormModal';
import TableHeader from '../../components/FormControls/TableHeader';
import useSort from '../../hooks/useSort';
import { dateDecorated } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../../components/ConfirmationModal';
import BinIcon from '../../icons/BinIcon';
import { toastSuccess } from '../../bootstrap/config';
import DELETE_FORM_TEMPLATE from '../../gql/mutation/DELETE_FORM_TEMPLATE';
import Can from '../../components/Can';
import EyeIcon from '../../icons/Eye';

const CommonForms = () => {
  const history = useHistory();
  const toast = useToast();
  const { data, loading, refetch } = useQuery(
    GET_COMMON_FORMS,
    {
      variables: {
        formTemplatesQueryInput: {
          isCommonForm: true,
        },
      },
      fetchPolicy: 'network-only',
    }
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();
  const [formToDelete, setFormToDelete] = useState<IFormTemplate>();
  const [deleteFormTemplate] = useMutation(DELETE_FORM_TEMPLATE);

  const commonForms: IFormTemplate[] = data?.getFormTemplates || [];
  const { sortedData, sortOrder, sortType, setSortType, setSortOrder } = useSort(commonForms || [], 'name');
  const handleColumnClick = (key: string) => {
    setSortType(key);
    setSortOrder(sortOrder === 'asc' && key === sortType ? 'desc' : 'asc');
  };

  const deleteForm = async () => {
    if (formToDelete) {
      await deleteFormTemplate({
        variables: {
          formTemplateInput: { _id: formToDelete._id },
        },
      });
      await refetch();
      setFormToDelete(undefined);
      toast({ ...toastSuccess, description: 'Form deleted successfully' });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ConfirmationModal
        modalTitle="Delete common form"
        modalText={`Are you sure you want to delete form "<b>${formToDelete?.name}</b>"?`}
        confirmButtonText="Remove"
        confirmButtonIcon={<BinIcon />}
        cancelButtonText="Discard"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        confirmAction={deleteForm}
      />
      <NewCommonFormModal isOpen={isOpen} onClose={onClose} />
      <Box overflowY='auto' overflowX='hidden' pr={4}>
        <HStack pb={6} justifyContent='space-between'>
          <Text fontSize='24px' fontWeight='700'>
            Common forms
          </Text>

          <Can
            action="commonForms.create"
            yes={() =>
              <Button
                h='32px'
                bg='brePink'
                color='white'
                fontSize='ssm'
                fontWeight='bold'
                alignItems='center'
                p={2}
                onClick={onOpen}
                leftIcon={<PlusIcon w='11px' h='11px' />}
                minW="none"
              >Create new form</Button>
            }
          />
        </HStack>
        {!commonForms?.length ? (
          <Text>No common forms has been created yet.</Text>
        ) : (
          <Flex bg="white" rounded="20px">
            <Table variant='simple'>
              <Thead fontSize="smm" fontWeight="bold" >
                <Tr>
                  <TableHeader
                    header='Form'
                    styles={{ paddingInline: 6, fontWeight: "bold", width: "65%" }}
                    icon={sortType === 'name' ? <ChevronDown
                      ml={2}
                      h="12px"
                      transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                      w="12px"
                    /> : undefined}
                    onClick={() => handleColumnClick('name')}
                  />
                  <TableHeader
                    header='Created At'
                    styles={{ paddingInline: 6, fontWeight: "bold", width: "15%" }}
                    icon={sortType === 'metatags.addedAt' ? <ChevronDown
                      ml={2}
                      h="12px"
                      transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                      w="12px"
                    /> : undefined}
                    onClick={() => handleColumnClick('metatags.addedAt')}
                  />
                  <TableHeader
                    header='Updated At'
                    styles={{ paddingInline: 6, fontWeight: "bold", width: "15%" }}
                    icon={sortType === 'metatags.updatedAt' ? <ChevronDown
                      ml={2}
                      h="12px"
                      transform={sortOrder === 'asc' ? "rotate(180deg)" : ""}
                      w="12px"
                    /> : undefined}
                    onClick={() => handleColumnClick('metatags.updatedAt')}
                  />
                  <Th w="5%" />
                </Tr>
              </Thead>
              <Tbody>
                {sortedData.map((form) => (
                  <Tr key={form._id}>
                    <Td w="20%">{form.name}</Td>
                    <Td w="15%">
                      {form.metatags?.addedAt ? dateDecorated(form.metatags?.addedAt) : '--'}
                    </Td>
                    <Td w="15%">
                      {form.metatags?.updatedAt ? dateDecorated(form.metatags?.updatedAt) : '--'}
                    </Td>
                    <Td w="5%">
                      <Flex
                        alignItems='center'
                        cursor='pointer'
                      >
                        <Can
                          action="commonForms.edit"
                          yes={() =>
                            <EditIcon
                              mr='1'
                              onClick={() => history.push(`/admin/common-forms/${form._id}`)}
                            />
                          }
                          no={() =>
                            <EyeIcon
                              mr='1'
                              color="white"
                              onClick={() => history.push(`/admin/common-forms/${form._id}`)}
                            />
                          }
                        />
                        <Can
                          action="commonForms.delete"
                          yes={() =>
                            <DeleteIcon
                              stroke='black'
                              onClick={() => {
                                setFormToDelete(form);
                                openDeleteModal();
                              }}
                            />
                          }
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default CommonForms;
