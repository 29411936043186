import { createIcon } from '@chakra-ui/icons';

const ClockFastIcon = createIcon({
  path: (
    <>
      <path
        d='M16.7878 6.89197L18.0903 5.58947L16.912 4.41113L15.4887 5.83363C14.6137 5.30863 13.5937 5.0003 12.5003 5.0003C9.28449 5.0003 6.66699 7.61697 6.66699 10.8336C6.66699 14.0503 9.28449 16.667 12.5003 16.667C15.7162 16.667 18.3337 14.0503 18.3337 10.8336C18.333 9.37199 17.781 7.96439 16.7878 6.89197ZM12.5003 15.0003C10.2028 15.0003 8.33366 13.1311 8.33366 10.8336C8.33366 8.53613 10.2028 6.66697 12.5003 6.66697C14.7978 6.66697 16.667 8.53613 16.667 10.8336C16.667 13.1311 14.7978 15.0003 12.5003 15.0003Z'
        fill='#282F36'
      />
      <path
        d='M11.667 8.33333H13.3337V11.6667H11.667V8.33333ZM10.8337 2.5H14.167V4.16667H10.8337V2.5ZM2.50033 6.66667H5.83366V8.33333H2.50033V6.66667ZM2.50033 13.3333H5.83366V15H2.50033V13.3333ZM1.66699 10H4.99199V11.6667H1.66699V10Z'
        fill='black'
      />
    </>
  ),
  viewBox: '0 0 20 20',
});

export default ClockFastIcon;
