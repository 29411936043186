import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import { CrossIcon, SaveIcon } from '../icons';
import ImageEditor from '@toast-ui/react-image-editor';
import { useEffect, useRef, useState } from 'react';

import useIsIpad from '../hooks/useIsIpad';
interface IAnnotationModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPhoto: any;
  onClickHandler: (...event: any[]) => void;
}

const AnnotationModal = ({
  isOpen,
  onClose,
  selectedPhoto,
  onClickHandler,
}: IAnnotationModalProps): JSX.Element => {
  const ipad = useIsIpad();
  const editorRef: any = useRef(null);

  useEffect(() => {
    if (ipad) {
      const intervalId = setInterval(() => {
        const width = 5;
        const imageEditor = editorRef.current?.getInstance();
        imageEditor?.setBrush({
          width,
        });

        (
          document.getElementsByClassName(
            'tie-draw-range-value'
          )[0] as HTMLInputElement
        ).value = String(width);

        (
          document.getElementsByClassName(
            'tui-image-editor-range-wrap'
          )[0] as HTMLInputElement
        ).style.display = 'none';
      }, 350);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [editorRef, ipad]);
  const [uploading, setUploading] = useState<boolean>(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} trapFocus={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent h='90vh' minW='90vw' bg='#1e1e1e'>
        <Flex position='relative' top='15px' right='15px' zIndex='2'>
          <Spacer />
          <Button
            bg='#FFF'
            color='breNavy'
            isLoading={uploading}
            loadingText='Saving...'
            _hover={{
              bg: 'reportFormHeader.buttonDisableBg',
              color: 'reportFormHeader.buttonDisableColor',
            }}
            onClick={() => {
              const editorInstance = editorRef.current.getInstance();
              let editedImage = editorInstance.toDataURL();
              setUploading(true);
              onClickHandler(editedImage);
              setUploading(false);
            }}
            onTouchEnd={() => {
              if (ipad) {
                const editorInstance = editorRef.current.getInstance();
                let editedImage = editorInstance.toDataURL();
                onClickHandler(editedImage);
              }
            }}
          >
            <SaveIcon stroke='breNavy' h='25px' w='20px' mr='10px' />
            Save and Close
          </Button>
          <Button
            ml='10px'
            bg='brePink'
            color='freeHandInput.saveCloseButton.color'
            _hover={{
              bg: 'reportFormHeader.buttonDisableBg',
              color: 'reportFormHeader.buttonDisableColor',
            }}
            onClick={onClose}
            onTouchEnd={onClose}
          >
            <CrossIcon
              stroke='freeHandInput.saveCloseButton.color'
              h='25px'
              w='20px'
              mr='10px'
            />
            Cancel
          </Button>
        </Flex>
        <ModalBody>
          <ImageEditor
            ref={editorRef}
            includeUI={{
              loadImage: {
                path: selectedPhoto ? selectedPhoto.imageData : '',
                name: 'Blank',
              },
              locale: {
                Range: 'Width',
              },
              theme: {
                'header.display': 'none',
              },
              menu: ['draw', 'shape', 'icon', 'text'],
              initMenu: 'draw',
              menuBarPosition: 'bottom',
              uiSize: {
                width: '100%',
                height: '100%',
              },
            }}
            cssMaxHeight={500}
            cssMaxWidth={800}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AnnotationModal;
