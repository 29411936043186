import { format } from 'date-fns';
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Tr, Td, Button, IconButton, Text } from "@chakra-ui/react";
import { CrossIcon, CheckIcon } from "../../../icons";

import UserAvatar from "../../UserAvatar";

const TableInputRow = ({ columns, index, dataValue, openModal, disabled }) => {
  return (
    <Tr>
      {columns?.map(({ value, type, variant }) => {
        if (type === 'peoplePicker') {
          return (
            <Td key={`row-${index}-${value}`} px={2}>
              <UserAvatar userId={dataValue[value]} />
            </Td>
          );
        } else if (type === 'datepicker') {
          switch (variant) {
            case 'date':
              return (
                <Td key={`row-${index}-${value}`} px={2}>
                  {dataValue[value] ? format(new Date(dataValue[value]), 'dd MMM yyyy') : ''}
                </Td>
              );

            case 'time':
              return (
                <Td key={`row-${index}-${value}`} px={2}>
                  {dataValue[value] ? format(new Date(dataValue[value]), 'HH:mm') : ''}
                </Td>
              );

            default: // datetime
              return (
                <Td key={`row-${index}-${value}`} px={2}>
                  {dataValue[value] ? format(new Date(dataValue[value]), 'dd MMM yyyy HH:mm') : ''}
                </Td>
              );
          }
        } else if (type === 'signature') {
          return (
            <Td key={`row-${index}-${value}`} px={2}>
              {dataValue[value] ? <CheckIcon color='breSuccess' w="16px" /> : <CrossIcon color='breFail' w="16px" />}
            </Td>
          );
        }
        return (
          <Td key={`row-${index}-${value}`} px={2}>
            <Text whiteSpace='break-spaces'>{dataValue[value]}</Text>
          </Td>
        );
      }
      )}
      {!disabled && (
        <>
          <Td fontWeight="semi_medium" paddingInline="0px">
            <Button
              disabled={disabled}
              _disabled={{ opacity: 0.75, cursor: "not-allowed" }}
              bg="TechSignatureTableRow.button.bg"
              h="30px"
              color="TechSignatureTableRow.button.color"
              pl="5px"
              pr="10px"
              fontSize="ssm"
              borderRadius="10px"
              borderWidth="1px"
              borderColor="TechSignatureTableRow.button.color"
              onClick={() =>
                openModal("edit", {
                  ...dataValue,
                  index: index,
                })
              }
            >
              <EditIcon
                w="12px"
                h="12px"
                ml="5px"
                mr="8px"
                stroke="TechSignatureTableRow.editIcon.stroke"
              />
              Edit
            </Button>
          </Td>
          <Td fontWeight="semi_medium" paddingInline="20px">
            <IconButton
              bg="TechSignatureTableRow.deleteIcon.bg"
              aria-label="delete"
              size="xs"
              isRound={true}
              disabled={disabled}
              _disabled={{ opacity: 0.75, cursor: "not-allowed" }}
              icon={
                <DeleteIcon
                  h="15"
                  w="15"
                  stroke="TechSignatureTableRow.deleteIcon.stroke"
                  onClick={() =>
                    openModal("delete", {
                      ...dataValue,
                      index: index,
                    })
                  }
                />
              }
            />
          </Td>
        </>
      )}
    </Tr>
  );
};

export default TableInputRow;
