import { useMemo } from 'react';
import { Box, Icon, Flex, Text, Stack, HStack } from '@chakra-ui/react';

import { INavigationLeftItem } from '../../interfaces/INavigationLeftItem';
import { useHistory, useLocation } from 'react-router-dom';
import { isPermitted } from '../Can';
import { useAppContext } from '../../contexts/AppProvider';

const NavigationLeftItem = (navigationLeftItem: INavigationLeftItem) => {
  const { label, icon, path, subMenus } = navigationLeftItem;
  const history = useHistory();
  const location = useLocation();
  const { user } = useAppContext();

  const isActive = useMemo(
    () =>
      location.pathname === path ||
      (subMenus || []).some(
        ({ path: subMenuPath }) => location.pathname.includes(subMenuPath)
      ),
    [location.pathname, path, subMenus]
  );

  const subMenuItems = (subMenus || []).filter(({ permission }) =>
    isPermitted({
      user,
      action: permission,
    })
  );

  return (
    <>
      <Box
        w='240px'
        h='42px'
        mt='5px'
        display='flex'
        pos='relative'
        alignItems='center'
        fontSize='md'
        fontWeight='normal'
        _hover={{
          cursor: 'pointer',
        }}
        onClick={() => history.push(path)}
      >
        <Flex h='100%' align='center'>
          <Flex
            w='30px'
            h='30px'
            ml='25px'
            alignItems='center'
            justifyContent='center'
            bg={isActive ? 'breNavy' : '#FFF'}
            rounded='8px'
          >
            <Icon
              w='15px'
              h='15px'
              as={icon}
              color={isActive ? '#FFF' : '#818197'}
            />
          </Flex>
        </Flex>
        <Box
          ml='5'
          fontSize='14px'
          fontWeight='semi_medium'
          opacity={isActive ? 1 : 0.5}
        >
          {label}
        </Box>
      </Box>
      {subMenuItems.length > 0 && isActive && (
        <Stack spacing={5} pl={9} mt={3}>
          {subMenuItems.map((subMenu) => (
            <HStack key={subMenu.path} spacing={4} align='center'>
              <Box
                w='8px'
                h='8px'
                bg={location.pathname.includes(subMenu.path) ? 'brePink' : 'none'}
                rounded='full'
              />
              <Box
                fontSize='smm'
                opacity={location.pathname.includes(subMenu.path) ? 1 : 0.5}
                onClick={() => history.push(subMenu.path)}
                cursor='pointer'
                fontWeight='normal'
              >
                <Text ml='15px'>{subMenu.label}</Text>
              </Box>
            </HStack>
          ))}
        </Stack>
      )}
    </>
  );
};

export default NavigationLeftItem;
