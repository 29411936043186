import { useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import JoditEditor from 'jodit-react';

import useValidate from '../../hooks/useValidate';
import { Asterisk } from '../../icons';
import { IField } from '../../interfaces/IField';
import { DefinedValidations } from '../../interfaces/Validations';

interface IRichTextEditor extends IField {
  styles?: {
    font?: string;
    width?: string;
  };
}

const definedValidations: DefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && !value) {
      return `${label} cannot be empty`;
    }
  },
};

const RichTextEditor = ({
  control,
  name,
  label,
  placeholder = '',
  tooltip = '',
  validations = {},
  disabled = false,
  required,
  styles,
}: IRichTextEditor) => {
  const validate = useValidate(label || name, validations, definedValidations);
  const [content, setContent] = useState('');
  const ref = useRef(null);

  const config = useMemo<any>(
    () => ({
      // all options from https://xdsoft.net/jodit/play.html
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'superscript',
        'subscript',
        'undo',
        'redo',
      ],
      disablePlugins: 'add-new-line,image',
      placeholder: placeholder || '',
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      toolbarAdaptive: false,
    }),
    [placeholder]
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        const { error } = fieldState;

        const handleChange = (content) => {
          setContent(content);
          onChange({ target: { name, value: content } });
        };

        return (
          <Box id={name} mt={2} w='full'>
            {label && (
              <Flex
                align='center'
                justify='space-between'
                mb='none'
                pb={1}
                pt={2}
              >
                <Box
                  color={
                    error
                      ? 'textInput.labelFont.error'
                      : styles
                        ? styles?.font
                        : 'textInput.labelFont.normal'
                  }
                  fontSize='11px'
                  fontWeight='bold'
                  left='none'
                  position='static'
                  h="16px"
                >
                  {label}
                  {!disabled && validations?.notEmpty && (
                    <Asterisk
                      fill='textInput.iconAsterisk'
                      h='9px'
                      mb='8px'
                      ml='5px'
                      stroke='textInput.iconAsterisk'
                      w='9px'
                    />
                  )}{' '}
                  {tooltip && (
                    <Tooltip hasArrow label={tooltip} placement='top'>
                      <Icon name='info' mb={1} h='14px' />
                    </Tooltip>
                  )}
                </Box>
                {!('notEmpty' in validations) && (
                  <Text
                    color='textInput.optional'
                    fontSize='xxs'
                    fontWeight='semi_medium'
                  >
                    Optional
                  </Text>
                )}
              </Flex>
            )}
            {!disabled && (
              <JoditEditor
                config={config}
                onBlur={handleChange}
                onChange={setContent}
                ref={ref}
                value={content || value}
              />
            )}
            {disabled && (
              <Box
                bg='#f7f7f7'
                cursor='not-allowed'
                dangerouslySetInnerHTML={{ __html: value }}
                minH='40px'
                overflowX='auto'
                padding='10px'
                rounded='9px'
                w='full'
              />
            )}
            {error && (
              <Box color='textInput.error' fontSize={14} ml={1}>
                {error.message}
              </Box>
            )}
          </Box>
        );
      }}
      rules={{ validate }}
    />
  );
};

export default RichTextEditor;
