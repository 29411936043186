import { Input, Tr, Td } from "@chakra-ui/react";

const MinutesTableRow = ({ integrity, minutes, secsPerItem, onChangeMinutes, onChangeSecsPerItem, disabled }) => {
    return (
        <Tr>
            <Td fontWeight='semi_medium' paddingInline='0px' w='full'>{integrity}</Td>
            <Td fontWeight='semi_medium' paddingInline='20px' color='#818197'>
                <Input
                    my='-8px'
                    ml='-20px'
                    mr='-5px'
                    minW='80px'
                    borderRadius='8px'
                    borderWidth='1px'
                    h='42px'
                    type='number'
                    fontSize='smm'
                    color='textInput.font'
                    bg='textInput.bg'
                    name="minutes"
                    defaultValue={minutes}
                    borderColor='textInput.border.normal'
                    _focus={{ borderColor: 'textInput.border.focus.normal' }}
                    _hover={{ cursor: 'auto' }}
                    onChange={onChangeMinutes}
                    cursor='pointer'
                    disabled={disabled}
                    _disabled={{
                        bg: 'textInput.disabled.bg',
                        color: 'textInput.disabled.font',
                        borderColor: 'textInput.disabled.border',
                        cursor: 'not-allowed',
                    }}
                    placeholder="00"
                    _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
                    css={{
                        '.chakra-switch__thumb': {
                            '&[data-checked]': {
                                background: '#462AC4'
                            }
                        }
                    }}

                />
            </Td>
            <Td fontWeight='semi_medium' paddingInline='10px' color='#818197'>
                <Input
                    ml='-10px'
                    my='-10px'
                    minW='80px'
                    borderRadius='8px'
                    borderWidth='1px'
                    h='42px'
                    type='number'
                    fontSize='smm'
                    color='textInput.font'
                    bg='textInput.bg'
                    name="minutes"
                    defaultValue={secsPerItem}
                    borderColor='textInput.border.normal'
                    _focus={{ borderColor: 'textInput.border.focus.normal' }}
                    _hover={{ cursor: 'auto' }}
                    onChange={onChangeSecsPerItem}
                    cursor='pointer'
                    disabled={disabled}
                    _disabled={{
                        bg: 'textInput.disabled.bg',
                        color: 'textInput.disabled.font',
                        borderColor: 'textInput.disabled.border',
                        cursor: 'not-allowed',
                    }}
                    placeholder="00"
                    _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
                    css={{
                        '.chakra-switch__thumb': {
                            '&[data-checked]': {
                                background: '#462AC4'
                            }
                        }
                    }}
                />
            </Td>
        </Tr>
    );
};

export default MinutesTableRow;

export const minutesTableRowStyles = {
    minutesTableRow: {

    }
}
