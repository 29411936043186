import { gql } from '@apollo/client';

const UPDATE_PROJECT_TEMPLATE = gql`
  mutation ($projectTemplateInput: ProjectTemplateInput!) {
    updateProjectTemplate(projectTemplateInput: $projectTemplateInput) {
      _id
    }
  }
`;

export default UPDATE_PROJECT_TEMPLATE;
