import { useContext, useEffect, useState } from 'react';

import {
    Box,
    Button,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
} from '@chakra-ui/react';
import { ModalContext } from '../../../contexts/ModalProvider';
import { Controller } from 'react-hook-form';
import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import { AddIcon } from '../../../icons';
import ClientContractorTableRow from './ClientContractorTableRow';
import ClientContractorTableModal from './ClientContractorTableModal';
import TableHeader from '../TableHeader';
import useValidate from '../../../hooks/useValidate';

interface IClientContractorTable extends IField {
    data?: any;
    description?: string;
    variant?: string;
    help?: string;
    styles?: {
        font?: string
    };
    formIndex?: string;
}

interface IClientContractorTableData {
    _id: undefined,
    name: string,
    signed: string,
    company: string,
    supervisor: string,
    supervisorSigned: string,
    date: string,
    formIndex: string
}

const definedValidations: DefinedValidations = {
    notEmpty: (label, validationValue, value) => {
        if (validationValue && !value) {
            return `Cannot be empty`;
        }
    },
};

const ClientContractorTable = ({ control, name, label, validations = {}, disabled = false, help = '', styles, formIndex }: IClientContractorTable) => {
    const validate = useValidate(label || name, validations || {}, definedValidations);
    const [dataValues, setdataValues] = useState<IClientContractorTableData[]>([]);
    const { modalState, setModalState } = useContext(ModalContext);
    const [currentDataValue, setCurrentDataValue] = useState<any>('');

    useEffect(() => {
        setdataValues(control._formValues.contractorClientRepresentatives);
    }, [control._formValues.contractorClientRepresentatives]);

    // Reset the form after closing
    useEffect(() => {
        if (modalState === 'closed') {
            setCurrentDataValue('');
        }
    }, [modalState]);

    // If modal opened in edit or delete mode, reset the form and set values of edited element
    const openClientContractorModal = (action: 'add' | 'edit' | 'delete', dataValue?: any) => {
        if (action !== 'add') {
            setCurrentDataValue(dataValue);
        } else {
            setCurrentDataValue({ name: "", signature: "", company: "", supervisor: "", supervisorSignature: "" });
        }
        setModalState(action);
    };


    return (
        <Controller
            name={name}
            control={control}
            rules={{ validate }}
            render={({ field, fieldState }) => {
                const { onChange } = field;
                const { error } = fieldState;

                return (
                    <>
                        <ClientContractorTableModal
                            modalState={modalState}
                            setModalState={setModalState}
                            dataValues={dataValues}
                            setdataValues={setdataValues}
                            selectedDataValue={currentDataValue}
                            onChange={onChange}
                            formIndex={formIndex}
                            name={name}></ClientContractorTableModal>
                        <Box w='full' id={name} mt='none' maxW='full'>
                            {label && (
                                <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
                                    <Box
                                        color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                                        fontWeight='bold'
                                        fontSize='md'
                                        position='static'
                                        left='none'
                                        zIndex={2}
                                    >
                                        {label}
                                    </Box>
                                </Flex>
                            )}
                            <Box maxW='full' overflowX='auto'>
                                <Table fontSize='smm'>
                                    <Thead>
                                        <Tr>
                                            <TableHeader header='Name' styles={{ paddingInline: '0px' }}></TableHeader>
                                            <TableHeader header='Signed' styles={{ paddingInline: '10px' }}></TableHeader>
                                            <TableHeader header='Company' styles={{ paddingInline: '20px' }}></TableHeader>
                                            <TableHeader header='Supervisor name' styles={{ paddingInline: '20px' }}></TableHeader>
                                            <TableHeader header='Supervisor signed'></TableHeader>
                                            <TableHeader header='Date' styles={{ paddingInline: '20px' }}></TableHeader>
                                            <TableHeader header=''></TableHeader>
                                            <TableHeader header=''></TableHeader>
                                        </Tr>
                                    </Thead>
                                    <Tbody left='-10px'>
                                        {dataValues && dataValues?.map((dataValue, index) => < ClientContractorTableRow
                                            key={index}
                                            dataValue={dataValue}
                                            disabled={disabled}
                                            onEdit={() => openClientContractorModal('edit', { ...dataValue, index: index })}
                                            onRemove={() => openClientContractorModal('delete', { ...dataValue, index: index })}
                                        ></ClientContractorTableRow >)}
                                    </Tbody>
                                </Table>
                            </Box>
                            <Box>
                                <Button
                                    disabled={disabled}
                                    _disabled={{ opacity: 0.75, cursor: 'not-allowed' }}
                                    bg='clientContractorTable.button.bg' color='clientContractorTable.button.color' mt='15px' pl='0px' pr='15px' fontSize='smm'
                                    borderRadius='10px'
                                    onClick={() => openClientContractorModal('add')}
                                    _hover={{
                                        background: 'clientContractorTable.button.hoverBg',
                                    }}
                                    _active={{
                                        background: 'clientContractorTable.button.activeBg',
                                    }}

                                >
                                    <AddIcon w='12.5px' h='12.5px' mr='05px' stroke='clientContractorTable.addIcon.stroke' />
                                    Add Row
                                </Button>
                            </Box >
                            {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
                        </Box >
                    </>
                );
            }}
        />
    );
};

export default ClientContractorTable;

export const clientContractorTableStyles = {
    clientContractorTable: {
        button: {
            bg: '#FFFFFF',
            color: '#DC0043',
            hoverBg: '#FFFFFF',
            activeBg: '#FFFFFF',
        },
        addIcon: {
            stroke: '#DC0043'
        }

    }
};