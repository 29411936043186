import { Tr, Td } from '@chakra-ui/react';

const PeerReviewTableRow = ({ data }) => {
  return (
    <Tr>
      <Td fontWeight='bold' fontSize={'14px'} paddingInline='0px' minW='80px'>
        {data.iso_standard}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['17065']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['17025']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['17020']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['17021']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['17024']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['9001']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['14001']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['27001']}
      </Td>
      <Td fontWeight='semi_medium' fontSize={'14px'} paddingInline='0px'>
        {data['45001']}
      </Td>
    </Tr>
  );
};

export default PeerReviewTableRow;

export const peerReviewTableRowStyles = {
  signatureTableRow: {
    td: {
      color: '#818197',
    },
    checkBox: {
      borderColor: 'rgba(129, 129, 151, 0.5)',
      colorScheme: 'gray',
    },
  },
};
