import React from 'react';

import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { IField } from '../../../interfaces/IField';
import { DefinedValidations } from '../../../interfaces/Validations';
import useValidate from '../../../hooks/useValidate';
import TableHeader from '../TableHeader';
import MinutesTableRow from './MinutesTableRow';


interface IMinutesTable extends IField {
    data?: any;
    description?: string;
    variant?: string;
    help?: string;
    styles?: {
        font?: string
    };
}

const definedValidations: DefinedValidations = {
    notEmpty: (label, validationValue, value) => {
        if (validationValue && !value) {
            return `Cannot be empty`;
        }
    },
};

const MinutesTable = ({ control, name, label, validations = {}, disabled = false, help = '', required, data, styles }: IMinutesTable) => {
    const validate = useValidate(label || name, validations || {}, definedValidations);
    return (
        <Controller
            name={name}
            control={control}
            rules={{ validate }}
            render={({ field, fieldState }) => {
                const { onChange, value } = field;
                const { error } = fieldState;
                const updateChangeMinutesValues = (event, index) => {
                    let updatedData = value ? [...value] : [...data]
                    let updatedMinuteTableRow = { ...updatedData[index], minutes: event.target.value }
                    updatedData[index] = updatedMinuteTableRow;
                    onChange({ target: { name, value: updatedData } });
                }

                const updateSecsPerItemValues = (event, index) => {
                    let updatedData = value ? [...value] : [...data]
                    let updatedMinuteTableRow = { ...updatedData[index], secsPerItem: event.target.value }
                    updatedData[index] = updatedMinuteTableRow;
                    onChange({ target: { name, value: updatedData } });
                }

                return (
                    <Box w='full' id={name} mt='none' maxW='700px'>
                        {label && (
                            <Flex pt='15px' pb={1} align='center' justify='space-between' mb='none'>
                                <Box
                                    color={error ? 'textInput.labelFont.error' : styles ? styles?.font : 'textInput.labelFont.normal'}
                                    fontWeight='bold'
                                    fontSize='md'
                                    position='static'
                                    left='none'
                                    zIndex={2}
                                >
                                    {label}
                                </Box>
                            </Flex>
                        )}
                        <Table fontSize='smm'>
                            <Thead>
                                <Tr>
                                    <TableHeader header='Integrity' styles={{ paddingInline: '0px', fontWeight: 'bold', fontSize: 'smm' }}></TableHeader>
                                    <TableHeader header='Minutes' styles={{ paddingInline: '0px' }}></TableHeader>
                                    <TableHeader header='Secs/Item' styles={{ paddingInline: '0px' }}></TableHeader>

                                </Tr>
                            </Thead>
                            <Tbody left='-10px'>
                                {value ? value?.map((dataValues, index) => <MinutesTableRow
                                    key={index}
                                    integrity={dataValues?.integrity}
                                    minutes={dataValues?.minutes} secsPerItem={dataValues?.secsPerItem}
                                    onChangeMinutes={(e) => updateChangeMinutesValues(e, index)}
                                    onChangeSecsPerItem={(e) => updateSecsPerItemValues(e, index)}
                                    disabled={disabled} />) : data?.map((dataValues, index) => <MinutesTableRow
                                        key={index}
                                        integrity={dataValues?.integrity}
                                        minutes={dataValues?.minutes} secsPerItem={dataValues?.secsPerItem}
                                        onChangeMinutes={(e) => updateChangeMinutesValues(e, index)}
                                        onChangeSecsPerItem={(e) => updateSecsPerItemValues(e, index)}
                                        disabled={disabled} />)}
                            </Tbody>
                        </Table>

                        {error && <Box fontSize='ssm' ml={1} color='textInput.error'>{error.message}</Box>}
                    </Box >
                );
            }}
        />
    );
};

export default MinutesTable;

export const minutesTableStyles = {
    minutesTable: {

    }
};
