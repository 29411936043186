import { gql, useQuery } from "@apollo/client";
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const GET_ACRONYM = gql`
  query {
    acronyms{
      _id
      data
    }
  }
`;

interface Acryonms {
  [name: string]: string
}

const GlossaryModal = ({ isOpen, onClose }) => {
  const [acronyms, setAcronyms] = useState<Acryonms>({});
  const { data } = useQuery(GET_ACRONYM);

  useEffect(() => {
    if (data) {
      const acronyms = Object.keys(data.acronyms.data).sort().reduce((accumulator, key) => {
        accumulator[key] = data.acronyms.data[key];
        return accumulator;
      }, {});
      setAcronyms(acronyms)
    } else {
      setAcronyms({});
    }
  }, [data]);


  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent rounded="20px" w="430px" pb="20px" h="600px">
        <ModalHeader color="markAsCompleteModal.headerColor" fontSize="smm">Glossary</ModalHeader>
        <ModalCloseButton onTouchEnd={onClose} />
        <ModalBody overflow="hidden" pt={0}>
          <Text fontSize="14px" pb={2}>These acronyms will be replaced on the created PDF documents, note they are case sensitive.</Text>
          <Flex h="calc(100% - 45px)" overflow="auto" flexDirection="column">{Object.entries(acronyms).map(([name, value]) => <Flex key={name} borderBottomWidth={1} py={1} color="breNavy"><Flex w="25%" justify="center" pr={3} fontWeight="bold" >{name.replace(/ /g, "")}</Flex><Flex w="75%" justify="flex-start">{value}</Flex></Flex>)}</Flex>
        </ModalBody>
      </ModalContent>
    </Modal >
  );

}

export default GlossaryModal